import { createSelector } from 'reselect';

const analytics = state => state.get('analytics');

const selectAnalyticsApiCount = () => createSelector(analytics, state => state.toJS().count);
const selectDataAtGlance = () => createSelector(analytics, state => state.toJS().dataAtGlance);
const selectDataAtGlanceFetched = () => createSelector(analytics, state => state.toJS().dataAtGlanceFetched);
const selectDifferentialData = () => createSelector(analytics, state => state.toJS().differentialData);
const selectDifferentialDataFetched = () => createSelector(analytics, state => state.toJS().differentialDataFetched);
const selectRankingData = () => createSelector(analytics, state => state.toJS().rankingData);
const selectRankingFetched = () => createSelector(analytics, state => state.toJS().rankingFetched);
const selectAnalyticsPerCategory = () => createSelector(analytics, state => state.toJS().analyticsPerCategoryData);
const selectFlagpoleData = () => createSelector(analytics, state => state.toJS().flagpoleData);
const selectFlagpoleFetched = () => createSelector(analytics, state => state.toJS().flagpoleFetched);
const selectOutcomeData = () => createSelector(analytics, state => state.toJS().scorecardData);
const selectScorecardDataFetched = () => createSelector(analytics, state => state.toJS().scorecardDataFetched);
const selectFilters = () => createSelector(analytics, state => state.toJS().filters);
const selectFiltersFetched = () => createSelector(analytics, state => state.toJS().filtersFetched);
const selectFiltersApplied = () => createSelector(analytics, state => state.toJS().filtersApplied);
const selectScorecardDropdown = () => createSelector(analytics, state => state.toJS().scorecardDropdown);
const selectFilterParam = () => createSelector(analytics, state => state.toJS().filterParam);
const selectScorecardError = () => createSelector(analytics, state => state.toJS().scorecardError);
const selectFlagpoleError = () => createSelector(analytics, state => state.toJS().flagpoleError);
const selectDifferentialError = () => createSelector(analytics, state => state.toJS().differentialError);
const selectRankingError = () => createSelector(analytics, state => state.toJS().rankingError);
const selectFiltersMapping = () => createSelector(analytics, state => state.toJS().filtersMapping);
const selectFiltersMappingFetched = () => createSelector(analytics, state => state.toJS().filtersMappingFetched);
const selectDefaultFilters = () => createSelector(analytics, state => state.toJS().defaultFilters);
const selectDefaultYear = () => createSelector(analytics, state => state.toJS().defaultYear);

export {
    selectAnalyticsApiCount,
    selectDataAtGlance,
    selectFilters,
    selectFiltersFetched,
    selectFiltersApplied,
    selectDataAtGlanceFetched,
    selectDifferentialData,
    selectDifferentialDataFetched,
    selectRankingData,
    selectRankingFetched,
    selectAnalyticsPerCategory,
    selectOutcomeData,
    selectScorecardDataFetched,
    selectFlagpoleData,
    selectFlagpoleFetched,
    selectScorecardDropdown,
    selectFilterParam,
    selectScorecardError,
    selectRankingError,
    selectFlagpoleError,
    selectDifferentialError,
    selectFiltersMapping,
    selectFiltersMappingFetched,
    selectDefaultFilters,
    selectDefaultYear,
};
