import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ArrowUpward, ArrowBack } from '@material-ui/icons';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import FilterSvg from '../assets/Filter.svg';
import DownloadIcon from '../assets/Download.svg';
import LeftBu from '../assets/LeftBu.svg';
import RightBuActive from '../assets/RightBuActive.svg';
import { getStaticText } from '../../../utils/constants';
import { getPptValue, getBenchmarkName, getPptValues } from '../../../utils/functions';
import getStaticTextDiagnose from '../constants';
import colors from '../../../sass/colors';
import ErrorToast from '../../../components/InformationToast/ErrorToast';

function BUReport({
    setReport,
    scoreData,
    errorInfo,
    updateFilterView,
    activeResetFilter,
    currentTheme,
    getPptData,
    benchmarks,
    defaultSettings,
    isPercentile,
    appliedFiltersInfo,
}) {
    const {
        quartileColors,
        report_name = '',
        report_name_or_client_name: rName = '',
        template_name: template_file_name,
        lang,
        survey_version,
        report_type = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { PROFILE_TILE_KEYS } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const { $white, $black } = colors;
    const {
        BU_HEAD,
        SCORECARD,
        FILTERS,
        BENCHMARK_HEAD,
        DOWNLOAD,
        DIFF_HEAD,
        PPT_RES_TEXT,
        PPT_RES_TEXT2,
        PPT_RES_TEXT3,
        BU_HEAD_TITLE,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        COMPANY_HEAD,
        ALL_PPT_TEXT,
        SIGNIFICANCE,
        PPT_VALUES,
        SIGNIFICANCES,
        HEALTH_BU_BOARD,
        PERCENT_FAVORABLE,
        PERCENTILES,
    } = SITE_TEXT || {};
    const { records = {}, sizes = {} } = scoreData;
    const { nSize = [0], n_respondents, n_survey } = sizes;
    const { ohi_score = {}, ...dataToIterate } = records;
    const {
        score: ohiScore = [],
        difference: ohiDiff = [],
        quartile: ohiQuartile = [],
        significance: ohiSign = [],
    } = ohi_score;
    const headerContent = [...ohiScore, ohiDiff[1]];
    const { pptAccessToken = '', buName = '' } = AuthStore;

    const surveyV = survey_version === '4' || survey_version === '3_2';

    const getPpt = (outcomeArray = [], prtArray = []) => {
        const { GLOBAL_VALS, practice_profile_bu_checkme } = PPT_VALUES({ rName, buName });
        const { Title_text } = practice_profile_bu_checkme;
        const label = getBenchmarkName(benchmarks);
        const pptArray = [
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${nSize[0]}); ${buName} (${NUMBER_TEXT}=${nSize[1]}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            ...getPptValues(GLOBAL_VALS),
        ];
        const data = [
            ...pptArray,
            {
                name: 'Title_text',
                value: `${rName} ${ALL_PPT_TEXT[7]} ${buName}`,
            },
            {
                name: 'report_title',
                value: `${rName} ${ALL_PPT_TEXT[8]}`,
            },
            {
                name: 'comparison_title',
                value: `${buName} ${ALL_PPT_TEXT[8]}`,
            },
        ];
        ohiScore.forEach((value, ohiIndex) => {
            const preName = !ohiIndex ? 'overall' : 'overallBU';
            const arrowName = !ohiIndex ? 'RightArrow' : 'RightArrowBU';
            const { background: bgcolor, border: border_color, top_position } =
                quartileColors[ohiQuartile[ohiIndex]] || {};
            data.push(
                {
                    name: `${preName}_bg`,
                    bgcolor,
                    border_color,
                },
                {
                    name: `${preName}_value`,
                    text_color: bgcolor,
                    value,
                },
                {
                    name: arrowName,
                    top_position,
                    bgcolor,
                }
            );
        });

        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'profile_bu_checkme',
                    data,
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'outcome_profile_bu_checkme',
                    data: [
                        ...outcomeArray,
                        ...pptArray,
                        {
                            name: 'Title_text',
                            value: `${rName} ${ALL_PPT_TEXT[9]} ${buName}`,
                        },
                        {
                            name: 'report_title',
                            value: `${rName} ${ALL_PPT_TEXT[10]}`,
                        },
                        {
                            name: 'comparison_title',
                            value: `${buName} ${ALL_PPT_TEXT[10]}`,
                        },
                        {
                            name: '3. Subtitle',
                            value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT,
                        },
                    ],
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'practice_profile_bu_checkme',
                    data: [
                        ...prtArray,
                        ...pptArray,
                        {
                            name: 'Title_text',
                            value: Title_text,
                        },
                        {
                            name: 'report_title',
                            value: COMPANY_HEAD,
                        },
                        {
                            name: 'comparison_title',
                            value: buName,
                        },
                        {
                            name: 'report_size',
                            value: nSize[0],
                        },
                        {
                            name: 'comparison_size',
                            value: nSize[1],
                        },
                        {
                            name: '3. Subtitle',
                            value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT2,
                        },
                    ],
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} BU Data`);
    };

    const getDataForPpt = () => {
        const outcomeArray = [];
        const prtArray = [];

        Object.keys(dataToIterate).forEach(innerContent => {
            const valueInRecord = records[innerContent];
            const { children, score, display_name, quartile } = valueInRecord;
            prtArray.push({
                name: `${innerContent}_text`,
                value: display_name,
            });
            score.forEach((parentSc, parentInd) => {
                const valueParent = {
                    score: parentSc,
                    display_name,
                    quartile: quartile[parentInd],
                };
                const valueKeyParent = !parentInd ? innerContent : `${innerContent}BU`;
                const parentArray = getPptValue(quartileColors, valueParent, valueKeyParent, true, true);
                outcomeArray.push(...parentArray);
            });
            Object.keys(children).forEach(childVal => {
                const valueInChild = children[childVal];
                const { score: childScoreArr, display_name: child_DP, quartile: childQT } = valueInChild;
                childScoreArr.forEach((parentSc, parentInd) => {
                    const valueParent = {
                        score: parentSc,
                        display_name: child_DP,
                        quartile: childQT[parentInd],
                    };
                    const valueKeyChild = !parentInd ? childVal : `${childVal}BU`;
                    const childArray = getPptValue(quartileColors, valueParent, valueKeyChild, false, true);
                    prtArray.push(...childArray);
                });
            });
        });
        getPpt(outcomeArray, prtArray);
    };

    return (
        <div className="scoreBoard reSurveyReportBoard clearfix" data-testid="buBoardContent">
            <Helmet>
                <title>{HEALTH_BU_BOARD}</title>
            </Helmet>
            <div className="breadCrumb">
                <span data-testid="setScoreFromBuBtn" onClick={() => setReport(0)}>
                    {SCORECARD}
                </span>{' '}
                / {BU_HEAD}
            </div>
            <h2 className="clearfix">
                <span data-testid="setBackFromBuBtn" onClick={() => setReport(0)}>
                    <ArrowBack />
                    {BU_HEAD}
                </span>

                <ul className="rightSideNav">
                    <li>
                        <span data-testid="setBuDetailReportBtn" onClick={() => setReport(4)}>
                            <img src={LeftBu} alt="" />
                        </span>
                        <span className="active">
                            <img src={RightBuActive} alt="" />
                        </span>
                    </li>
                    {!errorInfo &&
                        (!isOhi4 ? (
                            <li data-testid="getPptButton" onClick={getDataForPpt}>
                                <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                            </li>
                        ) : null)}
                    <li data-testid="buBoardFilterBtn" onClick={() => updateFilterView(true)}>
                        <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                        {FILTERS}
                    </li>
                </ul>
            </h2>
            {errorInfo && <ErrorToast message={errorInfo} />}
            {Object.keys(scoreData).length ? (
                <Fragment>
                    {appliedFiltersInfo()}
                    <ul className="headContent buHead">
                        {headerContent.map((headData, indexHead) => {
                            const { background = $white, color = $black, border: borderColor = $white } =
                                quartileColors[ohiQuartile[indexHead]] || {};
                            const { bg: secondBg = $white } = surveyV
                                ? SIGNIFICANCES[ohiSign[1]]
                                : SIGNIFICANCE[ohiSign[1]] || {};
                            const headBg = indexHead === 2 ? secondBg : 'none';

                            return (
                                <li
                                    style={{
                                        background,
                                        color,
                                        borderColor,
                                    }}
                                    key={indexHead}
                                >
                                    <h3 style={{ background: headBg }}>{headData}</h3>
                                    {BU_HEAD_TITLE[indexHead]}
                                </li>
                            );
                        })}
                    </ul>
                    <section className="scoreData BuData clearfix">
                        {PROFILE_TILE_KEYS.map(dataSet => {
                            const {
                                display_name = '',
                                score = [],
                                difference = [],
                                significance = [],
                                children = {},
                                quartile = [],
                            } = dataToIterate[dataSet];
                            const diff = difference[1];
                            const { bg = $white } = surveyV
                                ? SIGNIFICANCES[significance[1]]
                                : SIGNIFICANCE[significance[1]] || {};
                            return (
                                <div key={dataSet} className="scoreTile clearfix">
                                    <h4 className={c('ellipsisPro', { active: currentTheme.includes(dataSet) })}>
                                        {score.map((scoreCont, indexScore) => {
                                            const {
                                                background = $white,
                                                color = $black,
                                                border: borderColor = $white,
                                            } = quartileColors[quartile[indexScore]] || {};
                                            return (
                                                <span key={indexScore} style={{ background, color, borderColor }}>
                                                    {scoreCont}
                                                </span>
                                            );
                                        })}
                                        <span style={{ color: bg }} className={c('difference', { negative: diff < 0 })}>
                                            {diff}
                                            {diff !== 0 && <ArrowUpward />}
                                        </span>
                                        {display_name}
                                    </h4>
                                    <ul>
                                        {Object.keys(children).map(childSet => {
                                            const {
                                                display_name: childName = '',
                                                score: childScore = [],
                                                difference: childDiff = [],
                                                significance: childSign = [],
                                                quartile: childQuat = [],
                                            } = children[childSet];
                                            const diffChild = childDiff[1];
                                            const { bg: cg1 = $white } = surveyV
                                                ? SIGNIFICANCES[childSign[1]]
                                                : SIGNIFICANCE[childSign[1]] || {};
                                            return (
                                                <li
                                                    data-testid={childSet}
                                                    className={c('ellipsisPro', {
                                                        active: currentTheme.includes(childSet),
                                                    })}
                                                    key={childName}
                                                >
                                                    {childName}
                                                    {childScore.map((childScoreData, csIn) => {
                                                        const {
                                                            background = $white,
                                                            color = $black,
                                                            border: borderColor = $white,
                                                        } = quartileColors[childQuat[csIn]] || {};
                                                        return (
                                                            <span style={{ background, color, borderColor }} key={csIn}>
                                                                {childScoreData}
                                                            </span>
                                                        );
                                                    })}
                                                    <span
                                                        style={{ color: cg1 }}
                                                        className={c('difference', { negative: diffChild < 0 })}
                                                    >
                                                        {diffChild}
                                                        {diffChild !== 0 && <ArrowUpward />}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </section>
                    <ul className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors.map(({ title, background, border: borderColor }) => (
                            <li key={title} style={{ color: background }}>
                                <span style={{ background, borderColor }} />
                                {title}
                            </li>
                        ))}
                    </ul>
                    {surveyV ? (
                        <div>
                            <ul className="bottomList clearfix">
                                <li>{DIFF_HEAD} : </li>
                                {SIGNIFICANCES.map(({ title, bg: background }) => (
                                    <li key={title}>
                                        <span style={{ background }} />
                                        {title}
                                    </li>
                                ))}
                            </ul>
                            {report_type === 'percent favorable' && (
                                <div>
                                    <b>Note : </b> {PERCENT_FAVORABLE}
                                </div>
                            )}
                            {report_type === 'percentile' && (
                                <div>
                                    <b>Note : </b> {PERCENTILES}
                                </div>
                            )}
                        </div>
                    ) : (
                        <ul className="bottomList clearfix">
                            <li>{DIFF_HEAD} : </li>
                            {SIGNIFICANCE.map(({ title, bg: background }) => (
                                <li key={title}>
                                    <span style={{ background }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    )}
                </Fragment>
            ) : null}
        </div>
    );
}

BUReport.propTypes = {
    isPercentile: PropTypes.bool.isRequired,
    currentTheme: PropTypes.string.isRequired,
    errorInfo: PropTypes.string.isRequired,
    scoreData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    benchmarks: PropTypes.array.isRequired,
    setReport: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    updateFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
};

export default BUReport;
