import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import BlankPage from '../BlankPage/BlankPage';
import QuickHelp from '../QuickHelp/QuickHelp';

function PracticeRanking({ defaultSettings, getRanking, rankingFetched, rankingData, filterParams, rankingError }) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { TOP_RANKING, RANK, PRACTICES, MIDDLE_RANKING, BOTTOM_RANKING, GSOD_RANKING } = SITE_TEXT || {};
    const [helpView, helpMode] = useState(false);
    const { records: mainData = [] } = rankingData || {};

    useEffect(() => {
        if (!rankingFetched) {
            getRanking(filterParams);
        }
        // eslint-disable-next-line
    }, [rankingFetched]);

    const renderRow = ({ index, display_name, topRanking, bottomRanking }) => {
        const textColor = bottomRanking ? '#D51F31' : 'initial';
        const textColorToUse = topRanking ? '#0679C3' : textColor;
        return (
            <div
                className="tableRow"
                key={index}
                style={{
                    color: textColorToUse,
                }}
            >
                <span
                    className="recipe"
                    style={{
                        color: textColorToUse,
                    }}
                >
                    <div
                        className="circle"
                        style={{
                            borderRadius: topRanking ? '50%' : 'initial',
                            background: topRanking ? '#F2F2F2' : 'initial',
                            fontWeight: topRanking ? '500' : 'initial',
                        }}
                    >
                        {index + 1}
                    </div>
                </span>
                <span
                    className="score "
                    style={{
                        fontWeight: topRanking ? '500' : 'initial',
                    }}
                >
                    {display_name}
                </span>
            </div>
        );
    };

    return mainData.length ? (
        <Fragment>
            <section className="isV4">
                <div style={{ display: 'flex', justifyContent: 'space-between' }} className="leftHead leftSection">
                    <div className="tableWrapper">
                        <div className="rank-head">
                            <div className="topRanking">{TOP_RANKING}</div>
                        </div>
                        <div className="PracticeRankTable">
                            <div className="tableHeading">
                                <span className="recipe "> {RANK}</span>
                                <span className="score "> {PRACTICES}</span>
                            </div>

                            {mainData.slice(0, 12).map(({ display_name }, leftIndex) => {
                                return renderRow({
                                    index: leftIndex,
                                    display_name,
                                    topRanking: leftIndex < 12,
                                });
                            })}
                        </div>
                    </div>
                    <div className="tableWrapper">
                        <div className="rank-head">
                            <div className="bottomRanking">{MIDDLE_RANKING}</div>
                        </div>
                        <div className="PracticeRankTable">
                            <div className="tableHeading">
                                <span className="recipe ">{RANK}</span>
                                <div className="score ">{PRACTICES}</div>
                            </div>
                            {mainData.slice(12, mainData.length - 12).map(({ display_name }, leftIndex) => {
                                return renderRow({
                                    index: leftIndex + 12,
                                    display_name,
                                    topRanking: false,
                                    bottomRanking: false,
                                });
                            })}
                        </div>
                    </div>
                    <div className="tableWrapper">
                        <div className="rank-head">
                            <div className="bottomRanking">{BOTTOM_RANKING}</div>
                        </div>
                        <div className="PracticeRankTable">
                            <div className="tableHeading">
                                <span className="recipe ">{RANK}</span>
                                <div className="score ">{PRACTICES}</div>
                            </div>
                            {mainData.slice(mainData.length - 12).map(({ display_name }, leftIndex) => {
                                return renderRow({
                                    index: mainData.length - 12 + leftIndex,
                                    display_name,
                                    topRanking: false,
                                    bottomRanking: true,
                                });
                            })}
                        </div>
                    </div>
                </div>
                <div className="rankingInfo">
                    <div className="sub-head">{GSOD_RANKING}:</div>
                    <div className="topRank" /> {TOP_RANKING}
                    <div className="bottomRank" /> {BOTTOM_RANKING}
                </div>
            </section>
            <QuickHelp helpMode={helpMode} helpView={helpView} activePage="4" />
        </Fragment>
    ) : (
        <section className="sectionWrapper">
            <BlankPage error={rankingError} />
        </section>
    );
}

PracticeRanking.propTypes = {
    rankingData: PropTypes.object.isRequired,
    rankingFetched: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    getRanking: PropTypes.func.isRequired,
    filterParams: PropTypes.string.isRequired,
    rankingError: PropTypes.array.isRequired,
};

export default PracticeRanking;
