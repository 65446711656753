import React, { useState, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Tooltip } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import {
    getGapColor,
    sortChildren,
    removeUnderscore,
    getInclusionStyle,
    quartileTitle,
    getFiltersAppliedText,
} from '../../../utils/functions';
import { getStaticText } from '../../../utils/constants';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import ActiveFilter from '../../Diagnose/assets/ActiveFilter.svg';
import Loader from '../../../assets/Loader_Blue.svg';
import Individual from '../assets/Individual.svg';
import Enterprise from '../assets/Enterprise.svg';
import Edit from '../../Reports/assets/Edit.svg';
import CircleCheck from '../../Reports/assets/CircleCheck.svg';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';

function Overview({
    activeFilter,
    toggleFilterView,
    inclusionqbyq,
    allGapData,
    respondent,
    updateRespondents,
    comments,
    updateComments,
    updateIndex,
    scoreColor,
    iaApiParams,
    demographics,
    filters,
    benchmarkName,
    benchmarkVersions,
    bmVersionInUse,
    isPulse,
    allGapDataFetched,
}) {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        FILTERS,
        OVERVIEW_TEXT,
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        SURVEY_RES,
        PRCT_LARGE_GAP,
        DEMOGRAPHICS_LARGE_GAP,
        SCORECARD,
        PERSONAL_EXP,
        ENT_PERCEPTION,
        TOP,
        BOTTOM,
        THREE,
        PRACTICES,
        BY_SCR,
        VIEW_RES,
        VIEW_SCORE,
        VIEW_PRC_RANKING,
        VIEW_PRC,
        VIEW_DEMOGRAPHICS,
        INVITED_TEXT,
        RES_TO_TEXT,
        COMMENTS_TEXT,
        TOTAL_RES_TEXT,
        FILTERS_APPLIED_TEXT,
        ACROSS_DEMO,
        ON_SURVEY,
        FILTER_HOVER_TXT,
        FILTERS_APPLIED,
        BENCHMARKS_APPLIED,
        NUMBER_CAP_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        NONE,
        INCLUSION_OVERVIEW,
    } = SITE_TEXT;
    const { qbyq = {}, message = '' } = inclusionqbyq;
    const { records = {}, level_scores = {}, size = 0 } = qbyq;
    const { score: scoreStyles = [], gap: gapStyles = [], styles = [], benchmarkStyles = [] } = scoreColor;
    const { with_benchmark: withBenchmark = false } = iaApiParams;
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;
    const percentage = Math.ceil((size / respondent) * 100);
    const checkLength = Object.keys(qbyq).length;
    const { gap = {}, levels_gap = {} } = allGapData;
    const levelMeta = {
        individual: {
            text: PERSONAL_EXP,
            imgSrc: Enterprise,
        },
        enterprise: {
            text: ENT_PERCEPTION,
            imgSrc: Individual,
        },
    };
    const [isDisabled, setIsDisabled] = useState(true);
    const [isCommentsDisabled, setCommentsDisabled] = useState(true);
    const practices = useMemo(() => {
        let prctChild = {};
        Object.keys(records).forEach(mainKey => {
            const { children = {} } = records[mainKey];
            Object.keys(children).forEach(child => {
                prctChild = {
                    ...prctChild,
                    [child]: {
                        ...children[child],
                        mainKey,
                    },
                };
            });
        });
        return sortChildren(prctChild, 'score', -1);
    }, [records]);

    const filteredPractice = isPulse ? practices.filter(({ score }) => score !== 0) : practices;
    const selectedBm = benchmarkVersions.filter(
        ({ BenchmarkPreCalculationSubVersionId }) => BenchmarkPreCalculationSubVersionId === bmVersionInUse
    )[0];
    const { BenchmarkPreCalculationSubVersionName: bmName = '', SurveyCount = '', RespondentCount = '' } =
        selectedBm || {};
    const benchmarkText = `${NUMBER_CAP_TEXT} = ${RespondentCount} | ${NUMBER_CAP_SURVEY_TEXT} : ${SurveyCount} | ${bmName}`;

    const { gapPractices = [], gapDemographics = [] } = useMemo(() => {
        if (!Object.keys(gap).length || message) {
            return {};
        }
        let mainGap = {};
        let demoGap = {};
        let outcomeGap = {};
        Object.keys(records)
            .filter(k => records[k].level !== '-')
            .forEach(innerKey => {
                mainGap = {
                    ...mainGap,
                    [innerKey]: gap[innerKey],
                };
            });

        Object.keys(mainGap).forEach(parent => {
            demoGap = {
                ...demoGap,
                ...mainGap[parent].children,
            };
        });
        Object.keys(gap).forEach(parentKey => {
            if (Object.keys(gap[parentKey].children).length) {
                outcomeGap = { ...outcomeGap, [parentKey]: gap[parentKey] };
            }
        });

        const sortedGapOutcome = sortChildren(mainGap, 'gap', -1);
        let gapChild = {};
        sortedGapOutcome.forEach(({ children }) => {
            gapChild = { ...gapChild, ...children };
        });
        const sortedPractices = sortChildren(gapChild, 'gap', -1).filter(({ gap: gapV }) => gapV !== '-');
        demoGap = {
            ...demoGap,
            ...outcomeGap,
        };
        const sortedDemographic = sortChildren(demoGap, 'gap', -1)
            .filter(({ gap: gapV }) => gapV !== '-')
            .sort(({ gap: a }, { gap: b }) => b - a);
        return {
            gapPractices: sortedPractices.slice(0, 3),
            gapDemographics: sortedDemographic.slice(0, 3),
        };
        // eslint-disable-next-line
    }, [gap]);

    return (
        <div className="questionBoard overviewInclusion clearfix">
            <Helmet>
                <title>{INCLUSION_OVERVIEW}</title>
            </Helmet>
            <div className="underline">
                <div className="optionWrapper">
                    <div>
                        <h2>{OVERVIEW_TEXT}</h2>
                    </div>
                    <div>
                        <ul className="rightNav">
                            {checkLength ? (
                                <li className="respondents">
                                    {TOTAL_RES_TEXT}
                                    {size}
                                </li>
                            ) : null}
                            <Tooltip arrow title={FILTER_HOVER_TXT}>
                                <li
                                    className="separator"
                                    onClick={() => toggleFilterView(true)}
                                    data-testid="filterButton"
                                >
                                    <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" /> <span>{FILTERS}</span>
                                </li>
                            </Tooltip>
                        </ul>
                    </div>
                </div>
            </div>
            {message ? <ErrorToast message={message} /> : null}
            {checkLength ? (
                <div className="content">
                    <div>
                        <div className="legendContent tile">
                            <div>
                                <div>
                                    <ul className="classification">
                                        <li>{SCORE_CLASSIFICATION_TEXT}</li>
                                        {styleToUse
                                            .filter(
                                                ({ quartile, style_for }) =>
                                                    quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                            )
                                            .map(({ title, bgcolor }) => (
                                                <li key={title}>
                                                    <span className="circle" style={{ background: bgcolor }} />
                                                    {quartileTitle(withBenchmark, title)}
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                                <div>
                                    <ul className="classification">
                                        <li>{GAP_CLASSIFICATION_TEXT} </li>
                                        {gapStyles.map(({ title, bgcolor }) => (
                                            <li key={title}>
                                                <span style={{ background: bgcolor }} />
                                                {title}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <section className="upperSection clearfix">
                            <div className="clearfix">
                                <section className="clearfix">
                                    <div className="boxContent mainInfo">
                                        <h2>
                                            {SURVEY_RES}
                                            <span data-testid="resSpan" onClick={() => updateIndex(4)}>
                                                {VIEW_RES}
                                            </span>
                                        </h2>

                                        <ul>
                                            <li className="clearfix">
                                                <span>
                                                    {INVITED_TEXT}
                                                    <span className="small">({ACROSS_DEMO})</span>
                                                </span>
                                                <span>
                                                    <input
                                                        data-testid="respondentInput"
                                                        className={c({ activeInput: !isDisabled })}
                                                        onChange={({ target: { value } }) =>
                                                            updateRespondents(parseInt(value || 1, 10))
                                                        }
                                                        disabled={isDisabled}
                                                        type="number"
                                                        value={respondent}
                                                    />
                                                    {isDisabled ? (
                                                        <img
                                                            data-testid="resImgActive"
                                                            onClick={() => setIsDisabled(false)}
                                                            src={Edit}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <img
                                                            data-testid="resImgInActive"
                                                            onClick={() => setIsDisabled(true)}
                                                            src={CircleCheck}
                                                            alt=""
                                                        />
                                                    )}
                                                </span>
                                            </li>

                                            <li className="clearfix">
                                                <span>
                                                    {RES_TO_TEXT}
                                                    <span className="small">({FILTERS_APPLIED_TEXT})</span>
                                                </span>
                                                <span>
                                                    {size} <i>({percentage}%)</i>
                                                </span>
                                            </li>
                                            <li className="clearfix">
                                                <span>
                                                    {COMMENTS_TEXT} {ON_SURVEY}
                                                </span>
                                                <span>
                                                    <input
                                                        data-testid="commentsInput"
                                                        className={c({ activeInput: !isCommentsDisabled })}
                                                        onChange={({ target: { value } }) =>
                                                            updateComments(parseInt(value || 1, 10))
                                                        }
                                                        disabled={isCommentsDisabled}
                                                        type="number"
                                                        value={comments}
                                                    />
                                                    {isCommentsDisabled ? (
                                                        <img
                                                            data-testid="commentsActive"
                                                            onClick={() => setCommentsDisabled(false)}
                                                            src={Edit}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <img
                                                            data-testid="commentsInactive"
                                                            onClick={() => setCommentsDisabled(true)}
                                                            src={CircleCheck}
                                                            alt=""
                                                        />
                                                    )}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="scoreCard boxContent">
                                        <h2>
                                            {SCORECARD}
                                            <span data-testid="scoreSpan" onClick={() => updateIndex(1)}>
                                                {VIEW_SCORE}
                                            </span>
                                        </h2>
                                        <ul>
                                            {Object.keys(level_scores)
                                                .filter(levelName => levelName !== 'overall')
                                                .reverse()
                                                .map(levelInfo => {
                                                    const {
                                                        display_score: score = '',
                                                        inclusion_quartile = -1,
                                                        quartile = -1,
                                                    } = level_scores[levelInfo];
                                                    const { text, imgSrc } = levelMeta[levelInfo];
                                                    const { gap: gapV = '' } = levels_gap[levelInfo] || {};
                                                    const {
                                                        bgcolor = '',
                                                        text_color = '',
                                                        border_color: borderColor = '',
                                                    } = getInclusionStyle(
                                                        inclusion_quartile,
                                                        quartile,
                                                        benchmarkStyles,
                                                        styles
                                                    ) || {
                                                        bgcolor: '#ffffff',
                                                        border_color: '#00000',
                                                        text_color: '#ffffff',
                                                    };
                                                    const {
                                                        bgcolor: gapBgColor = '',
                                                        text_color: textBgColor = '',
                                                        border_color: gapBorderColor = '',
                                                    } = getGapColor(gapV, gapStyles);
                                                    return (
                                                        <li key={levelInfo} className="clearfix">
                                                            <img src={imgSrc} alt="" />
                                                            {text}
                                                            <div
                                                                style={{
                                                                    background: bgcolor,
                                                                    border: `0.5px solid ${borderColor}`,
                                                                }}
                                                                className="circle"
                                                            >
                                                                <div style={{ color: text_color }}>{score}</div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    background: gapBgColor,
                                                                    border: `0.5px solid ${gapBorderColor}`,
                                                                }}
                                                                className="rectangle"
                                                            >
                                                                <div style={{ color: textBgColor }}>
                                                                    {!allGapDataFetched ? (
                                                                        <div className="spinLoader">
                                                                            <img src={Loader} alt="" />
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ color: textBgColor }}>{gapV}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </div>
                                </section>

                                <section className="clearfix">
                                    <div className="boxContent">
                                        <h2>
                                            {PRCT_LARGE_GAP}
                                            <span data-testid="prcSpan" onClick={() => updateIndex(3)}>
                                                {VIEW_PRC}
                                            </span>
                                        </h2>
                                        <ul>
                                            {gapPractices.length ? (
                                                gapPractices.map(({ gap: gapV = '', key }) => {
                                                    const {
                                                        bgcolor: gapBgColor = '',
                                                        text_color: textBgColor = '',
                                                    } = getGapColor(gapV, gapStyles);
                                                    return (
                                                        <li key={key} className="clearfix">
                                                            {removeUnderscore(key)}
                                                            <div
                                                                style={{ background: gapBgColor }}
                                                                className="rectangle"
                                                            >
                                                                <div style={{ color: textBgColor }}>{gapV}</div>
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            ) : (
                                                <Fragment>
                                                    {!allGapDataFetched ? (
                                                        <div className="loaderContainer">
                                                            <div className="spinLoader">
                                                                <img src={Loader} alt="" />
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </Fragment>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="boxContent">
                                        <h2>
                                            {DEMOGRAPHICS_LARGE_GAP}
                                            <span data-testid="outcomeSpan" onClick={() => updateIndex(3)}>
                                                {VIEW_DEMOGRAPHICS}
                                            </span>
                                        </h2>
                                        <ul>
                                            {allGapDataFetched ? (
                                                gapDemographics.map(({ gap: gapV = '', key, demographic = '' }) => {
                                                    const {
                                                        bgcolor: gapBgColor = '',
                                                        text_color: textBgColor = '',
                                                    } = getGapColor(gapV, gapStyles);
                                                    let label = '';
                                                    const demo = demographics.filter(
                                                        ({ code }) => code === demographic
                                                    );
                                                    if (demo.length) {
                                                        const { label: demoLabel = '' } = demo[0];
                                                        label = demoLabel;
                                                    }
                                                    const demoGraphic = label.length
                                                        ? label.slice(0, 40) + '...'
                                                        : label;
                                                    return (
                                                        <Tooltip key={key} arrow title={label}>
                                                            <li className="clearfix">
                                                                {demoGraphic}{' '}
                                                                <span className="small">({removeUnderscore(key)})</span>
                                                                <div
                                                                    style={{ background: gapBgColor }}
                                                                    className="rectangle"
                                                                >
                                                                    <div style={{ color: textBgColor }}>{gapV}</div>
                                                                </div>
                                                            </li>
                                                        </Tooltip>
                                                    );
                                                })
                                            ) : (
                                                <div className="loaderContainer">
                                                    <div className="spinLoader">
                                                        <img src={Loader} alt="" />
                                                    </div>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                </section>
                            </div>

                            <div className="clearfix">
                                <h2>
                                    {TOP} {THREE} {PRACTICES} {BY_SCR}
                                    <span data-testid="prcRankingSpan" onClick={() => updateIndex(5)}>
                                        {VIEW_PRC_RANKING}
                                    </span>
                                </h2>
                                <ul>
                                    {practices
                                        .slice(0, 3)
                                        .map(
                                            ({
                                                display_name,
                                                key,
                                                score,
                                                mainKey,
                                                inclusion_quartile = -1,
                                                quartile = -1,
                                            }) => {
                                                let quart =
                                                    Number(quartile) > -1 ? Number(quartile) : inclusion_quartile;
                                                if (quart > 20) {
                                                    quart = benchmarkStyles.length - 1;
                                                }
                                                const { children = {} } = gap[mainKey] || {};
                                                const { gap: gapV = '' } = children[key] || {};
                                                const { bgcolor = '', text_color = '' } =
                                                    Number(quartile) > -1 ? benchmarkStyles[quart] : styles[quart];
                                                const {
                                                    bgcolor: gapBgColor = '',
                                                    text_color: textBgColor = '',
                                                } = getGapColor(gapV, gapStyles);
                                                return (
                                                    <li key={key} className="clearfix">
                                                        {display_name}
                                                        <div style={{ background: bgcolor }} className="circle">
                                                            <div style={{ color: text_color }}>{score}</div>
                                                        </div>
                                                        {allGapDataFetched ? (
                                                            <div
                                                                style={{ background: gapBgColor }}
                                                                className="rectangle"
                                                            >
                                                                <div style={{ color: textBgColor }}>{gapV}</div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{ background: gapBgColor }}
                                                                className="rectangle"
                                                            >
                                                                <div style={{ color: textBgColor }}>
                                                                    <div className="spinLoader">
                                                                        <img src={Loader} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </li>
                                                );
                                            }
                                        )}
                                </ul>
                                <h2>
                                    {BOTTOM} {THREE} {PRACTICES} {BY_SCR}
                                </h2>
                                <ul>
                                    {filteredPractice
                                        .slice(filteredPractice.length - 3)
                                        .map(
                                            ({
                                                display_name,
                                                key,
                                                score,
                                                mainKey,
                                                inclusion_quartile = -1,
                                                quartile = -1,
                                            }) => {
                                                const { children = {} } = gap[mainKey] || {};
                                                const { gap: gapV = '' } = children[key] || {};
                                                const { bgcolor = '', text_color = '' } = getInclusionStyle(
                                                    inclusion_quartile,
                                                    quartile,
                                                    benchmarkStyles,
                                                    styles
                                                );
                                                const {
                                                    bgcolor: gapBgColor = '',
                                                    text_color: textBgColor = '',
                                                } = getGapColor(gapV, gapStyles);
                                                return (
                                                    <li key={key} className="clearfix">
                                                        {display_name}
                                                        <div style={{ background: bgcolor }} className="circle">
                                                            <div style={{ color: text_color }}>{score}</div>
                                                        </div>
                                                        {allGapDataFetched ? (
                                                            <div
                                                                style={{ background: gapBgColor }}
                                                                className="rectangle"
                                                            >
                                                                <div style={{ color: textBgColor }}>{gapV}</div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{ background: gapBgColor }}
                                                                className="rectangle"
                                                            >
                                                                <div style={{ color: textBgColor }}>
                                                                    <div className="spinLoader">
                                                                        <img src={Loader} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </li>
                                                );
                                            }
                                        )}
                                </ul>
                            </div>
                        </section>
                    </div>
                    <div className="filterInfoSection">
                        <div>
                            <b>{FILTERS_APPLIED + ' : '}</b>
                            {getFiltersAppliedText(filters, demographics)
                                ? getFiltersAppliedText(filters, demographics)
                                : NONE}{' '}
                            | n = {size}
                        </div>
                        <div>
                            <b>{BENCHMARKS_APPLIED + ' : '}</b> {`${benchmarkName} | ${benchmarkText}`}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

Overview.defaultProps = {
    isPulse: false,
    benchmarkName: '',
    filters: [],
};

Overview.propTypes = {
    comments: PropTypes.number.isRequired,
    respondent: PropTypes.number.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    allGapData: PropTypes.object.isRequired,
    inclusionqbyq: PropTypes.object.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    updateRespondents: PropTypes.func.isRequired,
    updateComments: PropTypes.func.isRequired,
    updateIndex: PropTypes.func.isRequired,
    scoreColor: PropTypes.object.isRequired,
    iaApiParams: PropTypes.object.isRequired,
    filters: PropTypes.array,
    benchmarkName: PropTypes.string,
    demographics: PropTypes.array.isRequired,
    isPulse: PropTypes.bool,
    benchmarkVersions: PropTypes.array.isRequired,
    bmVersionInUse: PropTypes.number.isRequired,
    allGapDataFetched: PropTypes.bool.isRequired,
};

export default Overview;
