import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { cloneDeep } from 'lodash';

import FilterOptions from './FilterOptions';
import { getStaticText } from '../../utils/constants';
import '../FilterBar/index.scss';

function FilterContent({
    filters,
    activeApplyButton,
    filtersApplied,
    setDemoActive,
    updateFilterState,
    updateCurrentFilter,
    showHeader,
    resetFilters,
    activeProject,
    defaultSettings,
    defaultFilters,
    filtersMapping,
    updateFilters,
    isFiltersApplied,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { APPLY_FILTERS, RESET_FILTERS } = SITE_TEXT || {};
    const [activeFilter, updateView] = useState(null);
    const setBackOldView = (updateRedux = false) => {
        if (updateRedux) {
            updateFilterState(activeProject, [], true);
        }
        updateView(null);
    };
    const removeUnmappedDemo = filterObj => {
        const filterItem = cloneDeep(filterObj);
        const newFilterItem = [];
        let mappedItem = [];
        const filteredObj = {
            '2': [],
            '3': [],
            '4': [],
            '5': [],
            '6': [],
            '7': [],
            quartile: [],
        };
        const noneSelected = !filterItem
            .filter(({ filter_id }) => filter_id !== 'year')
            .filter(({ isSelected = false }) => isSelected).length;
        if (noneSelected) {
            return filters;
        }
        filterItem
            .filter(({ filter_id }) => filter_id !== 'year')
            .forEach(_item => {
                const { filter_items = [], isSelected = false, filter_id } = _item;
                if (isSelected) {
                    const selectedArr = [];
                    filter_items.forEach(({ filter_item_id, isSelected: selectedChild = false }) => {
                        if (selectedChild) {
                            selectedArr.push(filter_item_id);
                        }
                    });
                    mappedItem = filtersMapping.filter(({ [filter_id]: childkey }) =>
                        selectedArr.includes(parseInt(childkey, 10))
                    );
                }
            });
        mappedItem.forEach(item => {
            Object.keys(item).forEach(elem => {
                if (!filteredObj[elem].includes(item[elem])) {
                    filteredObj[elem].push(parseInt(item[elem], 10));
                }
            });
        });
        filterItem.forEach(_item => {
            const { filter_items = [], filter_id } = _item;
            if (filter_id === 'year') {
                newFilterItem.push(_item);
            } else {
                const childFilters = filter_items.filter(({ filter_item_id }) =>
                    filteredObj[filter_id].includes(filter_item_id)
                );
                newFilterItem.push({
                    ..._item,
                    filter_items: childFilters,
                });
            }
        });
        return newFilterItem;
    };

    useEffect(() => {
        const list = removeUnmappedDemo(filters);
        if (!activeProject) {
            updateFilters(filters);
        } else {
            updateFilters(list);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            {showHeader && !activeFilter && (
                <div className="projectTextContent">
                    <h3 className="projectName" onClick={() => updateCurrentFilter(null)}>
                        <ArrowBack />
                        {showHeader}
                    </h3>
                </div>
            )}
            {!isNaN(activeProject) && (
                <FilterOptions
                    defaultSettings={defaultSettings}
                    activeFilter={[filters[activeProject]]}
                    filters={filters}
                    setDemoActive={setDemoActive}
                    setBackOldView={setBackOldView}
                    updateFilters={newF => updateFilterState(activeProject, newF)}
                    showHeader={false}
                    activeProject={activeProject}
                    defaultFilters={defaultFilters}
                />
            )}
            {!isNaN(activeProject) && (
                <div className="buttonContent clearfix">
                    <Button
                        classes={{ root: c({ active: filtersApplied || isFiltersApplied }) }}
                        onClick={resetFilters}
                    >
                        {RESET_FILTERS}
                    </Button>
                    <Button classes={{ root: c({ active: activeApplyButton }) }} onClick={() => setBackOldView(true)}>
                        {APPLY_FILTERS}
                    </Button>
                </div>
            )}
        </Fragment>
    );
}

FilterContent.propTypes = {
    activeProject: PropTypes.number.isRequired,
    showHeader: PropTypes.string.isRequired,
    activeApplyButton: PropTypes.bool.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    updateFilterState: PropTypes.func.isRequired,
    setDemoActive: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    updateCurrentFilter: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    defaultFilters: PropTypes.array.isRequired,
    filtersMapping: PropTypes.array.isRequired,
    updateFilters: PropTypes.func.isRequired,
    isFiltersApplied: PropTypes.bool.isRequired,
};

export default FilterContent;
