import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MainBoard from './MainBoard';

import {
    getScoreData,
    getProjectsData,
    getReSurveyData,
    getDemographics,
    getBenchmarks,
    updateFilters,
    resetReSurveyDataFetched,
    getBuData,
    resetBuDataFetched,
    getPptData,
    toggleCreateTheme,
    setPracticeDisplay,
    getClientEngagementData,
    getSinglePastReport,
} from '../actions';

import { updateBuFilters, getReport, getRawPptData } from '../../Reports/actions';
import { selectBuFilters, selectReportError } from '../../Reports/selector';
import { getDefaultSettings } from '../../Login/selector';

import {
    selectScoreData,
    selectScoreDataFetched,
    selectApiParams,
    selectApiCount,
    selectProjects,
    selectProjectsFetched,
    selectReSurveyData,
    selectReSurveyDataFetched,
    getErrorInfo,
    selectFilters,
    selectFiltersApplied,
    selectBuData,
    selectBuDataFetched,
    selectCurrentTheme,
    selectClientEngagementData,
    selectClientEngagementFetched,
    selectPastReport,
    selectCohesionDemographic,
} from '../selector';

const stateToProps = createStructuredSelector({
    scoreData: selectScoreData(),
    scoreDataFetched: selectScoreDataFetched(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    projects: selectProjects(),
    projectsFetched: selectProjectsFetched(),
    reSurveyData: selectReSurveyData(),
    reSurveyDataFetched: selectReSurveyDataFetched(),
    errorInfo: getErrorInfo(),
    filters: selectFilters(),
    activeResetFilter: selectFiltersApplied(),
    buData: selectBuData(),
    buDataFetched: selectBuDataFetched(),
    buFilters: selectBuFilters(),
    currentTheme: selectCurrentTheme(),
    defaultSettings: getDefaultSettings(),
    clientEngagement: selectClientEngagementData(),
    clientEngagementFetched: selectClientEngagementFetched(),
    reportError: selectReportError(),
    singlePastReport: selectPastReport(),
    cohesionDemographic: selectCohesionDemographic(),
});

const dispatchToProps = {
    getScoreData,
    getProjectsData,
    getReSurveyData,
    getDemographics,
    getBenchmarks,
    updateFilters,
    resetReSurveyDataFetched,
    getBuData,
    resetBuDataFetched,
    updateBuFilters,
    getPptData,
    toggleCreateTheme,
    setPracticeDisplay,
    getClientEngagementData,
    getReport,
    getRawPptData,
    getSinglePastReport,
};

export default connect(stateToProps, dispatchToProps)(MainBoard);
