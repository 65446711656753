import {
    GET_REPORT,
    GET_RESURVEY_EXCEL,
    GET_DATA_CUBE,
    UPDATE_FILTER_INDEX,
    UPDATE_REPORT_API_PARAMS,
    UPDATE_RESPONDENTS,
    UPDATE_COMMENTS,
    UPDATE_BU_FILTERS,
    UPDATE_PRACTICE_BU_FILTERS,
    UPDATE_BULK_FILTERS,
    SET_BULK_TOGGLE,
    UPDATE_BENCHMARK_DATA_FETCHED,
    UPDATE_DEMOGRAHIC_DATA_FETCHED,
    GET_PAST_REPORT,
    SET_CURRENT_TAB,
    GET_PPT_RAW_DATA,
    GET_INCLUSION_DATA,
    GET_GAP_DATA_ALL,
    GET_GAP_DATA,
    GET_GRAPH_GAP_DATA,
    UPDATE_GRAPH_GAP_DATA,
    PENDING_REPORTS_STATUS,
    ADD_PENDING_REPORT,
    GET_RESPONDENTS_DATA,
    UPDATE_PREV_RESPONDENTS,
    GET_RESURVEY_MAPPING,
    UPDATE_RESURVEY_MAPPING_FETCHED,
    GET_INCLUSION_DATA_RES,
    GET_RES_GAP_DATA_ALL,
    DELETE_RESURVEY_MAPPING,
    PUT_PAST_REPORT,
    GET_RES_GAP_DATA,
    GET_MAPPING_EXCEL,
    GET_IA_MAPPED_DEMOGRAPHICS,
    SET_AVG_GAP_TOGGLE,
    DELETE_SCORE_COMPARISON,
    GET_QBYQ_NSIZE,
    UPDATE_TASK_ID,
    GET_MULTI_PPT_DATA,
    SAVE_PPT_ZIP,
    UPDATE_PAST_REPORTS,
    RESET_PENDING_REPORT,
    UPDATE_COMP_PREVIOUS_RESPONDENTS,
    GET_BULK_SCORE_DATA,
    RESET_ASSIGN_VALID,
} from './constants';

export const getReport = payload => ({
    type: `${GET_REPORT}_PENDING`,
    payload,
});

export const getReportSuccess = payload => ({
    type: `${GET_REPORT}_SUCCESS`,
    payload,
});

export const getReportFailed = (payload = {}) => ({
    type: `${GET_REPORT}_FAILED`,
    payload,
});

export const getResurveyExcel = payload => ({
    type: `${GET_RESURVEY_EXCEL}_PENDING`,
    payload,
});

export const getResurveyExcelSuccess = payload => ({
    type: `${GET_RESURVEY_EXCEL}_SUCCESS`,
    payload,
});

export const getResurveyExcelFailed = (payload = {}) => ({
    type: `${GET_RESURVEY_EXCEL}_FAILED`,
    payload,
});

export const getMappingExcel = payload => ({
    type: `${GET_MAPPING_EXCEL}_PENDING`,
    payload,
});

export const getMappedDemographicSuccess = payload => ({
    type: `${GET_IA_MAPPED_DEMOGRAPHICS}_SUCCESS`,
    payload,
});

export const getMappedDemographicFailed = payload => ({
    type: `${GET_IA_MAPPED_DEMOGRAPHICS}_SUCCESS`,
    payload,
});

export const getMappedDemographic = payload => ({
    type: `${GET_IA_MAPPED_DEMOGRAPHICS}_PENDING`,
    payload,
});

export const getMappingExcelSuccess = payload => ({
    type: `${GET_MAPPING_EXCEL}_SUCCESS`,
    payload,
});

export const getmappingExcelFailed = (payload = {}) => ({
    type: `${GET_MAPPING_EXCEL}_FAILED`,
    payload,
});

export const getDataCube = payload => ({
    type: `${GET_DATA_CUBE}_PENDING`,
    payload,
});

export const getDataCubeSuccess = payload => ({
    type: `${GET_DATA_CUBE}_SUCCESS`,
    payload,
});

export const getDataCubeFailed = (payload = {}) => ({
    type: `${GET_DATA_CUBE}_FAILED`,
    payload,
});

export const updateReportApiParams = payload => ({
    type: `${UPDATE_REPORT_API_PARAMS}`,
    payload,
});

export const updateBuFilters = payload => ({
    type: UPDATE_BU_FILTERS,
    payload,
});

export const updatePracticeBuFilters = payload => ({
    type: UPDATE_PRACTICE_BU_FILTERS,
    payload,
});

export const updateBulkFilters = (benchmarks, updateResurveyDemo) => ({
    type: UPDATE_BULK_FILTERS,
    payload: {
        benchmarks,
        updateResurveyDemo,
    },
});

export const setBulkToggle = payload => ({
    type: SET_BULK_TOGGLE,
    payload,
});

export const setAvgGapToggle = payload => ({
    type: SET_AVG_GAP_TOGGLE,
    payload,
});

export const updateBenchmarkDataFetched = () => ({
    type: UPDATE_BENCHMARK_DATA_FETCHED,
});

export const updateDemographicDataFetched = () => ({
    type: UPDATE_DEMOGRAHIC_DATA_FETCHED,
});

export const updateFilterIndex = payload => ({
    type: UPDATE_FILTER_INDEX,
    payload,
});

export const getPastReport = payload => ({
    type: `${GET_PAST_REPORT}_PENDING`,
    payload,
});

export const getPastReportSuccess = payload => ({
    type: `${GET_PAST_REPORT}_SUCCESS`,
    payload,
});

export const getPastReportFailed = (payload = {}) => ({
    type: `${GET_PAST_REPORT}_FAILED`,
    payload,
});

export const getRawPptData = payload => ({
    type: `${GET_PPT_RAW_DATA}_PENDING`,
    payload,
});

export const getRawPptDataSuccess = payload => ({
    type: `${GET_PPT_RAW_DATA}_SUCCESS`,
    payload,
});

export const getRawPptDataFailed = (payload = {}) => ({
    type: `${GET_PPT_RAW_DATA}_FAILED`,
    payload,
});

export const getMultiPptData = payload => ({
    type: `${GET_MULTI_PPT_DATA}_PENDING`,
    payload,
});

export const getMultiPptDataSuccess = payload => ({
    type: `${GET_MULTI_PPT_DATA}_SUCCESS`,
    payload,
});

export const getMultiPptDataFailed = (payload = {}) => ({
    type: `${GET_MULTI_PPT_DATA}_FAILED`,
    payload,
});

export const savePptData = (payload, token, pptName = 'abc', countToAdd = 1, report_name = '') => ({
    type: `${SAVE_PPT_ZIP}_PENDING`,
    payload,
    token,
    pptName,
    countToAdd,
    report_name,
});

export const savePptDataSuccess = payload => ({
    type: `${SAVE_PPT_ZIP}_SUCCESS`,
    payload,
});

export const savePptDataFailed = (payload = {}) => ({
    type: `${SAVE_PPT_ZIP}_FAILED`,
    payload,
});

export const setCurrentTab = payload => ({
    type: `${SET_CURRENT_TAB}`,
    payload,
});

export const getInclusionDataSuccess = payload => ({
    type: `${GET_INCLUSION_DATA}_SUCCESS`,
    payload,
});

export const getInclusionDataFailed = (payload = {}) => ({
    type: `${GET_INCLUSION_DATA}_FAILED`,
    payload,
});

export const getQbyqNsizeSuccess = payload => ({
    type: `${GET_QBYQ_NSIZE}_SUCCESS`,
    payload,
});

export const getQbyqNsizeFailed = payload => ({
    type: `${GET_QBYQ_NSIZE}_FAILED`,
    payload,
});

export const getQbyqNsize = payload => ({
    type: `${GET_QBYQ_NSIZE}_PENDING`,
    payload,
});

export const getInclusionData = payload => ({
    type: `${GET_INCLUSION_DATA}_PENDING`,
    payload,
});

export const getInclusionGapSuccess = payload => ({
    type: `${GET_GAP_DATA}_SUCCESS`,
    payload,
});

export const getInclusionGapFailed = (payload = {}) => ({
    type: `${GET_GAP_DATA}_FAILED`,
    payload,
});

export const getInclusionDataResurvey = payload => ({
    type: `${GET_INCLUSION_DATA_RES}_PENDING`,
    payload,
});

export const getInclusionDataResurveySuccess = payload => ({
    type: `${GET_INCLUSION_DATA_RES}_SUCCESS`,
    payload,
});

export const getInclusionDataResurveyFailed = (payload = {}) => ({
    type: `${GET_INCLUSION_DATA_RES}_FAILED`,
    payload,
});

export const getGapData = payload => ({
    type: `${GET_GAP_DATA}_PENDING`,
    payload,
});

export const getGraphGapData = payload => ({
    type: `${GET_GRAPH_GAP_DATA}_SUCCESS`,
    payload,
});

export const updateGraphGapData = payload => ({
    type: UPDATE_GRAPH_GAP_DATA,
    payload,
});

export const getAllGapSuccess = payload => ({
    type: `${GET_GAP_DATA_ALL}_SUCCESS`,
    payload,
});

export const getAllGapFailed = (payload = {}) => ({
    type: `${GET_GAP_DATA_ALL}_FAILED`,
    payload,
});

export const getAllGapData = payload => ({
    type: `${GET_GAP_DATA_ALL}_PENDING`,
    payload,
});

export const getResurveyAllGapSuccess = payload => ({
    type: `${GET_RES_GAP_DATA_ALL}_SUCCESS`,
    payload,
});

export const getResurveyAllGapFailed = (payload = {}) => ({
    type: `${GET_RES_GAP_DATA_ALL}_FAILED`,
    payload,
});

export const getResurveyAllGapData = payload => ({
    type: `${GET_RES_GAP_DATA_ALL}_PENDING`,
    payload,
});

export const getResurveyGapSuccess = payload => ({
    type: `${GET_RES_GAP_DATA}_SUCCESS`,
    payload,
});

export const getResurveyGapFailed = (payload = {}) => ({
    type: `${GET_RES_GAP_DATA}_FAILED`,
    payload,
});

export const getResurveyGapData = payload => ({
    type: `${GET_RES_GAP_DATA}_PENDING`,
    payload,
});

export const addPendingReport = payload => ({
    type: ADD_PENDING_REPORT,
    payload,
});

export const resetPendingReport = () => ({
    type: RESET_PENDING_REPORT,
});

export const getPendingReportStatus = payload => ({
    type: `${PENDING_REPORTS_STATUS}_PENDING`,
    payload,
});

export const getPendingReportStatusSuccess = payload => ({
    type: `${PENDING_REPORTS_STATUS}_SUCCESS`,
    payload,
});

export const getPendingReportStatusFailed = (payload = {}) => ({
    type: `${PENDING_REPORTS_STATUS}_FAILED`,
    payload,
});
export const updateRespondents = payload => ({
    type: UPDATE_RESPONDENTS,
    payload,
});

export const updatePreviousRespondent = payload => ({
    type: UPDATE_PREV_RESPONDENTS,
    payload,
});

export const updateComments = payload => ({
    type: UPDATE_COMMENTS,
    payload,
});

export const getRespondents = payload => ({
    type: `${GET_RESPONDENTS_DATA}_PENDING`,
    payload,
});

export const getRespondentsSuccess = payload => ({
    type: `${GET_RESPONDENTS_DATA}_SUCCESS`,
    payload,
});

export const getRespondentsFailed = (payload = {}) => ({
    type: `${GET_RESPONDENTS_DATA}_FAILED`,
    payload,
});

export const getResurveyMapping = (payload, indexToUse) => ({
    type: `${GET_RESURVEY_MAPPING}_PENDING`,
    payload,
    indexToUse,
});

export const getResurveyMappingSuccess = payload => ({
    type: `${GET_RESURVEY_MAPPING}_SUCCESS`,
    payload,
});

export const getResurveyMappingFailed = (payload = {}) => ({
    type: `${GET_RESURVEY_MAPPING}_FAILED`,
    payload,
});

export const updateResurveyMapping = () => ({
    type: UPDATE_RESURVEY_MAPPING_FETCHED,
});

// eslint-disable-next-line no-empty-pattern
export const deleteResurveyMapping = ({}) => ({
    type: DELETE_RESURVEY_MAPPING,
});

// eslint-disable-next-line no-empty-pattern
export const deleteScoreComparison = ({}) => ({
    type: DELETE_SCORE_COMPARISON,
});

export const putPastReport = payload => ({
    type: `${PUT_PAST_REPORT}_PENDING`,
    payload,
});

export const putPastReportSuccess = payload => ({
    type: `${PUT_PAST_REPORT}_SUCCESS`,
    payload,
});

export const putPastReportFailed = (payload = {}) => ({
    type: `${PUT_PAST_REPORT}_FAILED`,
    payload,
});

export const updateTaskId = (payload = '') => ({
    type: UPDATE_TASK_ID,
    payload,
});

export const updatedPastRpts = (payload = {}) => ({
    type: UPDATE_PAST_REPORTS,
    payload,
});

export const updateCompPrevRespondent = payload => ({
    type: UPDATE_COMP_PREVIOUS_RESPONDENTS,
    payload,
});

export const getBulkScoreData = payload => ({
    type: `${GET_BULK_SCORE_DATA}_PENDING`,
    payload,
});

export const getBulkScoreDataSuccess = payload => ({
    type: `${GET_BULK_SCORE_DATA}_SUCCESS`,
    payload,
});

export const getBulkScoreDataFailed = (payload = {}) => ({
    type: `${GET_BULK_SCORE_DATA}_FAILED`,
    payload,
});

export const resetBulkAssign = payload => ({
    type: `${RESET_ASSIGN_VALID}`,
    payload,
});
