import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ArrowUpward } from '@material-ui/icons';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
import DownloadIcon from '../assets/Download.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import FilterSvg from '../assets/Filter.svg';
import CompareView from '../assets/CompareView.svg';
import { getStaticText } from '../../../utils/constants';
import { getPptValue, getPptValues } from '../../../utils/functions';
import getStaticTextDiagnose from '../constants';
import colors from '../../../sass/colors';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import DwnldModal from '../../../components/InformationToast/DwnldModal';

function ReSurveyReport({
    setReport,
    setChartData,
    scoreData,
    cohesionScore,
    errorInfo,
    updateFilterView,
    activeResetFilter,
    currentTheme,
    defaultSettings,
    getPptData,
    years,
    pulseSurvey,
    isPercentile,
    appliedFiltersInfo,
    apiParams,
    getReport,
    getRawPptData,
    reportError,
    getResurveyParams,
    filters,
    getSinglePastReport,
    singlePastReport,
}) {
    const {
        quartileColors,
        report_name = '',
        report_name_or_client_name: rName = '',
        resurvey_settings = '',
        template_name: template_file_name,
        lang,
        survey_version,
        checkme_mapping,
        report_id = '',
        threshold,
        demographic = '',
        report_type = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { recipeImages, QUARTILE_RESURVEY } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, RESURVEY_EXPORT_PARAMS } = staticText;
    const isOhi4 = survey_version === '4';
    const isQuartMovement = resurvey_settings === QUARTILE_RESURVEY;
    const { $white, $black, $darkBlue100 } = colors;
    const {
        SCORECARD,
        FILTERS,
        BENCHMARK_HEAD,
        RESURVEY_TEXT,
        OUTCOMES_TEXT,
        STRONG_TEXT,
        VERY_STRONG_TEXT,
        NO_ALIGNMENT,
        RECIPE_NOTE,
        HEALTH_SCORE,
        COHESION_SCORE,
        DOWNLOAD,
        PPT_RES_TEXT,
        PPT_RES_TEXT2,
        PPT_RES_TEXT3,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        ALL_PPT_TEXT,
        SIGNIFICANCE,
        PPT_VALUES,
        TOTAL_RES_TEXT,
        SCORECARD_GROUP_TEXTS,
        SCORECARD_GROUP_HEAD,
        DIFF_HEAD,
        SIGNIFICANCES,
        NOTE,
        OHI4_RESURVEY_NOTE,
        QUARTILE_MOVE_LEGEND_TXT,
        HEALTH_RESURVEY_BOARD,
        PERCENT_FAVORABLE,
        PERCENTILES,
    } = SITE_TEXT || {};
    const { records = {}, recipe = {}, sizes = {} } = scoreData;
    const { nSize: n_size = [] } = sizes;
    const { ohi_score, ...dataToIterate } = records;
    const { options = {}, values = [] } = recipe;
    const [dwnld, setdwnld] = useState(false);
    const highScoreIndex = Object.keys(values).length
        ? values[0].reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0)
        : null;
    const highScoreKey = highScoreIndex !== null ? Object.keys(options)[highScoreIndex] : '';
    const recipeData = highScoreKey ? { ...options[highScoreKey], score: [values[0][highScoreIndex]] } : {};
    const headerContent = [ohi_score, { score: [cohesionScore], title: COHESION_SCORE }, recipeData];
    const { pptAccessToken = '', resurveyName1 } = AuthStore;
    const isQuartile = resurvey_settings === QUARTILE_RESURVEY ? 'SigChangeLegend' : 'QuartileLegend';
    const filterCheck = apiParams.filters || [];

    const headerVersions = [
        ohi_score,
        { score: [cohesionScore], title: COHESION_SCORE },
        { score: n_size, title: 'Total Respondents (n)' },
    ];

    const headerContents = isOhi4 ? headerVersions : headerContent;
    const surveyV = isOhi4 || survey_version === '3_2';
    const sigVInUse = surveyV ? SIGNIFICANCES : SIGNIFICANCE;

    const getPpt = (outcomeArray = [], prtArray = [], diffArray = [], childKeyNames = []) => {
        const { nSize, nSurvey, nRespondents, bmNames } = sizes;
        const { GLOBAL_VALS, resurvey_recipealignment_checkme } = PPT_VALUES();
        const pptArray = [
            {
                name: '4. Footnote',
                value: ALL_PPT_TEXT[11],
            },
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} ${years[0]} (${NUMBER_TEXT}=${nSize[0]}); ${BENCHMARK_HEAD}: ${bmNames[0]} (${NUMBER_TEXT}=${nSurvey[0]}, ${NUMBER_SURVEY_TEXT}=${nRespondents[0]}), ${resurveyName1} (${NUMBER_TEXT}=${nSize[1]}); ${BENCHMARK_HEAD}: ${bmNames[1]} (${NUMBER_TEXT}=${nSurvey[1]}, ${NUMBER_SURVEY_TEXT}=${nRespondents[1]})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            {
                name: isQuartile,
                is_delete: 'true',
            },
            ...getPptValues(GLOBAL_VALS),
        ];
        const { score = [], quartile = [], difference = [], significance = [] } = ohi_score;
        const signVal = significance[0];
        const { bg = $white } = sigVInUse[signVal] || {};

        const data = [
            ...pptArray,
            {
                name: 'OVERALL_HEALTHOVAL02',
                value: difference[0],
                text_color: '#ffffff',
                bgcolor: bg,
            },
            {
                name: 'OVERALL_HEALTHGRP01',
                is_delete: true,
            },
        ];

        const chart_data = [
            {
                index: 3,
                value: values[0][0] || '-',
                row_index: 4,
                column_index: 0,
                series: 0,
            },
            {
                index: 3,
                value: values[1][0] || '-',
                row_index: 4,
                column_index: 1,
                series: 1,
            },
            {
                index: 2,
                value: values[0][1] || '-',
                row_index: 3,
                column_index: 0,
                series: 0,
            },
            {
                index: 2,
                value: values[1][1] || '-',
                row_index: 3,
                column_index: 1,
                series: 1,
            },
            {
                index: 1,
                value: values[0][2] || '-',
                row_index: 2,
                column_index: 0,
                series: 0,
            },
            {
                index: 1,
                value: values[1][2] || '-',
                row_index: 2,
                column_index: 1,
                series: 1,
            },
            {
                index: 0,
                value: values[0][3] || '-',
                row_index: 1,
                column_index: 0,
                series: 0,
            },
            {
                index: 0,
                value: values[1][3] || '-',
                row_index: 1,
                column_index: 1,
                series: 1,
            },
        ];

        const recipeArray = [
            {
                name: 'RecipeChart',
                chart_data,
            },
            {
                name: 'YearCurr1,YearCurr2,YearCurr3,YearCurr4',
                value: years[1],
            },
            {
                name: 'YearPrev1,YearPrev2,YearPrev3,YearPrev4',
                value: years[0],
            },
        ];

        score.forEach((value, ohiIndex) => {
            const { background: bgcolor = $white, border: border_color = $white } =
                quartileColors[quartile[ohiIndex]] || {};
            data.push(
                {
                    name: `OVERALL_HEALTH0${ohiIndex + 2}`,
                    value,
                    text_color: bgcolor,
                },
                {
                    name: `OVERALL_HEALTHYEAR0${ohiIndex + 2}`,
                    value: years[ohiIndex],
                },
                {
                    name: `OVERALL_HEALTHFRAME0${ohiIndex + 2}_bg`,
                    bgcolor,
                    border_color,
                }
            );
        });

        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'resurvey_overall_health_checkme',
                    data: [
                        ...data,
                        {
                            name: 'AutoShape 250b',
                            value: ALL_PPT_TEXT[8],
                        },
                        {
                            name: 'Title_text',
                            value: ALL_PPT_TEXT[14],
                        },
                    ],
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'resurvey_outcome_profile_checkme',
                    data: [
                        ...outcomeArray,
                        ...pptArray,
                        {
                            name: 'Title_text',
                            value: ALL_PPT_TEXT[16],
                        },
                    ],
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'resurvey_practice_profile_checkme',
                    data: [
                        {
                            name: 'Size_prevyr_value',
                            value: nSize[1],
                        },
                        {
                            name: 'Size_CurrentYr_value',
                            value: nSize[0],
                        },
                        ...prtArray,
                        ...pptArray,
                        ...childKeyNames,
                        {
                            name: 'Title_text',
                            value: ALL_PPT_TEXT[13],
                        },
                    ],
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'resurvey_practice_change_checkme',
                    data: [
                        ...diffArray,
                        ...pptArray,
                        {
                            name: 'Title_text',
                            value: ALL_PPT_TEXT[18],
                        },
                    ],
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'resurvey_recipealignment_checkme',
                    data: [...recipeArray, ...pptArray, ...getPptValues(resurvey_recipealignment_checkme)],
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} Resurvey Comparison`);
    };

    const getDataForPpt = () => {
        const outcomeArray = [
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT,
            },
            {
                name: 'prevyr_title',
                value: `${resurveyName1} ${ALL_PPT_TEXT[17]}`,
            },
            {
                name: 'CurrentYr_title',
                value: `${rName} ${ALL_PPT_TEXT[17]}`,
            },
        ];
        const prtArray = [
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT2,
            },
        ];

        const diffArray = [
            {
                name: '3. Subtitle',
                value: isPercentile ? PPT_RES_TEXT3 : PPT_RES_TEXT2,
            },
        ];

        const childKeyNames = [];

        Object.keys(dataToIterate).forEach(dataSet => {
            const {
                display_name = '',
                score = [],
                difference = [],
                significance = [],
                children = {},
                quartile = [],
            } = dataToIterate[dataSet];
            const parentData = {
                name: `${dataSet}_text`,
                value: display_name,
                text_color: '',
            };
            diffArray.push(parentData);
            prtArray.push(parentData);
            const value = difference[0];
            const signVal = significance[0];
            score.forEach((parentSc, parentInd) => {
                const valueParent = {
                    score: parentSc,
                    display_name,
                    quartile: quartile[parentInd],
                };
                const valueKeyParent = !parentInd ? `${dataSet}_CurrentYr` : `${dataSet}_prevyr`;
                const parentArray = getPptValue(quartileColors, valueParent, valueKeyParent, true, true);
                outcomeArray.push(...parentArray);
                const { bg = $white, text_color = $black } = sigVInUse[signVal] || {};
                outcomeArray.push({
                    name: `${dataSet}_diff`,
                    value,
                    text_color,
                    bgcolor: bg,
                });
            });

            Object.keys(children).forEach(childVal => {
                const valueInChild = children[childVal];
                const {
                    score: childScoreArr = [],
                    display_name: child_DP,
                    quartile: childQT,
                    difference: childDiff = [],
                    significance: childSign = [],
                } = valueInChild;
                const diffChild = childDiff[0];
                const cSignVal = childSign[0];
                const { bg = $white, text_color = $black } = sigVInUse[cSignVal] || {};
                diffArray.push(
                    {
                        name: `${childVal}_text`,
                        value: child_DP,
                        text_color: '',
                    },
                    {
                        name: `${childVal}_diff`,
                        value: diffChild,
                        text_color,
                        bgcolor: bg,
                    }
                );

                childKeyNames.push({
                    name: `${childVal}_text`,
                    value: child_DP,
                    text_color: '',
                });

                childScoreArr.forEach((parentSc, parentInd) => {
                    const valueParent = {
                        score: parentSc,
                        display_name: child_DP,
                        quartile: childQT[parentInd],
                    };
                    const valueKeyChild = !parentInd ? `${childVal}_CurrentYr` : `${childVal}_prevyr`;
                    const childArray = getPptValue(quartileColors, valueParent, valueKeyChild, true, true);
                    prtArray.push(...childArray);
                    prtArray.push({
                        name: `${childVal}_diff`,
                        value: diffChild,
                        text_color,
                        bgcolor: bg,
                    });
                });
            });
        });
        getPpt(outcomeArray, prtArray, diffArray, childKeyNames);
    };

    const resurveyDemoName1 = AuthStore.resurveyFilter1 || 'Organization';
    const resurveyDemoName2 = AuthStore.resurveyFilter2 || 'Comparison survey';

    const v4PptDownload = () => {
        const { benchmark_str, ohid } = getResurveyParams(filters);
        const { bm_filters } = apiParams;
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const resurveyParams = {
            ...RESURVEY_EXPORT_PARAMS,
            report_name: rName,
            ...addOns,
            options: {
                ...RESURVEY_EXPORT_PARAMS.options,
                survey_names: `${ohid.split(',')[0]}${resurveyDemoName1.split(',')}$#$${
                    ohid.split(',')[1]
                }${resurveyDemoName2.split(',')}`,
            },
            bm_filters,
            benchmarks: benchmark_str,
            threshold,
            report_id,
        };
        const valueToSend = {
            ...resurveyParams,
            ...getResurveyParams(filters),
            single_page_meta: {
                checkme: checkme_mapping.resurvey_scorecard,
                name: 'scorecard',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    return (
        <div className="scoreBoard reSurveyReportBoard clearfix">
            <Helmet>
                <title>{HEALTH_RESURVEY_BOARD}</title>
            </Helmet>
            <h2>
                {rName} {SCORECARD}
                <ul className="rightSideNav">
                    {n_size.length && survey_version !== '4' ? (
                        <li className="strong">{`${TOTAL_RES_TEXT} ${n_size[0].toLocaleString('en-US')} (${
                            years[0]
                        }), ${n_size[1].toLocaleString('en-US')} (${years[1]})`}</li>
                    ) : null}
                    <li data-testid="setReSurveyModalBtn" className="hovered" onClick={() => setReport(0)}>
                        <img src={CompareView} alt="" />
                        {RESURVEY_TEXT}
                    </li>
                    {!errorInfo && (
                        <li data-testid="getPptButton" onClick={!surveyV ? getDataForPpt : v4PptDownload}>
                            <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                        </li>
                    )}
                    <li data-testid="filterButton" onClick={() => updateFilterView(true)}>
                        {/* <img src={activeResetFilter ? ActiveFilter : FilterSvg} alt="" /> */}
                        <img src={activeResetFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg} alt="" />
                        {FILTERS}
                    </li>
                </ul>
            </h2>
            {errorInfo && <ErrorToast message={errorInfo} />}
            {dwnld && !reportError && (
                <DwnldModal
                    getRawPptData={getRawPptData}
                    reportId={report_id}
                    open={dwnld}
                    setOpen={setdwnld}
                    getSinglePastReport={getSinglePastReport}
                    singlePastReport={singlePastReport}
                    defaultSettings={defaultSettings}
                />
            )}
            {Object.keys(scoreData).length ? (
                <Fragment>
                    {appliedFiltersInfo(years)}
                    <ul className={c('headContent', 'headList', 'cardsList', { pulseSurvey })}>
                        {headerContents.map(
                            (
                                { score = [0], title = '', quartile = [], difference = [null], significance = [] },
                                index
                            ) => {
                                const strong = score[0] >= 7 ? VERY_STRONG_TEXT : STRONG_TEXT;
                                const subHeader = score[0] >= 5 ? strong : NO_ALIGNMENT;
                                const diff = difference[0];
                                const titleInEnd = !index ? HEALTH_SCORE : title;
                                return (
                                    <li
                                        data-testid={`headContent${index}`}
                                        key={index}
                                        onClick={() => {
                                            if (index === 2 && survey_version !== '4') {
                                                setChartData(recipe, highScoreKey, highScoreIndex);
                                            }
                                        }}
                                        className="cardList"
                                    >
                                        {score.map((scoreCont, indexScore) => {
                                            const {
                                                background = $white,
                                                color = $darkBlue100,
                                                border: borderColor = $white,
                                            } = quartileColors[quartile[indexScore]] || {};
                                            return (
                                                <h3
                                                    key={indexScore}
                                                    style={{
                                                        background,
                                                        color,
                                                        borderColor,
                                                    }}
                                                >
                                                    {scoreCont}
                                                </h3>
                                            );
                                        })}
                                        {diff !== null && !surveyV && (
                                            <span
                                                style={{
                                                    color:
                                                        significance.length &&
                                                        significance[0] &&
                                                        sigVInUse[significance[0]]
                                                            ? sigVInUse[significance[0]].bg
                                                            : $black,
                                                }}
                                                className={c('difference', { negative: diff < 0 })}
                                            >
                                                {diff}
                                                {diff ? <ArrowUpward /> : null}
                                            </span>
                                        )}

                                        {diff !== null && surveyV && (
                                            <span
                                                style={{
                                                    color:
                                                        significance.length &&
                                                        significance[0] &&
                                                        sigVInUse[significance[0]]
                                                            ? sigVInUse[significance[0]].bg
                                                            : $black,
                                                }}
                                                className={c('difference', { negative: diff < 0 })}
                                            >
                                                {diff}
                                                {diff ? <ArrowUpward /> : null}
                                            </span>
                                        )}

                                        <div className="ellipsisPro">
                                            {index === 2 && survey_version !== '4' && <span>{RECIPE_NOTE}</span>}
                                            {index === 2 && survey_version !== '4' && <div>{subHeader}</div>}
                                            <span className="scoreContent">{titleInEnd}</span>
                                        </div>
                                        {index === 2 && survey_version !== '4' && (
                                            <img src={recipeImages[highScoreIndex]} alt="" />
                                        )}
                                    </li>
                                );
                            }
                        )}
                    </ul>
                    <h3>{OUTCOMES_TEXT}</h3>
                    {surveyV ? (
                        SCORECARD_GROUP_TEXTS.map((text, index) => {
                            const obj =
                                index === 0
                                    ? Object.keys(dataToIterate).slice(0, 3)
                                    : Object.keys(dataToIterate).slice(3, 7);
                            const objToUse = index === 2 ? Object.keys(dataToIterate).slice(7) : obj;

                            return (
                                <div className="scoreDatacard">
                                    <h3>
                                        <strong>{SCORECARD_GROUP_HEAD[index]}</strong> {text}
                                    </h3>
                                    <section className="scoreData clearfix">
                                        {objToUse.map(dataSet => {
                                            const {
                                                display_name = '',
                                                score = [],
                                                difference = [],
                                                significance = [],
                                                children = {},
                                                quartile = [],
                                            } = dataToIterate[dataSet];
                                            const diff = difference[0];
                                            const signVal = significance[0];
                                            const { bg: bg0 = $black } = sigVInUse[signVal] || {};
                                            return (
                                                <div key={dataSet} className="scoreTile clearfix">
                                                    <h4
                                                        className={c('ellipsisPro', {
                                                            active: currentTheme.includes(dataSet),
                                                        })}
                                                    >
                                                        {score.map((scoreCont, indexScore) => {
                                                            const {
                                                                background = $white,
                                                                color = $black,
                                                                border: borderColor = colors.$grey200,
                                                            } = quartileColors[quartile[indexScore]] || {};
                                                            return (
                                                                <div
                                                                    style={{
                                                                        background: surveyV
                                                                            ? `linear-gradient(135deg, ${borderColor} 25%, ${background} 25%)`
                                                                            : background,
                                                                        color,
                                                                        borderColor: surveyV
                                                                            ? 'transparent'
                                                                            : borderColor,
                                                                        borderWidth: quartile === '' ? 1 : 2,
                                                                        borderStyle: surveyV ? 'none' : 'solid',
                                                                    }}
                                                                    className="triangleOutcome"
                                                                >
                                                                    <div>{scoreCont}</div>
                                                                </div>
                                                            );
                                                        })}
                                                        <span
                                                            style={{ color: bg0 }}
                                                            className={c('difference', { negative: diff < 0 })}
                                                        >
                                                            {diff}
                                                            {diff ? <ArrowUpward /> : null}
                                                        </span>
                                                        {display_name}
                                                    </h4>
                                                    <ul>
                                                        {Object.keys(children).map(childSet => {
                                                            const {
                                                                display_name: childName = '',
                                                                score: childScore = [],
                                                                difference: childDiff = [],
                                                                significance: childSign = [],
                                                                quartile: childQuat = [],
                                                            } = children[childSet];
                                                            const diffChild = childDiff[0];
                                                            const cSignVal = childSign[0];
                                                            const { bg: cbg0 = $black } = sigVInUse[cSignVal] || {};
                                                            return (
                                                                <li
                                                                    data-testid={childSet}
                                                                    className={c('ellipsisPro', {
                                                                        active: currentTheme.includes(childSet),
                                                                    })}
                                                                    key={childName}
                                                                >
                                                                    {childName}
                                                                    {childScore.map(
                                                                        (childScoreData, childScoreIndex) => {
                                                                            const {
                                                                                background = $white,
                                                                                color = $black,
                                                                                // border: borderColor = colors.$grey200,
                                                                                border: borderColor = colors.$white,
                                                                            } =
                                                                                quartileColors[
                                                                                    childQuat[childScoreIndex]
                                                                                ] || {};
                                                                            return (
                                                                                <span
                                                                                    style={{
                                                                                        border: `1px solid white`,
                                                                                        background: borderColor
                                                                                            ? `linear-gradient(135deg, ${borderColor} 25%, ${background} 25%)`
                                                                                            : background,
                                                                                        color,
                                                                                    }}
                                                                                    key={childScoreIndex}
                                                                                >
                                                                                    {childScoreData}
                                                                                </span>
                                                                            );
                                                                        }
                                                                    )}
                                                                    <span
                                                                        style={{
                                                                            color: cbg0,
                                                                        }}
                                                                        className={c('difference', {
                                                                            negative: diffChild < 0,
                                                                        })}
                                                                    >
                                                                        {diffChild}
                                                                        {diffChild ? <ArrowUpward /> : null}
                                                                    </span>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            );
                                        })}
                                    </section>
                                </div>
                            );
                        })
                    ) : (
                        <section className="scoreData clearfix">
                            {Object.keys(dataToIterate).map(dataSet => {
                                const {
                                    display_name = '',
                                    score = [],
                                    difference = [],
                                    significance = [],
                                    children = {},
                                    quartile = [],
                                } = dataToIterate[dataSet];
                                const diff = difference[0];
                                const signVal = significance[0];
                                const { bg: bg0 = $black } = sigVInUse[signVal] || {};
                                return (
                                    <div key={dataSet} className="scoreTile clearfix">
                                        <h4 className={c('ellipsisPro', { active: currentTheme.includes(dataSet) })}>
                                            {score.map((scoreCont, indexScore) => {
                                                const {
                                                    background = $white,
                                                    color = $black,
                                                    border: borderColor = colors.$grey200,
                                                } = quartileColors[quartile[indexScore]] || {};
                                                return (
                                                    <span key={indexScore} style={{ background, color, borderColor }}>
                                                        {scoreCont}
                                                    </span>
                                                );
                                            })}
                                            <span
                                                style={{ color: bg0 }}
                                                className={c('difference', { negative: diff < 0 })}
                                            >
                                                {diff}
                                                {diff ? <ArrowUpward /> : null}
                                            </span>
                                            {display_name}
                                        </h4>
                                        <ul>
                                            {Object.keys(children).map(childSet => {
                                                const {
                                                    display_name: childName = '',
                                                    score: childScore = [],
                                                    difference: childDiff = [],
                                                    significance: childSign = [],
                                                    quartile: childQuat = [],
                                                } = children[childSet];
                                                const diffChild = childDiff[0];
                                                const cSignVal = childSign[0];
                                                const { bg: cbg0 = $black } = sigVInUse[cSignVal] || {};
                                                return (
                                                    <li
                                                        data-testid={childSet}
                                                        className={c('ellipsisPro', {
                                                            active: currentTheme.includes(childSet),
                                                        })}
                                                        key={childName}
                                                    >
                                                        {childName}
                                                        {childScore.map((childScoreData, childScoreIndex) => {
                                                            const {
                                                                background = $white,
                                                                color = $black,
                                                                border: borderColor = colors.$grey200,
                                                            } = quartileColors[childQuat[childScoreIndex]] || {};
                                                            return (
                                                                <span
                                                                    style={{ background, color, borderColor }}
                                                                    key={childScoreIndex}
                                                                >
                                                                    {childScoreData}
                                                                </span>
                                                            );
                                                        })}
                                                        <span
                                                            style={{
                                                                color: cbg0,
                                                            }}
                                                            className={c('difference', { negative: diffChild < 0 })}
                                                        >
                                                            {diffChild}
                                                            {diffChild ? <ArrowUpward /> : null}
                                                        </span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                );
                            })}
                        </section>
                    )}
                    {surveyV ? (
                        <div>
                            <ul className="benchmark clearfix">
                                <li>{BENCHMARK_HEAD} : </li>
                                {quartileColors
                                    .slice()
                                    .reverse()
                                    .map(({ title, background, border: borderColor }) => {
                                        const bottomDecileVar = title === 'Bottom decile';
                                        const BenchmarkTitle = title === 'No benchmarks';
                                        return (
                                            <li
                                                key={title}
                                                className={c({
                                                    hide: bottomDecileVar || BenchmarkTitle,
                                                })}
                                            >
                                                <span
                                                    className="benchmarkTriangle"
                                                    style={{
                                                        background,
                                                        borderWidth: '13px 13px 0 0',
                                                        borderColor: bottomDecileVar
                                                            ? `transparent ${borderColor} transparent transparent`
                                                            : `${borderColor} transparent transparent transparent`,
                                                        borderStyle: 'solid',
                                                    }}
                                                />
                                                <div style={{ color: 'black' }}>{title}</div>
                                            </li>
                                        );
                                    })}
                            </ul>
                            {/* <div>Note: OHI scores reported in percent favorable</div> */}
                        </div>
                    ) : (
                        <ul className="benchmark clearfix">
                            <li>{BENCHMARK_HEAD} : </li>
                            {quartileColors
                                .slice()
                                .reverse()
                                .map(({ title, background, border: borderColor }) => {
                                    const bottomDecileVar = title === 'Bottom decile';
                                    const BenchmarkTitle = title === 'No benchmarks';
                                    return (
                                        <li
                                            key={title}
                                            style={{ color: background }}
                                            className={c({
                                                hide: bottomDecileVar || BenchmarkTitle,
                                            })}
                                        >
                                            <span
                                                className="benchmarkTriangle"
                                                style={{
                                                    background,
                                                    borderWidth: '13px 13px 0 0',
                                                    border: borderColor,
                                                    borderStyle: 'solid',
                                                }}
                                            />
                                            <div style={{ color: 'black' }}>{title}</div>
                                        </li>
                                    );
                                })}
                        </ul>
                    )}
                    <ul className="bottomList clearfix">
                        <li>{DIFF_HEAD} : </li>
                        {sigVInUse.map(({ title, bg: background, text_color, checkSig = false, sup }, i) => {
                            if (checkSig && !isOhi4) return null;
                            const titleToUse = isQuartMovement && surveyV ? QUARTILE_MOVE_LEGEND_TXT[i] : title;
                            const supVal = sup && isOhi4 && !isQuartMovement ? sup : false;
                            return (
                                <li key={title}>
                                    <span
                                        style={{
                                            background,
                                            border:
                                                checkSig && isOhi4 && !isQuartMovement
                                                    ? `1px solid ${text_color}`
                                                    : 'none',
                                        }}
                                    />
                                    {titleToUse} {supVal && <sup>{sup}</sup>}
                                </li>
                            );
                        })}
                    </ul>
                    {isOhi4 && (
                        <div className="note">
                            {!isQuartMovement ? (
                                <Fragment>
                                    <b>{NOTE}:</b>
                                    <br />
                                    {OHI4_RESURVEY_NOTE[0]}
                                    <br />
                                    {OHI4_RESURVEY_NOTE[1]}
                                    <br />
                                    {OHI4_RESURVEY_NOTE[2]}
                                    {/* new  */}
                                    <br />
                                    {isOhi4 && report_type === 'percent favorable' && <div>4. {PERCENT_FAVORABLE}</div>}
                                    {isOhi4 && report_type === 'percentile' && <div>4. {PERCENTILES}</div>}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <b>{NOTE}:</b>
                                    {OHI4_RESURVEY_NOTE[3]}
                                </Fragment>
                            )}
                        </div>
                    )}
                </Fragment>
            ) : null}
        </div>
    );
}

ReSurveyReport.propTypes = {
    isPercentile: PropTypes.bool.isRequired,
    pulseSurvey: PropTypes.bool.isRequired,
    currentTheme: PropTypes.string.isRequired,
    errorInfo: PropTypes.string.isRequired,
    scoreData: PropTypes.object.isRequired,
    setChartData: PropTypes.func.isRequired,
    cohesionScore: PropTypes.number.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    years: PropTypes.array.isRequired,
    setReport: PropTypes.func.isRequired,
    updateFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    // apiParams: PropTypes.object.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getResurveyParams: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
};

export default ReSurveyReport;
