const AUTH0 = {
    apiBasePath: process.env.REACT_APP_API_BASEPATH,
    pptBasePath: process.env.REACT_APP_PPT_BASEPATH,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectBaseUrl: process.env.REACT_APP_REDIRECT_BASE_URL,
    oidcConfigUrl: process.env.REACT_APP_OIDC_CONFIG_URL,
    textAnalyticsUrl: process.env.REACT_APP_TEXT_ANALYTICS_BASE_URL,
    gsodBaseUrl: process.env.REACT_APP_GSOD_API_BASEPATH,
};

export default AUTH0;
