import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getStaticText } from '../../../../utils/constants';
import Engagement from './Engagement';
import EngagementDemographic from './EngagementDemographic';
import './index.scss';

function EngagementBoard({
    errorInfo,
    engagementDataFetched,
    getEngagementData,
    engagementData,
    defaultSettings,
    filters,
    engagementDemographicDataFetched,
    engagementDemographicData,
    getEngagementDemographicData,
    engagementDropdown,
    setEngagementDropdown,
    apiParams,
    toggleFilterView,
    activeFilter,
    scoreData,
    scoreDataFetched,
    getScoreData,
}) {
    const { lang, ohid, threshold = 10 } = defaultSettings || {};
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { filters: apiFilters = [] } = apiParams;
    const { ENGAGEMENT_HEADING } = SITE_TEXT || {};
    const { demographics = [] } = filters[0] || {};
    const [addOnTab, setAddOnTabs] = useState(0);

    const callEngagement = () => {
        getEngagementData({ lang: parseInt(lang, 10), ohid, demographic: '', filters: apiFilters, threshold });
    };

    const callEngagementDemographic = code => {
        const { code: defaultCode = '' } = demographics[0] || {};
        const demoCode = code || defaultCode;
        setEngagementDropdown(demoCode);
        getEngagementDemographicData({
            ohid,
            lang: parseInt(lang, 10),
            demographic: demoCode,
            pid: '',
            filters: apiFilters,
            threshold,
        });
    };

    useEffect(() => {
        if (!engagementDropdown) {
            const { code: defaultCode = '' } = demographics[0] || {};
            setEngagementDropdown(defaultCode);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!scoreDataFetched) {
            getScoreData(apiParams);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched]);

    const getPage = () => {
        switch (addOnTab) {
            case 0:
                return (
                    <Engagement
                        demographics={demographics}
                        pageData={engagementData}
                        errorInfo={errorInfo}
                        defaultSettings={defaultSettings}
                        callEngagement={callEngagement}
                        engagementDataFetched={engagementDataFetched}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        activeFilter={activeFilter}
                        addOnTab={addOnTab}
                        scoreData={scoreData}
                    />
                );
            case 1:
                return (
                    <EngagementDemographic
                        demographics={demographics}
                        engagementData={engagementDemographicData}
                        selectedDemographic={engagementDropdown}
                        callEngagementDemographic={callEngagementDemographic}
                        engagementDemographicDataFetched={engagementDemographicDataFetched}
                        errorInfo={errorInfo}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        activeFilter={activeFilter}
                        addOnTab={addOnTab}
                        engagementDropdown={engagementDropdown}
                        defaultSettings={defaultSettings}
                    />
                );
            default:
                return <Fragment />;
        }
    };

    return (
        <div className="engagementBoard clearfix" data-testid="engagementBoard">
            <h2>{ENGAGEMENT_HEADING}</h2>
            {getPage()}
        </div>
    );
}

EngagementBoard.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    engagementDataFetched: PropTypes.bool.isRequired,
    engagementData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    getEngagementData: PropTypes.func.isRequired,
    engagementDemographicDataFetched: PropTypes.bool.isRequired,
    engagementDemographicData: PropTypes.object.isRequired,
    getEngagementDemographicData: PropTypes.func.isRequired,
    engagementDropdown: PropTypes.string.isRequired,
    setEngagementDropdown: PropTypes.func.isRequired,
    apiParams: PropTypes.object.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    scoreData: PropTypes.object.isRequired,
    getScoreData: PropTypes.func.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
};

export default EngagementBoard;
