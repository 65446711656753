import { fromJS } from 'immutable';
import { getStaticTextAnalytics } from './constants';
import getFilterString, {
    getSelectedFilterIdsById,
    removeQuartileFromFilterParams,
    removeYearFromFilterParams,
} from './assets/functions';

const staticTextAnalytics = getStaticTextAnalytics();
const {
    GET_DATA_AT_GLANCE,
    UPDATE_ANALYTICS_FILTERS,
    GET_DIFFERENTIAL,
    GET_RANKING,
    GET_ANALYTICS_CATEGORY,
    GET_FILTERS_DATA,
    GET_ANALYTICS_FLAGPOLE,
    GET_ANALYTICS_SCORECARD,
    UPDATE_SCORECARD_DROPDOWN,
    UPDATE_ANALYTICS_CATEGORY,
    UPDATE_DIFFERENTIAL_DATA,
    GET_FILTERS_MAPPING,
    GET_DEFAULT_YEAR,
} = staticTextAnalytics;

export const INITIAL_STATE = fromJS({
    count: 0,
    dataAtGlance: fromJS({}),
    dataAtGlanceFetched: false,
    differentialData: fromJS({}),
    differentialDataFetched: false,
    differentialError: fromJS([]),
    rankingData: fromJS({}),
    rankingFetched: false,
    rankingError: fromJS([]),
    analyticsPerCategoryData: fromJS({}),
    flagpoleData: fromJS({}),
    flagpoleFetched: false,
    flagpoleError: fromJS([]),
    scorecardData: fromJS({}),
    scorecardDataFetched: false,
    scorecardError: fromJS([]),
    filters: [],
    defaultFilters: [],
    filterParam: '',
    scorecardDropdown: '',
    filtersFetched: false,
    filtersApplied: false,
    filtersMapping: fromJS([]),
    filtersMappingFetched: false,
    defaultYear: '',
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {} }) => {
    switch (type) {
        case `${GET_FILTERS_DATA}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_FILTERS_DATA}_SUCCESS`: {
            const { data, year } = payload || {};
            const filterYear = `year:${year}`;
            return state
                .update('count', count => count - 1)
                .set('filters', data)
                .set('defaultFilters', data)
                .set('filterParam', filterYear)
                .set('filtersFetched', true);
        }
        case `${GET_FILTERS_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('filters', fromJS([]))
                .set('filtersFetched', true);
        }
        case `${GET_FILTERS_MAPPING}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_FILTERS_MAPPING}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('filtersMapping', payload)
                .set('filtersMappingFetched', true);
        }
        case `${GET_FILTERS_MAPPING}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('filtersMapping', fromJS([]))
                .set('filtersMappingFetched', true);
        }
        case `${GET_DATA_AT_GLANCE}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_DATA_AT_GLANCE}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('dataAtGlance', payload)
                .set('dataAtGlanceFetched', true);
        }
        case `${GET_DATA_AT_GLANCE}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('dataAtGlance', fromJS({}))
                .set('dataAtGlanceFetched', true);
        }
        case `${GET_DIFFERENTIAL}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_DIFFERENTIAL}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('differentialData', payload)
                .set('differentialDataFetched', true);
        }
        case `${GET_DIFFERENTIAL}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('differentialData', fromJS({}))
                .set('differentialDataFetched', true)
                .set('differentialError', fromJS(errorMsg));
        }
        case `${GET_RANKING}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_RANKING}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('rankingData', payload)
                .set('rankingFetched', true);
        }
        case `${GET_RANKING}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('rankingError', fromJS(errorMsg))
                .set('rankingData', fromJS({}))
                .set('rankingFetched', true);
        }
        case `${GET_ANALYTICS_CATEGORY}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_ANALYTICS_CATEGORY}_SUCCESS`: {
            return state.update('count', count => count - 1).set('analyticsPerCategoryData', payload);
        }
        case `${GET_ANALYTICS_CATEGORY}_FAILED`: {
            return state.update('count', count => count - 1).set('analyticsPerCategoryData', payload.data);
        }
        case `${GET_ANALYTICS_FLAGPOLE}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_ANALYTICS_FLAGPOLE}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('flagpoleData', payload)
                .set('flagpoleFetched', true);
        }
        case `${GET_ANALYTICS_FLAGPOLE}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('flagpoleError', fromJS(errorMsg))
                .set('flagpoleData', fromJS({}))
                .set('flagpoleFetched', true);
        }
        case `${GET_ANALYTICS_SCORECARD}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_ANALYTICS_SCORECARD}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('scorecardData', payload)
                .set('scorecardDataFetched', true);
        }
        case `${GET_ANALYTICS_SCORECARD}_FAILED`: {
            const { errors: errorMsg = [] } = payload || {};
            return state
                .update('count', count => count - 1)
                .set('scorecardError', fromJS(errorMsg))
                .set('scorecardData', fromJS({}))
                .set('scorecardDataFetched', true);
        }
        case UPDATE_ANALYTICS_FILTERS: {
            const { filters } = payload || {};
            const filterParam = getFilterString(filters);

            const [filterYear] = getSelectedFilterIdsById(filters, 'year');
            const defaultYear = state.get('defaultYear');

            let differentialDataFetchedState = false;
            const filterQuartile = getSelectedFilterIdsById(filters, 'quartile');
            if (filterQuartile.length) {
                const filterParamExcQuartile = removeQuartileFromFilterParams(filterParam);
                const removeYear = removeYearFromFilterParams(filterParamExcQuartile);
                differentialDataFetchedState = !removeYear;
            }
            if (defaultYear !== filterYear) {
                const defaultParam = `year:${filterYear}`;
                return state
                    .set('filters', payload.filters)
                    .set('filtersApplied', payload.filtersApplied)
                    .set('filterParam', defaultParam)
                    .set('differentialDataFetched', false)
                    .set('dataAtGlanceFetched', false)
                    .set('analyticsPerCategoryData', fromJS({}))
                    .set('scorecardDataFetched', false)
                    .set('rankingFetched', false)
                    .set('flagpoleFetched', false)
                    .set('filtersFetched', false)
                    .set('filtersMappingFetched', false)
                    .set('defaultYear', filterYear);
            }
            return state
                .set('filters', payload.filters)
                .set('filtersApplied', payload.filtersApplied)
                .set('filterParam', filterParam)
                .set('differentialDataFetched', differentialDataFetchedState)
                .set('dataAtGlanceFetched', false)
                .set('analyticsPerCategoryData', fromJS({}))
                .set('scorecardDataFetched', false)
                .set('rankingFetched', false)
                .set('flagpoleFetched', false);
        }
        case UPDATE_SCORECARD_DROPDOWN: {
            return state.set('scorecardDropdown', payload);
        }
        case UPDATE_ANALYTICS_CATEGORY: {
            return state.set('analyticsPerCategoryData', payload);
        }
        case UPDATE_DIFFERENTIAL_DATA: {
            return state.set('differentialData', payload);
        }
        case `${GET_DEFAULT_YEAR}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_DEFAULT_YEAR}_SUCCESS`: {
            const filterYear = `year:${payload}`;
            return state
                .update('count', count => count - 1)
                .set('defaultYear', payload)
                .set('filterParam', filterYear);
        }
        case `${GET_DEFAULT_YEAR}_FAILED`: {
            return state.set('defaultYear', '').update('count', count => count - 1);
        }
        default:
            return state;
    }
};

export default reducer;
