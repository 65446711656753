import React, { Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

import { cloneDeep, reverse } from 'lodash';
import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import colors from '../../../../sass/colors';
import EnpsDemo from './EnpsDemo';
import OrgHealth from './OrgHealth';
import DeepDiveBoard from './DeepDiveBoard';

function DeepDive({
    filters,
    selectedDemographic,
    ieHeatmap,
    ieHeatmapFetched,
    getEmpExpFactor,
    sortIeHeatmap,
    defaultSettings,
    apiParams,
    getHeatMapPractice,
    heatMapPracticeFetched,
    heatMapPractice,
    callHeatMap,
    empExpHeatmaps,
    empExpOhiHeatmaps,
    getOhiScoreForEmp,
    crossTabDemo,
    setCrossTabDemo,
    selectedTab,
    setSelectedTab,
    getOrgHealthData,
    orgHealth,
    singleQuestion,
    orgHealthFetched,
    enpsFetched,
}) {
    const { quartileColors = [], demographic = '', survey_version = '', lang: langTxt = '' } = defaultSettings;
    const { filters: filterParams } = apiParams || {};
    const staticTextDiagnose = getStaticTextDiagnose(langTxt);
    const { IE_CROSSTAB_OPTIONS, DEFAULT_STATE, ORGHEALTH_DEMO } = staticTextDiagnose;
    const staticText = getStaticText(langTxt);
    const { SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION } = staticText;
    const {
        BENCHMARK_HEAD,
        BENCHMARK_NA,
        PRACTICE_CATEGORY_MAP,
        PRACTICE_CATEGORY_MAP_3_2,
        EMPLOYEE_FACTORS_POSITIVE,
        EMPLOYEE_FACTORS_NEGATIVE,
    } = SITE_TEXT;
    const is3_2survey = survey_version === '3_2';
    const mapToUse = is3_2survey ? PRACTICE_CATEGORY_MAP_3_2 : PRACTICE_CATEGORY_MAP;

    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
        columns: 10000,
    };

    const getPage = () => {
        const defaultProps = {
            filters,
            selectedDemographic,
            ieHeatmap,
            ieHeatmapFetched,
            sortIeHeatmap,
            defaultSettings,
            apiParams,
            getHeatMapPractice,
            heatMapPracticeFetched,
            heatMapPractice,
            callHeatMap,
            selectedTab,
        };
        switch (crossTabDemo) {
            case DEFAULT_STATE:
                return <DeepDiveBoard {...defaultProps} />;
            case ORGHEALTH_DEMO:
                return (
                    <OrgHealth
                        orgHealth={orgHealth}
                        defaultSettings={defaultSettings}
                        singleQuestion={singleQuestion}
                        getOrgHealthData={getOrgHealthData}
                        filterParams={filterParams}
                        orgHealthFetched={orgHealthFetched}
                    />
                );
            default:
                return (
                    <EnpsDemo
                        filters={filters}
                        getEmpExpFactor={getEmpExpFactor}
                        selectedDemographic={selectedDemographic}
                        apiParams={apiParams}
                        defaultSettings={defaultSettings}
                        crossTabDemo={crossTabDemo}
                        empExpHeatmaps={empExpHeatmaps}
                        empExpOhiHeatmaps={empExpOhiHeatmaps}
                        getOhiScoreForEmp={getOhiScoreForEmp}
                        addOns={addOns}
                        enpsFetched={enpsFetched}
                    />
                );
        }
    };

    const tabOptionsToUse = crossTabDemo === DEFAULT_STATE ? mapToUse.slice(0, 3) : [];
    const legends = reverse(cloneDeep(IND_EXP_SCORE_CLASSIFICATION));
    return (
        <Fragment>
            <div className="deepDiveHeading">
                {IE_CROSSTAB_OPTIONS.filter(({ code }) => code === crossTabDemo)[0].label}
            </div>
            <div className="buttonWrapper">
                <div className="buttonContent">
                    {tabOptionsToUse.map(({ title: tabVal }, tabIndex) => {
                        return (
                            <button
                                key={tabVal}
                                type="button"
                                className={c('separateButton', { isActive: tabIndex === selectedTab })}
                                onClick={() => setSelectedTab(tabIndex)}
                            >
                                {tabVal}
                            </button>
                        );
                    })}
                </div>
                <div className="rightOptions">
                    <span>
                        <Select
                            IconComponent={KeyboardArrowDownSharpIcon}
                            value={crossTabDemo}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={({ target: { value: code } }) => setCrossTabDemo(code)}
                        >
                            {IE_CROSSTAB_OPTIONS.map(({ label, code }) => {
                                return (
                                    <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </span>
                </div>
            </div>
            <div
                className={c(
                    { orgHealthBoard: crossTabDemo === ORGHEALTH_DEMO },
                    'demographicBoard clearfix clearPadding'
                )}
            >
                {getPage()}
                <ul className="benchmark clearfix">
                    <li>{BENCHMARK_HEAD} : </li>
                    {quartileColors
                        .slice()
                        .reverse()
                        .map(({ title, background, border: borderColor }) => {
                            const bottomDecileVar = title === 'Bottom decile';
                            const BenchmarkTitle = title === 'No benchmarks';
                            return (
                                <li
                                    key={title}
                                    style={{ color: background }}
                                    className={c({
                                        hide: bottomDecileVar || BenchmarkTitle,
                                    })}
                                >
                                    <span
                                        className="benchmarkTriangle"
                                        style={{
                                            background,
                                            borderWidth: '13px 13px 0 0',
                                            borderColor: bottomDecileVar
                                                ? `transparent ${borderColor} transparent transparent`
                                                : `${borderColor} transparent transparent transparent`,
                                            borderStyle: 'solid',
                                        }}
                                    />
                                    <div style={{ color: 'black' }}>{title}</div>
                                </li>
                            );
                        })}
                    <li>
                        <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                        {BENCHMARK_NA}
                    </li>
                </ul>
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                    {legends.map(({ title, positiveBg: background, border: borderColor }) => (
                        <li key={title}>
                            <span style={{ background, borderColor }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                    {legends.map(({ title, negativeBg: background, border: borderColor }) => (
                        <li key={title}>
                            <span style={{ background, borderColor }} />
                            {title}
                        </li>
                    ))}
                </ul>
            </div>
        </Fragment>
    );
}

DeepDive.propTypes = {
    filters: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    getHeatMapPractice: PropTypes.func.isRequired,
    heatMapPracticeFetched: PropTypes.bool.isRequired,
    heatMapPractice: PropTypes.object.isRequired,
    callHeatMap: PropTypes.func.isRequired,
    empExpHeatmaps: PropTypes.object.isRequired,
    empExpOhiHeatmaps: PropTypes.object.isRequired,
    getOhiScoreForEmp: PropTypes.func.isRequired,
    crossTabDemo: PropTypes.string.isRequired,
    setCrossTabDemo: PropTypes.func.isRequired,
    ieHeatmap: PropTypes.object.isRequired,
    ieHeatmapFetched: PropTypes.bool.isRequired,
    getEmpExpFactor: PropTypes.func.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    selectedTab: PropTypes.number.isRequired,
    setSelectedTab: PropTypes.func.isRequired,
    getOrgHealthData: PropTypes.func.isRequired,
    orgHealth: PropTypes.object.isRequired,
    singleQuestion: PropTypes.object.isRequired,
    orgHealthFetched: PropTypes.bool.isRequired,
    enpsFetched: PropTypes.bool.isRequired,
};

export default DeepDive;
