const getFilterString = (filterArr = [], preString = '', endString = '') => {
    if (!filterArr.length) {
        return [];
    }
    const filters = [];
    filterArr.forEach(({ filter_items: options = [], filter_id: parentCode = '' }) => {
        const optionsCode = options
            .filter(({ isSelected }) => isSelected)
            .reduce((pre, { filter_item_id: code = '' }) => [...pre, code], []);
        if (optionsCode.length) {
            filters.push(`${preString}${parentCode}:${optionsCode.join(',')}${endString}`);
        }
    });
    if (!filters.length) {
        return '';
    }
    return filters.length > 1 ? filters.join(';') : filters[0];
};

export const getSelectedFilterIdsById = (filters, filterId) => {
    if (!filters.length) {
        return '';
    }
    const { filter_items: selectedFilterByIdChild = [] } = filters.find(({ filter_id }) => filter_id === filterId);

    return selectedFilterByIdChild.filter(({ isSelected }) => isSelected).map(({ filter_item_id }) => filter_item_id);
};

export const removeQuartileFromFilterParams = filterParam => {
    if (filterParam.includes('quartile')) {
        return filterParam.replace(/;quartile:[^;]*/g, '');
    }
    return filterParam;
};

export const removeYearFromFilterParams = filterParam => {
    return filterParam.replace(/year:[^;]*/g, '');
};

export const getTabName = name => {
    if (name.includes('McKinsey')) {
        return name.replace(/McKinsey /, '');
    }
    return name;
};
export default getFilterString;
