import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { List, ListItem, Collapse, Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import { cloneDeep } from 'lodash';

import { getStaticText } from '../../utils/constants';
// import { SITE_TEXT, FILTER_LIST_RANGE } from '../../utils/constants';
import { addZeroToNUmber } from '../../utils/functions';
import CloseIcon from '../../assets/Close.svg';
import SearchIcon from '../../assets/Magnifying.svg';
import '../FilterBar/index.scss';

function FilterOptions({ activeFilter, setDemoActive, updateFilters, activeProject, defaultSettings, defaultFilters }) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, FILTER_LIST_RANGE } = staticText;
    const { VIEW_ALL, ALL_SELECTED } = SITE_TEXT || {};
    const selectAllChild = ({ checked }) => {
        const newFilterItem = cloneDeep(activeFilter[0]);
        if (!checked) {
            updateFilters(defaultFilters[activeProject]);
            return null;
        }
        newFilterItem.isSelected = checked;
        newFilterItem.filter_items.forEach(option => {
            option.isSelected = checked;
        });
        setDemoActive(true);
        updateFilters(newFilterItem);
        return null;
    };

    const handleChildCheck = ({ checked }, childCode, parentCode) => {
        const newFilterItem = cloneDeep(activeFilter[0]);
        newFilterItem.isSelected = true;
        newFilterItem.isOpen = true;
        const selectedItemIndex = newFilterItem.filter_items.findIndex(
            ({ filter_item_id: code }) => code === childCode
        );
        if (parentCode === 'year') {
            newFilterItem.filter_items[selectedItemIndex].isSelected = true;
            newFilterItem.filter_items.forEach((item, i) => {
                if (i !== selectedItemIndex) {
                    newFilterItem.filter_items[i].isSelected = false;
                }
            });
            setDemoActive(true);
            return updateFilters(newFilterItem);
        }
        newFilterItem.filter_items[selectedItemIndex].isSelected = checked;
        const checkSelectedChild = newFilterItem.filter_items.filter(({ isSelected = false }) => isSelected).length;
        setDemoActive(true);
        if (!checkSelectedChild) {
            // newFilterItem.isSelected = false;
            setDemoActive(false);
            updateFilters(defaultFilters[activeProject]);
            return null;
        }
        updateFilters(newFilterItem);
        return null;
    };

    const selectOptions = ({ value }) => {
        const newFilterItem = cloneDeep(activeFilter)[0];
        newFilterItem.search = value;
        updateFilters(newFilterItem);
    };

    const resetSearch = () => {
        const newFilterItem = cloneDeep(activeFilter)[0];
        newFilterItem.search = '';
        updateFilters(newFilterItem);
    };

    const toggleView = () => {
        const newFilterItem = cloneDeep(activeFilter)[0];
        newFilterItem.viewAll = true;
        updateFilters(newFilterItem);
    };

    const checkSearchItem = (options, search) => {
        return options.filter(({ filter_item_name: l = '' }) => `${l}`.toLowerCase().includes(search.toLowerCase()));
    };

    return (
        <div className={c('filterContent clearfix increaseMargin')}>
            <div className="upperContent clearfix analyticsUpperContent">
                {activeFilter.map(
                    ({
                        filter_id: code = '',
                        filter_name: label = '',
                        filter_items: options = [],
                        isDefault = false,
                        viewAll = false,
                        search = '',
                    }) => {
                        const indexParent = activeProject;
                        const selectedLength = options.filter(({ isSelected }) => isSelected).length;
                        const selectedOptions = search.trim() ? checkSearchItem(options, search) : options;
                        const optionsToUse = viewAll ? selectedOptions : selectedOptions.slice(0, FILTER_LIST_RANGE);
                        const textToShow =
                            selectedLength === options.length
                                ? ALL_SELECTED
                                : `${addZeroToNUmber(selectedLength)}/${addZeroToNUmber(options.length)}`;

                        return (
                            <div className="mainListWrap" key={code}>
                                <Collapse in timeout="auto" unmountOnExit classes={{ container: 'childWrapper' }}>
                                    <div className="searchHead">
                                        <img src={SearchIcon} alt="" className="searchIcon" />
                                        <TextField
                                            fullWidth
                                            value={search}
                                            placeholder="Search"
                                            onChange={({ target }) => selectOptions(target)}
                                            inputProps={{
                                                'data-testid': `Text${label}`,
                                            }}
                                        />
                                        <img
                                            src={CloseIcon}
                                            alt=""
                                            className="closeIcon"
                                            onClick={() => resetSearch(indexParent)}
                                            data-testid={`Close${label}`}
                                        />
                                    </div>
                                    <div className="optionList analyticsOptionList">
                                        <List component="div" disablePadding className={c({ isDefault }, 'selectAll')}>
                                            <ListItem
                                                onClick={() =>
                                                    selectAllChild({ checked: selectedLength === options.length })
                                                }
                                            >
                                                <FormControlLabel
                                                    classes={{
                                                        root: 'optionItem selectAll',
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedLength === options.length}
                                                            onChange={({ target }) =>
                                                                selectAllChild(target, indexParent)
                                                            }
                                                            name={code}
                                                            inputProps={{
                                                                'data-testid': `ParentCheck${label}`,
                                                            }}
                                                        />
                                                    }
                                                    label="Select All"
                                                />
                                                {selectedLength ? (
                                                    <span className="showOption">{textToShow}</span>
                                                ) : null}
                                            </ListItem>
                                        </List>
                                        {optionsToUse.map(
                                            ({
                                                filter_item_name: childLabel = '',
                                                filter_item_id: childCode = '',
                                                isSelected = false,
                                                allowSelect = false,
                                                optionNo = '',
                                            }) => {
                                                return (
                                                    <List component="div" disablePadding key={childCode}>
                                                        <ListItem>
                                                            <FormControlLabel
                                                                classes={{
                                                                    root: c('optionItem', 'analyticsOptionItem', {
                                                                        width80: optionNo !== '',
                                                                        isDefault,
                                                                        allowSelect,
                                                                    }),
                                                                }}
                                                                control={
                                                                    <Checkbox
                                                                        checked={isSelected}
                                                                        onChange={({ target }) =>
                                                                            handleChildCheck(target, childCode, code)
                                                                        }
                                                                        name={childCode}
                                                                        inputProps={{
                                                                            'data-testid': `ChildCheck${childLabel}`,
                                                                        }}
                                                                    />
                                                                }
                                                                label={childLabel}
                                                            />
                                                        </ListItem>
                                                    </List>
                                                );
                                            }
                                        )}
                                    </div>
                                    {!viewAll && selectedOptions.length > FILTER_LIST_RANGE ? (
                                        <div onClick={() => toggleView(indexParent)} className="viewAll">
                                            {VIEW_ALL}
                                        </div>
                                    ) : null}
                                </Collapse>
                            </div>
                        );
                    }
                )}
            </div>
        </div>
    );
}

FilterOptions.defaultProps = {
    setDemoActive: () => {},
};

FilterOptions.propTypes = {
    setDemoActive: PropTypes.func,
    activeFilter: PropTypes.array.isRequired,
    updateFilters: PropTypes.func.isRequired,
    activeProject: PropTypes.number.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    defaultFilters: PropTypes.array.isRequired,
};

export default FilterOptions;
