import axios, { CancelToken } from 'axios';
import AuthStore from '../common/AuthStore';

function getConfig(token = '') {
    const { accessToken = '' } = AuthStore;
    const Authorization = token || accessToken;
    let cancel;
    // Return the cancel function and add it to the header.
    // See: https://github.com/mzabriskie/axios#cancellation
    const config = {
        cancelToken: new CancelToken(c => {
            // An executor function receives a cancel function as a parameter
            cancel = c;
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization,
        },
    };

    return {
        cancel,
        config,
    };
}

export function webApiGet(url, token) {
    const config = getConfig(token);
    return {
        request: axios.get(url, config.config),
        cancel: config.cancel,
    };
}

export function webApiPost(url, options, token) {
    const config = getConfig(token);
    return {
        request: axios.post(url, options, config.config),
        cancel: config.cancel,
    };
}

export function webApiDelete(url, data, token) {
    const config = getConfig(token);
    return {
        request: axios.delete(url, { ...config.config, data }),
        cancel: config.cancel,
    };
}

export function webApiPut(url, options, token) {
    const config = getConfig(token);
    return {
        request: axios.put(url, options, config.config),
        cancel: config.cancel,
    };
}
