import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import DemographicBoard from './DemographicBoard';
import ResurveyBoard from './ResurveyBoard';
import FilterBar from '../../../components/FilterBar';
import getStaticTextDiagnose from '../constants';
import { updateFilterParams } from '../../../utils/functions';
import './index.scss';

function MainBoard(props) {
    const {
        toolOpen,
        defaultSettings,
        filters,
        apiParams,
        projects,
        activeFilter,
        getHeatMapResurvey,
        heatMapResurveyTab,
        heatMapResurveyInfo,
        apiLoadingCount,
        updateHeatMapInfo,
        setResurveyTab,
        resurveyOutcomeHeatMapFetched,
        resurveyPracticeHeatMapFetched,
        resurveyOutcomeHeatMap,
        resurveyPracticeHeatMap,
        updateFilters,
        getPptData,
        isCfgClient,
        isIngClient,
        getReport,
        getRawPptData,
        reportError,
        getSinglePastReport,
        singlePastReport,
    } = props;
    const [currentView, setReport] = useState(0);
    const [reSurveyFilter, updateFilterView] = useState(false);

    const { benchmark = [], lang } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { OUTCOME_VAR } = staticTextDiagnose;
    const { ohid: oldId, year: oldYear } = apiParams;
    const { selectedProjectIndex = '' } = heatMapResurveyInfo;
    const { ohid: newOhid, year: newYear } = projects[selectedProjectIndex] || {};
    const projectInFilter = [`${oldId} - ${oldYear}`, `${newOhid} - ${newYear}`];
    const heatMapData = heatMapResurveyTab === OUTCOME_VAR ? resurveyOutcomeHeatMap : resurveyPracticeHeatMap;
    const { n_respondents = [0, 0], n_survey = [0, 0], items = [] } = heatMapData;
    const { items: subItems = [] } = items[0] || [];
    const { score: n_size = [0, 0] } = subItems[0] || {};

    const setFilters = (newFilters, filtersApplied) => {
        updateFilterView(false);
        const { demographics = [], benchmarks: oldBench = [] } = newFilters[0];
        const updatedParams = updateFilterParams(demographics, oldBench);
        updateFilters({ filters: newFilters, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
    };

    const getPage = () => {
        let page;
        switch (currentView) {
            case 0:
            default:
                page = <DemographicBoard {...props} setReport={setReport} />;
                break;
            case 1:
                page = newOhid ? (
                    <ResurveyBoard
                        toolOpen={toolOpen}
                        apiParams={apiParams}
                        apiLoadingCount={apiLoadingCount}
                        filters={filters}
                        activeFilter={activeFilter}
                        defaultSettings={defaultSettings}
                        updateFilterView={updateFilterView}
                        setReport={setReport}
                        projects={projects}
                        heatMapResurveyInfo={heatMapResurveyInfo}
                        setResurveyTab={setResurveyTab}
                        heatMapResurveyTab={heatMapResurveyTab}
                        updateHeatMapInfo={updateHeatMapInfo}
                        getHeatMapResurvey={getHeatMapResurvey}
                        resurveyOutcomeHeatMapFetched={resurveyOutcomeHeatMapFetched}
                        resurveyPracticeHeatMapFetched={resurveyPracticeHeatMapFetched}
                        heatMapData={heatMapData}
                        getPptData={getPptData}
                        isCfgClient={isCfgClient}
                        isIngClient={isIngClient}
                        getReport={getReport}
                        getRawPptData={getRawPptData}
                        reportError={reportError}
                        getSinglePastReport={getSinglePastReport}
                        singlePastReport={singlePastReport}
                        setFilters={setFilters}
                    />
                ) : null;
                break;
        }
        return page;
    };

    return (
        <Fragment>
            {getPage()}
            {reSurveyFilter && (
                <FilterBar
                    activeResetFilter={activeFilter}
                    size={n_size}
                    surveyRespondents={n_respondents}
                    surveys={n_survey}
                    filters={filters}
                    projectInFilter={projectInFilter}
                    currentView={currentView}
                    closeFilters={updateFilterView}
                    setFilters={setFilters}
                    defaultBenchmark={benchmark}
                    defaultSettings={defaultSettings}
                    years={[oldYear, newYear]}
                />
            )}
        </Fragment>
    );
}

MainBoard.propTypes = {
    filters: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    heatMapData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    heatMapPractice: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    demographicCurrentTab: PropTypes.string.isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    heatMapPracticeFetched: PropTypes.bool.isRequired,
    toolOpen: PropTypes.bool.isRequired,
    isOutcomeRetrieved: PropTypes.bool.isRequired,
    heatMapDataFetched: PropTypes.bool.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    isPracticeRetrived: PropTypes.bool.isRequired,
    loadHeatMapPractice: PropTypes.bool.isRequired,
    loadHeatMapOutcome: PropTypes.bool.isRequired,
    practicePageNo: PropTypes.number.isRequired,
    outcomePageNo: PropTypes.number.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    heatMapInclusion: PropTypes.object.isRequired,
    heatMapInclusionFetched: PropTypes.bool.isRequired,
    heatMapInclusionError: PropTypes.string.isRequired,
    sortHeatMapOutcome: PropTypes.func.isRequired,
    setDemographicCurrentTab: PropTypes.func.isRequired,
    getHeatMapOutcome: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    setSelectedDemographic: PropTypes.func.isRequired,
    getHeatMapPractice: PropTypes.func.isRequired,
    setHeatMapOutcomeData: PropTypes.func.isRequired,
    setHeatMapPracticeData: PropTypes.func.isRequired,
    setPracticePageNo: PropTypes.func.isRequired,
    setOutcomePageNo: PropTypes.func.isRequired,
    updateHeatMapPractice: PropTypes.func.isRequired,
    updateHeatMapOutcome: PropTypes.func.isRequired,
    getHeatMapInclusion: PropTypes.func.isRequired,
    getMappedDemographics: PropTypes.func.isRequired,
    heatMapResurveyInfo: PropTypes.object.isRequired,
    heatMapResurveyTab: PropTypes.string.isRequired,
    resurveyOutcomeHeatMapFetched: PropTypes.bool.isRequired,
    resurveyPracticeHeatMapFetched: PropTypes.bool.isRequired,
    resurveyOutcomeHeatMap: PropTypes.object.isRequired,
    resurveyPracticeHeatMap: PropTypes.object.isRequired,
    updateHeatMapInfo: PropTypes.func.isRequired,
    setResurveyTab: PropTypes.func.isRequired,
    getHeatMapResurvey: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    isCfgClient: PropTypes.bool.isRequired,
    isIngClient: PropTypes.bool.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    pastReports: PropTypes.object.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getResurveyParams: PropTypes.func.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
};

export default MainBoard;
