import React, { Fragment, useEffect, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';

import Loader from 'react-spinners/FadeLoader';
import Layout from '../../components/Layout';
import FilterBar from '../../components/FilterBar';
import Overview from './Overview';
import Scorecard from './Scorecard';
import QuestionBoard from './Questions';
import PracticeRanking from './PracticeRanking';
import Respondents from './Respondents';
import DemographicInclusion from './DemographicInclusion';
import { updateFilterParams, getInclusionBenchmarksLabel } from '../../utils/functions';
import { NAV_LINKS } from './constants';
import { getStaticText } from '../../utils/constants';

import './index.scss';
import AuthStore from '../../common/AuthStore';
import InclusionSelectModal from '../../components/InclusionSelectModal';
import { NEW_FEATURE_IMAGES } from '../Reports/constants';
import InclusionFeaturesModal from '../../components/InclusionFeaturesModal';
import InfoToast from '../../components/InformationToast/InfoToast';
import ErrorToast from '../../components/InformationToast/ErrorToast';

function Inclusion({
    inclusionApiCount,
    reportApiCount,
    getInclusionData,
    getInclusionDataResurvey,
    apiParams,
    iaApiParams,
    inclusionqbyq,
    getDemographics,
    filters,
    getIaBenchmarks,
    defaultSettings,
    activeResetFilter,
    inclusionDataFetched,
    dropDownValue,
    setDropDownValue,
    demoApiCount,
    allGapData,
    getAllGapData,
    scoreColor,
    updateInclusionFilters,
    updateReportApiParams,
    demographicList,
    reportApiParams,
    resurveyDataFetched,
    resurveyData,
    updateResurveyFilters,
    projects,
    getIaProjects,
    deleteResurveyMapping,
    getResurveyAllGapData,
    getMappedDemographic,
    getBenchmarkVersion,
    benchmarkVersions,
    bmVersionInUse,
    setBmVersionInUse,
    allGapDataFetched,
    setPrevSurvey,
    prevSurvey,
    showAvgGap,
    setAvgGapToggle,
    deleteScoreComparison,
    projectsFetched,
    getProjectsData,
    setDemoComparisonSurvey,
    demoComparisonSurvey,
    allProjects,
    resurveyGapDataFetched,
    resurveyDemoFetched,
    getQbyqNsize,
    demoNSize,
    demographicMappedData,
    error,
}) {
    const staticText = getStaticText();
    const { SITE_TEXT, INCLUSION_DEFAULT_BENCHMARK_STR } = staticText;
    const { INCLUSION_NAV_LINKS } = SITE_TEXT;
    const { ohid: currentOhid, filters: apiFilters = [], lang } = apiParams;
    const { filters: iaApiFilters, benchmarks: benchC = '', resurveyFilters = [] } = iaApiParams;
    const { exclude_inclusion_gap: exclude_from_gap = '' } = reportApiParams;
    const { benchmarks: benchmarkFilter = [] } = filters[0] || {};
    const { demographics: resurveyDemographic = [] } = filters[1] || {};
    const {
        benchmark = [],
        inclusion_threshold = 10,
        full_inclusion_access = false,
        inclusion_survey_type: surveyType = '',
    } = defaultSettings;
    const [activeIndex, updateIndex] = useState(full_inclusion_access ? 0 : 1);
    const [filterOpen, toggleFilterView] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [showResurvey, setResurvey] = useState(false);
    const [showDemoComparison, setDemoComparison] = useState(false);
    const [allSurveys, setAllSurveys] = useState([]);
    const [modalType, setModalType] = useState(1);
    const [feature, setFeature] = useState(true);
    const { qbyq = {} } = inclusionqbyq;
    const { qbyq: resurveyQbyq = {} } = resurveyData;
    const { size = 0 } = qbyq;
    const { size: resurveySize = 0 } = resurveyQbyq;
    const { qbyq: resHeatmapQbyq } = demoNSize || {};
    const { size: demoPrevNsize } = resHeatmapQbyq || {};

    const closeResurvey = () => {
        setPrevSurvey('');
        deleteResurveyMapping({});
        deleteScoreComparison({});
        setDemoComparison(false);
        setResurvey(false);
        setModalType(1);
    };

    const closeDemoComparisonSurvey = () => {
        setDemoComparisonSurvey('');
        deleteResurveyMapping({});
        deleteScoreComparison({});
        setDemoComparison(false);
        setResurvey(false);
        setModalType(1);
    };

    const showNewmodal = () => {
        AuthStore.showFeatureModal = false;
        setFeature(false);
    };

    useEffect(() => {
        const { showFeatureModal } = AuthStore;
        if (showFeatureModal === 'true') {
            setFeature(false);
        } else {
            setFeature(false);
        }
    }, [feature]);

    const selectProject = (ohId, demoGroup) => {
        if (modalType === 2) {
            if (activeIndex === 1) {
                closeResurvey();
            } else {
                closeDemoComparisonSurvey();
            }
            return;
        }
        if (activeIndex === 1) {
            const { ohid = [], year = '' } = allSurveys[ohId];
            setPrevSurvey({ prevSurvey: ohid, prevYear: year });
            setResurvey(false);
        } else {
            const { previous_ohid: ohid = [], previous_ohid_year = '' } = projects[ohId];
            setDemoComparisonSurvey({ prevSurvey: ohid, prevYear: previous_ohid_year, resurveyDropdown: demoGroup });
            setDemoComparison(false);
        }
    };

    const projectInFilter = () => {
        if (resurveyDataFetched && activeIndex === 1) {
            return [`${currentOhid} (Current)`, `${prevSurvey} (Previous)`];
        }
        if (resurveyGapDataFetched && activeIndex === 3) {
            return [`${currentOhid} (Current)`, `${demoComparisonSurvey} (Previous)`];
        }

        return [];
    };

    const setFilters = (newFilters, filtersApplied, activeFilter = true, indexToUpdate = 0) => {
        toggleFilterView(false);
        const { demographics = [], benchmarks = [] } = newFilters[indexToUpdate];
        const updatedParams = updateFilterParams(demographics, benchmarks);
        const { benchmarks: updatedBenchmark = '' } = updatedParams;
        const withBenchM = updatedBenchmark !== '-1:-1';
        if (indexToUpdate === 1 && (activeIndex === 1 || activeIndex === 3)) {
            if (activeIndex === 1) {
                deleteResurveyMapping({});
                const { filters: resFilters = [] } = updatedParams;
                updateResurveyFilters({
                    filters: newFilters,
                    filtersApplied: activeFilter ? filtersApplied : false,
                    apiParams: { ...apiParams, resurveyFilters: resFilters, benchmarks, with_benchmark: withBenchM },
                });
            } else {
                deleteScoreComparison({});
                const { filters: resFilters = [] } = updatedParams;
                updateResurveyFilters({
                    filters: newFilters,
                    filtersApplied: activeFilter ? filtersApplied : false,
                    apiParams: {
                        ...apiParams,
                        resurveyDemoFilters: resFilters,
                        benchmarks,
                        with_benchmark: withBenchM,
                    },
                });
            }
        }
        if (indexToUpdate === 0) {
            updateInclusionFilters({
                filters: newFilters,
                filtersApplied,
                apiParams: { ...apiParams, ...updatedParams, with_benchmark: withBenchM },
            });
        }
    };

    useEffect(() => {
        if (!projectsFetched) {
            const { report_id = '' } = AuthStore;
            getProjectsData({ report_id: parseInt(report_id, 10) });
        } else {
            setAllSurveys(
                allProjects.filter(({ ohid = '', inclusion_added = false }) => ohid !== currentOhid && inclusion_added)
            );
        }
        // eslint-disable-next-line
    }, [projectsFetched]);

    useEffect(() => {
        const { ohid, lang: currentLang, year } = apiParams;
        const { benchmarks = [], demographics = [] } = filters[0] || {};
        if (benchmarks.length <= 1) {
            getIaBenchmarks({ ohid, year, lang }, 0, benchmark, 'inclusion');
            getBenchmarkVersion({ ohid, year, version: 2, getVersion: 1 }, 0, benchmark, 'inclusion');
        }
        if (!demographics.length) {
            getDemographics({ ohid, lang: currentLang }, 0);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { benchmarks = [] } = filters[0] || {};
        if (!inclusionDataFetched) {
            if (benchmarks.length <= 1) {
                getInclusionData({
                    ohid: currentOhid,
                    filters: iaApiFilters,
                    inclusion_threshold,
                    with_benchmark: benchC !== '-1:-1',
                    benchmarks: INCLUSION_DEFAULT_BENCHMARK_STR,
                    pid: '',
                    benchmark_sub_version_id: bmVersionInUse,
                });
            } else {
                getInclusionData({
                    ohid: currentOhid,
                    filters: iaApiFilters,
                    inclusion_threshold,
                    with_benchmark: benchC !== '-1:-1',
                    benchmarks: benchC,
                    pid: '',
                    benchmark_sub_version_id: bmVersionInUse,
                });
            }
            if (!allGapDataFetched) {
                getAllGapData({
                    ohid: currentOhid,
                    filters: iaApiFilters,
                    lang,
                    inclusion_threshold,
                    exclude_from_gap,
                });
            }
            getIaProjects({
                ohid: currentOhid,
                previous_ohid: '1',
            });
        }
        // eslint-disable-next-line
    }, [inclusionDataFetched]);

    const getPage = () => {
        const { demographics = [] } = filters[0] || {};
        let page = <div />;
        if (showResurvey || showDemoComparison) {
            const projectArr = projects.map(arrItem => {
                const { previous_ohid: prev_ohid = '', previous_ohid_year = '' } = arrItem;
                return {
                    ohid: prev_ohid,
                    id: prev_ohid,
                    year: previous_ohid_year,
                };
            });
            page = (
                <InclusionSelectModal
                    defaultSettings={defaultSettings}
                    oldSelectedId={currentOhid}
                    projects={activeIndex === 1 ? allSurveys : projectArr}
                    projectsFetched
                    closeModal={() => (activeIndex === 1 ? setResurvey(false) : setDemoComparison(false))}
                    selectProject={selectProject}
                    modalType={modalType}
                    heatMapResurvey={activeIndex === 3}
                    demographics={demographics}
                    demographicMappedData={demographicMappedData}
                    getMappedDemographics={getMappedDemographic}
                />
            );
            return page;
        }
        switch (activeIndex) {
            case 0:
            default:
                page = (
                    <Overview
                        allGapData={allGapData}
                        filters={apiFilters}
                        benchmarkName={getInclusionBenchmarksLabel(benchmarkFilter)}
                        benchmarkVersions={benchmarkVersions}
                        bmVersionInUse={bmVersionInUse}
                        toggleFilterView={toggleFilterView}
                        inclusionqbyq={inclusionqbyq}
                        updateIndex={updateIndex}
                        scoreColor={scoreColor}
                        iaApiParams={iaApiParams}
                        demographics={demographics}
                        isPulse={surveyType === 'Pulse' && !!surveyType}
                    />
                );
                break;
            case 1:
                page = (
                    <Scorecard
                        allGapData={allGapData}
                        filters={apiFilters}
                        allFilters={filters}
                        toggleFilterView={toggleFilterView}
                        inclusionqbyq={inclusionqbyq}
                        currentOhid={currentOhid}
                        lang={lang}
                        inclusion_threshold={inclusion_threshold}
                        setDropDownValue={setDropDownValue}
                        dropDownValue={dropDownValue}
                        demographics={demographics}
                        inclusionApiCount={inclusionApiCount}
                        scoreColor={scoreColor}
                        iaApiParams={iaApiParams}
                        reportApiParams={reportApiParams}
                        project={prevSurvey}
                        resurveyFilters={resurveyFilters}
                        resurveyDemographic={resurveyDemographic}
                        isPulse={surveyType === 'Pulse' && !!surveyType}
                        benchmarkName={getInclusionBenchmarksLabel(benchmarkFilter)}
                        benchmarkVersions={benchmarkVersions}
                        bmVersionInUse={bmVersionInUse}
                        showGapToggle={showAvgGap}
                        setShowGapToggle={setAvgGapToggle}
                        showResurvey={showResurvey}
                        setResurvey={setResurvey}
                        setModalType={setModalType}
                        prevSurvey={prevSurvey}
                        deleteResurveyMapping={deleteResurveyMapping}
                        getInclusionDataResurvey={getInclusionDataResurvey}
                        getResurveyAllGapData={getResurveyAllGapData}
                        resurveyDemoFetched={resurveyDemoFetched}
                        getDemographics={getDemographics}
                        resurveyInactive={!allSurveys.length}
                    />
                );
                break;
            case 2:
                page = (
                    <QuestionBoard
                        allGapData={allGapData}
                        filters={apiFilters}
                        toggleFilterView={toggleFilterView}
                        inclusionqbyq={inclusionqbyq}
                        demographics={demographics}
                        scoreColor={scoreColor}
                        iaApiParams={iaApiParams}
                        reportApiParams={reportApiParams}
                        benchmarkName={getInclusionBenchmarksLabel(benchmarkFilter)}
                        benchmarkVersions={benchmarkVersions}
                        bmVersionInUse={bmVersionInUse}
                        showAvgGap={showAvgGap}
                        isPulse={surveyType === 'Pulse' && !!surveyType}
                    />
                );
                break;
            case 3:
                page = (
                    <DemographicInclusion
                        demographics={demographics}
                        filters={apiFilters}
                        iaApiParams={iaApiParams}
                        toggleFilterView={toggleFilterView}
                        currentOhid={currentOhid}
                        previousOhid={demoComparisonSurvey}
                        lang={lang}
                        inclusion_threshold={inclusion_threshold}
                        setDropDownValue={setDropDownValue}
                        dropDownValue={dropDownValue}
                        inclusionqbyq={inclusionqbyq}
                        scoreColor={scoreColor}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        reportApiParams={reportApiParams}
                        showResurvey={showDemoComparison}
                        resurveyFilters={resurveyFilters}
                        resurveyDemographic={resurveyDemographic}
                        isPulse={surveyType === 'Pulse' && !!surveyType}
                        benchmarkName={getInclusionBenchmarksLabel(benchmarkFilter)}
                        benchmarkVersions={benchmarkVersions}
                        bmVersionInUse={bmVersionInUse}
                        allIaGapData={allGapData}
                        allGapDataFetched={allGapDataFetched}
                        showAvgGap={showAvgGap}
                        setAvgGap={setAvgGapToggle}
                        resurveyDataFetched={resurveyDataFetched}
                        setResurvey={setDemoComparison}
                        setModalType={setModalType}
                        getDemographics={getDemographics}
                        getMappedDemographic={getMappedDemographic}
                        deleteScoreComparison={deleteScoreComparison}
                        resurveyDemoFetched={resurveyDemoFetched}
                        getQbyqNsize={getQbyqNsize}
                        demoNSize={demoNSize}
                        getResurveyAllGapData={getResurveyAllGapData}
                        resurveyInactive={!projects.length}
                        defaultSettings={defaultSettings}
                    />
                );
                break;
            case 4:
                page = (
                    <Respondents
                        filters={apiFilters}
                        toggleFilterView={toggleFilterView}
                        inclusionqbyq={inclusionqbyq}
                        ohid={currentOhid}
                        lang={lang}
                        inclusion_threshold={inclusion_threshold}
                        benchmarkName={getInclusionBenchmarksLabel(benchmarkFilter)}
                        demographics={demographics}
                        benchmarkVersions={benchmarkVersions}
                        bmVersionInUse={bmVersionInUse}
                    />
                );
                break;
            case 5:
                page = (
                    <PracticeRanking
                        allGapData={allGapData}
                        toggleFilterView={toggleFilterView}
                        inclusionqbyq={inclusionqbyq}
                        filters={apiFilters}
                        demographics={demographics}
                        scoreColor={scoreColor}
                        iaApiParams={iaApiParams}
                        reportApiParams={reportApiParams}
                        benchmarkName={getInclusionBenchmarksLabel(benchmarkFilter)}
                    />
                );
                break;
        }

        return page;
    };

    return (
        <Layout showHeader>
            <div className="inclusionContent clearfix">
                <ul className={c({ isLoading: inclusionApiCount })}>
                    {NAV_LINKS.map(({ imgSrc, imgSrcActive, itemIndex, checkToShow = false }, mainIndex) => {
                        const text = INCLUSION_NAV_LINKS[mainIndex];
                        if (checkToShow && !full_inclusion_access) return null;
                        return (
                            <li
                                className={c({
                                    selected: activeIndex === itemIndex,
                                })}
                                key={text}
                                data-testid={text}
                                disabled={activeIndex !== 2}
                                onClick={() => {
                                    updateIndex(itemIndex);
                                }}
                            >
                                <img src={activeIndex === itemIndex ? imgSrcActive : imgSrc} alt="" />
                                {text}
                            </li>
                        );
                    })}
                </ul>
                {reportApiCount || inclusionApiCount || demoApiCount ? (
                    <Loader
                        color="#000000"
                        cssOverride={{
                            margin: '30vh auto',
                        }}
                    />
                ) : (
                    <Fragment>
                        {error && <ErrorToast message={error} />}
                        {getPage()}
                        {filterOpen && (
                            <FilterBar
                                activeResetFilter={activeResetFilter}
                                size={[size, activeIndex === 3 ? demoPrevNsize : resurveySize, 0]}
                                surveyRespondents={[0]}
                                surveys={[0]}
                                filters={filters}
                                closeFilters={toggleFilterView}
                                setFilters={setFilters}
                                defaultBenchmark={[INCLUSION_DEFAULT_BENCHMARK_STR]}
                                showBenchmarks
                                defaultSettings={defaultSettings}
                                isInclusion
                                isIaResurvey={
                                    (resurveyDataFetched && activeIndex === 1) ||
                                    (resurveyGapDataFetched && activeIndex === 3)
                                }
                                projectInFilter={projectInFilter()}
                                updateReportApiParams={updateReportApiParams}
                                reportApiParams={reportApiParams}
                                demographicList={demographicList}
                                benchmarkVersions={benchmarkVersions}
                                bmVersionInUse={bmVersionInUse}
                                setBmVersionInUse={setBmVersionInUse}
                            />
                        )}
                        {feature && (
                            <InclusionFeaturesModal
                                imageArr={NEW_FEATURE_IMAGES}
                                closeModal={showNewmodal}
                                defaultSettings={defaultSettings}
                            />
                        )}
                        {!allGapDataFetched && !error && <InfoToast />}
                    </Fragment>
                )}
            </div>
        </Layout>
    );
}

Inclusion.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    inclusionDataFetched: PropTypes.bool.isRequired,
    allGapData: PropTypes.object.isRequired,
    inclusionqbyq: PropTypes.object.isRequired,
    reportApiCount: PropTypes.number.isRequired,
    inclusionApiCount: PropTypes.number.isRequired,
    demoApiCount: PropTypes.number.isRequired,
    apiParams: PropTypes.object.isRequired,
    getInclusionData: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    dropDownValue: PropTypes.string.isRequired,
    setDropDownValue: PropTypes.func.isRequired,
    getAllGapData: PropTypes.func.isRequired,
    scoreColor: PropTypes.object.isRequired,
    iaApiParams: PropTypes.object.isRequired,
    updateInclusionFilters: PropTypes.func.isRequired,
    getIaBenchmarks: PropTypes.func.isRequired,
    updateReportApiParams: PropTypes.func.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    demographicList: PropTypes.array.isRequired,
    getInclusionDataResurvey: PropTypes.func.isRequired,
    resurveyDataFetched: PropTypes.bool.isRequired,
    projects: PropTypes.array.isRequired,
    getIaProjects: PropTypes.func.isRequired,
    resurveyData: PropTypes.object.isRequired,
    deleteResurveyMapping: PropTypes.func.isRequired,
    updateResurveyFilters: PropTypes.func.isRequired,
    getResurveyAllGapData: PropTypes.func.isRequired,
    getMappedDemographic: PropTypes.func.isRequired,
    getBenchmarkVersion: PropTypes.func.isRequired,
    benchmarkVersions: PropTypes.array.isRequired,
    bmVersionInUse: PropTypes.number.isRequired,
    setBmVersionInUse: PropTypes.func.isRequired,
    allGapDataFetched: PropTypes.bool.isRequired,
    setPrevSurvey: PropTypes.func.isRequired,
    prevSurvey: PropTypes.string.isRequired,
    showAvgGap: PropTypes.bool.isRequired,
    setAvgGapToggle: PropTypes.func.isRequired,
    deleteScoreComparison: PropTypes.func.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    getProjectsData: PropTypes.func.isRequired,
    setDemoComparisonSurvey: PropTypes.func.isRequired,
    demoComparisonSurvey: PropTypes.string.isRequired,
    allProjects: PropTypes.array.isRequired,
    resurveyGapDataFetched: PropTypes.bool.isRequired,
    resurveyDemoFetched: PropTypes.bool.isRequired,
    getQbyqNsize: PropTypes.func.isRequired,
    demoNSize: PropTypes.object.isRequired,
    demographicMappedData: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
};

export default Inclusion;
