import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import QuestionBoard from './QuestionBoard';

import { getScoreData, getPptData, getSinglePastReport, setAddOnTabs, getScoreDataForHybrid } from '../actions';

import {
    selectScoreData,
    selectScoreDataFetched,
    selectApiParams,
    selectApiCount,
    selectFiltersApplied,
    getErrorInfo,
    selectFilters,
    selectPastReport,
    selectScoreDataHybrid,
    selectScoreDataHybridFetched,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';
import { selectPastReports, selectReportError } from '../../Reports/selector';
import { getReport, getRawPptData } from '../../Reports/actions';

const stateToProps = createStructuredSelector({
    scoreData: selectScoreData(),
    scoreDataFetched: selectScoreDataFetched(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    activeFilter: selectFiltersApplied(),
    errorInfo: getErrorInfo(),
    filters: selectFilters(),
    defaultSettings: getDefaultSettings(),
    pastReports: selectPastReports(),
    reportError: selectReportError(),
    singlePastReport: selectPastReport(),
    scoreDataHybrid: selectScoreDataHybrid(),
    scoreDataHybridFetched: selectScoreDataHybridFetched(),
});

const dispatchToProps = {
    getScoreData,
    getPptData,
    getReport,
    getRawPptData,
    getSinglePastReport,
    setAddOnTabs,
    getScoreDataForHybrid,
};

export default connect(stateToProps, dispatchToProps)(QuestionBoard);
