import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { MenuItem, Select, Tooltip } from '@material-ui/core';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilter from '../../assets/ActiveFilter.svg';
import getStaticTextDiagnose from '../../constants';
import { getStaticText } from '../../../../utils/constants';
import ErrorToast from '../../../../components/InformationToast/ErrorToast';
import { manipulateHeatmapTitle } from '../../../../utils/functions';

function NPSDemographic({
    npsData,
    selectedDemographic,
    callNpsDemographic,
    npsDemographicDataFetched,
    errorInfo,
    defaultSettings,
    addOnTab,
    setAddOnTabs,
    npsDropdown,
    toggleFilterView,
    activeFilter,
    demographics,
}) {
    const { lang } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { MAX_LENGTH_SUBSTRING, NPS_TABLE_HEADERS } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { NPS_NAV_LINKS, FILTERS, OVERALL } = SITE_TEXT || {};
    useEffect(() => {
        if (!npsDemographicDataFetched) {
            callNpsDemographic(selectedDemographic);
        }
        // eslint-disable-next-line
    }, [npsDemographicDataFetched]);

    const renderTable = itemsToIterate => {
        return itemsToIterate.map((subItems, index) => {
            const {
                promoters = '',
                detractors = '',
                passively_satisfied = '',
                enps = '',
                n_size = '',
                title: label = '',
            } = subItems;
            const title = manipulateHeatmapTitle(label);
            return (
                <div className="sectionDiv" key={index}>
                    <ul>
                        {!index ? (
                            <li className="sectionContent">{OVERALL}</li>
                        ) : (
                            <Tooltip placement="top" arrow title={title} aria-label={title}>
                                <li className="sectionContent">
                                    {title?.length > MAX_LENGTH_SUBSTRING
                                        ? `${title.substring(0, MAX_LENGTH_SUBSTRING) + '...'}`
                                        : title}
                                </li>
                            </Tooltip>
                        )}
                        <li className="contentList">{n_size.toLocaleString('en-US')}</li>
                        <li className="contentList">{promoters.toLocaleString('en-US')}</li>
                        <li className="contentList">{passively_satisfied.toLocaleString('en-US')}</li>
                        <li className="contentList">{detractors.toLocaleString('en-US')}</li>
                        <li className="contentList">{enps.toLocaleString('en-US')}</li>
                    </ul>
                </div>
            );
        });
    };
    return (
        <Fragment>
            <div className="optionWrapper">
                <div>
                    <ul className="leftNav">
                        {NPS_NAV_LINKS.map((tabName, i) => {
                            return (
                                <li
                                    data-testid={tabName}
                                    key={tabName}
                                    className={c({ activeLink: addOnTab === i })}
                                    onClick={() => setAddOnTabs(i)}
                                >
                                    {tabName}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="rightOptions">
                    <span>
                        <Select
                            IconComponent={KeyboardArrowDownSharpIcon}
                            value={npsDropdown}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={({ target: { value: codeN } }) => {
                                callNpsDemographic(codeN);
                            }}
                        >
                            {demographics.map(({ label, code: codeN }) => {
                                return (
                                    <MenuItem classes={{ root: 'demographicOptions' }} key={codeN} value={codeN}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </span>
                    <ul className="rightNav">
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" />
                            {FILTERS}
                        </li>
                    </ul>
                </div>
            </div>
            <div className="demographicBoard">
                {!!npsData.length && (
                    <section className="sectionWrapper">
                        <div id="myHeader" className="headerWrapper clearfix">
                            <div>
                                <ul className="tabLeft">
                                    {NPS_TABLE_HEADERS.map(header => {
                                        return (
                                            <li className="headerList" key={header}>
                                                {header}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="contentWrapper clearfix">
                            <div className="mainContent">
                                {npsData.length && !errorInfo ? renderTable(npsData) : null}
                            </div>
                        </div>
                    </section>
                )}
                {!npsData.length && errorInfo && <ErrorToast message={errorInfo} />}
            </div>
        </Fragment>
    );
}

NPSDemographic.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    callNpsDemographic: PropTypes.func.isRequired,
    npsDemographicDataFetched: PropTypes.bool.isRequired,
    npsData: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    demographics: PropTypes.array.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    npsDropdown: PropTypes.string.isRequired,
};

export default NPSDemographic;
