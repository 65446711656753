import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';

import { Box } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilter from '../../assets/ActiveFilter.svg';
import { getStaticText } from '../../../../utils/constants';
import ErrorToast from '../../../../components/InformationToast/ErrorToast';

const showProgress = value => {
    const progessStyle = {
        background: `
                    radial-gradient(closest-side, #ffffff 79%, transparent 80% 100%),
                    conic-gradient(#000000 ${value}%, rgb(239, 238, 239) 0)`,
    };
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <div className="progress-bar" style={progessStyle}>
                <progress value={value} min="0" max="100" style={{ height: 0, width: 0 }} />
                <div className="percentValue">{`${value}%`}</div>
            </div>
        </Box>
    );
};

function NPS({
    pageData,
    errorInfo,
    defaultSettings,
    npsDataFetched,
    callNps,
    toggleFilterView,
    activeFilter,
    addOnTab,
    setAddOnTabs,
    scoreData,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { promoters = '', passively_satisfied = '', detractors = '', enps = '' } = pageData || {};
    const { qbyq = {} } = scoreData;
    const { size = '' } = qbyq;
    const {
        NET_PROMOTER_SCORE,
        PROMOTERS,
        NET_PROMOTER_SCORE_DESC,
        PROMOTERS_DESC,
        PASSIVES,
        PASSIVES_DESC,
        DETRACTORS,
        DETRACTORS_DESC,
        NPS_SCORE,
        NPS_QUES,
        NPS_NAV_LINKS,
        FILTERS,
        TOTAL_RES_TEXT,
    } = SITE_TEXT || {};
    const checkDataLength = pageData && !!Object.keys(pageData).length;
    useEffect(() => {
        if (!npsDataFetched) {
            callNps();
        }
        // eslint-disable-next-line
    }, [npsDataFetched]);
    return (
        <Fragment>
            <div className="optionWrapper">
                <div>
                    <ul className="leftNav">
                        {NPS_NAV_LINKS.map((tabName, index) => {
                            return (
                                <li
                                    data-testid={tabName}
                                    key={tabName}
                                    className={c({ activeLink: addOnTab === index })}
                                    onClick={() => setAddOnTabs(index)}
                                >
                                    {tabName}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="rightOptions">
                    <ul className="rightNav">
                        <li className="strong">
                            {TOTAL_RES_TEXT} {size.toLocaleString('en-US')}
                        </li>
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" />
                            {FILTERS}
                        </li>
                    </ul>
                </div>
            </div>
            <section className="sectionWrapper">
                {checkDataLength ? (
                    <div className="npsWrapper">
                        <div className="npsInfo">
                            <div className="heading">{NPS_SCORE}</div>
                            <div className="description">{NPS_QUES}</div>
                        </div>
                        <div className="cardWrapper">
                            <div className="cardHeader">
                                <div className="heading">
                                    {NET_PROMOTER_SCORE}
                                    <span className="scoreBox">{enps}</span>
                                </div>
                                <div className="infoText">{NET_PROMOTER_SCORE_DESC}</div>
                            </div>
                            <div className="cardBody">
                                <div className="cardData">
                                    <div className="iconContainer">
                                        <ThumbUpIcon />
                                    </div>
                                    <div className="tagName">
                                        {PROMOTERS} <br />
                                        <span className="infoText">{PROMOTERS_DESC}</span>
                                    </div>
                                    <div className="percentProgress">{showProgress(promoters)}</div>
                                </div>
                                <div className="cardData">
                                    <div className="iconContainer rotateThumb">
                                        <ThumbUpIcon />
                                    </div>
                                    <div className="tagName">
                                        {PASSIVES}
                                        <br />
                                        <span className="infoText">{PASSIVES_DESC}</span>
                                    </div>
                                    <div className="percentProgress">{showProgress(passively_satisfied)}</div>
                                </div>
                                <div className="cardData">
                                    <div className="iconContainer">
                                        <ThumbDownIcon />
                                    </div>
                                    <div className="tagName">
                                        {DETRACTORS} <br />
                                        <span className="infoText">{DETRACTORS_DESC}</span>
                                    </div>
                                    <div className="percentProgress">{showProgress(detractors)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {!checkDataLength && errorInfo && <ErrorToast message={errorInfo} />}
            </section>
        </Fragment>
    );
}

NPS.propTypes = {
    pageData: PropTypes.object.isRequired,
    errorInfo: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    npsDataFetched: PropTypes.bool.isRequired,
    callNps: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    scoreData: PropTypes.object.isRequired,
};

export default NPS;
