import getStaticTextDiagnose from './constants';

const staticTextDiagnose = getStaticTextDiagnose();
const {
    GET_SCORE_DATA,
    GET_PRACTICE_DATA,
    GET_PRACTICE_BU_DATA,
    GET_DEMOGRAPHICS,
    GET_BENCHMARKS,
    UPDATE_LEFT_BENCHMARKS,
    UPDATE_RIGHT_BENCHMARKS,
    GET_HEATMAP_OUTCOME,
    UPDATE_API_PARAMS,
    SET_SELECTED_DEMOGRAPHIC,
    GET_HEATMAP_PRACTICE,
    GET_HEATMAP_INCLUSION,
    SET_DEMOGRAPHIC_CURRENT_TAB,
    UPDATE_HEAT_MAP_OUTCOME,
    UPDATE_HEAT_MAP_PRACTICE,
    SORT_HEAT_MAP_OUTCOME,
    GET_PROJECTS,
    GET_RESURVEY_DATA,
    RESET_RESURVEY_FETCHED,
    RESET_BU_FETCHED,
    RESET_PRACTICE_BU_FETCHED,
    UPDATE_FILTERS,
    GET_BU_DATA,
    GET_PPT_DATA,
    RES_PPT_DATA,
    GET_PPT_STATUS,
    RESET_FILTER_INDEX,
    CREATE_THEME,
    DELETE_THEME,
    GET_TOOLBOX_THEME,
    TOGGLE_CREATE_THEME,
    SET_THEME,
    SET_PRACTICE_DISPLAY,
    GET_COHESION_DATA,
    SET_COHESION_DEMOGRAPHIC,
    SET_HEATMAP_PRACTICE_DATA,
    SET_HEATMAP_OUTCOME_DATA,
    SET_PRACTICE_PAGE_NO,
    SET_OUTCOME_PAGE_NO,
    SET_PRACTICE_RETRIEVED,
    SET_OUTCOME_RETRIVED,
    GET_VALUES_DATA,
    GET_TEXT_COMMENTS,
    TOGGLE_PROGRESS_BAR,
    SET_COHESION_SORT,
    GET_IA_BENCHMARK,
    GET_MAPPED_DEMOGRAPHICS,
    UPDATE_HEATMAP_RESURVEY,
    GET_RESURVEY_HEATMAP_OUTCOME,
    GET_RESURVEY_HEATMAP_PRACTICE,
    SET_RESURVEY_HEATMAP_TAB,
    GET_BENCHMARK_VERSION,
    GET_NPS_DATA,
    GET_NPS_DEMOGRAPHIC_DATA,
    SET_NPS_DROPDOWN,
    GET_ENGAGEMENT_DATA,
    GET_ENGAGEMENT_DEMOGRAPHIC_DATA,
    SET_ENGAGEMENT_DROPDOWN,
    GET_CLIENT_ENGAGEMENT_DATA,
    GET_SINGLE_QUESTION,
    GET_IE_HEATMAP,
    SORT_IE_HEAT_MAP,
    GET_EMP_EXP_HEATMAP,
    GET_OHI_EMP_EXP_HEATMAP,
    SET_FILTER_COLUMNS,
    GET_CUSTOM_BU_SCORE_DATA,
    GET_CUSTOM_BU_LANG_DATA,
    GET_TLNX_ENGAGEMENT_DATA,
    SET_CUSTOM_BU_FILTER,
    GET_SINGLE_PAST_REPORT,
    GET_ORGHEALTH_DATA,
    GET_RANKING_HEATMAP,
    SET_RANKING_HEATMAP_RETRIEVED,
    SET_HEATMAP_RANKING_DATA,
    UPDATE_HEAT_MAP_RANKING,
    SET_RANKING_PAGE_NO,
    SORT_HEAT_MAP_RANKING,
    GET_COMP_YR_PRACTICE_DATA,
    SET_ADD_ON_TAB,
    GET_SCORE_DATA_HYBRID,
} = staticTextDiagnose;
export const getScoreData = payload => ({
    type: `${GET_SCORE_DATA}_PENDING`,
    payload,
});

export const getScoreDataSuccess = payload => ({
    type: `${GET_SCORE_DATA}_SUCCESS`,
    payload,
});

export const getScoreDataFailed = (payload = {}) => ({
    type: `${GET_SCORE_DATA}_FAILED`,
    payload,
});

export const getValuesData = payload => ({
    type: `${GET_VALUES_DATA}_PENDING`,
    payload,
});

export const getValuesDataSuccess = payload => ({
    type: `${GET_VALUES_DATA}_SUCCESS`,
    payload,
});

export const getValuesDataFailed = payload => ({
    type: `${GET_VALUES_DATA}_FAILED`,
    payload,
});

export const getTextComments = payload => ({
    type: `${GET_TEXT_COMMENTS}_PENDING`,
    payload,
});

export const getTextCommentsSuccess = payload => ({
    type: `${GET_TEXT_COMMENTS}_SUCCESS`,
    payload,
});

export const getTextCommentsFailed = payload => ({
    type: `${GET_TEXT_COMMENTS}_FAILED`,
    payload,
});

export const getPracticeData = payload => ({
    type: `${GET_PRACTICE_DATA}_PENDING`,
    payload,
});

export const getPracticeDataSuccess = payload => ({
    type: `${GET_PRACTICE_DATA}_SUCCESS`,
    payload,
});

export const getPracticeDataFailed = (payload = {}) => ({
    type: `${GET_PRACTICE_DATA}_FAILED`,
    payload,
});

export const getPracticeBuData = payload => ({
    type: `${GET_PRACTICE_BU_DATA}_PENDING`,
    payload,
});

export const getPracticeBuDataSuccess = payload => ({
    type: `${GET_PRACTICE_BU_DATA}_SUCCESS`,
    payload,
});

export const getPracticeBuDataFailed = (payload = {}) => ({
    type: `${GET_PRACTICE_BU_DATA}_FAILED`,
    payload,
});

export const getDemographics = (payload, indexToUpdate) => ({
    type: `${GET_DEMOGRAPHICS}_PENDING`,
    payload,
    indexToUpdate,
});

export const getDemographicsSuccess = payload => ({
    type: `${GET_DEMOGRAPHICS}_SUCCESS`,
    payload,
});

export const getDemographicsFailed = (payload = {}) => ({
    type: `${GET_DEMOGRAPHICS}_FAILED`,
    payload,
});

export const getBenchmarks = (payload, indexToUpdate = 0, defaultBenchmarks = []) => ({
    type: `${GET_BENCHMARKS}_PENDING`,
    payload,
    indexToUpdate,
    defaultBenchmarks,
});

export const getBenchmarksSuccess = payload => ({
    type: `${GET_BENCHMARKS}_SUCCESS`,
    payload,
});

export const getBenchmarksFailed = (payload = {}) => ({
    type: `${GET_BENCHMARKS}_FAILED`,
    payload,
});

export const getIaBenchmarksSuccess = payload => ({
    type: `${GET_IA_BENCHMARK}_SUCCESS`,
    payload,
});

export const getIaBenchmarksFailed = (payload = {}) => ({
    type: `${GET_IA_BENCHMARK}_FAILED`,
    payload,
});

export const getIaBenchmarks = (payload, indexToUpdate = 0, defaultBenchmarks = []) => ({
    type: `${GET_IA_BENCHMARK}_PENDING`,
    payload,
    indexToUpdate,
    defaultBenchmarks,
});

export const getBenchmarkVersionSuccess = payload => ({
    type: `${GET_BENCHMARK_VERSION}_SUCCESS`,
    payload,
});

export const getBenchmarkVersionFailed = (payload = {}) => ({
    type: `${GET_BENCHMARK_VERSION}_FAILED`,
    payload,
});

export const getBenchmarkVersion = (payload, indexToUpdate = 0, defaultBenchmarks = []) => ({
    type: `${GET_BENCHMARK_VERSION}_PENDING`,
    payload,
    indexToUpdate,
    defaultBenchmarks,
});

export const updateLeftBenchmarks = payload => ({
    type: UPDATE_LEFT_BENCHMARKS,
    payload,
});

export const updateRightBenchmarks = payload => ({
    type: UPDATE_RIGHT_BENCHMARKS,
    payload,
});

export const updateFilters = payload => ({
    type: UPDATE_FILTERS,
    payload,
});

export const getHeatMapOutcome = (payload, callApi = false) => ({
    type: `${GET_HEATMAP_OUTCOME}_PENDING`,
    payload,
    callApi,
});

export const getHeatMapOutcomeSuccess = payload => ({
    type: `${GET_HEATMAP_OUTCOME}_SUCCESS`,
    payload,
});

export const getHeatMapOutcomeFailed = (payload = {}) => ({
    type: `${GET_HEATMAP_OUTCOME}_FAILED`,
    payload,
});

export const getHeatMapPractice = (payload, callApi = false) => ({
    type: `${GET_HEATMAP_PRACTICE}_PENDING`,
    payload,
    callApi,
});

export const getHeatMapPracticeSuccess = payload => ({
    type: `${GET_HEATMAP_PRACTICE}_SUCCESS`,
    payload,
});

export const getHeatMapPracticeFailed = (payload = {}) => ({
    type: `${GET_HEATMAP_PRACTICE}_FAILED`,
    payload,
});

export const getHeatMapInclusion = payload => ({
    type: `${GET_HEATMAP_INCLUSION}_PENDING`,
    payload,
});

export const getHeatMapInclusionSuccess = payload => ({
    type: `${GET_HEATMAP_INCLUSION}_SUCCESS`,
    payload,
});

export const getHeatMapInclusionFailed = payload => ({
    type: `${GET_HEATMAP_INCLUSION}_FAILED`,
    payload,
});

export const updateApiParams = payload => ({
    type: UPDATE_API_PARAMS,
    payload,
});

export const setSelectedDemographic = payload => ({
    type: SET_SELECTED_DEMOGRAPHIC,
    payload,
});

export const setDemographicCurrentTab = payload => ({
    type: SET_DEMOGRAPHIC_CURRENT_TAB,
    payload,
});

export const updateHeatMapOutcome = payload => {
    return {
        type: `${UPDATE_HEAT_MAP_OUTCOME}_PENDING`,
        payload,
    };
};

export const updateHeatMapOutcomeSuccess = payload => {
    return {
        type: `${UPDATE_HEAT_MAP_OUTCOME}_SUCCESS`,
        payload,
    };
};

export const updateHeatMapPractice = payload => ({
    type: `${UPDATE_HEAT_MAP_PRACTICE}_PENDING`,
    payload,
});

export const updateHeatMapPracticeSuccess = payload => ({
    type: `${UPDATE_HEAT_MAP_PRACTICE}_SUCCESS`,
    payload,
});

export const sortHeatMapOutcome = payload => ({
    type: SORT_HEAT_MAP_OUTCOME,
    payload,
});

export const getProjectsData = payload => ({
    type: `${GET_PROJECTS}_PENDING`,
    payload,
});

export const getProjectsDataSuccess = payload => ({
    type: `${GET_PROJECTS}_SUCCESS`,
    payload,
});

export const getProjectsDataFailed = (payload = {}) => ({
    type: `${GET_PROJECTS}_FAILED`,
    payload,
});

export const resetReSurveyDataFetched = payload => ({
    type: RESET_RESURVEY_FETCHED,
    payload,
});

export const getReSurveyData = payload => ({
    type: `${GET_RESURVEY_DATA}_PENDING`,
    payload,
});

export const getReSurveyDataSuccess = payload => ({
    type: `${GET_RESURVEY_DATA}_SUCCESS`,
    payload,
});

export const getReSurveyDataFailed = (payload = {}) => ({
    type: `${GET_RESURVEY_DATA}_FAILED`,
    payload,
});

export const getBuData = payload => ({
    type: `${GET_BU_DATA}_PENDING`,
    payload,
});

export const getBuDataSuccess = payload => ({
    type: `${GET_BU_DATA}_SUCCESS`,
    payload,
});

export const getBuDataFailed = (payload = {}) => ({
    type: `${GET_BU_DATA}_FAILED`,
    payload,
});

export const resetBuDataFetched = payload => ({
    type: RESET_BU_FETCHED,
    payload,
});

export const resetPracticeBuDataFetched = payload => ({
    type: RESET_PRACTICE_BU_FETCHED,
    payload,
});

export const getPptData = (
    payload,
    token,
    pptName = 'Scorecard',
    countToAdd = 1,
    taskId = '',
    report_name = '',
    downloadType = 'ppt'
) => ({
    type: `${GET_PPT_DATA}_PENDING`,
    payload,
    token,
    pptName,
    countToAdd,
    taskId,
    report_name,
    downloadType,
});

export const getPptDataSuccess = payload => ({
    type: `${GET_PPT_DATA}_SUCCESS`,
    payload,
});

export const getPptDataFailed = (payload = {}) => ({
    type: `${GET_PPT_DATA}_FAILED`,
    payload,
});

export const resPptData = payload => ({
    type: `${RES_PPT_DATA}_PENDING`,
    payload,
});

export const resPptDataSuccess = payload => ({
    type: `${RES_PPT_DATA}_SUCCESS`,
    payload,
});

export const resPptDataFailed = (payload = {}) => ({
    type: `${RES_PPT_DATA}_FAILED`,
    payload,
});

export const getPptDataStatus = (token, taskId) => ({
    type: `${GET_PPT_STATUS}_PENDING`,
    token,
    taskId,
});

export const getPptDataStatusSuccess = payload => ({
    type: `${GET_PPT_STATUS}_SUCCESS`,
    payload,
});

export const getPptDataStatusFailed = (payload = {}) => ({
    type: `${GET_PPT_STATUS}_FAILED`,
    payload,
});

export const resetFilterIndex = (payload = {}) => ({
    type: RESET_FILTER_INDEX,
    payload,
});

export const getToolData = () => ({
    type: `${GET_TOOLBOX_THEME}_PENDING`,
});

export const getToolDataSuccess = payload => ({
    type: `${GET_TOOLBOX_THEME}_SUCCESS`,
    payload,
});

export const getToolDataFailed = (payload = {}) => ({
    type: `${GET_TOOLBOX_THEME}_FAILED`,
    payload,
});

export const createThemeData = payload => ({
    type: `${CREATE_THEME}_PENDING`,
    payload,
});

export const createThemeDataSuccess = payload => ({
    type: `${CREATE_THEME}_SUCCESS`,
    payload,
});

export const createThemeDataFailed = (payload = {}) => ({
    type: `${CREATE_THEME}_FAILED`,
    payload,
});

export const deleteTheme = (payload, indexToRemove) => ({
    type: `${DELETE_THEME}_PENDING`,
    payload,
    indexToRemove,
});

export const deleteThemeSuccess = payload => ({
    type: `${DELETE_THEME}_SUCCESS`,
    payload,
});

export const deleteThemeFailed = (payload = {}) => ({
    type: `${DELETE_THEME}_FAILED`,
    payload,
});

export const setTheme = payload => ({
    type: SET_THEME,
    payload,
});

export const setPracticeDisplay = payload => ({
    type: SET_PRACTICE_DISPLAY,
    payload,
});

export const toggleCreateTheme = payload => ({
    type: TOGGLE_CREATE_THEME,
    payload,
});

export const getCohesionData = (payload, updateScore = true) => ({
    type: `${GET_COHESION_DATA}_PENDING`,
    payload,
    updateScore,
});

export const getCohesionDataSuccess = payload => ({
    type: `${GET_COHESION_DATA}_SUCCESS`,
    payload,
});

export const getCohesionDataFailed = (payload = {}) => ({
    type: `${GET_COHESION_DATA}_FAILED`,
    payload,
});

export const setCohesionDemographic = payload => ({
    type: SET_COHESION_DEMOGRAPHIC,
    payload,
});

export const setHeatMapOutcomeData = payload => ({
    type: SET_HEATMAP_OUTCOME_DATA,
    payload,
});

export const setHeatMapPracticeData = payload => ({
    type: SET_HEATMAP_PRACTICE_DATA,
    payload,
});

export const setHeatMapRankingData = payload => ({
    type: SET_HEATMAP_RANKING_DATA,
    payload,
});

export const setPracticePageNo = payload => ({
    type: SET_PRACTICE_PAGE_NO,
    payload,
});

export const setOutcomePageNo = payload => ({
    type: SET_OUTCOME_PAGE_NO,
    payload,
});

export const setRankingPageNo = payload => ({
    type: SET_RANKING_PAGE_NO,
    payload,
});

export const setPracticeRetrieved = payload => ({
    type: SET_PRACTICE_RETRIEVED,
    payload,
});

export const setOutcomeRetrieved = payload => ({
    type: SET_OUTCOME_RETRIVED,
    payload,
});

export const setRankingHeatmapRetrieved = payload => ({
    type: SET_RANKING_HEATMAP_RETRIEVED,
    payload,
});

export const toggleProgressBar = payload => ({
    type: TOGGLE_PROGRESS_BAR,
    payload,
});

export const setCohesionSort = payload => ({
    type: `${SET_COHESION_SORT}_PENDING`,
    payload,
});

export const setCohesionSortSuccess = payload => ({
    type: `${SET_COHESION_SORT}_SUCCESS`,
    payload,
});

export const setCohesionSortFailed = (payload = {}) => ({
    type: `${SET_COHESION_SORT}_FAILED`,
    payload,
});

export const getMappedDemographics = (payload, indexToUse) => ({
    type: `${GET_MAPPED_DEMOGRAPHICS}_PENDING`,
    payload,
    indexToUse,
});

export const getMappedDemographicsSuccess = payload => ({
    type: `${GET_MAPPED_DEMOGRAPHICS}_SUCCESS`,
    payload,
});

export const getMappedDemographicsFailed = (payload = {}) => ({
    type: `${GET_MAPPED_DEMOGRAPHICS}_FAILED`,
    payload,
});

export const updateHeatMapInfo = (payload = {}) => ({
    type: UPDATE_HEATMAP_RESURVEY,
    payload,
});

export const setResurveyTab = payload => ({
    type: SET_RESURVEY_HEATMAP_TAB,
    payload,
});

export const getHeatMapResurvey = payload => ({
    type: `${GET_RESURVEY_HEATMAP_OUTCOME}_PENDING`,
    payload,
});

export const getHeatMapResurveyOutcomeSuccess = payload => ({
    type: `${GET_RESURVEY_HEATMAP_OUTCOME}_SUCCESS`,
    payload,
});

export const getHeatMapResurveyOutcomeFailed = (payload = {}) => ({
    type: `${GET_RESURVEY_HEATMAP_OUTCOME}_FAILED`,
    payload,
});

export const getHeatMapResurveyPracticeSuccess = payload => ({
    type: `${GET_RESURVEY_HEATMAP_PRACTICE}_SUCCESS`,
    payload,
});

export const getHeatMapResurveyPracticeFailed = (payload = {}) => ({
    type: `${GET_RESURVEY_HEATMAP_PRACTICE}_FAILED`,
    payload,
});

export const getNPSData = payload => ({
    type: `${GET_NPS_DATA}_PENDING`,
    payload,
});

export const getNPSDataSuccess = payload => ({
    type: `${GET_NPS_DATA}_SUCCESS`,
    payload,
});

export const getNPSDataFailed = payload => ({
    type: `${GET_NPS_DATA}_FAILED`,
    payload,
});

export const getNPSDemographicData = payload => ({
    type: `${GET_NPS_DEMOGRAPHIC_DATA}_PENDING`,
    payload,
});

export const getNPSDemographicDataSuccess = payload => ({
    type: `${GET_NPS_DEMOGRAPHIC_DATA}_SUCCESS`,
    payload,
});

export const getNPSDemographicDataFailed = payload => ({
    type: `${GET_NPS_DEMOGRAPHIC_DATA}_FAILED`,
    payload,
});

export const setNpsDropdown = payload => ({
    type: SET_NPS_DROPDOWN,
    payload,
});

export const getEngagementData = payload => ({
    type: `${GET_ENGAGEMENT_DATA}_PENDING`,
    payload,
});

export const getEngagementDataSuccess = payload => ({
    type: `${GET_ENGAGEMENT_DATA}_SUCCESS`,
    payload,
});

export const getEngagementDataFailed = payload => ({
    type: `${GET_ENGAGEMENT_DATA}_FAILED`,
    payload,
});

export const getEngagementDemographicData = payload => ({
    type: `${GET_ENGAGEMENT_DEMOGRAPHIC_DATA}_PENDING`,
    payload,
});

export const getEngagementDemographicDataSuccess = payload => ({
    type: `${GET_ENGAGEMENT_DEMOGRAPHIC_DATA}_SUCCESS`,
    payload,
});

export const getEngagementDemographicDataFailed = payload => ({
    type: `${GET_ENGAGEMENT_DEMOGRAPHIC_DATA}_FAILED`,
    payload,
});

export const setEngagementDropdown = payload => ({
    type: SET_ENGAGEMENT_DROPDOWN,
    payload,
});

export const getClientEngagementData = payload => ({
    type: `${GET_CLIENT_ENGAGEMENT_DATA}_PENDING`,
    payload,
});

export const getClientEngagementDataSuccess = payload => ({
    type: `${GET_CLIENT_ENGAGEMENT_DATA}_SUCCESS`,
    payload,
});

export const getClientEngagementDataFailed = payload => ({
    type: `${GET_CLIENT_ENGAGEMENT_DATA}_FAILED`,
    payload,
});

export const getSingleQuestionSuccess = payload => ({
    type: `${GET_SINGLE_QUESTION}_SUCCESS`,
    payload,
});

export const getSingleQuestionFailed = payload => ({
    type: `${GET_SINGLE_QUESTION}_FAILED`,
    payload,
});

export const getSingleQuestion = payload => ({
    type: `${GET_SINGLE_QUESTION}_PENDING`,
    payload,
});

export const getIeHeatmapSuccess = payload => ({
    type: `${GET_IE_HEATMAP}_SUCCESS`,
    payload,
});

export const getIeHeatmapFailed = payload => ({
    type: `${GET_IE_HEATMAP}_FAILED`,
    payload,
});

export const getIeHeatmap = payload => ({
    type: `${GET_IE_HEATMAP}_PENDING`,
    payload,
});

export const sortIeHeatmap = payload => ({
    type: SORT_IE_HEAT_MAP,
    payload,
});

export const getEmpExpFactorSuccess = payload => ({
    type: `${GET_EMP_EXP_HEATMAP}_SUCCESS`,
    payload,
});

export const getEmpExpFactorFailed = payload => ({
    type: `${GET_EMP_EXP_HEATMAP}_FAILED`,
    payload,
});

export const getEmpExpFactor = payload => ({
    type: `${GET_EMP_EXP_HEATMAP}_PENDING`,
    payload,
});

export const getOhiScoreForEmpExpSuccess = payload => ({
    type: `${GET_OHI_EMP_EXP_HEATMAP}_SUCCESS`,
    payload,
});

export const getOhiScoreForEmpFailed = payload => ({
    type: `${GET_OHI_EMP_EXP_HEATMAP}_FAILED`,
    payload,
});

export const getOhiScoreForEmp = payload => ({
    type: `${GET_OHI_EMP_EXP_HEATMAP}_PENDING`,
    payload,
});

export const setfilterColumns = payload => ({
    type: SET_FILTER_COLUMNS,
    payload,
});

export const getCustomBuScoreData = payload => ({
    type: `${GET_CUSTOM_BU_SCORE_DATA}_PENDING`,
    payload,
});

export const getCustomBuScoreDataSuccess = payload => ({
    type: `${GET_CUSTOM_BU_SCORE_DATA}_SUCCESS`,
    payload,
});

export const getCustomBuScoreDataFailed = payload => ({
    type: `${GET_CUSTOM_BU_SCORE_DATA}_FAILED`,
    payload,
});

export const getCustomBuLangData = payload => ({
    type: `${GET_CUSTOM_BU_LANG_DATA}_PENDING`,
    payload,
});

export const getCustomBuLangDataSuccess = payload => ({
    type: `${GET_CUSTOM_BU_LANG_DATA}_SUCCESS`,
    payload,
});

export const getCustomBuLangDataFailed = payload => ({
    type: `${GET_CUSTOM_BU_LANG_DATA}_FAILED`,
    payload,
});

export const getTlnxEngagementData = payload => ({
    type: `${GET_TLNX_ENGAGEMENT_DATA}_PENDING`,
    payload,
});

export const getTlnxEngagementDataSuccess = payload => ({
    type: `${GET_TLNX_ENGAGEMENT_DATA}_SUCCESS`,
    payload,
});

export const getTlnxEngagementDataFailed = payload => ({
    type: `${GET_TLNX_ENGAGEMENT_DATA}_FAILED`,
    payload,
});

export const setCustomBUFilters = payload => ({
    type: SET_CUSTOM_BU_FILTER,
    payload,
});

export const getSinglePastReport = payload => ({
    type: `${GET_SINGLE_PAST_REPORT}_PENDING`,
    payload,
});

export const getPastReportSuccess = payload => ({
    type: `${GET_SINGLE_PAST_REPORT}_SUCCESS`,
    payload,
});

export const getPastReportFailed = (payload = {}) => ({
    type: `${GET_SINGLE_PAST_REPORT}_FAILED`,
    payload,
});

export const getOrgHealthData = payload => ({
    type: `${GET_ORGHEALTH_DATA}_PENDING`,
    payload,
});

export const getOrgHealthDataSuccess = payload => ({
    type: `${GET_ORGHEALTH_DATA}_SUCCESS`,
    payload,
});

export const getOrgHealthDataFailed = payload => ({
    type: `${GET_ORGHEALTH_DATA}_FAILED`,
    payload,
});

export const getRankingHeatMap = (payload, callApi = false) => ({
    type: `${GET_RANKING_HEATMAP}_PENDING`,
    payload,
    callApi,
});

export const getRankingHeatMapSuccess = payload => ({
    type: `${GET_RANKING_HEATMAP}_SUCCESS`,
    payload,
});

export const getRankingHeatMapFailed = (payload = {}) => ({
    type: `${GET_RANKING_HEATMAP}_FAILED`,
    payload,
});

export const updateHeatMapRanking = payload => ({
    type: `${UPDATE_HEAT_MAP_RANKING}_PENDING`,
    payload,
});

export const updateHeatMapRankingSuccess = payload => ({
    type: `${UPDATE_HEAT_MAP_RANKING}_SUCCESS`,
    payload,
});

export const sortHeatMapRanking = payload => ({
    type: SORT_HEAT_MAP_RANKING,
    payload,
});

export const getComparisonYrPracticeData = payload => ({
    type: `${GET_COMP_YR_PRACTICE_DATA}_PENDING`,
    payload,
});

export const getComparisonYrPracticeDataSuccess = payload => ({
    type: `${GET_COMP_YR_PRACTICE_DATA}_SUCCESS`,
    payload,
});

export const getComparisonYrPracticeDataFailed = (payload = {}) => ({
    type: `${GET_COMP_YR_PRACTICE_DATA}_FAILED`,
    payload,
});

export const setAddOnTabs = payload => ({
    type: SET_ADD_ON_TAB,
    payload,
});

export const getScoreDataForHybrid = payload => ({
    type: `${GET_SCORE_DATA_HYBRID}_PENDING`,
    payload,
});

export const getScoreDataForHybridSuccess = payload => ({
    type: `${GET_SCORE_DATA_HYBRID}_SUCCESS`,
    payload,
});

export const getScoreDataForHybridFailed = (payload = {}) => ({
    type: `${GET_SCORE_DATA_HYBRID}_FAILED`,
    payload,
});
