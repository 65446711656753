import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    getDataAtGlanceFailed,
    getDataAtGlanceSuccess,
    getFilterDataFailed,
    getFilterDataSuccess,
    getScorecardFailed,
    getScorecardSuccess,
    getAnalyticsPerCategoryFailed,
    getAnalyticsPerCategorySuccess,
    getRankingFailed,
    getRankingSuccess,
    getFlagpoleSuccess,
    getFlagpoleFailed,
    getDifferentialSuccess,
    getDifferentialFailed,
    getFiltersMappingSuccess,
    getFiltersMappingFailed,
    getDefaultYearSuccess,
    getDefaultYearFailed,
} from './actions';
import { getStaticTextAnalytics } from './constants';
import {
    getAnalyticsDataUrl,
    getFilterDataUrl,
    getScorecardUrl,
    getAnalyticsPerCategoryUrl,
    getFlagpoleUrl,
    getAnalyticsRankingUrl,
    getDifferentialUrl,
    getFilterMappingUrl,
    getDefaultYearUrl,
} from './apis';
import { removeQuartileFromFilterParams } from './assets/functions';

const staticTextAnalytics = getStaticTextAnalytics();
const {
    GET_DATA_AT_GLANCE,
    GET_FILTERS_DATA,
    GET_ANALYTICS_SCORECARD,
    GET_ANALYTICS_FLAGPOLE,
    GET_ANALYTICS_CATEGORY,
    GET_RANKING,
    GET_DIFFERENTIAL,
    GET_FILTERS_MAPPING,
    GET_DEFAULT_YEAR,
} = staticTextAnalytics;

export function* filtersDataSaga({ payload }) {
    try {
        const { data = [] } = yield call(getFilterDataUrl, payload);
        const sortedData = data.sort(({ display_order: a }, { display_order: b }) => a - b);
        const year = `${payload}`;
        if (sortedData.length) {
            const filterYearIndex = sortedData.findIndex(({ filter_id }) => filter_id === 'year');
            const { filter_items = [] } = sortedData[filterYearIndex];
            const latestYrIndex = filter_items.findIndex(({ filter_item_id }) => filter_item_id === payload);
            filter_items.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                if (parseInt(filter_item_name, 10) >= 2023) {
                    sortedData[filterYearIndex].filter_items[i].allowSelect = true;
                }
            });
            sortedData[filterYearIndex].isSelected = true;
            sortedData[filterYearIndex].isDefault = true;
            sortedData[filterYearIndex].filter_items[latestYrIndex].isSelected = true;
        }
        yield put(getFilterDataSuccess({ data: sortedData, year }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getFilterDataFailed({ message, error, status }));
    }
}

export function* filtersMappingSaga({ payload }) {
    try {
        const { data = {} } = yield call(getFilterMappingUrl, payload);
        yield put(getFiltersMappingSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getFiltersMappingFailed({ message, error, status }));
    }
}

export function* dataAtGlanceSaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsDataUrl, payload);
        yield put(getDataAtGlanceSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getDataAtGlanceFailed({ message, error, status }));
    }
}

export function* differentialDataSaga({ payload }) {
    const newPayload = removeQuartileFromFilterParams(payload);
    try {
        const { data = {} } = yield call(getDifferentialUrl, newPayload);
        yield put(getDifferentialSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getDifferentialFailed({ message, errors, status }));
    }
}

export function* rankingDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsRankingUrl, payload);
        yield put(getRankingSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getRankingFailed({ message, errors, status }));
    }
}

export function* analyticsPerCategorySaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsPerCategoryUrl, payload);
        const state = yield select();
        const analyticsData = state.get('analytics').toJS() || {};
        const { analyticsPerCategoryData = {} } = analyticsData || {};
        const { filter_id = '' } = payload || {};
        const newData = {
            ...analyticsPerCategoryData,
            [filter_id]: data,
        };
        yield put(getAnalyticsPerCategorySuccess(newData));
    } catch ({ response = {} }) {
        const state = yield select();
        const analyticsData = state.get('analytics').toJS() || {};
        const { analyticsPerCategoryData = {} } = analyticsData || {};
        const { filter_id = '' } = payload || {};
        const newData = {
            ...analyticsPerCategoryData,
            [filter_id]: {},
        };
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getAnalyticsPerCategoryFailed({ message, error, status, data: newData }));
    }
}

export function* flagpoleSaga({ payload }) {
    try {
        const { data = {} } = yield call(getFlagpoleUrl, payload);
        yield put(getFlagpoleSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getFlagpoleFailed({ message, errors, status }));
    }
}

export function* scorecardSaga({ payload }) {
    try {
        const { data = {} } = yield call(getScorecardUrl, payload);
        yield put(getScorecardSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getScorecardFailed({ message, errors, status }));
    }
}

export function* defaultYearSaga({ payload }) {
    try {
        const { data } = yield call(getDefaultYearUrl, payload);
        const { default_year } = data;
        yield put(getDefaultYearSuccess(default_year));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getDefaultYearFailed({ message, error, status }));
    }
}

const analyticsSagas = [
    takeLatest(`${GET_FILTERS_DATA}_PENDING`, filtersDataSaga),
    takeLatest(`${GET_FILTERS_MAPPING}_PENDING`, filtersMappingSaga),
    takeLatest(`${GET_DATA_AT_GLANCE}_PENDING`, dataAtGlanceSaga),
    takeLatest(`${GET_DIFFERENTIAL}_PENDING`, differentialDataSaga),
    takeLatest(`${GET_RANKING}_PENDING`, rankingDataSaga),
    takeLatest(`${GET_ANALYTICS_CATEGORY}_PENDING`, analyticsPerCategorySaga),
    takeLatest(`${GET_ANALYTICS_FLAGPOLE}_PENDING`, flagpoleSaga),
    takeLatest(`${GET_ANALYTICS_SCORECARD}_PENDING`, scorecardSaga),
    takeLatest(`${GET_DEFAULT_YEAR}_PENDING`, defaultYearSaga),
];

export default analyticsSagas;
