import React, { Fragment, useEffect, useRef, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { ListSubheader, MenuItem, Select, Tooltip } from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import Sort from '@material-ui/icons/Sort';
import Loader from '../../../assets/Loader_Blue.svg';
import DownloadIcon from '../../Diagnose/assets/Download.svg';
import { getStaticText } from '../../../utils/constants';
import { getGapColor, getInclusionStyle, quartileTitle } from '../../../utils/functions';
import { SLICE_VALUE, MAX_LENGTH_SUBSTRING, DEFAULT_STYLE, CRH_CLIENT_OHI12922 } from '../constants';
import ArrowRight from '../../Diagnose/assets/ArrowRight.svg';
import ArrowLeft from '../../Diagnose/assets/ArrowLeft.svg';
import './index.scss';
import Dropdown from '../../../components/DropdownWithCheckbox/Dropdown';

const DemographicInclusionData = ({
    scoreData,
    scoreColor,
    withBenchmark,
    showTag,
    currentOhid,
    isPulse,
    selectedSubTab,
    dropDownValue,
    setDropDownValue,
    callGapData,
    demographics,
    excludeOption,
    qbyq,
    child,
    setChild,
    sort,
    setSort,
    sortGroup,
    dwnldDrop,
    setDwnldDrop,
    setSelectedDownload,
    setShowTag,
    setAvgGap,
    showAvgGap,
    getDataForPpt,
    overallView,
    setOverallView,
    allGapDataFetched,
    error,
}) => {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        OVERALL_INCLUSION,
        DOWNLOAD,
        BUTTON_TAG,
        ON,
        OFF,
        LARGEST_GAP,
        AVG_GAP,
        OVERALL_SCORE,
        OVERALL_SORT,
        SORT,
        N_SIZE_SORT,
        N_SIZE_TEXT,
        SELECT_DEMO_GROUP,
        VIEW_BY,
        SHOW_OVERALL,
        GAP_SCORE_ARE_LOADING_PLEASE_WAIT,
    } = SITE_TEXT;
    const {
        headerArr = [],
        finalArr = [],
        gapArr = [],
        scoreForGap = [],
        allGapArr = [],
        overallArr: overallScores = [],
    } = scoreData;
    const [page, updatePage] = useState(1);
    const [isSticky, setSticky] = useState(false);
    const [rearrangedArr, setRearrangedArr] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [nsizeArr, setNSizeArr] = useState([]);
    const { score: scoreStyles = [], gap: gapStyle = [], benchmarkStyles = [], styles: styleArr = [] } = scoreColor;
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;
    const highLowBtn = useRef({});
    const isCRHClientOHI12922 = currentOhid === CRH_CLIENT_OHI12922;
    const showScoreData = (scoreDataArr, arr2) => {
        const arr = [];
        const header = scoreDataArr.map(item => item[0].displayValue);
        const nSizes = scoreDataArr.map(item => item[1].displayValue);
        const gapHeaders = [selectedSubTab === 0 ? 'Dimension' : 'Outcome', 'Demo Largest Gap', ...header];
        const nSizeKeys = ['N-size', '', ...nSizes];
        setNSizeArr(nSizeKeys);
        setHeaders(gapHeaders);
        for (let i = 1; i < headerArr.length; i += 1) {
            const arr1 = [];
            arr1.push({ displayValue: headerArr[i] });
            arr1.push({ displayValue: arr2[i + 1] });
            for (let j = 0; j < scoreDataArr.length; j += 1) {
                arr1.push(scoreDataArr[j][i + 1]);
            }
            arr.push(arr1);
        }
        setRearrangedArr(arr);
    };

    useEffect(() => {
        if (Object.keys(highLowBtn.current).length) {
            if (child.length) {
                highLowBtn.current.style.opacity = 0.5;
                highLowBtn.current.style.pointerEvents = 'none';
                if (showTag) {
                    setShowTag(false);
                }
            } else {
                highLowBtn.current.style.opacity = 1;
                highLowBtn.current.style.pointerEvents = 'all';
            }
        }
        // eslint-disable-next-line
    }, [child]);

    useEffect(() => {
        if (isCRHClientOHI12922 === true) {
            setOverallView(false);
        }
        // eslint-disable-next-line
    }, [isCRHClientOHI12922]);

    const listenToScroll = () => {
        const headerId = document.getElementById('myHeader');
        const sticky = headerId.offsetTop;
        if (window.pageYOffset > sticky && window.pageYOffset > 320) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        if (gapArr.length) {
            showScoreData(finalArr, gapArr);
        }
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
        // eslint-disable-next-line
    }, [finalArr, scoreData, selectedSubTab]);

    const scoresHeaders = (arr, type) => {
        const newArr = arr.slice((page - 1) * SLICE_VALUE + 2, page * SLICE_VALUE + 2);
        return (
            <Fragment>
                <li className="headerList headerLeft" data-testid="arrowup">
                    <div>{arr[0]}</div>
                    {type === 'header' && <div>{selectedSubTab === 0 ? 'Outcome' : 'Practice'}</div>}
                </li>
                <li className="headerList gapHeader" data-testid="arrowup">
                    {arr[1]}
                </li>
                {newArr.map((val, i) => {
                    const displayValue =
                        val.length > MAX_LENGTH_SUBSTRING ? `${val.substring(0, MAX_LENGTH_SUBSTRING) + '...'}` : val;
                    return (
                        <Tooltip
                            key={`${val}${i}`}
                            placement="top"
                            arrow
                            title={type === 'nsize' ? '' : val}
                            aria-label={val}
                        >
                            <li className="headerList" data-testid="arrowup">
                                {displayValue}
                            </li>
                        </Tooltip>
                    );
                })}
            </Fragment>
        );
    };

    const scoreToDisplay = score => {
        return score === 0 && isPulse ? '-' : score;
    };

    const gapToDisplay = (gap, scoreArr) => {
        return scoreArr.every(elem => elem === 0) && gap === 0 ? '-' : gap;
    };

    const scoresTable = (arr, overallArr, overallGapArr) => {
        if (!arr.length) return null;
        return arr.map((item, i) => {
            const { displayValue: displayValue0 = '' } = item[0];
            const { displayValue: displayValue1 = '' } = item[1];
            const { score: qbyqScore = '', quartile: overallQuart = -1, inclusion_quartile: incQuart = -1 } =
                overallArr[i] || {};
            const { bgcolor: qbyqScoreBg = '', text_color: qbyqScoreTxtClr = '' } =
                qbyqScore !== 0 ? getInclusionStyle(incQuart, overallQuart, benchmarkStyles, styleArr) : DEFAULT_STYLE;

            const {
                display_score: overallQbyqScore = '',
                quartile: ovQbyqQuart = -1,
                inclusion_quartile: ovQbyqIncQuart = -1,
            } = overallScores[0] || {};
            const { bgcolor: overallQbyqBg = '', text_color: overallQbyqText = '' } =
                overallQbyqScore !== '-'
                    ? getInclusionStyle(ovQbyqIncQuart, ovQbyqQuart, benchmarkStyles, styleArr)
                    : DEFAULT_STYLE;
            const overallAllGap = overallScores[1].score;
            const demographicsOverall = overallScores[1].overallDemographics;
            const overallAllGapStyle = getGapColor(overallAllGap, gapStyle);
            const { score: gapOverall = '' } = overallScores[2] || {};
            const gapOverallStyle = getGapColor(gapOverall, gapStyle);

            const excludeDemo999 = item.filter(({ isDemo_999 }) => !isDemo_999);
            const lastIndex = excludeDemo999.length;
            const scoreArr = excludeDemo999.map(({ displayValue }) => displayValue).slice(2);
            const overallScoreArr = overallScores
                .filter(({ isDemo_999 }) => !isDemo_999)
                .map(({ score }) => score)
                .slice(3);
            const gapVal = gapToDisplay(displayValue1, scoreArr);
            const gapStyles = !isNaN(gapVal) ? getGapColor(displayValue1, gapStyle) : DEFAULT_STYLE;
            const highestVal = showTag ? Math.max(...scoreArr) : -2;
            const lowestVal = showTag ? Math.min(...scoreArr) : -2;
            const largestOverall = showTag ? Math.max(...overallScoreArr) : -2;
            const lowestOverall = showTag ? Math.min(...overallScoreArr) : -2;
            const { gap: overallGap = '', demographic: allDemographic } = overallGapArr[i] || {};
            const { bgcolor: overallGapBg = '', text_color: overallGapTextColor = '' } =
                overallGap !== 0 ? getGapColor(overallGap, gapStyle) : DEFAULT_STYLE;

            const { label: overallLabel = '' } =
                demographics.filter(({ code }) => code === demographicsOverall)[0] || {};
            const { label: allLabel = '' } = demographics.filter(({ code }) => code === allDemographic)[0] || {};
            return (
                <>
                    {i === 0 && overallView && (
                        <div className="sectionDiv" key={`${item}${i}_ov`}>
                            <ul className={c({ tabLeft: true })}>
                                <li
                                    key={`${displayValue0}${i}_0`}
                                    className={c('sectionContent', {
                                        outcomeScorePractice: i < 9 && i > 2,
                                        practiceScorePractice: i >= 9,
                                        levelScorePractice: i <= 2,
                                    })}
                                >
                                    {' '}
                                </li>
                                <li
                                    key={`${displayValue0}${i}_1`}
                                    className={c('sectionContent1', {
                                        outcomeScorePractice: i < 9 && i > 2,
                                        practiceScorePractice: i >= 9,
                                        levelScorePractice: i <= 2,
                                    })}
                                >
                                    <div className="outcomesScore">{OVERALL_INCLUSION}</div>
                                    <div
                                        className="gapScores"
                                        style={{
                                            borderRadius: '50%',
                                            background: overallQbyqBg,
                                            color: overallQbyqText,
                                        }}
                                    >
                                        <div>{overallQbyqScore}</div>
                                    </div>
                                    <div
                                        className="allGap"
                                        style={{
                                            borderRadius: '20%',
                                            background: overallAllGapStyle.bgcolor,
                                            color: overallAllGapStyle.text_color,
                                        }}
                                    >
                                        {showAvgGap ? (
                                            <div>{overallAllGap}</div>
                                        ) : (
                                            <Tooltip title={overallLabel}>
                                                <div>{overallAllGap}</div>
                                            </Tooltip>
                                        )}
                                        {!allGapDataFetched ? (
                                            <Tooltip title={GAP_SCORE_ARE_LOADING_PLEASE_WAIT}>
                                                <div className="spinLoader">
                                                    <img src={Loader} alt="" />
                                                </div>
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                </li>
                                <li
                                    className={c({ smallText: gapVal === '-' }, 'contentList gapHeader')}
                                    style={{
                                        background: gapOverallStyle.bgcolor,
                                        color: gapOverallStyle.text_color,
                                    }}
                                >
                                    {gapOverall}
                                </li>
                                {overallScores
                                    .slice((page - 1) * SLICE_VALUE + 3, page * SLICE_VALUE + 3)
                                    .map((elem, index) => {
                                        const {
                                            score: displayValue = '',
                                            inclusion_quartile = -1,
                                            quartile = -1,
                                        } = elem;
                                        const scoreVal = scoreToDisplay(displayValue);
                                        const styleObj = getInclusionStyle(
                                            inclusion_quartile,
                                            quartile,
                                            benchmarkStyles,
                                            styleArr
                                        );
                                        const { bgcolor: bgColor = '', text_color: textColor = '' } = styleObj || {
                                            bgcolor: '',
                                            text_color: '',
                                        };
                                        const checkIndex = (page - 1) * SLICE_VALUE + index;
                                        return (
                                            <li
                                                style={{ background: bgColor, color: textColor }}
                                                className={c({ smallText: scoreVal === '-' }, 'contentList')}
                                                key={`${displayValue}${index}${Math.random()}`}
                                            >
                                                {displayValue === largestOverall && checkIndex !== lastIndex - 1 ? (
                                                    <Tooltip title="Highest score" placement="right-end">
                                                        <div className="highestVal">H</div>
                                                    </Tooltip>
                                                ) : null}
                                                {displayValue === lowestOverall && checkIndex !== lastIndex - 1 ? (
                                                    <Tooltip title="Lowest score">
                                                        <div className="lowestVal">L</div>
                                                    </Tooltip>
                                                ) : null}
                                                {scoreVal}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    )}
                    <div className="sectionDiv" key={`${item}${i}`}>
                        <ul className={c({ tabLeft: true })}>
                            <li
                                key={`${displayValue0}${i}_0`}
                                className={c('sectionContent', {
                                    outcomeScorePractice: i < 9 && i > 2,
                                    practiceScorePractice: i >= 9,
                                    levelScorePractice: i <= 2,
                                })}
                            >
                                {displayValue0.split('_')[0]}
                            </li>
                            <li
                                key={`${displayValue0}${i}_1`}
                                className={c('sectionContent1', {
                                    outcomeScorePractice: i < 9 && i > 2,
                                    practiceScorePractice: i >= 9,
                                    levelScorePractice: i <= 2,
                                })}
                            >
                                <div className="outcomesScore">{displayValue0.split('_')[1]}</div>
                                <div
                                    className="gapScores"
                                    style={{
                                        borderRadius: '50%',
                                        background: qbyqScoreBg,
                                        color: qbyqScoreTxtClr,
                                    }}
                                >
                                    <div>{scoreToDisplay(qbyqScore)}</div>
                                </div>
                                <div
                                    className="allGap"
                                    style={{
                                        borderRadius: '20%',
                                        background: overallGapBg,
                                        color: overallGapTextColor,
                                    }}
                                >
                                    {showAvgGap ? (
                                        <div>{overallGap}</div>
                                    ) : (
                                        <Tooltip title={allLabel}>
                                            <div>{overallGap}</div>
                                        </Tooltip>
                                    )}
                                    {!allGapDataFetched ? (
                                        <Tooltip title={GAP_SCORE_ARE_LOADING_PLEASE_WAIT}>
                                            <div className="spinLoader">
                                                <img src={Loader} alt="" />
                                            </div>
                                        </Tooltip>
                                    ) : null}
                                </div>
                            </li>
                            <li
                                className={c({ smallText: gapVal === '-' }, 'contentList gapHeader')}
                                style={{
                                    background: gapStyles.bgcolor,
                                    color: gapStyles.text_color,
                                }}
                            >
                                {gapVal}
                            </li>
                            {item.slice((page - 1) * SLICE_VALUE + 2, page * SLICE_VALUE + 2).map((elem, index) => {
                                const { displayValue, inclusion_quartile = -1, quartile = -1 } = elem;
                                const scoreVal = scoreToDisplay(displayValue);
                                const styleObj =
                                    scoreVal !== '-'
                                        ? getInclusionStyle(inclusion_quartile, quartile, benchmarkStyles, styleArr)
                                        : { bgcolor: '', text_color: 'black' };
                                const { bgcolor: bgColor = '', text_color: textColor = '' } = styleObj || {
                                    bgcolor: '',
                                    text_color: '',
                                };
                                const checkIndex = (page - 1) * SLICE_VALUE + index;
                                return (
                                    <li
                                        style={{ background: bgColor, color: textColor }}
                                        className={c({ smallText: scoreVal === '-' }, 'contentList')}
                                        key={`${displayValue}${index}${Math.random()}`}
                                    >
                                        {displayValue === highestVal && checkIndex !== lastIndex - 2 ? (
                                            <Tooltip title="Highest score" placement="right-end">
                                                <div className="highestVal">H</div>
                                            </Tooltip>
                                        ) : null}
                                        {displayValue === lowestVal && checkIndex !== lastIndex - 2 ? (
                                            <Tooltip title="Lowest score">
                                                <div className="lowestVal">L</div>
                                            </Tooltip>
                                        ) : null}
                                        {scoreVal}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            );
        });
    };

    return (
        <div className="content">
            <div className="upperHeader heatmap">
                <div>
                    <div className="demographicDropdown">
                        <Select
                            data-testid="demographicOptions"
                            IconComponent={KeyboardArrowDownSharpIcon}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={dropDownValue}
                            displayEmpty
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={({ target: { value: code } }) => {
                                if (code) {
                                    setDropDownValue(code);
                                    callGapData(code);
                                }
                            }}
                        >
                            <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                <em>{SELECT_DEMO_GROUP}</em>
                            </MenuItem>
                            {demographics
                                .filter(({ code }) => !excludeOption.includes(code))
                                .map(({ code = '', label = '' }) => {
                                    return (
                                        <MenuItem key={code} classes={{ root: 'demographicOptions' }} value={code}>
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </div>
                    <div className="demographicDropdown">
                        <Dropdown
                            selectedDemographic={Object.keys(qbyq)}
                            selected={child}
                            setSelected={setChild}
                            demographics={demographics.filter(({ code }) => code === dropDownValue)[0].options}
                        />
                    </div>
                    <div className="demographicDropdown noBorder">
                        <Select
                            data-testid="demographicOptions"
                            IconComponent={Sort}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sort}
                            displayEmpty
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={({ target: { value: code } }) => {
                                setSort(code);
                            }}
                        >
                            <MenuItem classes={{ root: 'sortOptions' }} value="">
                                <span>{SORT}</span>
                            </MenuItem>
                            <ListSubheader>{N_SIZE_TEXT}</ListSubheader>
                            {N_SIZE_SORT.map((item, i) => {
                                return (
                                    <MenuItem key={item} classes={{ root: 'sortOptions' }} value={i}>
                                        {sortGroup.length > 0 ? <span className="activeSort" /> : null}
                                        {item}
                                    </MenuItem>
                                );
                            })}
                            <ListSubheader>{OVERALL_SCORE}</ListSubheader>
                            {OVERALL_SORT.map((item, i) => {
                                return (
                                    <MenuItem key={item} classes={{ root: 'sortOptions' }} value={i + 2}>
                                        {sortGroup.length > 0 ? <span className="activeSort" /> : null}
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                {!error ? (
                    <div>
                        <div>
                            <ul>
                                <li className="toggleBtn">
                                    <div className="tagName">{SHOW_OVERALL}</div>
                                    <div className={c({ overallView })} onClick={() => setOverallView(!overallView)}>
                                        <div data-testid="hideGapBtn" className={c({ isActive: !overallView })} />
                                        <div data-testid="showGapBtn" className={c({ isActive: overallView })} />
                                    </div>
                                </li>
                                <li
                                    data-testid="getPptBtn"
                                    className={c({ disabled: false }, 'separator')}
                                    onClick={() => setDwnldDrop(!dwnldDrop)}
                                >
                                    <div>
                                        <img src={DownloadIcon} alt="" />
                                    </div>
                                    <span>{DOWNLOAD}</span>
                                    {dwnldDrop && (
                                        <div className="dropdown">
                                            <div onClick={getDataForPpt}>ppt</div>
                                            <div onClick={() => setSelectedDownload(0)}>excel</div>
                                        </div>
                                    )}
                                </li>

                                <li className="leftPadding" ref={highLowBtn}>
                                    <div className="tagName">{BUTTON_TAG}</div>
                                    <div>
                                        <div
                                            data-testid="hideGapBtn"
                                            className={c({ isActive: !showTag })}
                                            onClick={() => setShowTag(false)}
                                        >
                                            {OFF}
                                        </div>
                                        <div
                                            data-testid="showGapBtn"
                                            className={c({ isActive: showTag })}
                                            onClick={() => setShowTag(true)}
                                        >
                                            {ON}
                                        </div>
                                    </div>
                                </li>
                                <li className="separator" />
                                <li className="leftPadding">
                                    <div className="tagName">{VIEW_BY}</div>
                                    <div>
                                        <div
                                            data-testid="hideGapBtn"
                                            className={c({ isActive: !showAvgGap })}
                                            onClick={() => setAvgGap(false)}
                                        >
                                            {LARGEST_GAP}
                                        </div>
                                        <div
                                            data-testid="showGapBtn"
                                            className={c({ isActive: showAvgGap })}
                                            onClick={() => setAvgGap(true)}
                                        >
                                            {AVG_GAP}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                ) : (
                    <div />
                )}
            </div>
            <div className="legendContent tile">
                <div>
                    <div>
                        <ul className="classification">
                            <li>{SCORE_CLASSIFICATION_TEXT} </li>
                            {styleToUse
                                .filter(
                                    ({ quartile, style_for }) =>
                                        quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                )
                                .map(({ title, bgcolor }) => (
                                    <li key={title}>
                                        <span className="circle" style={{ background: bgcolor }} />
                                        {quartileTitle(withBenchmark, title)}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div>
                        <ul className="classification">
                            <li>{GAP_CLASSIFICATION_TEXT} </li>
                            {gapStyle.map(({ title, bgcolor }) => (
                                <li key={title}>
                                    <span style={{ background: bgcolor }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <section className="sectionWrapper">
                <div
                    id="myHeader"
                    className={c('headerWrapper clearfix', {
                        fixedHeader: isSticky,
                        adjustHeader: isSticky,
                    })}
                >
                    {page > 1 ? (
                        <div data-testid="arrowLeft" className="arrow" onClick={() => updatePage(() => page - 1)}>
                            <img className="imgClass" src={ArrowLeft} alt="" />
                        </div>
                    ) : null}
                    <div>
                        {headers && headers.length ? (
                            <Fragment>
                                <ul className="mainHeader">{scoresHeaders(headers, 'header')}</ul>
                                <ul className="nSizeHeader">{scoresHeaders(nsizeArr, 'nsize')}</ul>
                            </Fragment>
                        ) : null}
                    </div>
                    {headers.length > page * SLICE_VALUE + 2 ? (
                        <div data-testid="arrowRight" className="arrow arrowRight" onClick={() => updatePage(page + 1)}>
                            <img className="imgClass" src={ArrowRight} alt="" />
                        </div>
                    ) : null}
                </div>
                <div className="contentWrapper clearfix">
                    <div className="mainContent">
                        {!overallView
                            ? scoresTable(rearrangedArr.slice(0, 2), scoreForGap.slice(0, 2), allGapArr.slice(23))
                            : null}
                        {selectedSubTab === 0
                            ? scoresTable(rearrangedArr.slice(2, 8), scoreForGap.slice(2, 8), allGapArr.slice(0, 6))
                            : scoresTable(rearrangedArr.slice(8), scoreForGap.slice(8), allGapArr.slice(6))}
                    </div>
                </div>
            </section>
        </div>
    );
};
DemographicInclusionData.propTypes = {
    scoreData: PropTypes.object.isRequired,
    scoreColor: PropTypes.object.isRequired,
    withBenchmark: PropTypes.bool.isRequired,
    showTag: PropTypes.bool.isRequired,
    isPulse: PropTypes.bool.isRequired,
    selectedSubTab: PropTypes.number.isRequired,
    dropDownValue: PropTypes.string.isRequired,
    setDropDownValue: PropTypes.func.isRequired,
    callGapData: PropTypes.func.isRequired,
    demographics: PropTypes.array.isRequired,
    excludeOption: PropTypes.array.isRequired,
    qbyq: PropTypes.object.isRequired,
    child: PropTypes.array.isRequired,
    setChild: PropTypes.func.isRequired,
    sort: PropTypes.string.isRequired,
    setSort: PropTypes.func.isRequired,
    sortGroup: PropTypes.array.isRequired,
    dwnldDrop: PropTypes.bool.isRequired,
    setDwnldDrop: PropTypes.func.isRequired,
    setSelectedDownload: PropTypes.func.isRequired,
    setShowTag: PropTypes.func.isRequired,
    setAvgGap: PropTypes.func.isRequired,
    showAvgGap: PropTypes.bool.isRequired,
    getDataForPpt: PropTypes.func.isRequired,
    currentOhid: PropTypes.string.isRequired,
    overallView: PropTypes.bool.isRequired,
    setOverallView: PropTypes.func.isRequired,
    allGapDataFetched: PropTypes.object.isRequired,
    error: PropTypes.string.isRequired,
};
export default DemographicInclusionData;
