import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Toast } from '@mds/mds-reactjs-library';
import { cloneDeep } from 'lodash';
// import Loader from '../../../assets/Loader_Blue.svg';
import ProgressBarLoader from '../../../components/ProgressBarLoader';
import AuthStore from '../../../common/AuthStore';
import { getStaticText } from '../../../utils/constants';
import { removeUnderscore } from '../../../utils/functions';
import './index.scss';
import Table from '../../../components/Table';

let timeoutAutoComplete;

function PastReports({
    pastReport,
    reportApiCount,
    getPastReport,
    getRawPptData,
    showProgressBar,
    pendingReports,
    addPendingReport,
    defaultSettings,
    putPastReport,
    pptStatus,
    getPptDataStatus,
    pptStatusFetching,
    pptError,
    taskId,
    updateTaskId,
    updatedPastRpts,
    showMultiProgress,
    resetPendingReport,
}) {
    const { lang, ohid = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { records = [] } = pastReport || {};
    const { report_id = '', pptAccessToken = '' } = AuthStore;
    const { NO_REPORTS, QUEUE, ZIP_FILE_MSG, PASTREPORT_TABLE_HEADING, PAST_REPORT } = SITE_TEXT || {};
    const [dwnldPerm, setDwnldPerm] = useState(true);
    const [statusDialog, openStatusDialog] = useState(false);
    const [rNameToDwnld, setRName] = useState('');
    const [open, setOpen] = useState(true);
    const [multiReportOpen, setMultiReportOpen] = useState(true);

    useEffect(() => {
        getPastReport(report_id);
        // eslint-disable-next-line
    }, []);

    const action = (
        <React.Fragment>
            <div className="button" onClick={() => openStatusDialog(true)}>
                More Info
            </div>
        </React.Fragment>
    );

    useEffect(() => {
        const { records: oldRecords = [] } = pastReport || {};
        if (oldRecords.length) {
            const rewRecord = oldRecords[0] || {};
            const { id = '', status = '' } = rewRecord;
            if (QUEUE.includes(status) && !pendingReports.filter(({ id: oldId }) => oldId === id).length) {
                addPendingReport({
                    id,
                    status: QUEUE[0],
                });
            }
            if (!QUEUE.includes(status)) {
                resetPendingReport();
            }
        }
        // eslint-disable-next-line
    }, [pastReport, dwnldPerm]);

    const getExport = (id, export_name, fileToDownload = false) => {
        const taskIdToUse = Math.ceil(Math.random() * 1000000);
        updateTaskId(taskIdToUse);
        setRName(export_name);
        getRawPptData({ id, pptAccessToken, taskIdToUse, export_name, fileToDownload });
    };

    const showTable = !!records.length;
    const checkSelectedLength = records.filter(({ isSelected: selected = false }) => selected).length;

    const handlePutReport = id => {
        putPastReport({
            export_id: id,
            ohid,
        });
        getPastReport(report_id);
    };

    const handleMultipleDwnld = (selectedItem, i) => {
        const { checked } = selectedItem;
        const newList = cloneDeep(records);
        newList[i].isSelected = checked;
        updatedPastRpts({ ...pastReport, records: newList });
    };

    useEffect(() => {
        if (!pptStatusFetching && !pptError && showProgressBar) {
            const timeToUse = pptStatus.length ? 2 : 1;
            timeoutAutoComplete = setTimeout(() => {
                getPptDataStatus(pptAccessToken, taskId);
            }, timeToUse * 1000);
        }
        if (pptError) {
            openStatusDialog(true);
            // clearTimeout(timeoutAutoComplete);
        }
        if (!showProgressBar) {
            clearTimeout(timeoutAutoComplete);
            setDwnldPerm(true);
            openStatusDialog(false);
        } else {
            setDwnldPerm(false);
        }
        // eslint-disable-next-line
    }, [pptStatusFetching, pptStatus, showProgressBar]);

    return (
        <div className="pastReportWrapper">
            <Helmet>
                <title>{PAST_REPORT}</title>
            </Helmet>
            {statusDialog && showProgressBar ? (
                <ProgressBarLoader
                    defaultSettings={defaultSettings}
                    taskId={taskId}
                    openStatusDialog={openStatusDialog}
                    reportName={rNameToDwnld}
                />
            ) : null}
            {showTable && (
                <section className="tableData">
                    <Table
                        headCells={PASTREPORT_TABLE_HEADING}
                        rows={records}
                        removeUnderscore={removeUnderscore}
                        handleMultipleDwnld={handleMultipleDwnld}
                        defaultSettings={defaultSettings}
                        getExport={getExport}
                        dwnldPerm={dwnldPerm}
                        handlePutReport={handlePutReport}
                        checkSelectedLength={checkSelectedLength}
                    />
                </section>
            )}
            {!reportApiCount && !showTable && <div className="noData">{NO_REPORTS}</div>}

            {showProgressBar && pptStatus.length && open && !pptError ? (
                <div className="infoToastContainer">
                    <Toast
                        type="neutral"
                        title="Downloading Report"
                        onCloseClick={() => setOpen(false)}
                        action={action}
                    >
                        {`Your report "${rNameToDwnld}" is being downloaded`}
                    </Toast>
                </div>
            ) : null}

            {showMultiProgress && multiReportOpen ? (
                <div className="infoToastContainer">
                    <Toast
                        type="neutral"
                        title="Downloading multiple reports"
                        onCloseClick={() => setMultiReportOpen(false)}
                    >
                        {ZIP_FILE_MSG}
                    </Toast>
                </div>
            ) : null}
        </div>
    );
}

PastReports.defaultProps = {
    putPastReport: () => {},
};

PastReports.propTypes = {
    pastReport: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    pendingReports: PropTypes.array.isRequired,
    showProgressBar: PropTypes.bool.isRequired,
    reportApiCount: PropTypes.number.isRequired,
    getPastReport: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    addPendingReport: PropTypes.func.isRequired,
    putPastReport: PropTypes.func,
    pptStatus: PropTypes.array.isRequired,
    getPptDataStatus: PropTypes.func.isRequired,
    pptStatusFetching: PropTypes.bool.isRequired,
    pptError: PropTypes.bool.isRequired,
    taskId: PropTypes.number.isRequired,
    updateTaskId: PropTypes.func.isRequired,
    updatedPastRpts: PropTypes.func.isRequired,
    showMultiProgress: PropTypes.bool.isRequired,
    resetPendingReport: PropTypes.func.isRequired,
};

export default PastReports;
