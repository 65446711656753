import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import PracticeRankingBoard from './PracticeRankingBoard';
import { getRanking, getDifferential, setDifferentialData } from '../actions';

import {
    selectRankingData,
    selectRankingFetched,
    selectFiltersApplied,
    selectFilterParam,
    selectDifferentialData,
    selectDifferentialDataFetched,
    selectRankingError,
    selectDifferentialError,
    selectDataAtGlance,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';

const stateToProps = createStructuredSelector({
    rankingData: selectRankingData(),
    rankingFetched: selectRankingFetched(),
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
    filterParams: selectFilterParam(),
    differentialData: selectDifferentialData(),
    differentialDataFetched: selectDifferentialDataFetched(),
    rankingError: selectRankingError(),
    differentialError: selectDifferentialError(),
    dataAtGlance: selectDataAtGlance(),
});

const dispatchToProps = {
    getRanking,
    getDifferential,
    setDifferentialData,
};

export default connect(stateToProps, dispatchToProps)(PracticeRankingBoard);
