import Overview from '../assets/overview.svg';
import PracticeRanking from '../../Diagnose/assets/PracticeRanking.svg';
import PracticeRanking_active from '../../Diagnose/assets/practiceranking_active.svg';
import Scorecard from '../../Diagnose/assets/Scorecard.svg';
import Info from '../assets/intro.svg';
import Flagpole from '../assets/Flagpole.svg';
import Clipboard from '../assets/clipboard.svg';
import Team from '../assets/team.svg';
import Average from '../assets/average.svg';
import Office from '../assets/office.svg';
import People from '../assets/people.svg';
import Calendar from '../assets/calendar.svg';
import Chart from '../assets/chart.svg';
import Scorecard_active from '../../Diagnose/assets/Scorecard_active.svg';
import recipe from '../assets/recipe.svg';
import createBm from '../assets/create_bm.svg';
import automaticBox from '../assets/automatic_box.svg';
import summaryBox from '../assets/summary_box.svg';
import Database from '../assets/gsod_overview.svg';
import ScorecardSS from '../assets/gsod_scorecard.svg';
import FlagpoleSS from '../assets/gsod_flagpole.svg';
import RankingSS from '../assets/gsod_pr.svg';
import FilterDropdown from '../assets/Filter_Dropdown.svg';
import colors from '../../../sass/colors';

export const NAV_LINKS = [
    {
        imgSrc: Info,
        imgSrcActive: Info,
        index: 0,
    },
    {
        imgSrc: Overview,
        imgSrcActive: Overview,
        index: 1,
    },
    {
        imgSrc: Scorecard,
        imgSrcActive: Scorecard_active,
        index: 2,
    },
    {
        imgSrc: Flagpole,
        imgSrcActive: Flagpole,
        index: 3,
    },
    {
        imgSrc: PracticeRanking,
        imgSrcActive: PracticeRanking_active,
        index: 4,
    },
];

export const OVERVIEW_HEADER = {
    total_survey: {
        label: 'Number of Surveys _',
        imgSrc: Clipboard,
    },
    survey_completion: {
        label: 'Total Respondents(n)',
        imgSrc: Team,
    },
    response_rate: {
        label: 'Average response rate',
        imgSrc: Average,
    },
};

export const SIGNIFICANCE = [
    {
        title: 'Negative Differentiator',
        bg: colors.$pink,
        text_color: colors.$white,
        borderWidth: 2,
    },
    {
        title: 'Neutral',
        bg: colors.$yellow200,
        text_color: colors.$white,
        borderWidth: 2,
    },
    {
        title: 'Positive Differentiator',
        bg: colors.$green400,
        text_color: colors.$white,
        borderWidth: 2,
    },
];

export const cardData = [
    {
        title: 'Organizations',
        heading: '>2,300',
        imgSrc: Office,
    },
    {
        title: 'Participants Surveyed',
        heading: '~7.5m',
        imgSrc: People,
    },
    {
        title: 'Years of usage',
        heading: '>20',
        imgSrc: Calendar,
    },
    {
        title: 'Industries, regional and context benchmarks',
        heading: '~100',
        imgSrc: Chart,
    },
];

export const analyticsPageInfo = [
    {
        tabName: 'Overview',
        tabNo: 1,
        tabDesc:
            'Provide an overall view about surveys gathered in our database and statistics by years regions and sectors',
        preview: {
            mainImg: Database,
            leftRemark: 'Switch between Pointers',
            rightRemark: 'Choose multiple selection filter to for required results',
            rightImg: FilterDropdown,
        },
    },
    {
        tabName: 'Scorecard',
        tabNo: 2,
        tabDesc:
            'Provide an overall view of average overall health, outcome, and practice scores globally, or in specified sector, region, etc.',
        preview: {
            mainImg: ScorecardSS,
            leftRemark: 'Switch between Pointers',
            rightRemark: 'Choose multiple selection filter to for required results',
            rightImg: FilterDropdown,
        },
    },
    {
        tabName: 'Flagpole',
        tabNo: 3,
        tabDesc:
            'Show how organizations’ scores in a given sector or region compare to other organizations in our global benchmark. With these comparisons, you can identify which outcome and practice scores are relatively stronger or weaker in comparison to the median global benchmark score',
        preview: {
            mainImg: FlagpoleSS,
            leftRemark: 'Expand to see Practice Scores',
            rightRemark: 'Choose multiple selection filter to for required results',
            rightImg: FilterDropdown,
        },
    },
    {
        tabName: 'Practice Ranking',
        tabNo: 4,
        tabDesc:
            'Provide insight into the management practices that, on average, are most and least emphasized by organizations in a specified sector, region, etc.',
        preview: {
            mainImg: RankingSS,
            leftRemark: 'Expand to see Practice Scores',
            rightRemark: 'Choose multiple selection filter to for required results',
            rightImg: FilterDropdown,
        },
    },
];

export const upcomingFeatures = [
    {
        imgSrc: recipe,
        description: 'Recipe alignments by benchmarks',
    },
    {
        imgSrc: createBm,
        description: '​Create your own benchmark',
    },
    {
        imgSrc: automaticBox,
        description: '​Automatic year-end benchmark update',
    },
    {
        imgSrc: summaryBox,
        description: 'Summary of score changes by health quartiles',
    },
];

export const PAGE_WISE_HELP = {
    2: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'With this profile and comparison, you can identify which outcome and practice scores are relatively stronger or weaker in the selected sample compared to the median global benchmark score',
        },
        {
            helpQues: 'What are the scores ?',
            helpText: 'Median outcome and practices scores in the selected sample vs. global database.',
        },
    ],
    3: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'With this profile and comparison, you can identify which outcome and practice scores are relatively stronger or weaker in the selected sample compared to the median global benchmark score.',
        },
        {
            helpQues: 'What are the scores ?',
            helpText: 'Median outcome and practices scores in the selected sample vs. global database.',
        },
    ],
    4: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'On average, what practices are the most vs. least emphasized by organizations from the selected sample?',
        },
        {
            helpQues: 'What the numbers are ?',
            helpText: 'Practice rank for organizations from the selected sample.',
        },
    ],
    5: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'The practice ranks for top and bottom organizations tell us what it generally “feels like” to work at a healthy vs. unhealthy organization in the selected sample The difference between ranks tells us which practices most differentiate healthy from unhealthy organizations in the selected sample',
        },
        {
            helpQues: 'General rules of thumb to identify differentiators',
            helpText: '',
            helpTexts: [
                {
                    helpQues: 'Positive differentiators are practices healthy organizations uniquely emphasize',
                    helpText:
                        'To easily identify them: Find practices that rank in the top 12 of healthy organizations Identify the practices that have a minimum absolute rank difference of 12 from unhealthy organizations',
                },
                {
                    helpQues: 'Negative differentiators are practices that unhealthy organizations uniquely emphasize',
                    helpText:
                        'To easily identify them: Select the practices that rank in the top 12 of unhealthy organizations Identify the practices that have a minimum rank difference of -12 from healthy organizations',
                },
            ],
        },
    ],
    6: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'The scorecard shows the median scores of the surveys in the selected sample (global benchmark by default when you start). The scores change based on the filters applied to get at sub-benchmarks. The quartile colors change based on comparison of the median score to the global benchmark.',
        },
    ],
};

export const SITE_TEXT = {
    INTRO_BTN_TXT: "Let's Start",
    PREV: 'Prev',
    NEXT: 'Next',
    INTRO_HEAD: 'Welcome to the OHI Benchmark Explorer (OHIBMX)',
    INTRO_TXT:
        'The OHI BMX includes insights from OHI surveys included in the global benchmarks. The dashboard allows teams using OHI surveys to deep-dive into global, sector, regional, and other sub-benchmarks to develop relevant and tailored insights for clients',
    INTRO_TXT_QUES: '​​What is GSOD? ',
    GSOD_OHI_STATS: 'OHI 2024 benchmark',
    GSOD_LEARN_TXT: 'How to navigate',
    MORE_TXT: 'And many more...',
    ERROR_DEFAULT_TXT: 'There is insufficient data to generate the desired result.',
    INSUFFICIENT_DATA: 'Insufficient Data',
    QUICK_HELPT: 'What’s on this page?',
    YEAR: 'Year',
    BM_EXPLORER: 'Introduction to Benchmark Explorer',
    VIEW_ONLINE: 'VIEW ONLINE CLASS ',
    LEARN_MORE: 'To learn more about OHI Methodology, please refer to OHI Methodology e-Learning course',
    LEARN_MORE_LINK:
        'https://mckinseylearning.csod.com/samldefault.aspx?returnUrl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlodetails%2526lo%253d684aef4c-e57c-4d83-bd2a-d2351584451d',
};

export const ANALYTICS_QUARTILE_COLORS = [
    {
        title: 'Top Decile',
        background: '#002960',
        border: '#ffd800',
        color: '#ffffff',
        top_position: '19000',
    },
    {
        title: 'Top Quartile',
        background: '#002960',
        border: '#002960',
        color: '#ffffff',
        top_position: '23500',
    },
    {
        title: 'Second Quartile',
        background: '#128fa7',
        border: '#128fa7',
        color: '#ffffff',
        top_position: '31000',
    },
    {
        title: 'Third Quartile',
        background: '#9fb9bd',
        border: '#9fb9bd',
        color: '#ffffff',
        top_position: '41000',
    },
    {
        title: 'Bottom Quartile',
        background: '#d9d9d9',
        border: '#d9d9d9',
        color: '#000000',
        top_position: '51000',
    },
    {
        title: 'No Benchmarks',
        background: '#ffffff',
        border: '#ffffff',
        color: '#000000',
        top_position: '61000',
    },
];

export const NPS_TABLE_HEADERS = ['N', 'Promoters', 'Passives', 'Detractors', 'Net Score'];
export const ENGAGEMENT_TABLE_HEADERS = [' ', 'Size', 'Disagree', 'Agree', 'Neutral'];

export const GET_DATA_AT_GLANCE = 'GET_DATA_AT_GLANCE';
export const GET_DIFFERENTIAL = 'GET_DIFFERENTIAL';
export const GET_ANALYTICS_FLAGPOLE = 'GET_GET_ANALYTICS_FLAGPOLE';
export const GET_ANALYTICS_SCORECARD = 'GET_ANALYTICS_SCORECARD';
export const GET_RANKING = 'GET_RANKING';
export const GET_ANALYTICS_CATEGORY = 'GET_ANALYTICS_CATEGORY';
export const GET_FILTERS_DATA = 'GET_FILTERS_DATA';
export const UPDATE_ANALYTICS_FILTERS = 'UPDATE_ANALYTICS_FILTERS';
export const UPDATE_SCORECARD_DROPDOWN = 'UPDATE_SCORECARD_DROPDOWN';
export const UPDATE_ANALYTICS_CATEGORY = 'UPDATE_ANALYTICS_CATEGORY';
export const UPDATE_DIFFERENTIAL_DATA = 'UPDATE_DIFFERENTIAL_DATA ';
export const DEFAULT_FILTER = 'year:2024';
export const GET_FILTERS_MAPPING = 'GET_FILTERS_MAPPING';
export const GET_DEFAULT_YEAR = 'GET_DEFAULT_YEAR';
