import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import Loader from '../../components/Loader';
import AuthStore from '../../common/AuthStore';
import { getStaticText } from '../../utils/constants';
import { parseJwt } from '../../utils/functions';
import lockIcon from './assets/lockIcon.png';
import loginHead from './assets/loginHead.png';
import './index.scss';

function Login({ mid, children, getAccessDetails, userData, resetToken, removeToken }) {
    const { accessToken = '' } = AuthStore;

    const [loginState, updateState] = useState({
        isAuthed: false,
        isLoading: true,
    });

    const { isAuthed, isLoading } = loginState;
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const { LOGIN_BTN_TEXT, OHI_ANALYTICS_LOGIN } = SITE_TEXT;

    const checkIsAuth = checkAuth => {
        updateState({ isAuthed: checkAuth, isLoading: false });
    };

    useEffect(() => {
        const jwtMatches = window.location.hash.match(/jwt=([a-zA-Z0-9_.-]+)/);
        if (jwtMatches) {
            const access_token = jwtMatches[1];
            getAccessDetails({ access_token });
            window.location.hash = '';
        } else if (accessToken) {
            checkIsAuth(true);
        } else if (window.location.pathname.includes('/auth/callback')) {
            mid.isAuthed().then(loginStatus => {
                checkIsAuth(loginStatus);
            });
        } else {
            checkIsAuth(false);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { token } = userData;
        if (token) {
            updateState({ isAuthed: true, isLoading: false });
        }
        // eslint-disable-next-line
    }, [userData]);

    useEffect(() => {
        if (resetToken) {
            updateState({ isAuthed: false, isLoading: false });
            removeToken(false);
        }
        // eslint-disable-next-line
    }, [resetToken]);

    const makeUserLogin = () => {
        mid.isAuthed().then(loginStatus => {
            updateState({ isAuthed: loginStatus, isLoading: false });
        });
    };

    const submitForm = () => {
        updateState({ ...loginState, isLoading: true });
        const access_token = mid.accessToken();
        const infoFromToken = parseJwt(access_token);
        const { exp = 0 } = infoFromToken;
        if (exp - moment().unix() > 0) {
            getAccessDetails({ access_token });
        } else {
            mid.login().finally(() => {
                makeUserLogin();
            });
        }
    };

    return isAuthed ? (
        children
    ) : (
        <div className="wrapper">
            <Helmet>
                <title>{OHI_ANALYTICS_LOGIN}</title>
            </Helmet>
            <div className="loginWrapper">
                {isLoading && <Loader position="absolute" showBackground />}
                <img src={loginHead} className="loginHead" alt="" />
                <div data-testid="loginButton" className="loginButton" onClick={submitForm}>
                    <img src={lockIcon} alt="" />
                    <span>{LOGIN_BTN_TEXT}</span>
                </div>
            </div>
        </div>
    );
}

Login.propTypes = {
    mid: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    resetToken: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    getAccessDetails: PropTypes.func.isRequired,
    removeToken: PropTypes.func.isRequired,
};

export default Login;
