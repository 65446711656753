import React, { useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { KeyboardArrowRight } from '@material-ui/icons';
import { cloneDeep } from 'lodash';
import { Button } from '@material-ui/core';
import { getStaticText } from '../../utils/constants';
import CloseIcon from '../../assets/Close.svg';
import '../FilterBar/index.scss';
import FilterContent from './FilterContent';
import { getTabName } from '../../containers/Analytics/assets/functions';

function FilterBar({
    closeFilters,
    filters,
    setFilters,
    defaultSettings,
    filtersApplied,
    filtersMapping,
    defaultFilters,
    isFiltersApplied,
    hideFilterIds,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const [activeProject, updateCurrentFilter] = useState(null);
    const [stateFilters, updateFilters] = useState(filters);
    const [demoSelected, setDemoActive] = useState(false);
    const { FILTER_FOR, RESET_FILTERS, APPLY_FILTERS } = SITE_TEXT || {};
    const itemInFilter = filters.map(({ filter_name }) => filter_name);

    const selectOptions = filterObj => {
        const filterItem = cloneDeep(filterObj);
        const noneSelected = !filterItem
            // .filter(({ filter_id }) => filter_id !== 'year')
            .filter(({ isSelected = false }) => isSelected).length;
        if (noneSelected) {
            return filterItem;
        }
        filterItem
            // .filter(({ filter_id }) => filter_id !== 'year')
            .forEach(_item => {
                const { filter_items = [], isSelected = false } = _item;
                if (isSelected) {
                    const selectedArr = [];
                    filter_items.forEach(({ filter_item_id, isSelected: selectedChild = false }) => {
                        if (selectedChild) {
                            selectedArr.push(filter_item_id);
                        }
                    });
                }
            });
        return filterItem;
    };

    const updateFilterState = (index, value, updateRedux, activeFilter = true) => {
        const list = [...stateFilters.slice(0, index), value, ...stateFilters.slice(index + 1)];
        const newList = selectOptions(list);
        updateFilters(newList);
        if (updateRedux) {
            setFilters(stateFilters, activeFilter);
            closeFilters(false);
        }
        return newList;
    };

    const resetFilters = () => {
        const updateRedux = filters.filter(({ isDefault, isSelected }) => !isDefault && isSelected).length;
        updateFilters(defaultFilters);
        setDemoActive(false);
        if (updateRedux) {
            setFilters(defaultFilters, false);
        }
    };

    const checkAppliedFilter = filterObj => {
        const { filter_items = [] } = filterObj;
        for (let i = 0; i < filter_items.length; i += 1) {
            const { isSelected = false } = filter_items[i];
            if (isSelected) {
                return true;
            }
        }
        return false;
    };
    return (
        <div className="filterDialogAnalytics filterDialog" data-testid="filterDialog">
            <div className="backdropRoot" />
            <div className="rightSideFilters">
                <div className="itemList">
                    <div className="topContent clearfix">
                        <h2>{FILTER_FOR}</h2>
                        <div className="closeContent">
                            <img
                                data-testid="closeFilters"
                                src={CloseIcon}
                                alt=""
                                onClick={() => closeFilters(false)}
                            />
                        </div>
                    </div>
                    {activeProject === null && (
                        <ul className="selectList">
                            {filters.map(({ filter_name, filter_id }, index) => {
                                const isFilterApplied = checkAppliedFilter(filters[index]);
                                const isHidden = hideFilterIds.includes(filter_id);
                                const filterName = getTabName(filter_name);
                                return !isHidden ? (
                                    <li
                                        data-testid={filterName}
                                        key={index}
                                        onClick={() => updateCurrentFilter(index)}
                                        className={c('filtered', {
                                            filtersApplied: isFilterApplied,
                                        })}
                                    >
                                        {filterName}
                                        <KeyboardArrowRight />
                                    </li>
                                ) : null;
                            })}
                        </ul>
                    )}
                </div>
                {activeProject !== null || !itemInFilter.length ? (
                    <FilterContent
                        setDemoActive={setDemoActive}
                        activeApplyButton={demoSelected}
                        updateFilterState={updateFilterState}
                        updateCurrentFilter={updateCurrentFilter}
                        closeFilters={closeFilters}
                        showHeader={itemInFilter[activeProject]}
                        resetFilters={resetFilters}
                        filters={stateFilters}
                        activeProject={activeProject}
                        defaultSettings={defaultSettings}
                        filtersApplied={filtersApplied}
                        defaultFilters={defaultFilters}
                        filtersMapping={filtersMapping}
                        updateFilters={updateFilters}
                        isFiltersApplied={isFiltersApplied}
                    />
                ) : null}
                {!isNaN(activeProject) && (
                    <div className="buttonContent clearfix">
                        <Button
                            classes={{ root: c({ active: demoSelected || isFiltersApplied }) }}
                            onClick={resetFilters}
                        >
                            {RESET_FILTERS}
                        </Button>
                        <Button
                            classes={{ root: c({ active: demoSelected }) }}
                            onClick={() => updateFilterState(activeProject, [], true)}
                        >
                            {APPLY_FILTERS}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

FilterBar.propTypes = {
    filters: PropTypes.array.isRequired,
    closeFilters: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    filtersMapping: PropTypes.array.isRequired,
    defaultFilters: PropTypes.array.isRequired,
    isFiltersApplied: PropTypes.bool.isRequired,
    hideFilterIds: PropTypes.array.isRequired,
};

export default FilterBar;
