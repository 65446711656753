import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Helmet } from 'react-helmet';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import Differentiator from './DifferentiatorAnalysis';
import PracticeRanking from './PracticeRanking';

function PracticeRankingBoard({
    toggleFilterView,
    defaultSettings,
    getRanking,
    rankingFetched,
    rankingData,
    getDifferential,
    differentialDataFetched,
    differentialData,
    renderfilterApplied,
    filterParams,
    setDifferentialData,
    tab,
    setTab,
    rankingError,
    differentialError,
    dataAtGlance,
    filtersApplied,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        FILTERS,
        ANALYTICS_RANKING_TABS,
        PRACTICE_RANK_PAGE_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_TEXT,
        PRACTICE_HEAD,
        NOTE,
        GSOD_PRAC_NOTE,
        GSOD_PRAC_ANALYSIS,
    } = SITE_TEXT || {};
    const { records: mainData = [], n_survey = 0 } = rankingData || {};
    const { n_survey: differentialDataSize = 0 } = differentialData || {};

    const getFilterYear = filters => {
        if (filters) {
            const regex = /year:(\d+)/;
            const match = filters.match(regex);
            return match ? +match[1] : '';
        }
        return '';
    };

    const getPage = () => {
        switch (tab) {
            case 0:
                return (
                    <PracticeRanking
                        defaultSettings={defaultSettings}
                        getRanking={getRanking}
                        rankingFetched={rankingFetched}
                        rankingData={rankingData}
                        filterParams={filterParams}
                        rankingError={rankingError}
                    />
                );
            case 1:
                return (
                    <Differentiator
                        getDifferential={getDifferential}
                        differentialData={differentialData}
                        differentialDataFetched={differentialDataFetched}
                        filterParams={filterParams}
                        setDifferentialData={setDifferentialData}
                        defaultSettings={defaultSettings}
                        error={differentialError}
                        pracRankingTab={tab}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="practiceBoard clearfix">
            <Helmet>
                <title>{PRACTICE_RANK_PAGE_TEXT['5']}</title>
            </Helmet>
            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="mainHeading">{PRACTICE_HEAD}</div>
                    <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${
                        tab === 0 ? n_survey : differentialDataSize
                    }`}</div>
                </div>
                <ul className="navScoreList">
                    <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                        <span className="filter">{FILTERS}</span>
                    </li>
                </ul>
            </div>
            {renderfilterApplied}
            <div className="optionWrapper">
                <div>
                    <ul className="leftNav">
                        {ANALYTICS_RANKING_TABS.map((tabName, index) => (
                            <li className={c({ activeLink: tab === index })} onClick={() => setTab(index)}>
                                {tabName}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="rightOptions">
                    {/* <div>
                        <ul className="rightSideNav">
                            <li className="strong">{`Number of Surveys (n) = ${n_survey}`}</li>
                            <li
                                onClick={() => toggleFilterView(true)}
                                data-testid="filterButton"
                                className="filterButton"
                            >
                                <img src={filtersApplied ? ActiveFilter : FilterSvg} alt="" />
                                {FILTERS}
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
            {getPage()}
            {mainData.length > 0 && tab === 0 && (
                <div className="note">
                    <b>{NOTE}:</b>
                    <div>
                        {getFilterYear(filterParams) === 2024 && tab === 0 ? GSOD_PRAC_NOTE[0] : GSOD_PRAC_NOTE[1]}
                    </div>
                </div>
            )}

            {Object.keys(differentialData).length > 0 && tab === 1 && (
                <div className="note">
                    <b>{NOTE}:</b>
                    <div>
                        {getFilterYear(filterParams) === 2024 && tab === 1 ? (
                            <div>
                                <div>1. {GSOD_PRAC_NOTE[0]}</div>
                                <div>2. {GSOD_PRAC_ANALYSIS[0]}</div>
                            </div>
                        ) : (
                            GSOD_PRAC_NOTE[1]
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

PracticeRankingBoard.propTypes = {
    rankingData: PropTypes.object.isRequired,
    rankingFetched: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    getRanking: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    differentialData: PropTypes.object.isRequired,
    differentialDataFetched: PropTypes.bool.isRequired,
    getDifferential: PropTypes.func.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    filterParams: PropTypes.string.isRequired,
    setDifferentialData: PropTypes.func.isRequired,
    tab: PropTypes.number.isRequired,
    setTab: PropTypes.func.isRequired,
    rankingError: PropTypes.array.isRequired,
    differentialError: PropTypes.array.isRequired,
    dataAtGlance: PropTypes.object.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
};

export default PracticeRankingBoard;
