import React, { useState } from 'react';
import { Select, MenuItem, Button, TextField } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import c from 'classnames';

import { cloneDeep } from 'lodash';
import InfoIcon from '@material-ui/icons/Info';
import { getStaticText } from '../../utils/constants';
import { NO_BM_STYLE } from './constants';

function Customization({ data, errorInfo, updateData, resetError, defaultSettings }) {
    const { customSettings: oldCustomSettings = [], quartileColors = [] } = data;
    const [customSettings, updateCustom] = useState(oldCustomSettings);
    const [active, enableSubmit] = useState(false);
    const { lang: defaultLang } = defaultSettings;
    const staticText = getStaticText(defaultLang);
    const { SITE_TEXT } = staticText;
    const { APPLY_SETTINGS } = SITE_TEXT || {};

    const [settingScoreValue, setSettingScoreValue] = useState('');

    const setCustomData = (index, selected_val) => {
        updateCustom([
            ...customSettings.slice(0, index),
            { ...customSettings[index], selected_val },
            ...customSettings.slice(index + 1),
        ]);
        enableSubmit(true);
        if (errorInfo) {
            resetError();
        }
        setSettingScoreValue(selected_val);
    };

    const saveData = () => {
        const dataToSend = customSettings.reduce(
            (o, { api_param, selected_val }) => ({ ...o, [api_param]: selected_val }),
            {}
        );
        const { lang, report_type: oldReport } = dataToSend;
        const report_type = oldReport === 'percentile' ? 'percentile' : '';
        const updatedQColors = cloneDeep(quartileColors);
        for (let i = quartileColors.length; i < 6; i += 1) {
            updatedQColors.push(NO_BM_STYLE);
        }
        updateData({ ...dataToSend, quartileColors: JSON.stringify(updatedQColors) }, dataToSend, {
            report_type,
            lang: parseInt(lang, 10),
        });
        enableSubmit(false);
    };

    return (
        <div className="customization">
            {customSettings.map(({ title, description, options, selected_val, type = '' }, index) => (
                <section key={title} className="clearfix">
                    <div className="textSettings">
                        <h3>{title}</h3>
                        <p>{description}</p>
                    </div>
                    {type === 'text' ? (
                        <TextField
                            value={selected_val}
                            placeholder={title}
                            inputProps={{ 'data-testid': `Text${title}`, maxLength: 100 }}
                            onChange={({ target }) => setCustomData(index, target.value)}
                        />
                    ) : (
                        <div className="selectedScore">
                            <Select
                                value={selected_val}
                                IconComponent={ExpandMore}
                                onChange={({ target }) => setCustomData(index, target.value)}
                                className="select"
                            >
                                {options.map(({ key, value = '' }) => (
                                    <MenuItem key={key} value={key} classes={{ root: 'customSelOptions' }}>
                                        {value || key}
                                    </MenuItem>
                                ))}
                            </Select>
                            {index === 1 && settingScoreValue === 'percentile' ? (
                                <div className="benchmarkSettings">
                                    <div className="benchmarkPercentile">
                                        <InfoIcon className="benchImg" />
                                        <div className="benchInfo">
                                            Benchmark flagpole comparisons not available for percentile scores
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    )}
                </section>
            ))}
            <div className="buttonContent clearfix">
                <Button data-testid="customSaveBtn" classes={{ root: c({ active }) }} onClick={saveData}>
                    {APPLY_SETTINGS}
                </Button>
            </div>
        </div>
    );
}

Customization.propTypes = {
    data: PropTypes.object.isRequired,
    errorInfo: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
};

export default Customization;
