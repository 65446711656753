import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import MainBoard from './MainBoard';

import {
    setDemographicCurrentTab,
    getHeatMapOutcome,
    setSelectedDemographic,
    getHeatMapPractice,
    sortHeatMapOutcome,
    getPptData,
    setHeatMapOutcomeData,
    setHeatMapPracticeData,
    setPracticePageNo,
    setOutcomePageNo,
    updateHeatMapPractice,
    updateHeatMapOutcome,
    getHeatMapInclusion,
    getMappedDemographics,
    updateHeatMapInfo,
    getHeatMapResurvey,
    getDemographics,
    getBenchmarks,
    setResurveyTab,
    updateFilters,
    getSinglePastReport,
    getRankingHeatMap,
    updateHeatMapRanking,
    setRankingPageNo,
    setHeatMapRankingData,
    sortHeatMapRanking,
    getCohesionData,
    setCohesionDemographic,
} from '../actions';

import {
    selectApiParams,
    selectApiCount,
    selectHeatMapData,
    selectFilters,
    selectHeatMapPractiseDataFetched,
    selectHeatMapPractiseData,
    selectSelectedDemographic,
    selectDemographicCurrentTab,
    selectHeatMapDataFetched,
    selectFiltersApplied,
    selectIsOutcomeRetrieved,
    selectIsPractiseRetrived,
    selectLoadHeatMapPracticeData,
    selectLoadHeatMapOutcomeData,
    selectPracticePageNo,
    selectOutcomePageNo,
    selectHeatMapInclusion,
    selectHeatMapInclusionFetched,
    selectHeatMapInclusionError,
    selectProjects,
    selectHeatMapResurveyTab,
    selectHeatMapResurveyInfo,
    selectResurveyPracticeHeatMapFetched,
    selectResurveyOutcomeHeatMapFetched,
    selectResurveyOutcomeHeatMap,
    selectResurveyPracticeHeatMap,
    selectPastReport,
    selectRankingHeatmapFetched,
    selectHeatMapRanking,
    selectLoadHeatMapRankingData,
    selectRankingPageNo,
    selectCohesionDataFetched,
    selectScoreData,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';
import { selectReportError } from '../../Reports/selector';
import { getReport, getRawPptData } from '../../Reports/actions';

const stateToProps = createStructuredSelector({
    apiParams: selectApiParams(),
    heatMapData: selectHeatMapData(),
    apiLoadingCount: selectApiCount(),
    selectedDemographic: selectSelectedDemographic(),
    heatMapPracticeFetched: selectHeatMapPractiseDataFetched(),
    heatMapPractice: selectHeatMapPractiseData(),
    demographicCurrentTab: selectDemographicCurrentTab(),
    heatMapDataFetched: selectHeatMapDataFetched(),
    filters: selectFilters(),
    activeFilter: selectFiltersApplied(),
    defaultSettings: getDefaultSettings(),
    isOutcomeRetrieved: selectIsOutcomeRetrieved(),
    isPracticeRetrived: selectIsPractiseRetrived(),
    loadHeatMapPractice: selectLoadHeatMapPracticeData(),
    loadHeatMapOutcome: selectLoadHeatMapOutcomeData(),
    practicePageNo: selectPracticePageNo(),
    outcomePageNo: selectOutcomePageNo(),
    heatMapInclusion: selectHeatMapInclusion(),
    heatMapInclusionFetched: selectHeatMapInclusionFetched(),
    heatMapInclusionError: selectHeatMapInclusionError(),
    projects: selectProjects(),
    heatMapResurveyInfo: selectHeatMapResurveyInfo(),
    heatMapResurveyTab: selectHeatMapResurveyTab(),
    resurveyPracticeHeatMapFetched: selectResurveyPracticeHeatMapFetched(),
    resurveyOutcomeHeatMapFetched: selectResurveyOutcomeHeatMapFetched(),
    resurveyOutcomeHeatMap: selectResurveyOutcomeHeatMap(),
    resurveyPracticeHeatMap: selectResurveyPracticeHeatMap(),
    reportError: selectReportError(),
    singlePastReport: selectPastReport(),
    heatMapRankingFetched: selectRankingHeatmapFetched(),
    heatMapRanking: selectHeatMapRanking(),
    loadHeatMapRanking: selectLoadHeatMapRankingData(),
    rankingPageNo: selectRankingPageNo(),
    cohesionDataFetched: selectCohesionDataFetched(),
    scoreData: selectScoreData(),
});

const dispatchToProps = {
    setDemographicCurrentTab,
    getHeatMapOutcome,
    setSelectedDemographic,
    getHeatMapPractice,
    sortHeatMapOutcome,
    getPptData,
    setHeatMapOutcomeData,
    setHeatMapPracticeData,
    setPracticePageNo,
    setOutcomePageNo,
    updateHeatMapOutcome,
    updateHeatMapPractice,
    getHeatMapInclusion,
    getDemographics,
    getBenchmarks,
    getMappedDemographics,
    updateHeatMapInfo,
    getHeatMapResurvey,
    setResurveyTab,
    updateFilters,
    getReport,
    getRawPptData,
    getSinglePastReport,
    getRankingHeatMap,
    updateHeatMapRanking,
    setRankingPageNo,
    setHeatMapRankingData,
    sortHeatMapRanking,
    getCohesionData,
    setCohesionDemographic,
};

export default connect(stateToProps, dispatchToProps)(MainBoard);
