import React, { Fragment, useEffect, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Remove, ChangeHistory } from '@material-ui/icons';
import Loader from 'react-spinners/FadeLoader';
import { cloneDeep } from 'lodash';
import { getStaticText } from '../../../utils/constants';
import { deltaToDisplay, getGapColor, getInclusionStyle, quartileTitle, sortChildren } from '../../../utils/functions';
import { RES_GAP_SLICE_VALUE as SLICE_VALUE, MAX_LENGTH_SUBSTRING } from '../constants';
import ArrowRight from '../../Diagnose/assets/ArrowRight.svg';
import ArrowLeft from '../../Diagnose/assets/ArrowLeft.svg';
import './index.scss';

const ResurveyGapTable = ({
    scoreData,
    demographics,
    dropDownValue,
    scoreColor,
    withBenchmark,
    resDemo,
    isPulse,
    prevYear,
    year,
    selectedSubTab,
    excludeArr,
    excludeOption,
    getResAllGapInclusionData,
    currentOhid,
    lang,
    inclusion_threshold,
    demographicMappedData,
    resAllGapInclusionDataFetched,
    resAllGapInclusionData,
    resAllGapInclusionDataLoading,
    getAllGapInclusionData,
    allGapInclusionData,
    allGapInclusionDataFetched,
    allGapInclusionDataLoading,
    previousOhid,
    incQbyq,
    resIncQbyq,
    setAvgGap,
    showAvgGap,
    filters,
    resurveyFilters,
    overallView,
    setOverallView,
}) => {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        SCORE_CLASSIFICATION_TEXT,
        GAP_CLASSIFICATION_TEXT,
        CHANGE_CLASSIFICATION,
        CHANGE,
        OVERALL_INCLUSION,
        PERSONAL_EXP,
        ENT_PERCEPTION,
        VIEW_BY,
        AVG_GAP,
        LARGEST_GAP,
        SHOW_OVERALL,
    } = SITE_TEXT;

    const [gapScoreData, setGapScoreData] = useState([]);
    const [prevGapScore, setPrevGapScore] = useState([]);
    const [isSticky, setSticky] = useState(false);
    const { scoreForGap = [], allGapArr = [], overallArr: scoreHeatmapOverall = [] } = scoreData || {};
    const { resScoreForGap = [], resurveyAllGap = [] } = resDemo || {};

    const mappingObject = () => {
        const obj = {};
        demographicMappedData.forEach(({ current_demographic_parent = '', previous_demographic_parent = '' }) => {
            obj[current_demographic_parent] = previous_demographic_parent;
        });
        return obj;
    };

    const loadResGapDataHandler = () => {
        const filteredDemo = mappingObject();
        return getResAllGapInclusionData({
            ohid: previousOhid,
            group_on: '',
            filters: resurveyFilters,
            lang,
            inclusion_threshold,
            demographics: filteredDemo,
            page: 1,
            benchmark_sub_version_id: 1,
        });
    };

    const loadGapDataHandler = () => {
        const filteredDemo = demographics.filter(
            ({ code }) => !excludeArr.includes(code) && !excludeOption.includes(code)
        );
        return getAllGapInclusionData({
            ohid: currentOhid,
            group_on: '',
            filters,
            lang,
            inclusion_threshold,
            demographics: filteredDemo,
            page: 1,
            benchmark_sub_version_id: 1,
        });
    };
    useEffect(() => {
        if (!allGapInclusionDataFetched) {
            if (!allGapInclusionDataLoading) {
                loadGapDataHandler();
            }
        }
        if (!resAllGapInclusionDataFetched) {
            if (!resAllGapInclusionDataLoading) {
                loadResGapDataHandler();
            }
        }
        // eslint-disable-next-line
    }, []);

    const setTableData = (allDataObj, qbyqObj) => {
        const allGapIaData = Object.keys(allDataObj);
        const allInclusionGap = Object.keys(allGapInclusionData);
        if (allGapIaData.length) {
            const obj = {
                finalArr: [],
                headerArr: [],
                scoreArr: [],
                demoArr: [],
                overallGapArr: [],
                qbyqScores: [],
            };
            const { level_scores: mainLevel = {} } = qbyqObj;
            obj.overallGapArr.push({ ...mainLevel.overall, score: mainLevel.overall.display_score });
            allInclusionGap.forEach(key => {
                if (!allGapIaData.includes(key)) {
                    obj.finalArr.push(Array(25).fill('-'));
                    obj.overallGapArr.push('-');
                } else {
                    const { levels_gap = {}, records = {} } = allDataObj[key];
                    const allGapData = cloneDeep(records);
                    const { records: qbyqRecords = {} } = qbyqObj;
                    const { enterprise = {}, individual = {}, overall: overallDemoGap = {} } = levels_gap;
                    const { gap: indGap = '' } = individual;
                    const { gap: entGap = '' } = enterprise;
                    const newObj = [];
                    const outcomesHead = [];
                    const prtByPrtType = [];
                    const headerArray = [`_${PERSONAL_EXP}`, `_${ENT_PERCEPTION}`];
                    newObj.push(indGap);
                    newObj.push(entGap);
                    Object.keys(qbyqRecords).forEach(k => {
                        const { display_name, sort_order, level, children = {} } = qbyqRecords[k];
                        allGapData[k] = { display_name, sort_order, level, ...allGapData[k] };
                        const childObj = children;
                        Object.keys(children).forEach(childK => {
                            const { display_name: displayName, sort_order: sortOrder } = childObj[childK];
                            const { children: allGapDataChild = {} } = allGapData[k] || {};
                            const childrenData = allGapDataChild[childK] || [];
                            allGapData[k].children[childK] = {
                                display_name: displayName,
                                sort_order: sortOrder,
                                ...childrenData,
                            };
                        });
                    });

                    const filterWithoutDash = sortChildren(
                        Object.keys(allGapData)
                            .filter(k => allGapData[k].level !== '-')
                            .reduce((o, k) => {
                                o[k] = allGapData[k];
                                return o;
                            }, {})
                    );
                    Object.keys(filterWithoutDash).forEach(val => {
                        const {
                            gap: oGap = '',
                            key: oKey = '',
                            display_name: oDisplayName = '',
                            level = '',
                        } = filterWithoutDash[val];
                        newObj.push(oGap);
                        headerArray.push(`${level}_${oDisplayName}`);
                        outcomesHead.push(oKey);
                    });
                    Object.keys(filterWithoutDash).forEach(val => {
                        const { children = {}, display_name = '' } = filterWithoutDash[val];
                        const sortChildrenObj = sortChildren(children);
                        Object.keys(sortChildrenObj).forEach(childVal => {
                            const { gap: childGap = '', key: cKey, display_name: pDisplayName = '' } = sortChildrenObj[
                                childVal
                            ];
                            newObj.push(childGap);
                            headerArray.push(`${display_name}_${pDisplayName}`);
                            prtByPrtType.push(cKey);
                        });
                    });

                    obj.headerArr = headerArray;
                    obj.demoArr.push(key);
                    obj.finalArr.push(newObj);
                    obj.overallGapArr.push(overallDemoGap.gap);
                }
            });
            return obj;
        }
        return null;
    };

    useEffect(() => {
        const table = setTableData(allGapInclusionData, incQbyq);
        setGapScoreData(table);
        // eslint-disable-next-line
    }, [allGapInclusionData]);

    useEffect(() => {
        const table = setTableData(resAllGapInclusionData, resIncQbyq);
        setPrevGapScore(table);
        // eslint-disable-next-line
    }, [resAllGapInclusionData]);
    const { headerArr = [], finalArr = [], demoArr = [], overallGapArr: overallScores = [] } = gapScoreData || {};
    const { overallGapArr: resurveyOverall = [] } = prevGapScore || {};
    const sliceValue = finalArr.length + 1;
    const [page, updatePage] = useState(1);
    const [rearrangedArr, setRearrangedArr] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [nsizeArr, setNSizeArr] = useState([]);
    const [resurveyArr, setResurveyArr] = useState([]);
    const { label = '' } = demographics.filter(({ code }) => code === dropDownValue)[0] || {};
    const { score: scoreStyles = [], gap: gapStyle = [], benchmarkStyles = [], styles: styleArr = [] } = scoreColor;
    const styleToUse = !withBenchmark ? scoreStyles : benchmarkStyles;

    const scoreToDisplay = score => {
        return score === 0 ? '-' : score;
    };

    const gapToDisplay = (gap, scoreArr) => {
        return scoreArr.every(elem => elem === 0) && gap === 0 ? '-' : gap;
    };

    const showScoreData = (scoreDataArr, outcomes, demogs) => {
        const arr = [];
        const nSizes = scoreDataArr.map(item => item[1]);
        const demogLabels = demogs.map(demoCode => {
            const { label: demoLabel = '', title = '' } = demographics.filter(({ code }) => code === demoCode)[0];
            return title || demoLabel;
        });
        const gapHeaders = [selectedSubTab === 0 ? 'Dimension' : 'Outcome', ...demogLabels];
        const nSizeKeys = ['N-size', '', ...nSizes];
        setNSizeArr(nSizeKeys);
        setHeaders(gapHeaders);
        for (let i = 0; i < outcomes.length; i += 1) {
            const arr1 = [];
            arr1.push(outcomes[i]);
            for (let j = 0; j < scoreDataArr.length; j += 1) {
                arr1.push(scoreDataArr[j][i]);
            }
            arr.push(arr1);
        }
        setRearrangedArr(arr);
    };

    const resurveyScoreData = (scoreDataArr, outcomes) => {
        const arr = [];
        for (let i = 0; i < outcomes.length; i += 1) {
            const arr1 = [];
            arr1.push(outcomes[i]);
            for (let j = 0; j < scoreDataArr.length; j += 1) {
                arr1.push(scoreDataArr[j][i]);
            }
            arr.push(arr1);
        }
        setResurveyArr(arr);
    };

    const getDeltaElement = (value, type, isNa = false) => {
        const defaultTextColor = '#d2d238';
        let valueTextColor = value > 0 ? 'green' : 'red';
        if (type === 'gap') {
            valueTextColor = value < 0 ? 'green' : 'red';
        }
        const textColor = value === 0 ? defaultTextColor : valueTextColor;
        if (value === 0) {
            return (
                <div className={c({ gap: type === 'gap' }, 'delta')} style={{ color: defaultTextColor }}>
                    <div>
                        <div style={{ color: defaultTextColor }}>{value}</div>
                    </div>
                </div>
            );
        }
        if (isNa || isNaN(value)) {
            return (
                <div className={c({ gap: type === 'gap' }, 'delta')} style={{ fontSize: '1.4vh' }}>
                    <div>-</div>
                </div>
            );
        }
        return (
            <div className={c({ gap: type === 'gap' }, 'delta')} style={{ color: textColor }}>
                <div>
                    {value > 0 ? (
                        <div>
                            <span>
                                {value}
                                <ArrowUpward />
                            </span>
                        </div>
                    ) : (
                        <div>
                            <span>
                                {value}
                                <ArrowDownward />
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderHeaders = (headersArr, type, resHeader) => {
        const newResNSize =
            type === 'nsize' ? resHeader.slice((page - 1) * SLICE_VALUE + 2, page * SLICE_VALUE + 2) : [];
        if (type === 'nsize') {
            return headersArr.map((val, i) => {
                const displayValue =
                    val.length > MAX_LENGTH_SUBSTRING ? `${val.substring(0, MAX_LENGTH_SUBSTRING) + '...'}` : val;
                return (
                    <div className="resurveyList headerList">
                        <li className="headerList resurveyHeader" data-testid="arrowup">
                            {displayValue}
                        </li>
                        <li className="headerList resurveyHeader" data-testid="arrowup">
                            {newResNSize[i]}
                        </li>
                        <li className="headerList resurveyHeader" data-testid="arrowup" />
                    </div>
                );
            });
        }
        return headersArr.map((val, i) => {
            const displayValue =
                val.length > MAX_LENGTH_SUBSTRING ? `${val.substring(0, MAX_LENGTH_SUBSTRING) + '...'}` : val;
            return (
                <Tooltip key={`${val}${i}`} placement="top" arrow title={type === 'nsize' ? '' : val} aria-label={val}>
                    <div className="headerList resurveyList" data-testid="arrowup">
                        <li className="headerList">{displayValue}</li>
                        <li className="headerList" />
                    </div>
                </Tooltip>
            );
        });
    };

    const listenToScroll = () => {
        const headerId = document.getElementById('myHeader');
        const sticky = headerId.offsetTop;
        if (window.pageYOffset > sticky && window.pageYOffset > 320) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        const { finalArr: prevFinalArr = [], demoArr: prevDemo = [] } = prevGapScore || {};
        if (finalArr.length) {
            showScoreData(finalArr.slice(0, sliceValue), headerArr, demoArr);
        }
        if (prevFinalArr.length) {
            resurveyScoreData(prevFinalArr.slice(0, sliceValue), headerArr, demoArr, prevDemo);
        }
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
        // eslint-disable-next-line
    }, [finalArr, scoreData, selectedSubTab]);

    const scoresHeaders = (arr, type, resHeader) => {
        const newArr = arr.slice((page - 1) * SLICE_VALUE + 1, page * SLICE_VALUE + 1);
        if (type === 'resHeader') {
            return (
                <Fragment>
                    <li className="headerList headerLeft resHeader" />
                    {newArr.map(() => (
                        <div className="resurveyList headerList resHeader">
                            <li className="headerList resurveyHeader" data-testid="arrowup">
                                {prevYear}
                            </li>
                            <li className="headerList resurveyHeader" data-testid="arrowup">
                                {year}
                            </li>
                            <li className="headerList resurveyHeader delta" data-testid="arrowup">
                                <ChangeHistory />
                            </li>
                        </div>
                    ))}
                </Fragment>
            );
        }
        return (
            <Fragment>
                <li className="headerList headerLeft" data-testid="arrowup">
                    <div>{arr[0]}</div>
                    {type === 'header' && <div>{selectedSubTab === 0 ? 'Outcome' : 'Practice'}</div>}
                </li>
                {renderHeaders(newArr, type, resHeader)}
            </Fragment>
        );
    };

    const scoresTable = scoreTableData => {
        const { arr, overallArr, resOverallArr, allGap, resArr, resAllGapArr } = scoreTableData || {};
        if (!arr.length) return null;
        return arr.map((item, i) => {
            const newResurveyArr = resArr;
            const displayValue0 = item[0] || '';
            if (!newResurveyArr[i]) {
                return null;
            }
            const resPageData = newResurveyArr[i].slice((page - 1) * SLICE_VALUE + 1, page * SLICE_VALUE + 1);
            const scoreArr = item.slice(0, item.length);
            const {
                score: qbyqScoreVal = '',
                inclusion_quartile: incQuart = -1,
                quartile: overallQuart = -1,
            } = overallArr[i];
            const qbyqScore = scoreToDisplay(qbyqScoreVal, [], isPulse);
            const { bgcolor: qbyqScoreBg = '', text_color: qbyqScoreTxtClr = '' } =
                qbyqScore !== '-'
                    ? getInclusionStyle(incQuart, overallQuart, benchmarkStyles, styleArr)
                    : { bgcolor: 'white', text_color: 'black' };
            const {
                score: overallQbyqScore = '',
                quartile: ovQbyqQuart = -1,
                inclusion_quartile: ovQbyqIncQuart = -1,
            } = overallScores[0];
            const { score: resQbyqScore = 0 } = resOverallArr[i] || {};
            const qbyqScoreDelta = deltaToDisplay(qbyqScore, resQbyqScore, qbyqScore);
            const { bgcolor: overallQbyqBg = '', text_color: overallQbyqText = '' } =
                overallQbyqScore !== 0
                    ? getInclusionStyle(ovQbyqIncQuart, ovQbyqQuart, benchmarkStyles, styleArr)
                    : { bgcolor: 'white', text_color: 'black' };
            const { score: resOverall = 0 } = resurveyOverall[0];
            const resOverallDelta = deltaToDisplay(overallQbyqScore, resOverall, overallQbyqScore, isPulse);
            const prevAllGap = resAllGapArr;
            const { gap: overallGap = '' } = gapToDisplay(allGap[i], [qbyqScoreVal]);
            const { bgcolor: overallGapBg = '', text_color: overallGapTextColor = '' } =
                overallGap !== 0 ? getGapColor(overallGap, gapStyle) : { bgcolor: 'white', text_color: 'black' };
            const allGapDelta = deltaToDisplay(overallGap, prevAllGap[i], qbyqScore);
            const overallAllGap = scoreHeatmapOverall[1].score;
            const overallAllGapStyle = getGapColor(overallAllGap, gapStyle);
            const overallAllGapDelta = deltaToDisplay(overallAllGap, resurveyAllGap[1], overallAllGap, isPulse);
            return (
                <>
                    {i === 0 && overallView && (
                        <div className="sectionDiv" key={`${item}${i}_ov`}>
                            <ul className={c({ tabLeft: true })}>
                                <li
                                    key={`${displayValue0}${i}_0`}
                                    className={c('sectionContent', {
                                        outcomeScorePractice: i < 9 && i > 2,
                                        practiceScorePractice: i >= 9,
                                        levelScorePractice: i <= 2,
                                    })}
                                >
                                    {' '}
                                </li>
                                <li
                                    key={`${displayValue0}${i}_1`}
                                    className={c('sectionContent1', {
                                        outcomeScorePractice: i < 9 && i > 2,
                                        practiceScorePractice: i >= 9,
                                        levelScorePractice: i <= 2,
                                    })}
                                >
                                    <div className="outcomesScore">{OVERALL_INCLUSION}</div>
                                    <div
                                        className="gapScores"
                                        style={{
                                            borderRadius: '50%',
                                            background: overallQbyqBg,
                                            color: overallQbyqText,
                                        }}
                                    >
                                        <div>{overallQbyqScore}</div>
                                    </div>
                                    {/* <div className="changeIcon">{getDeltaElement(resOverallDelta, 'delta', false)}</div> */}
                                    <div
                                        className="allGap"
                                        style={{
                                            borderRadius: '20%',
                                            background: overallAllGapStyle.bgcolor,
                                            color: overallAllGapStyle.text_color,
                                        }}
                                    >
                                        <div>{overallAllGap}</div>
                                    </div>
                                    <div className="changeIcon">{getDeltaElement(resOverallDelta, 'delta', false)}</div>
                                    <div className="changeIcon">
                                        {getDeltaElement(overallAllGapDelta, 'gap', false)}
                                    </div>
                                </li>
                                {overallScores
                                    .slice((page - 1) * SLICE_VALUE + 1, page * SLICE_VALUE + 1)
                                    .map((elem, index) => {
                                        const resDisplayValue =
                                            resurveyOverall.slice((page - 1) * SLICE_VALUE + 1, page * SLICE_VALUE + 1)[
                                                index
                                            ] || {};
                                        const resScoreval = gapToDisplay(resDisplayValue, [resDisplayValue]);
                                        const resStyleObj =
                                            resScoreval !== '-'
                                                ? getGapColor(resScoreval, gapStyle)
                                                : { bgcolor: 'white', text_color: 'black' };
                                        const {
                                            bgcolor: resBgColor = '',
                                            text_color: resTextColor = '',
                                        } = resStyleObj || {
                                            bgcolor: '',
                                            text_color: '',
                                        };

                                        const displayValue = elem;
                                        const scoreVal = gapToDisplay(displayValue, [displayValue]);
                                        const styleObj = getGapColor(scoreVal, gapStyle);
                                        const { bgcolor: bgColor = '', text_color: textColor = '' } = styleObj || {
                                            bgcolor: '',
                                            text_color: '',
                                        };
                                        const overallDelta = deltaToDisplay(scoreVal, resScoreval, scoreVal, 'gap');
                                        return (
                                            <div className="resurveyList">
                                                <li
                                                    style={{ background: resBgColor, color: resTextColor }}
                                                    className={c({ smallText: resScoreval === '-' }, 'contentList')}
                                                    key={`${displayValue}${index}${Math.random()}`}
                                                >
                                                    {resScoreval}
                                                </li>
                                                <li
                                                    style={{ background: bgColor, color: textColor }}
                                                    className={c({ smallText: scoreVal === '-' }, 'contentList')}
                                                    key={`${displayValue}${index}${Math.random()}`}
                                                >
                                                    {scoreVal}
                                                </li>
                                                <li
                                                    className="contentList deltaList"
                                                    key={`${scoreVal}}${index}${Math.random()}`}
                                                >
                                                    {getDeltaElement(overallDelta, 'delta', false)}
                                                </li>
                                            </div>
                                        );
                                    })}
                            </ul>
                        </div>
                    )}
                    <div className="sectionDiv" key={`${item}${i}`}>
                        <ul className={c({ tabLeft: true })}>
                            <li
                                key={`${displayValue0}${i}`}
                                className={c('sectionContent', {
                                    outcomeScorePractice: i < 9 && i > 2,
                                    practiceScorePractice: i >= 9,
                                    levelScorePractice: i <= 2,
                                })}
                            >
                                {displayValue0.includes('_') ? displayValue0.split('_')[0] : displayValue0}
                            </li>
                            <li
                                key={`${displayValue0}${i}_1`}
                                className={c('sectionContent1', {
                                    outcomeScorePractice: i < 9 && i > 2,
                                    practiceScorePractice: i >= 9,
                                    levelScorePractice: i <= 2,
                                })}
                            >
                                {displayValue0.includes('_') ? displayValue0.split('_')[1] : displayValue0}
                                <div
                                    className="gapScores"
                                    style={{
                                        borderRadius: '50%',
                                        background: qbyqScoreBg,
                                        color: qbyqScoreTxtClr,
                                    }}
                                >
                                    <div>{qbyqScore}</div>
                                </div>
                                <div className="changeIcon">
                                    {getDeltaElement(qbyqScoreDelta, 'delta', qbyqScoreDelta === '-')}
                                </div>
                                <div
                                    className="allGap"
                                    style={{
                                        borderRadius: '20%',
                                        background: overallGapBg,
                                        color: overallGapTextColor,
                                    }}
                                >
                                    <div>{overallGap}</div>
                                </div>
                                <div className="changeIcon">
                                    {getDeltaElement(allGapDelta, 'gap', allGapDelta === '-')}
                                </div>
                            </li>
                            {item.slice((page - 1) * SLICE_VALUE + 1, page * SLICE_VALUE + 1).map((elem, index) => {
                                const displayValue = elem;
                                const scoreVal = scoreToDisplay(displayValue, scoreArr);
                                const resValue = resPageData[index];
                                const { bgcolor: bgColor = '', text_color: textColor = '' } =
                                    scoreVal !== '-'
                                        ? getGapColor(displayValue, gapStyle)
                                        : { bgcolor: 'white', text_color: 'black' };
                                const resScoreVal = scoreToDisplay(resValue, resPageData);
                                const { bgcolor: resBgColor = '', text_color: resTextColor = '' } =
                                    resScoreVal !== '-'
                                        ? getGapColor(resValue, gapStyle)
                                        : { bgcolor: 'white', text_color: 'black' };
                                const delta = deltaToDisplay(
                                    displayValue,
                                    resValue,
                                    displayValue,
                                    resScoreVal,
                                    isPulse
                                );

                                return (
                                    <div className="resurveyList">
                                        <li
                                            style={{ background: resBgColor, color: resTextColor }}
                                            className={c({ smallText: resScoreVal === '-' }, 'contentList')}
                                            key={`${resValue}${index}${Math.random()}`}
                                        >
                                            {resScoreVal}
                                        </li>
                                        <li
                                            style={{ background: bgColor, color: textColor }}
                                            className={c({ smallText: scoreVal === '-' }, 'contentList')}
                                            key={`${displayValue}${index}${Math.random()}`}
                                        >
                                            {scoreVal}
                                        </li>
                                        <li
                                            className="contentList deltaList"
                                            key={`${resValue}${index}${Math.random()}`}
                                        >
                                            {getDeltaElement(delta, 'delta', delta === '-')}
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                    </div>
                </>
            );
        });
    };

    const renderScoreTable = () => {
        const obj = {
            arr: selectedSubTab === 0 ? rearrangedArr.slice(2, 8) : rearrangedArr.slice(8),
            overallArr: selectedSubTab === 0 ? scoreForGap.slice(2, 8) : scoreForGap.slice(8),
            resOverallArr: selectedSubTab === 0 ? resScoreForGap.slice(2, 8) : resScoreForGap.slice(8),
            allGap: selectedSubTab === 0 ? allGapArr.slice(0, 6) : allGapArr.slice(6),
            resArr: selectedSubTab === 0 ? resurveyArr.slice(2, 8) : resurveyArr.slice(8),
            resAllGapArr: selectedSubTab === 0 ? resurveyAllGap.slice(2, 8) : resurveyAllGap.slice(8),
        };
        const levelObj = {
            arr: rearrangedArr.slice(0, 2),
            overallArr: scoreForGap.slice(0, 2),
            resOverallArr: resScoreForGap.slice(0, 2),
            allGap: allGapArr.slice(23),
            resArr: resurveyArr.slice(0, 2),
            resAllGapArr: resurveyAllGap[0],
        };
        return (
            <Fragment>
                {!overallView ? scoresTable(levelObj) : null}
                {scoresTable(obj)}
            </Fragment>
        );
    };

    if (!resAllGapInclusionDataFetched || !allGapInclusionDataFetched)
        return (
            <Loader
                color="#000000"
                cssOverride={{
                    margin: '10vh auto',
                }}
            />
        );

    return (
        <div className="content">
            <div className="upperHeader heatmap">
                <div>
                    <ul>
                        <li className="toggleBtn">
                            <div className="tagName">{SHOW_OVERALL}</div>
                            <div className={c({ overallView })} onClick={() => setOverallView(!overallView)}>
                                <div data-testid="hideGapBtn" className={c({ isActive: !overallView })} />
                                <div data-testid="showGapBtn" className={c({ isActive: overallView })} />
                            </div>
                        </li>
                        <li className="leftPadding">
                            <div className="tagName">{VIEW_BY}</div>
                            <div>
                                <div
                                    data-testid="hideGapBtn"
                                    className={c({ isActive: !showAvgGap })}
                                    onClick={() => setAvgGap(false)}
                                >
                                    {LARGEST_GAP}
                                </div>
                                <div
                                    data-testid="showGapBtn"
                                    className={c({ isActive: showAvgGap })}
                                    onClick={() => setAvgGap(true)}
                                >
                                    {AVG_GAP}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="legendContent tile">
                <div>
                    <div>
                        <ul className="classification">
                            <li>{SCORE_CLASSIFICATION_TEXT} </li>
                            {styleToUse
                                .filter(
                                    ({ quartile, style_for }) =>
                                        quartile !== '0' && quartile !== '999' && style_for !== 'blank_data'
                                )
                                .map(({ title, bgcolor }) => (
                                    <li key={title}>
                                        <span className="circle" style={{ background: bgcolor }} />
                                        {quartileTitle(withBenchmark, title)}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <div>
                        <ul className="classification">
                            <li>{GAP_CLASSIFICATION_TEXT} </li>
                            {gapStyle.map(({ title, bgcolor }) => (
                                <li key={title}>
                                    <span style={{ background: bgcolor }} />
                                    {title}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <ul className="classification clearfix">
                            <li>{CHANGE} </li>
                            {CHANGE_CLASSIFICATION.map(({ label: changeLabel = '', color, type = '' }, index) => {
                                let sign = <Remove />;
                                if (type) {
                                    sign = type === 'up' ? <ArrowUpward /> : <ArrowDownward />;
                                }
                                return (
                                    <li data-testid={`change${index}`} key={label}>
                                        <span style={{ color }} className="changeIcon">
                                            {sign}
                                        </span>
                                        {changeLabel}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <section className="sectionWrapper">
                <div
                    id="myHeader"
                    className={c('headerWrapper clearfix', {
                        fixedHeader: isSticky,
                        adjustHeader: isSticky,
                    })}
                >
                    {page > 1 ? (
                        <div data-testid="arrowLeft" className="arrow" onClick={() => updatePage(() => page - 1)}>
                            <img className="imgClass" src={ArrowLeft} alt="" />
                        </div>
                    ) : null}
                    <div>
                        {headers && headers.length ? (
                            <Fragment>
                                <ul className="mainHeader">{scoresHeaders(headers, 'header')}</ul>
                                <ul className="resurveyHeader">{scoresHeaders(headers, 'resHeader', nsizeArr)}</ul>
                            </Fragment>
                        ) : null}
                    </div>
                    {headers.length > page * SLICE_VALUE + 2 ? (
                        <div data-testid="arrowRight" className="arrow arrowRight" onClick={() => updatePage(page + 1)}>
                            <img className="imgClass" src={ArrowRight} alt="" />
                        </div>
                    ) : null}
                </div>
                <div className="contentWrapper clearfix">
                    <div className="mainContent resurveyTable">
                        {resAllGapInclusionDataFetched && allGapInclusionDataFetched ? renderScoreTable() : null}
                    </div>
                </div>
            </section>
        </div>
    );
};
ResurveyGapTable.propTypes = {
    scoreData: PropTypes.object.isRequired,
    demographics: PropTypes.array.isRequired,
    dropDownValue: PropTypes.string.isRequired,
    scoreColor: PropTypes.object.isRequired,
    withBenchmark: PropTypes.bool.isRequired,
    resDemo: PropTypes.object.isRequired,
    year: PropTypes.string.isRequired,
    prevYear: PropTypes.string.isRequired,
    isPulse: PropTypes.bool.isRequired,
    selectedSubTab: PropTypes.number.isRequired,
    excludeArr: PropTypes.array.isRequired,
    excludeOption: PropTypes.array.isRequired,
    getResAllGapInclusionData: PropTypes.func.isRequired,
    currentOhid: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    inclusion_threshold: PropTypes.number.isRequired,
    demographicMappedData: PropTypes.array.isRequired,
    resAllGapInclusionDataFetched: PropTypes.bool.isRequired,
    resAllGapInclusionData: PropTypes.object.isRequired,
    resAllGapInclusionDataLoading: PropTypes.bool.isRequired,
    getAllGapInclusionData: PropTypes.func.isRequired,
    allGapInclusionData: PropTypes.object.isRequired,
    allGapInclusionDataFetched: PropTypes.bool.isRequired,
    allGapInclusionDataLoading: PropTypes.bool.isRequired,
    previousOhid: PropTypes.string.isRequired,
    incQbyq: PropTypes.object.isRequired,
    resIncQbyq: PropTypes.object.isRequired,
    setAvgGap: PropTypes.func.isRequired,
    showAvgGap: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    resurveyFilters: PropTypes.array.isRequired,
    overallView: PropTypes.bool.isRequired,
    setOverallView: PropTypes.func.isRequired,
};
export default ResurveyGapTable;
