import React, { useState, useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import AuthStore from './common/AuthStore';
import PrivateRoute from './PrivateRoute';
import Page404 from './containers/Page404';
import Diagnose from './containers/Diagnose';
import ActionCompendium from './containers/ActionCompendium';
import Reports from './containers/Reports';
import Settings from './containers/Settings';
import UserReports from './containers/UserReports';
import DemographicScores from './containers/Reports/DemographicScores';
import Inclusion from './containers/Inclusion';
import ResponseRate from './containers/ResponseRate';
import UserManagement from './containers/UserManagement';

import Loader from './components/Loader';
import WarningModal from './components/WarningModal';
import CallbackComponent from './components/CallbackComponent';
import LogoutComponent from './components/LogoutComponent';
import useInterval from './components/useInterval';

import { getStaticText } from './utils/constants';

import { fetchDefaultSettings, removeToken } from './containers/Login/actions';
import { updateApiParams } from './containers/Diagnose/actions';
import { getPendingReportStatus } from './containers/Reports/actions';
import { getDefaultSettings, selectUserData, selectDefaultSettingsFetched } from './containers/Login/selector';
import { selectPendingReports } from './containers/Reports/selector';
import getCurrentStatus from './reducers/loaderSelector';
import Analytics from './containers/Analytics';

function Routes({
    currentStatus,
    defaultSettings = {},
    updateDefaultParams,
    fetchSettings,
    userData,
    deleteToken,
    mid,
    pendingReports,
    fetchReportsStatus,
    defaultSettingsFetched,
}) {
    const { lang = '1033' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { QUEUE = [] } = staticText || {};
    const { accessToken = '', ohid = '' } = AuthStore;
    const [tokenToUse, updateToken] = useState(accessToken);

    useEffect(() => {
        const { token } = userData;
        if (!tokenToUse) {
            updateToken(token);
        }
        // eslint-disable-next-line
    }, [userData]);

    useEffect(() => {
        if (ohid && !defaultSettingsFetched) {
            updateDefaultParams({ ohid });
            fetchSettings({ ohid });
        }
        // eslint-disable-next-line
    }, [defaultSettingsFetched]);

    const reportsInQueue = pendingReports.filter(({ status }) => QUEUE.includes(status));

    useInterval(
        () => fetchReportsStatus({ export_ids: JSON.stringify(pendingReports.map(({ id }) => id)) }),
        reportsInQueue.length ? 10000 : null
    );

    const settingsObtained = Object.keys(defaultSettings).length > 1;
    const status = [401, 403].includes(currentStatus);

    return (
        <div className="clearFix">
            {status && <WarningModal defaultSettings={defaultSettings} deleteToken={deleteToken} />}
            <Switch>
                <Route
                    path="/"
                    exact
                    render={() => {
                        const page = tokenToUse ? (
                            <Suspense fallback={<Loader position="absolute" showBackground />}>
                                <UserReports />
                            </Suspense>
                        ) : (
                            <Suspense fallback={<Loader position="absolute" showBackground />}>
                                <LogoutComponent />
                            </Suspense>
                        );
                        return page;
                    }}
                />
                <PrivateRoute
                    path="/diagnose"
                    settingsObtained={settingsObtained}
                    token={tokenToUse}
                    component={Diagnose}
                    exact
                />
                <PrivateRoute
                    path="/reports"
                    settingsObtained={settingsObtained}
                    token={tokenToUse}
                    component={Reports}
                    exact
                />
                <PrivateRoute
                    path="/settings"
                    settingsObtained={settingsObtained}
                    token={tokenToUse}
                    component={Settings}
                    exact
                />
                <PrivateRoute
                    path="/scores"
                    settingsObtained={settingsObtained}
                    token={tokenToUse}
                    component={DemographicScores}
                    exact
                />
                <PrivateRoute
                    path="/inclusion"
                    settingsObtained={settingsObtained}
                    token={tokenToUse}
                    component={Inclusion}
                    exact
                />
                <PrivateRoute
                    path="/response-rate"
                    settingsObtained={settingsObtained}
                    token={tokenToUse}
                    component={ResponseRate}
                    exact
                />
                <PrivateRoute
                    path="/gsod"
                    settingsObtained={settingsObtained}
                    token={tokenToUse}
                    component={Analytics}
                    exact
                />
                <PrivateRoute
                    path="/usersList"
                    settingsObtained={settingsObtained}
                    token={tokenToUse}
                    component={UserManagement}
                    exact
                />
                <PrivateRoute path="/action" settingsObtained token={tokenToUse} component={ActionCompendium} exact />
                <Route path="/auth/callback" exact render={() => <CallbackComponent mid={mid} />} />
                <Route component={Page404} />
            </Switch>
        </div>
    );
}

Routes.propTypes = {
    currentStatus: PropTypes.number.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    mid: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    pendingReports: PropTypes.array.isRequired,
    updateDefaultParams: PropTypes.func.isRequired,
    fetchSettings: PropTypes.func.isRequired,
    deleteToken: PropTypes.func.isRequired,
    fetchReportsStatus: PropTypes.func.isRequired,
    defaultSettingsFetched: PropTypes.bool.isRequired,
};

const stateToProps = createStructuredSelector({
    currentStatus: getCurrentStatus(),
    defaultSettings: getDefaultSettings(),
    userData: selectUserData(),
    pendingReports: selectPendingReports(),
    defaultSettingsFetched: selectDefaultSettingsFetched(),
});

const dispatchToProps = {
    updateDefaultParams: updateApiParams,
    fetchSettings: fetchDefaultSettings,
    deleteToken: removeToken,
    fetchReportsStatus: getPendingReportStatus,
};

export default connect(stateToProps, dispatchToProps)(Routes);
