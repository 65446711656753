import React, { useEffect, useState, useMemo } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import XLSX from 'xlsx-color';
import { Helmet } from 'react-helmet';
import moment from 'moment-timezone';

import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import FilterBar from '../../components/FilterBar';
import DailyResponses from './DailyResponses';
import DemoResponses from './DemoResponses';
import CrossTabs from './CrossTabs';
import { NAV_LINKS, RESET_TAB_REDUX } from './constants';
import { updateFilterParams, getSubFromMain } from '../../utils/functions';
import { getStaticText } from '../../utils/constants';
import './index.scss';
import AddInput from '../../components/AddCustomInputModal/AddInput';

function Survey({
    errorInfo,
    surveyLoadCount,
    demoApiCount,
    apiParams,
    activeFilter,
    filters,
    defaultSettings,
    updateFilters,
    getDemographics,
    getDailyResponses,
    dailyResponses,
    dailyResFetched,
    dropDownValue,
    setDropDownValue,
    demoResponses,
    demoResFetched,
    getDemoResponses,
    getCrossTab,
    crossTabData,
    crossTabFetched,
    crossTabDrop,
    reloadSiteData,
    reloadTabData,
    setCrossTabsDropDown,
}) {
    const { rr_survey_type = 1, lang, role } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, USER_ROLES } = staticText;
    const { SURVEY_NAV_LINKS, RR_SURVEY_OPTIONS, REFRESH_TEXT, RESPONSE_RATE_TEXT, LAST_UPDATE } = SITE_TEXT || {};
    const [activeIndex, updateIndex] = useState(0);
    const [filterOpen, toggleFilterView] = useState(false);
    const [ind_global, setIndGlobal] = useState(rr_survey_type);
    const [addCustomInput, setAddCustomInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isClient = role === USER_ROLES.CLIENT;

    const showUpdateTarget = useMemo(() => {
        return (ind_global === RR_SURVEY_OPTIONS[2].code || rr_survey_type === RR_SURVEY_OPTIONS[2].code) && !isClient;
        // eslint-disable-next-line
    }, [ind_global]);

    const { ohid, filters: appliedFilters } = apiParams;
    const { n_size = 0, last_updated_at } = dailyResponses;
    const { n_size: demoSize = 0, last_updated_at: lastDemo } = demoResponses[dropDownValue] || {};
    const { n_size: crossTabsDemoSize = 0, last_updated_at: lastCross } = crossTabData;
    const sizeDailyDemo = activeIndex === 0 ? n_size : demoSize;
    const sizeInUse = activeIndex === 2 ? crossTabsDemoSize : sizeDailyDemo;
    const { demographics = [] } = filters[0] || {};
    const lastUpdateTime = [last_updated_at, lastDemo, lastCross];

    const lastUpdatedText = useMemo(() => {
        const oldTime = lastUpdateTime[activeIndex];
        if (!oldTime) {
            return null;
        }
        const start = moment(oldTime).format('MMMM Do YYYY, h:mm:ss a');
        const end = moment()
            .tz('Europe/London')
            .format('MMMM Do YYYY, h:mm:ss a');
        const duration = moment.duration(
            moment(end, 'MMMM Do YYYY, h:mm:ss a').diff(moment(start, 'MMMM Do YYYY, h:mm:ss a'))
        );
        //    const yearss = parseInt(duration.asYears(), 10);
        const years = Math.round(duration.asYears());
        const days = parseInt(duration.asDays(), 10);
        const hours = parseInt(duration.asHours(), 10) % 24;
        const minutes = parseInt(duration.asMinutes(), 10) % 60;
        const daysInUse = days > 365 ? days - 365 : days;

        return `${getSubFromMain(years, 'year', ',')} ${getSubFromMain(daysInUse, 'day', ',')} ${getSubFromMain(
            hours,
            'hour',
            'and'
        )} ${getSubFromMain(minutes, 'minute', 'ago', false)} `;
        // eslint-disable-next-line
    }, [lastUpdateTime, activeIndex]);

    const updateSurveyAndReload = updatedInd => {
        reloadSiteData();
        setIndGlobal(updatedInd);
    };

    useEffect(() => {
        if (!demographics.length) {
            getDemographics({ ohid, lang: parseInt(lang, 10) }, 0);
        }
        // eslint-disable-next-line
    }, []);

    const setFilters = (newFilters, filtersApplied) => {
        toggleFilterView(false);
        const { demographics: newDemo = [], benchmarks = [] } = newFilters[0];
        const updatedParams = updateFilterParams(newDemo, benchmarks);
        updateFilters({ filters: newFilters, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
    };

    const getExport = () => {
        const table = document.getElementById('tableData');
        if (table) {
            const text = SURVEY_NAV_LINKS[activeIndex];
            const wb = XLSX.utils.table_to_book(table, { sheet: 'Report', raw: true });
            let indexInCheck = activeIndex === 1 ? 7 : 6;
            Object.keys(wb.Sheets.Report).forEach((val, valIndex) => {
                if (typeof wb.Sheets.Report[val] === 'object') {
                    const filterSplit = wb.Sheets.Report[val].v.split('#') || [];
                    let align = 'left';
                    let styles = {};
                    if (filterSplit.length > 1) {
                        indexInCheck += 1;
                    }
                    if (filterSplit.length > 1 || valIndex >= indexInCheck) {
                        align = 'center';
                        styles = {
                            font: {
                                sz: 12,
                            },
                        };
                    } else {
                        styles = {
                            font: {
                                bold: true,
                                sz: 14,
                            },
                        };
                    }
                    wb.Sheets.Report[val].s = {
                        alignment: {
                            vertical: align,
                            horizontal: align,
                        },
                        ...styles,
                    };
                }
            });
            XLSX.writeFile(wb, `${text} ${moment().format('MM_DD_YYYY')}.xlsx`);
        }
    };

    const renderDemographicFilter = () => {
        const labelArr = appliedFilters.map(demographicFilter => {
            let concatLabel = '';
            if (demographicFilter.includes(':')) {
                const splitDemographic = demographicFilter.split(':');
                const firstPart = splitDemographic[0];
                const index = demographics.map(item => item.code).indexOf(firstPart);
                const { label: demographicLabel = '', options = [] } = demographics[index] || -1;
                if (index > -1) {
                    const subLabels = options.filter(({ isSelected }) => isSelected).map(({ label }) => label);
                    concatLabel = demographicLabel + ' - ' + subLabels.join(',');
                }
            }
            return concatLabel;
        });
        return labelArr.map((label, i) => {
            return <td key={`${label}${i}`}>#{label}</td>;
        });
    };

    const getPage = () => {
        let page;
        const apiParamsToSend = { ohid, lang: parseInt(lang, 10), ind_global, filters: appliedFilters };
        switch (activeIndex) {
            case 0:
            default:
                page = (
                    <DailyResponses
                        renderDemographicFilter={renderDemographicFilter}
                        toggleFilterView={toggleFilterView}
                        getDailyResponses={getDailyResponses}
                        activeFilter={activeFilter}
                        errorInfo={errorInfo}
                        apiParams={apiParamsToSend}
                        dailyResFetched={dailyResFetched}
                        dailyResponses={dailyResponses}
                        surveyLoadCount={surveyLoadCount}
                        getExport={getExport}
                        originalInd={rr_survey_type}
                        reloadTabData={reloadTabData}
                        setIndGlobal={updateSurveyAndReload}
                        addCustomInput={setAddCustomInput}
                        demographics={demographics}
                        defaultSettings={defaultSettings}
                        filters={filters}
                        showUpdateTarget={showUpdateTarget}
                    />
                );
                break;
            case 1:
                page = (
                    <DemoResponses
                        defaultSettings={defaultSettings}
                        renderDemographicFilter={renderDemographicFilter}
                        demographics={demographics}
                        toggleFilterView={toggleFilterView}
                        getDemoResponses={getDemoResponses}
                        activeFilter={activeFilter}
                        errorInfo={errorInfo}
                        apiParams={apiParamsToSend}
                        demoResFetched={demoResFetched}
                        demoResponses={demoResponses}
                        surveyLoadCount={surveyLoadCount}
                        getExport={getExport}
                        setDropDownValue={setDropDownValue}
                        dropDownValue={dropDownValue}
                        originalInd={rr_survey_type}
                        reloadTabData={reloadTabData}
                        setIndGlobal={updateSurveyAndReload}
                        addCustomInput={setAddCustomInput}
                        showUpdateTarget={showUpdateTarget}
                        setIsLoading={setIsLoading}
                    />
                );
                break;
            case 2:
                page = (
                    <CrossTabs
                        defaultSettings={defaultSettings}
                        renderDemographicFilter={renderDemographicFilter}
                        demographics={demographics}
                        toggleFilterView={toggleFilterView}
                        activeFilter={activeFilter}
                        errorInfo={errorInfo}
                        apiParams={apiParamsToSend}
                        surveyLoadCount={surveyLoadCount}
                        crossTabData={crossTabData}
                        crossTabFetched={crossTabFetched}
                        getCrossTab={getCrossTab}
                        crossTabDrop={crossTabDrop}
                        setCrossTabsDropDown={setCrossTabsDropDown}
                        originalInd={rr_survey_type}
                        reloadTabData={reloadTabData}
                        setIndGlobal={updateSurveyAndReload}
                        addCustomInput={setAddCustomInput}
                        showUpdateTarget={showUpdateTarget}
                    />
                );
                break;
        }
        return page;
    };

    const changePage = newPageIndex => {
        updateIndex(newPageIndex);
    };

    const closeAddModal = (checkToShow, checkToReload) => {
        setAddCustomInput(checkToShow);
        if (checkToReload) {
            reloadSiteData();
        }
    };

    return (
        <Layout showHeader>
            <div className="surveys clearfix">
                <Helmet>
                    <title>
                        {RESPONSE_RATE_TEXT} - {SURVEY_NAV_LINKS[activeIndex]}
                    </title>
                </Helmet>
                {surveyLoadCount || demoApiCount || isLoading ? (
                    <Loader position="absolute" showBackground background="transparent" />
                ) : null}
                <div className="navLinkWrap clearfix">
                    <ul className="navLink clearfix">
                        {NAV_LINKS.map(({ imgSrc, imgSrcActive }, index) => {
                            const text = SURVEY_NAV_LINKS[index];
                            return (
                                <li
                                    data-testid={`surveyTab${index}`}
                                    className={c({ selected: activeIndex === index })}
                                    key={text}
                                    onClick={() => changePage(index)}
                                >
                                    <img src={activeIndex === index ? imgSrcActive : imgSrc} alt="" />
                                    {text}
                                </li>
                            );
                        })}
                    </ul>
                    <ul className="rightLink clearfix">
                        {lastUpdatedText && (
                            <li className="strong">
                                {LAST_UPDATE}: {lastUpdatedText}
                            </li>
                        )}
                        <li onClick={() => reloadTabData(RESET_TAB_REDUX[activeIndex])}>
                            <span className="refreshBtn">{REFRESH_TEXT}</span>
                        </li>
                    </ul>
                </div>
                {getPage()}
                {filterOpen && (
                    <FilterBar
                        activeResetFilter={activeFilter}
                        size={[sizeInUse, 0, 0]}
                        surveyRespondents={[0]}
                        surveys={[0]}
                        filters={filters}
                        closeFilters={toggleFilterView}
                        setFilters={setFilters}
                        defaultBenchmark={[]}
                        showBenchmarks={false}
                        defaultSettings={defaultSettings}
                        isRR
                    />
                )}
                {addCustomInput && (
                    <AddInput
                        apiParams={apiParams}
                        setAddCustomInput={closeAddModal}
                        demographics={demographics}
                        defaultSettings={defaultSettings}
                    />
                )}
            </div>
        </Layout>
    );
}

Survey.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    dailyResponses: PropTypes.object.isRequired,
    demoResponses: PropTypes.object.isRequired,
    crossTabData: PropTypes.object.isRequired,
    surveyLoadCount: PropTypes.number.isRequired,
    demoApiCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.array.isRequired,
    crossTabDrop: PropTypes.array.isRequired,
    apiParams: PropTypes.object.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    dailyResFetched: PropTypes.bool.isRequired,
    crossTabFetched: PropTypes.bool.isRequired,
    demoResFetched: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    dropDownValue: PropTypes.string.isRequired,
    setDropDownValue: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getDailyResponses: PropTypes.func.isRequired,
    getDemoResponses: PropTypes.func.isRequired,
    getCrossTab: PropTypes.func.isRequired,
    reloadTabData: PropTypes.func.isRequired,
    reloadSiteData: PropTypes.func.isRequired,
    setCrossTabsDropDown: PropTypes.func.isRequired,
};

export default Survey;
