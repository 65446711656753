import React, { useState, useEffect, Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Select, MenuItem, Tooltip } from '@material-ui/core';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { Helmet } from 'react-helmet';

import { cloneDeep } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import AuthStore from '../../../common/AuthStore';
import ExcelResurvey from './ExcelResurvey';
import {
    getBenchmarkString,
    getFilterArray,
    getDemographicName,
    getBenchmarkName,
    getTableMeta,
    getStylesForPpt,
    getPptValues,
    getFiltersAppliedText,
    updateFilterParams,
    // new
    getOutputFilter,
} from '../../../utils/functions';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';
import ActiveFilter from '../assets/ActiveFilter.svg';
import FilterSvg from '../assets/Filter.svg';
import CompareView from '../assets/CompareView.svg';
import ArrowLeft from '../assets/ArrowLeft.svg';
import ArrowRight from '../assets/ArrowRight.svg';
import colors from '../../../sass/colors';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import FocusArea from '../assets/FocusArea.svg';
import viewIcon from '../../Reports/assets/viewIcon.svg';

function ResurveyBoard({
    toolOpen,
    activeFilter,
    filters,
    defaultSettings,
    apiParams,
    apiLoadingCount,
    updateFilterView,
    setReport,
    projects,
    heatMapResurveyInfo,
    updateHeatMapInfo,
    setResurveyTab,
    heatMapResurveyTab,
    getHeatMapResurvey,
    resurveyOutcomeHeatMapFetched,
    resurveyPracticeHeatMapFetched,
    heatMapData,
    getPptData,
    isCfgClient,
    getReport,
    getRawPptData,
    reportError,
    getSinglePastReport,
    singlePastReport,
    // new
    setFilters,
}) {
    const [page, updatePage] = useState(1);
    const [isSticky, setSticky] = useState(false);
    const [selectedDownload, setSelectedDownload] = useState('');

    const { $white, $black } = colors;
    const {
        quartileColors = [],
        demographic = '',
        lang,
        ohid,
        engagement,
        resurvey_settings,
        report_name = '',
        report_name_or_client_name: rName = '',
        template_name: template_file_name,
        survey_version,
        report_id = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const {
        OUTCOME_VAR,
        PRACTICE_VAR,
        SLICE_VALUE,
        PERCENTILE,
        RESURVEY_BREAKPOINT,
        RESURVEY_BREAKPOINT1,
        PPT_SLICE,
        PPT_SLICE_V2,
        PPT_SLICE_V3,
        QUARTILE_RESURVEY,
    } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, NON_PROFIT_SURVEY, RESURVEY_EXPORT_PARAMS } = staticText;
    const isOhi4V = survey_version === '4';
    const isOhi4 = survey_version === '4' || survey_version === '3_2';

    const isQuartile = resurvey_settings === QUARTILE_RESURVEY ? 'SigChangeLegend' : 'QuartileLegend';
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const {
        DEMOGRAPHIC,
        FILTERS,
        PRACTICES,
        OUTCOMES,
        RESURVEY_TEXT,
        SIGNIFICANCE,
        SELECT_DOWNLOAD,
        DOWNLOAD_EXCEL,
        DOWNLOAD_PPT_V1,
        DOWNLOAD_PPT_V2,
        DOWNLOAD_PPT_V3,
        PPT_RES_TEXT,
        PPT_RES_TEXT2,
        PPT_RES_TEXT3,
        SOURCE_TEXT,
        BENCHMARK_HEAD,
        NUMBER_SURVEY_TEXT,
        N_SIZE,
        DIFF_TEXT,
        OUTCOME_TEXT,
        PRACTICE_TEXT,
        ALL_PPT_TEXT,
        PPT_VALUES,
        DIFF_HEAD,
        // BENCHMARKS_APPLIED,
        // FILTERS_APPLIED,
        // NONE,
        OHI4_RESURVEY_NOTE,
        NOTE,
        SIGNIFICANCES,
        QUARTILE_MOVE_LEGEND_TXT,
        VIEW_LESS,
        VIEW_MORE,
        BENCHMARK,
        RESURVEY_BOARD_PAGE,
    } = SITE_TEXT || {};
    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
    };
    const { items = [], error: message = '', status = '', header = [], loadMoreData } = heatMapData;
    const { items: subItemsInfo = [] } = items[0] || [];
    const { score: n_size = [0, 0] } = subItemsInfo[0] || {};
    const { demographics = [] } = filters[0] || {};
    const { selectedProjectIndex = '', heatMapDemo = '' } = heatMapResurveyInfo;
    const { ohid: newOhid, year: newYear, mappedDemos = [] } = projects[selectedProjectIndex] || {};
    const demographicsInUse = demographics.filter(({ code }) => mappedDemos.indexOf(code) !== -1);
    const { year: oldYear, report_type, threshold } = apiParams;
    const isPercentile = report_type === PERCENTILE;
    const isOutcome = heatMapResurveyTab === OUTCOME_VAR;
    const [dwnld, setdwnld] = useState(false);
    const [filterArray, setFilterArray] = useState([]);
    const isQuartMovement = resurvey_settings === QUARTILE_RESURVEY;
    const sigVInUse = survey_version === '4' || survey_version === '3_2' ? SIGNIFICANCES : SIGNIFICANCE;
    const surveyV = survey_version === '4' || survey_version === '3_2';

    const listenToScroll = () => {
        const headerId = document.getElementById('myHeader');
        const sticky = headerId.offsetTop;
        if (window.pageYOffset > sticky && window.pageYOffset > 80) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    const [showAll, setShowAll] = useState(false);
    const [showCompAll, setShowCompAll] = useState(false);

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    useEffect(() => {
        const { heatMapDemo: newDemo } = heatMapResurveyInfo;
        if (
            (!resurveyOutcomeHeatMapFetched && !apiLoadingCount && heatMapResurveyTab === OUTCOME_VAR) ||
            (!resurveyPracticeHeatMapFetched && !apiLoadingCount && heatMapResurveyTab === PRACTICE_VAR)
        ) {
            updatePage(1);
            const filtersArray = [];
            const benchmarksArray = [];
            filters.forEach(({ benchmarks: newB = [], demographics: newD = [] }, indexToUse) => {
                const benchMarkYear = indexToUse ? newYear : oldYear;
                const preString = indexToUse ? '||' : '';
                const endString = indexToUse ? '' : '||';
                const filtersObtained = getFilterArray(newD, preString, endString);
                filtersObtained.forEach(result => {
                    filtersArray.push(result);
                });
                if (newB.length) {
                    benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
                }
            });
            setFilterArray(filtersArray);
            getHeatMapResurvey({
                ...apiParams,
                ...addOns,
                filters: filtersArray,
                benchmark_str: benchmarksArray.join(','),
                ohid: `${ohid},${newOhid}`,
                pid: '',
                type: heatMapResurveyTab,
                demographic: newDemo,
                page: 1,
                comparison_type: resurvey_settings === QUARTILE_RESURVEY ? 2 : 1,
            });
        }
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
        // eslint-disable-next-line
    }, [
        resurveyOutcomeHeatMapFetched,
        resurveyPracticeHeatMapFetched,
        heatMapResurveyTab,
        apiLoadingCount,
        heatMapResurveyInfo,
        filters,
    ]);

    const handleTabData = tab => {
        if (heatMapResurveyTab !== tab) {
            setResurveyTab(tab);
            updatePage(1);
        }
    };

    const getStylesArr = () => {
        const styleArr = getStylesForPpt(quartileColors);
        if (!isOutcome) {
            styleArr.push({
                bgcolor: 'FFFFFF',
                text_color: '000000',
                border_color: 'FFFFFF,FFFFFF,CFCFCF,FFFFFF', // Outcome and practise header
            });
            styleArr.push({
                bgcolor: 'FFFFFF',
                text_color: '000000', // Headers other than Outcome and practice
                border_color: 'FFFFFF',
            });
            styleArr.push({
                bgcolor: 'FFFFFF',
                text_color: '000000', // practise column exclude header
                border_color: 'CFCFCF,FFFFFF,CFCFCF,A6A6A6',
                height: '1000',
            });
            styleArr.push({
                bgcolor: 'FFFFFF',
                text_color: '000000',
                border_color: 'CFCFCF', // outcome column exclude header
            });
        } else {
            styleArr.push({
                text_color: '#000000',
                bgcolor: '#ffffff',
            });
        }
        SIGNIFICANCE.forEach(({ bg: bgcolor }) => {
            styleArr.push({
                text_color: 'FFFFFF',
                bgcolor,
                border_color: 'FFFFFF',
            });
        });
        styleArr.push({
            text_color: 'ffffff',
        });
        return styleArr;
    };

    const headerObject = (
        value,
        row_index,
        column_index,
        column_width = '',
        style = '5',
        colSpan = false,
        alignment = 'left',
        font_size = null
    ) => {
        const obj = {
            value,
            row_index,
            column_index,
            column_width,
            style,
            alignment,
            is_bold: 'true',
        };
        if (colSpan) {
            obj.colspan = 2;
        }
        if (font_size) {
            obj.font_size = font_size;
        }
        return obj;
    };

    const dataObject = (row_index, column_index, value, style, alignment = 'center', rowspan, font_size = null) => {
        const obj = {
            row_index,
            column_index,
            value,
            style,
            alignment,
            is_bold: 'false',
        };
        if (rowspan) {
            obj.rowspan = rowspan;
            obj.is_bold = 'true';
        }
        if (font_size) {
            obj.font_size = font_size;
        }
        return obj;
    };

    const getPpt = (slideArr = [], pptName = 'ResurveyDemographic') => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: slideArr,
        };
        getPptData(output, pptAccessToken, pptName);
    };

    const removeIntitalSlide = (isPractice = false) => {
        return [
            {
                identify_shape_name: 'Checkme',
                identify_shape_value: isPractice ? 'practicebydemo' : 'copyslide',
                is_copy: false,
                is_delete: true,
                styles: [],
            },
        ];
    };

    const slidesArrayObject = (styleArr, tableDataArr, iteration, itemPerPage, isPractice = false) => {
        const { n_respondents = [], n_survey = [] } = heatMapData;
        const { benchmarks: selectedBenchmark = [] } = filters[0] || {};
        const { benchmarks: selectedBenchmark2 = [] } = filters[1] || {};
        const label = getBenchmarkName(selectedBenchmark);
        const label2 = getBenchmarkName(selectedBenchmark2);
        const demograhicLabel = getDemographicName(demographicsInUse, heatMapDemo);
        const originalSub = isPractice ? PPT_RES_TEXT2 : PPT_RES_TEXT;
        const { GLOBAL_VALS } = PPT_VALUES({ rName });

        return {
            identify_shape_name: 'Checkme',
            identify_shape_value: isPractice ? 'practicebydemo' : 'copyslide',
            is_copy: true,
            is_delete: false,
            styles: styleArr,
            data: [
                ...tableDataArr,
                {
                    name: 'AutoShape 250',
                    value: isPercentile ? PPT_RES_TEXT3 : originalSub,
                },
                {
                    name: 'Checkme',
                    is_delete: 'true',
                },
                {
                    name: isQuartile,
                    is_delete: 'true',
                },
                { name: 'OHILegend_TopDecile_Alt', style: 0 },
                { name: 'OHILegend_TopQuartile', style: 1 },
                { name: 'OHILegend_SecondQuartile', style: 2 },
                { name: 'OHILegend_ThirdQuartile', style: 3 },
                { name: 'OHILegend_BottomQuartile', style: 4 },
                {
                    name: 'HeaderShape',
                    value: isPractice
                        ? `${PRACTICE_TEXT} ${demograhicLabel} (${iteration}/${itemPerPage})`
                        : `${OUTCOME_TEXT} by ${demograhicLabel} (${iteration}/${itemPerPage})`,
                },
                {
                    name: 'source',
                    value: `${SOURCE_TEXT} ${rName} ${oldYear} (n=${n_size[0]}); ${BENCHMARK_HEAD}: ${label} (n=${n_respondents[0]}, ${NUMBER_SURVEY_TEXT}=${n_survey[0]}); ${rName} ${newYear} (n=${n_size[1]}); ${BENCHMARK_HEAD}: ${label2} (n=${n_respondents[1]}, ${NUMBER_SURVEY_TEXT}=${n_survey[1]})`,
                },
                ...getPptValues(GLOBAL_VALS),
                { name: '4. Footnote', value: ALL_PPT_TEXT[28] },
            ],
        };
    };

    const getDataForPptPractice = (addCol = false, pptName) => {
        const slidesArr = [];
        const sliceInUse = addCol ? PPT_SLICE_V2 : PPT_SLICE;
        const getPageNo = Math.ceil((header.length - 1) / sliceInUse);
        const itemPerPage = !getPageNo ? 1 : getPageNo;
        const obj = {};
        items
            .filter(({ parent_name }) => parent_name)
            .forEach(({ parent_name = '' }) => {
                if (obj[parent_name]) {
                    obj[parent_name] += 1;
                } else {
                    obj[parent_name] = 1;
                }
            });
        const mainHeadWidth = addCol ? 10500 : 8500;
        const subHeadWidth = 3500;
        for (let iteration = 1; iteration <= itemPerPage; iteration += 1) {
            const dataArr = [];
            const pptArr = [];
            const arr = [];
            pptArr.push(
                {
                    row_index: 0,
                    column_index: 0,
                    value: OUTCOMES,
                    column_width: 15000,
                    style: '5',
                    rowspan: '2',
                    alignment: 'left',
                    v_alignment: 'top',
                    is_bold: 'true',
                },
                {
                    row_index: 0,
                    column_index: 1,
                    value: PRACTICES,
                    column_width: 20000,
                    style: '5',
                    rowspan: 2,
                    alignment: 'left',
                    v_alignment: 'top',
                    is_bold: 'true',
                },
                headerObject(`${header[0]} ${oldYear}`, 0, 2, mainHeadWidth, '6', true, 'left', 6),
                headerObject('', 0, 3, subHeadWidth, '6')
            );
            if (addCol) {
                pptArr.push(headerObject(`${header[0]} ${newYear}`, 0, 4, mainHeadWidth, '6', false, 'left', 6));
            }
            let headIndex = addCol ? 5 : 4;
            header.slice((iteration - 1) * sliceInUse + 1, iteration * sliceInUse + 1).forEach(val => {
                pptArr.push(headerObject(`${val} ${oldYear}`, 0, headIndex, mainHeadWidth, '6', true, 'left', 6));
                pptArr.push(headerObject('', 0, headIndex + 1, subHeadWidth, '6'));
                headIndex += 2;
                if (addCol) {
                    pptArr.push(
                        headerObject(`${val} ${newYear}`, 0, headIndex + 1, mainHeadWidth, '6', false, 'left', 6)
                    );
                    headIndex += 1;
                }
            });

            items.forEach(({ parent_name = '', title = '', items: subItems = [] }, itemIndex) => {
                const { quartile: qMain = [], score: sMain = [], difference: diffM = '-', significance: signM } =
                    subItems[0] || {};
                const scoreToSendVal = sMain[0] === '' ? '-' : sMain[0];
                const scoreToSendVal1 = sMain[1] === '' ? '-' : sMain[1];
                const quatToSend = qMain[0] && qMain[0] !== '-' ? qMain[0] : '6';
                const quatToSend1 = qMain[1] && qMain[1] !== '-' ? qMain[1] : '6';
                dataArr.push(
                    dataObject(
                        itemIndex + 1,
                        2,
                        scoreToSendVal,
                        !itemIndex ? '5' : quatToSend,
                        'center',
                        false,
                        !itemIndex ? 6 : null
                    ),
                    dataObject(
                        itemIndex + 1,
                        3,
                        !itemIndex ? '.' : diffM,
                        !isNaN(signM) && itemIndex ? signM + 10 : 13,
                        'center',
                        false,
                        !itemIndex ? 2 : null
                    )
                );

                if (addCol) {
                    dataArr.push(
                        dataObject(
                            itemIndex + 1,
                            4,
                            scoreToSendVal1,
                            quatToSend1,
                            'center',
                            false,
                            !itemIndex ? 6 : null
                        )
                    );
                }

                let colIndex = addCol ? 5 : 4;
                if (arr.indexOf(parent_name) > -1) {
                    dataArr.push(dataObject(itemIndex + 1, 1, title, '7'));
                    subItems
                        .slice((iteration - 1) * sliceInUse + 1, iteration * sliceInUse + 1)
                        .forEach(({ quartile = [], score = [], difference = '-', significance }) => {
                            const scoreToSend = score[0] === '' ? '-' : score[0];
                            const scoreToSend1 = score[1] === '' ? '-' : score[1];
                            const quartileToSend = quartile[0] !== '-' ? quartile[0] : '6';
                            const quartileToSend1 = quartile[1] !== '-' ? quartile[1] : '6';
                            dataArr.push(
                                dataObject(itemIndex + 1, colIndex, scoreToSend, quartileToSend),
                                dataObject(
                                    itemIndex + 1,
                                    colIndex + 1,
                                    difference,
                                    !isNaN(significance) ? significance + 10 : '6'
                                )
                            );
                            if (addCol) {
                                dataArr.push(dataObject(itemIndex + 1, colIndex + 2, scoreToSend1, quartileToSend1));
                            }
                            colIndex += addCol ? 3 : 2;
                        });
                } else if (parent_name) {
                    arr.push(parent_name);
                    dataArr.push(dataObject(itemIndex + 1, 0, parent_name, '8', 'center', obj[parent_name]));
                    dataArr.push(dataObject(itemIndex + 1, 1, title, '7'));
                    subItems
                        .slice((iteration - 1) * sliceInUse + 1, iteration * sliceInUse + 1)
                        .forEach(({ quartile = [], score = [], difference, significance }) => {
                            const scoreToSend = score[0] === '' ? '-' : score[0];
                            const scoreToSend1 = score[1] === '' ? '-' : score[1];
                            const quartileToSend = quartile[0] !== '-' ? quartile[0] : '6';
                            const quartileToSend1 = quartile[1] !== '-' ? quartile[1] : '6';
                            dataArr.push(
                                dataObject(itemIndex + 1, colIndex, scoreToSend, quartileToSend),
                                dataObject(
                                    itemIndex + 1,
                                    colIndex + 1,
                                    difference,
                                    !isNaN(significance) ? significance + 10 : '6'
                                )
                            );
                            if (addCol) {
                                dataArr.push(dataObject(itemIndex + 1, colIndex + 2, scoreToSend1, quartileToSend1));
                            }
                            colIndex += addCol ? 3 : 2;
                        });
                } else if (title === N_SIZE) {
                    subItems
                        .slice((iteration - 1) * sliceInUse + 1, iteration * sliceInUse + 1)
                        .forEach(({ score = [] }) => {
                            const scoreToSend = score[0] === '' ? '-' : score[0];
                            const scoreToSend1 = score[1] === '' ? '-' : score[1];
                            dataArr.push(
                                dataObject(itemIndex + 1, colIndex, scoreToSend, '5', 'center', false, 6),
                                dataObject(itemIndex + 1, colIndex + 1, '.', 13, 'center', false, 2)
                            );
                            if (addCol) {
                                dataArr.push(
                                    dataObject(itemIndex + 1, colIndex + 2, scoreToSend1, '5', 'center', false, 6)
                                );
                            }
                            colIndex += addCol ? 3 : 2;
                        });
                }
            });
            const mainTable = [
                {
                    name: '',
                    type: 'table',
                    meta_data: getTableMeta(!isOutcome, 16000, 100),
                    table_data: [...pptArr, ...dataArr],
                },
            ];
            slidesArr.push(slidesArrayObject(getStylesArr(), mainTable, iteration, itemPerPage, true));
        }
        getPpt([...slidesArr, ...removeIntitalSlide(true)], pptName);
    };

    const getDataForPpt = pptName => {
        const pptArr = [];
        const slidesArr = [];
        header.unshift('');
        let headerCol = 0;
        header.forEach((val, i) => {
            pptArr.push(headerObject(val, 0, headerCol, !i ? 18000 : 5100, '5', i > 1, 'center'));
            headerCol += 1;
            if (i > 1) {
                pptArr.push(headerObject('', 0, headerCol, 3500, '5'));
                headerCol += 1;
            }
        });
        const itemsInUse = items.slice(1);
        const itemPerPage = Math.ceil(itemsInUse.length / RESURVEY_BREAKPOINT);
        for (let iteration = 0; iteration < itemPerPage; iteration += 1) {
            const dataArr = [];
            const splitArr = itemsInUse.slice(iteration * RESURVEY_BREAKPOINT, (iteration + 1) * RESURVEY_BREAKPOINT);
            [items[0], ...splitArr].forEach(({ title = '', items: subItems = [] }, itemIndex) => {
                const row_index = itemIndex + 1;
                let column_index = 0;
                dataArr.push(dataObject(row_index, column_index, title, '5', 'left'));
                subItems.forEach(({ quartile = [], score = [], difference, significance }, subIndex) => {
                    column_index += 1;
                    const scoreToSend = score[0] === '' ? '-' : score[0];
                    const quatToSend = quartile[0] !== '-' ? quartile[0] : '5';
                    dataArr.push(dataObject(row_index, column_index, scoreToSend, quatToSend));
                    if (subIndex) {
                        column_index += 1;
                        dataArr.push(
                            dataObject(
                                row_index,
                                column_index,
                                difference,
                                !isNaN(significance) ? significance + 7 : '5'
                            )
                        );
                    }
                });
            });
            const mainTable = [
                {
                    name: '',
                    type: 'table',
                    meta_data: getTableMeta(!isOutcome, 15434, 3000),
                    table_data: [...pptArr, ...dataArr],
                },
            ];
            slidesArr.push(slidesArrayObject(getStylesArr(), mainTable, iteration + 1, itemPerPage, !isOutcome));
        }
        getPpt([...slidesArr, ...removeIntitalSlide()], pptName);
    };

    const getDataForPptV2 = pptName => {
        const pptArr = [];
        const pptArr2 = [];
        const slidesArr = [];
        header.unshift('');
        let headerCol = 0;
        header.forEach((val, i) => {
            pptArr.push(headerObject(val, 0, headerCol, !i ? 18000 : 5100, '5', i > 1, 'center'));
            headerCol += 1;
            let col_width = 8600;
            if (i > 1) {
                pptArr.push(headerObject('', 0, headerCol, 3500, '5'));
                headerCol += 1;
            } else {
                col_width = !i ? 18000 : 5600;
            }
            pptArr2.push(headerObject(val, 0, i, col_width, '5', false, 'center'));
        });
        const itemsInUse = items.slice(1);
        const itemPerPage = Math.ceil(itemsInUse.length / RESURVEY_BREAKPOINT1);
        for (let iteration = 0; iteration < itemPerPage; iteration += 1) {
            const dataArr = [];
            const dataArr2 = [];
            const splitArr = itemsInUse.slice(iteration * RESURVEY_BREAKPOINT1, (iteration + 1) * RESURVEY_BREAKPOINT1);
            [items[0], ...splitArr].forEach(({ title = '', items: subItems = [] }, itemIndex) => {
                const row_index = itemIndex + 1;
                let column_index = 0;
                let column_index1 = 0;
                dataArr.push(dataObject(row_index, column_index, title, '5', 'left'));
                dataArr2.push(dataObject(row_index, column_index1, title, '5', 'left'));
                subItems.forEach(({ quartile = [], score = [], difference, significance }, subIndex) => {
                    column_index += 1;
                    column_index1 += 1;
                    const scoreToSend = score[0] === '' ? '-' : score[0];
                    const quatToSend = quartile[0] !== '-' ? quartile[0] : '5';
                    const scoreToSend1 = score[1] === '' ? '-' : score[1];
                    const quatToSend1 = quartile[1] !== '-' ? quartile[1] : '5';
                    dataArr.push(dataObject(row_index, column_index, scoreToSend, quatToSend));
                    dataArr2.push(dataObject(row_index, column_index1, scoreToSend1, quatToSend1));
                    if (subIndex) {
                        const signToSend = !isNaN(significance) ? significance + 7 : '5';
                        column_index += 1;
                        dataArr.push(dataObject(row_index, column_index, difference, signToSend));
                    }
                });
            });
            const mainTable = [
                {
                    name: '',
                    type: 'table',
                    meta_data: getTableMeta(!isOutcome, !isOutcome ? 13800 : 15434, !isOutcome ? 100 : 450),
                    table_data: [...pptArr, ...dataArr],
                },
                {
                    name: '',
                    type: 'table',
                    meta_data: getTableMeta(!isOutcome, !isOutcome ? 13800 : 40000, !isOutcome ? 100 : 450),
                    table_data: [...pptArr2, ...dataArr2],
                },
            ];
            slidesArr.push(slidesArrayObject(getStylesArr(), mainTable, iteration + 1, itemPerPage, !isOutcome));
        }
        getPpt([...slidesArr, ...removeIntitalSlide()], pptName);
    };

    const getDataForPpt3 = pptName => {
        const headerPerSlide = Math.ceil(header.length / PPT_SLICE_V3);
        const slidesArr = [];
        const itemsInUse = items.slice(1);
        const itemPerPage = Math.ceil(itemsInUse.length / RESURVEY_BREAKPOINT);
        let currentPage = 0;
        for (let iteration = 0; iteration < itemPerPage; iteration += 1) {
            const splitArr = itemsInUse.slice(iteration * RESURVEY_BREAKPOINT, (iteration + 1) * RESURVEY_BREAKPOINT);
            for (let iterationHead = 0; iterationHead < headerPerSlide; iterationHead += 1) {
                let headerCol = 0;
                const table_data = [];
                const headerIn = header.slice(iterationHead * PPT_SLICE_V3 + 1, (iterationHead + 1) * PPT_SLICE_V3 + 1);
                ['', header[0], ...headerIn].forEach((val, i) => {
                    if (i) {
                        table_data.push(
                            headerObject(`${val} ${oldYear}`, 0, headerCol, 7200, '', true, 'left', 6),
                            headerObject('', 0, headerCol + 1, 4200, ''),
                            headerObject(`${val} ${newYear}`, 0, headerCol + 2, 7200, '', false, 'left', 6)
                        );
                        headerCol += 3;
                    } else {
                        table_data.push(headerObject(val, 0, headerCol, 18000, '5', false, 'center'));
                        headerCol += 1;
                    }
                });
                [items[0], ...splitArr].forEach(({ title = '', items: subItems = [] }, itemIndex) => {
                    const row_index = itemIndex + 1;
                    let column_index = 0;
                    table_data.push(dataObject(row_index, column_index, title, '5', 'left'));
                    const subItmesIn = subItems.slice(
                        iterationHead * PPT_SLICE_V3 + 1,
                        (iterationHead + 1) * PPT_SLICE_V3 + 1
                    );
                    [subItems[0], ...subItmesIn].forEach(
                        ({ quartile = [], score = [], difference, significance }, subIndex) => {
                            const scoreToSend = score[0] === '' ? '-' : score[0];
                            const quatToSend = quartile[0] !== '-' ? quartile[0] : '5';
                            const scoreToSend2 = score[1] === '' ? '-' : score[1];
                            const quatToSend2 = quartile[1] !== '-' ? quartile[1] : '5';
                            table_data.push(
                                dataObject(row_index, column_index + 1, scoreToSend, quatToSend),
                                dataObject(
                                    row_index,
                                    column_index + 2,
                                    difference,
                                    isNaN(significance) || !subIndex ? '5' : significance + 7
                                ),
                                dataObject(row_index, column_index + 3, scoreToSend2, quatToSend2)
                            );
                            column_index += 3;
                        }
                    );
                });
                const mainTable = [
                    {
                        name: '',
                        type: 'table',
                        meta_data: getTableMeta(false, 15434, 3000),
                        table_data,
                    },
                ];
                currentPage += 1;
                slidesArr.push(
                    slidesArrayObject(getStylesArr(), mainTable, currentPage, itemPerPage * headerPerSlide, false)
                );
            }
        }
        getPpt([...slidesArr, ...removeIntitalSlide()], pptName);
    };

    const getHeapMapValues = itemsToIterate => {
        return itemsToIterate.map(({ title = '', items: subItems = [] }, indexItem) => {
            const { quartile: fQuartile = [], score: fScore = [], difference, significance } = subItems[0] || {};
            const quartile1 = fQuartile[0];
            const quartile2 = fQuartile[1];
            const score1 = fScore[0];
            const score2 = fScore[1];
            const { background: bgC1, color: c1, border: bc1 = $white } = quartileColors[quartile1] || {};
            const { background: bgC2, color: c2, border: bc2 = $white } = quartileColors[quartile2] || {};
            const { bg = $white } = sigVInUse[significance] || {};
            const colorNSize = isNaN(significance) ? $white : 'inherit';
            const bc1NSize = isNaN(score1) ? $black : bc1;
            const bc2NSize = isNaN(score2) ? $black : bc2;
            const color = isOutcome ? colorNSize : bg;
            const nSize = !indexItem && !isOutcome;
            const colorInUseTemp = indexItem ? color : $black;
            const colorInUse = significance === '' && difference !== '-' ? $black : colorInUseTemp;
            return (
                <div className={c('sectionDiv', { nSize })} key={`${title}-${indexItem}`}>
                    <ul>
                        <Tooltip placement="top" arrow title={title} aria-label={title}>
                            <li className="sectionContent">{title}</li>
                        </Tooltip>
                        <li className="contentList">
                            <span
                                style={{
                                    borderColor: bc1NSize,
                                    background: bgC1,
                                    color: c1,
                                }}
                            >
                                {score1}
                            </span>
                            <span
                                style={{
                                    borderColor: bc2NSize,
                                    background: bgC2,
                                    color: c2,
                                }}
                            >
                                {score2}
                            </span>
                            <span
                                style={{ color: colorInUse }}
                                className={c('difference', { negative: difference < 0 })}
                            >
                                {difference}
                                {difference && !isOutcome ? <ArrowUpward /> : null}
                            </span>
                        </li>
                        {subItems
                            .slice((page - 1) * SLICE_VALUE + 1, page * SLICE_VALUE + 1)
                            .map(
                                (
                                    {
                                        score: iFScore = [],
                                        quartile: iFQuartile = [],
                                        difference: cDiff,
                                        significance: cSign,
                                    },
                                    index
                                ) => {
                                    const innerQuartile1 = iFQuartile[0];
                                    const innerQuartile2 = iFQuartile[1];
                                    const innerScore1 = iFScore[0];
                                    const innerScore2 = iFScore[1];
                                    const { background: fCground1, color: fCColor1, border: fCBColor1 = $white } =
                                        quartileColors[innerQuartile1] || {};
                                    const { background: fCground2, color: fCColor2, border: fCBColor2 = $white } =
                                        quartileColors[innerQuartile2] || {};
                                    const { bg: cbg = $white } = sigVInUse[cSign] || {};
                                    const colorNSign = isNaN(cSign) ? $white : 'inherit';
                                    const colorInUseT = nSize ? colorNSign : cbg;
                                    const colorInUseP = cSign === '' && cDiff !== '-' ? $black : colorInUseT;
                                    const fbc1NSize = isNaN(innerScore1) ? $black : fCBColor1;
                                    const fbc2NSize = isNaN(innerScore2) ? $black : fCBColor2;

                                    return (
                                        <li className="contentList" key={index}>
                                            <span
                                                style={{
                                                    borderColor: fbc1NSize,
                                                    background: fCground1,
                                                    color: fCColor1,
                                                }}
                                            >
                                                {innerScore1}
                                            </span>
                                            <span
                                                style={{
                                                    borderColor: fbc2NSize,
                                                    background: fCground2,
                                                    color: fCColor2,
                                                }}
                                            >
                                                {innerScore2}
                                            </span>
                                            <span
                                                style={{
                                                    color: colorInUseP,
                                                }}
                                                className={c('difference', { negative: cDiff < 0 })}
                                            >
                                                {cDiff}
                                                {cDiff ? <ArrowUpward /> : null}
                                            </span>
                                        </li>
                                    );
                                }
                            )}
                    </ul>
                </div>
            );
        });
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const toggleCompShowAll = () => {
        setShowCompAll(!showCompAll);
    };

    const handleAllFilterData = (
        parKey,
        childKey,
        filterDemographic,
        filtersDemo,
        updateRedux = true,
        activateFilter = true
    ) => {
        const newDemographic = cloneDeep(filterDemographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks = [] } = filtersDemo[0];
        const allList = { demographics: newDemographic, benchmarks };
        const newList = [...filters.slice(0, 0), allList, ...filters.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true, activateFilter, 0);
        }
    };

    const handleAllCompFilter = (
        parKey,
        childKey,
        compDemographic,
        filtersHere,
        updateRedux = true,
        activedFilter = true
    ) => {
        const newDemographic = cloneDeep(compDemographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks = [] } = filtersHere[0];
        const allList = { demographics: newDemographic, benchmarks };
        const newList = [...filters.slice(0, 1), allList];
        if (updateRedux === true) {
            setFilters(newList, true, activedFilter, 1);
        }
    };

    const maxItemsToShow = 6;

    const resurveyDemoName1 = AuthStore.resurveyFilter1 || 'Organization';
    const resurveyDemoName2 = AuthStore.resurveyFilter2 || 'Comparison survey';

    const appliedFiltersInfo = years => {
        const { benchmarks = [] } = filters[0] || {};
        const { filters: filterApplied = [] } = apiParams;
        const label = getBenchmarkName(benchmarks);
        const { benchmarks: compBenchmarks = [], demographics: compDemographic = [] } = filters[1];
        const { filters: compFilter = [] } = updateFilterParams(compDemographic, compBenchmarks);

        const arrItem = getFiltersAppliedText(filterApplied, demographics);
        const newItem = getOutputFilter(filterApplied, demographics);
        const arrItemComp = getFiltersAppliedText(compFilter, compDemographic);
        const newItemComp = getOutputFilter(compFilter, compDemographic);
        const { n_respondents: nDemographicRes = [], n_survey: nSurveyRes = [] } = heatMapData || {};

        return (
            <div className="filterAppliedContent">
                {arrItem.length > 0 && newItem !== null && (
                    <div className="filterDemo">
                        {newItem !== null && <div className="heading">{resurveyDemoName1 + ` (${years[0]}) : `}</div>}
                        {newItem !== null &&
                            newItem
                                .slice(0, showAll ? newItem.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllFilterData(parentCode, childCode, demographics, filters);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItem.length >= maxItemsToShow || showAll) && (
                            <div onClick={toggleShowAll}>
                                {showAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {arrItemComp.length > 0 && newItemComp !== null && (
                    <div className="filterDemo">
                        {newItemComp !== null && (
                            <div className="heading">{resurveyDemoName2 + ` (${years[1]}) : `}</div>
                        )}
                        {newItemComp !== null &&
                            newItemComp
                                .slice(0, showCompAll ? newItemComp.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllCompFilter(
                                                        parentCode,
                                                        childCode,
                                                        compDemographic,
                                                        filters
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItemComp.length >= maxItemsToShow || showCompAll) && (
                            <div onClick={toggleCompShowAll}>
                                {showCompAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className="filterDemo">
                    <div className="heading">{BENCHMARK + `:`}</div>
                    <div className="nsizeContent">
                        <div>Year : </div>
                        <div>{years[0]}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n : </div>
                        <div>{nDemographicRes[0]}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>No of surveys : </div>
                        <div>{nSurveyRes[0]}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{label}</div>
                    </div>

                    <div className="heading">{BENCHMARK + `:`}</div>
                    <div className="nsizeContent">
                        <div>Year : </div>
                        <div>{years[1]}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n : </div>
                        <div>{nDemographicRes[1]}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>No of surveys : </div>
                        <div>{nSurveyRes[1]}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{getBenchmarkName(compBenchmarks)}</div>
                    </div>
                </div>
            </div>
        );
    };

    const getResurveyParams = newFilters => {
        const selectedIds = [ohid, newOhid];
        const resOhid = `${selectedIds.join(',')}`;
        const compare_ids = [`${ohid}-${newOhid}`];
        const filtersArray = [];
        const benchmarksArray = [];
        newFilters.forEach(({ benchmarks: newB = [], demographics: newD = [] }, indexToUse) => {
            let benchMarkYear = oldYear;
            let preString = '';
            let endString = '';

            if (indexToUse) {
                const projectIndex = selectedIds[indexToUse - 1];
                if (isNaN(Number(projectIndex))) return;
                const { year } = projects[projectIndex];
                benchMarkYear = year;
                preString = indexToUse === 1 ? '||' : '||||';
                endString = indexToUse === 1 && selectedIds.length === 2 ? '||' : '';
            } else {
                endString = selectedIds.length === 2 ? '||||' : '||';
            }
            const filtersObtained = getFilterArray(newD, preString, endString);
            filtersObtained.forEach(result => {
                filtersArray.push(result);
            });
            if (newB.length) {
                benchmarksArray.push(`${getBenchmarkString(newB)}@${benchMarkYear}`);
            }
        });
        return {
            ohid: resOhid,
            // filters: filtersArray,
            filters: filterArray,
            benchmark_str: benchmarksArray.join(','),
            compare_ids,
            report_type,
            comparison_type: resurvey_settings === QUARTILE_RESURVEY ? 2 : 1,
            lang: parseInt(lang, 10),
        };
    };

    const getHeatmapDemographic = () => {
        const map = new Map();
        demographics.forEach(({ code, label }) => {
            if (heatMapDemo === code) {
                map.set(code, label);
            }
        });
        return Object.fromEntries(map);
    };

    const v4PptDownload = (sName, sVersion) => {
        const { benchmark_str, ohid: ohidParam } = getResurveyParams(filters);
        const { bm_filters } = apiParams;
        const { year = '' } = defaultSettings;
        const resurveyParams = {
            ...RESURVEY_EXPORT_PARAMS,
            ...addOns,
            options: {
                ...RESURVEY_EXPORT_PARAMS.options,
                survey_names: `${ohidParam.split(',')[0]}$#$${ohidParam.split(',')[1]}`,
            },
            bm_filters,
            benchmarks: benchmark_str,
            report_id: `${report_id}`,
            threshold,
            report_name: sName,
            practicePptVersion: sVersion,
            outcomePptVersion: sVersion,
            heatmap_demographic: getHeatmapDemographic(),
            year: parseInt(year, 10),
        };
        const valueToSend = {
            ...resurveyParams,
            ...getResurveyParams(filters),
            single_page_meta: {
                checkme: isOutcome ? ['copyslide_resurvey'] : ['practicebydemo_resurvey'],
                name: 'heatmap',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const downloadFile = code => {
        setSelectedDownload(code);
        if (!isOhi4) {
            if (code === DOWNLOAD_PPT_V1) {
                if (isOutcome) {
                    getDataForPpt(`${report_name} ${heatMapDemo} ${OUTCOME_TEXT} ${RESURVEY_BOARD_PAGE['1']} 1`);
                } else {
                    getDataForPptPractice(
                        false,
                        `${report_name} ${heatMapDemo} ${PRACTICE_TEXT} ${RESURVEY_BOARD_PAGE['1']} 1`
                    );
                }
            }
            if (code === DOWNLOAD_PPT_V2) {
                if (isOutcome) {
                    getDataForPptV2(`${report_name} ${heatMapDemo} ${OUTCOME_TEXT} ${RESURVEY_BOARD_PAGE['1']} 2`);
                } else {
                    getDataForPptPractice(
                        true,
                        `${report_name} ${heatMapDemo} ${PRACTICE_TEXT} ${RESURVEY_BOARD_PAGE['1']} 2`
                    );
                }
            }
            if (code === DOWNLOAD_PPT_V3) {
                getDataForPpt3(`${report_name} ${heatMapDemo} ${OUTCOME_TEXT} ${RESURVEY_BOARD_PAGE['1']} 3`);
            }
        } else {
            if (code === DOWNLOAD_PPT_V1) {
                v4PptDownload(`${report_name} ${heatMapDemo} ${OUTCOME_TEXT} ${RESURVEY_BOARD_PAGE['1']} 1`, '1');
            }
            if (code === DOWNLOAD_PPT_V2) {
                v4PptDownload(`${report_name} ${heatMapDemo} ${OUTCOME_TEXT} ${RESURVEY_BOARD_PAGE['1']} 2`, '2');
            }
            if (code === DOWNLOAD_PPT_V3) {
                v4PptDownload(`${report_name} ${heatMapDemo} ${OUTCOME_TEXT} ${RESURVEY_BOARD_PAGE['1']} 3`, '3');
            }
        }
    };

    return (
        <div className="demographicBoard headMapBoard clearfix">
            <Helmet>
                <title>
                    {RESURVEY_BOARD_PAGE['2']} {heatMapResurveyTab} {RESURVEY_BOARD_PAGE['3']}
                </title>
            </Helmet>
            <h2>{DEMOGRAPHIC}</h2>
            {items.length ? <Fragment>{appliedFiltersInfo([oldYear, newYear])}</Fragment> : null}
            <div className="optionWrapper">
                <div>
                    <ul className="leftNav">
                        <li
                            data-testid={OUTCOME_VAR}
                            className={c({ activeLink: heatMapResurveyTab === OUTCOME_VAR })}
                            onClick={() => handleTabData(OUTCOME_VAR)}
                        >
                            {OUTCOMES}
                        </li>
                        <li
                            data-testid={PRACTICE_VAR}
                            className={c({ activeLink: heatMapResurveyTab === PRACTICE_VAR })}
                            onClick={() => handleTabData(PRACTICE_VAR)}
                        >
                            {PRACTICES}
                        </li>
                    </ul>
                </div>
                <div className="rightOptions">
                    <span>
                        <Select
                            IconComponent={KeyboardArrowDownSharpIcon}
                            value={heatMapDemo}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={({ target: { value: code } }) => {
                                updateHeatMapInfo({ heatMapDemo: code });
                            }}
                        >
                            {demographicsInUse.map(({ label, code }) => {
                                return (
                                    <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </span>
                    <span>
                        {!isCfgClient && items.length ? (
                            <Select
                                IconComponent={KeyboardArrowDownSharpIcon}
                                value={selectedDownload}
                                displayEmpty
                                onOpen={() => setSelectedDownload('')}
                                onChange={({ target: { value: code } }) => downloadFile(code)}
                                disabled={loadMoreData}
                            >
                                <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                    <em>{SELECT_DOWNLOAD}</em>
                                </MenuItem>
                                {!isNonProfit && (
                                    <MenuItem classes={{ root: 'demographicOptions' }} value={DOWNLOAD_PPT_V1}>
                                        {DOWNLOAD_PPT_V1}
                                    </MenuItem>
                                )}
                                {!isNonProfit && (
                                    <MenuItem classes={{ root: 'demographicOptions' }} value={DOWNLOAD_PPT_V2}>
                                        {DOWNLOAD_PPT_V2}
                                    </MenuItem>
                                )}
                                {!isNonProfit && isOutcome && (
                                    <MenuItem classes={{ root: 'demographicOptions' }} value={DOWNLOAD_PPT_V3}>
                                        {DOWNLOAD_PPT_V3}
                                    </MenuItem>
                                )}
                                <MenuItem classes={{ root: 'demographicOptions' }} value={DOWNLOAD_EXCEL}>
                                    {DOWNLOAD_EXCEL}
                                </MenuItem>
                            </Select>
                        ) : null}
                    </span>
                    {selectedDownload === DOWNLOAD_EXCEL ? (
                        <ExcelResurvey
                            demographicsInUse={demographicsInUse}
                            filters={filters}
                            items={items}
                            header={header}
                            defaultSettings={defaultSettings}
                            selectedDemographic={heatMapDemo}
                            isOutcome={isOutcome}
                            years={[oldYear, newYear]}
                        />
                    ) : null}
                    <div>
                        <ul className="rightNav hovered">
                            <li className="hovered" onClick={() => setReport(0)}>
                                <img src={CompareView} alt="" />
                                {RESURVEY_TEXT}
                            </li>
                            <li onClick={() => updateFilterView(true)} data-testid="filterButton">
                                <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" /> {FILTERS}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="sectionWrapper">
                <div
                    id="myHeader"
                    className={c('headerWrapper clearfix', {
                        fixedHeader: isSticky,
                        adjustHeader: toolOpen && isSticky,
                    })}
                >
                    {page > 1 && (
                        <div data-testid="arrowLeft" className="arrow" onClick={() => updatePage(page - 1)}>
                            <img className="imgClass" src={ArrowLeft} alt="" />
                        </div>
                    )}
                    {header.length ? (
                        <div>
                            <ul>
                                <Tooltip placement="top" arrow title={header[0] || ''} aria-label={header[0]}>
                                    <li className="headerList" data-testid="headerList">
                                        {header[0]}
                                    </li>
                                </Tooltip>
                                {header
                                    .slice((page - 1) * SLICE_VALUE + 1, page * SLICE_VALUE + 1)
                                    .map((val, valIndex) => (
                                        <Tooltip
                                            key={`${val}-${valIndex}`}
                                            placement="top"
                                            title={val}
                                            aria-label={val}
                                            arrow
                                        >
                                            <li className="headerList">{val}</li>
                                        </Tooltip>
                                    ))}
                            </ul>
                            <div className="yearInfo">
                                <ul>
                                    <li className="headerList">
                                        <span>{oldYear}</span>
                                        <span>{newYear}</span>
                                        <span>{DIFF_TEXT}</span>
                                    </li>
                                    {header
                                        .slice((page - 1) * SLICE_VALUE + 1, page * SLICE_VALUE + 1)
                                        .map((yearKey, yearKeyIndex) => (
                                            <li key={yearKeyIndex} className="headerList">
                                                <span>{oldYear}</span>
                                                <span>{newYear}</span>
                                                <span>{DIFF_TEXT}</span>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    ) : null}
                    {header.length > page * SLICE_VALUE + 1 && (
                        <div data-testid="arrowRight" className="arrow arrowRight" onClick={() => updatePage(page + 1)}>
                            <img className="imgClass" src={ArrowRight} alt="" />
                        </div>
                    )}
                </div>
                {status === 400 ? <ErrorToast message={message} /> : null}
                {dwnld && !reportError && (
                    <DwnldModal
                        getRawPptData={getRawPptData}
                        reportId={report_id}
                        open={dwnld}
                        setOpen={setdwnld}
                        getSinglePastReport={getSinglePastReport}
                        singlePastReport={singlePastReport}
                        defaultSettings={defaultSettings}
                    />
                )}
                <div className="contentWrapper clearfix">
                    <div className="mainContent">{getHeapMapValues(items)}</div>
                </div>
            </section>
            {items.length ? (
                <Fragment>
                    <ul className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors.map(({ title, background, border: borderColor }) => (
                            <li key={title} style={{ color: background }}>
                                <span style={{ background, borderColor }} />
                                {title}
                            </li>
                        ))}
                    </ul>
                    <ul className="bottomList clearfix">
                        <li>{DIFF_HEAD} : </li>
                        {sigVInUse.map(({ title, bg: background, text_color, checkSig = false, sup }, i) => {
                            if (checkSig && !isOhi4V) return null;
                            const titleToUse = isQuartMovement && surveyV ? QUARTILE_MOVE_LEGEND_TXT[i] : title;
                            const supVal = sup && isOhi4V && !isQuartMovement ? sup : false;
                            return (
                                <li key={title}>
                                    <span
                                        style={{
                                            background,
                                            border:
                                                checkSig && isOhi4 && !isQuartMovement
                                                    ? `1px solid ${text_color}`
                                                    : 'none',
                                        }}
                                    />
                                    {titleToUse} {supVal && <sup>{sup}</sup>}
                                </li>
                            );
                        })}
                    </ul>

                    {isOhi4V && (
                        <div className="note">
                            {!isQuartMovement ? (
                                <Fragment>
                                    <b>{NOTE}:</b>
                                    <br />
                                    {OHI4_RESURVEY_NOTE[0]}
                                    <br />
                                    {OHI4_RESURVEY_NOTE[1]}
                                    <br />
                                    {OHI4_RESURVEY_NOTE[2]}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <b>{NOTE}:</b>
                                    {OHI4_RESURVEY_NOTE[3]}
                                </Fragment>
                            )}
                        </div>
                    )}
                </Fragment>
            ) : null}
        </div>
    );
}

ResurveyBoard.propTypes = {
    toolOpen: PropTypes.bool.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    heatMapResurveyTab: PropTypes.string.isRequired,
    resurveyOutcomeHeatMapFetched: PropTypes.bool.isRequired,
    resurveyPracticeHeatMapFetched: PropTypes.bool.isRequired,
    heatMapData: PropTypes.object.isRequired,
    setReport: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
    heatMapResurveyInfo: PropTypes.object.isRequired,
    updateFilterView: PropTypes.func.isRequired,
    updateHeatMapInfo: PropTypes.func.isRequired,
    setResurveyTab: PropTypes.func.isRequired,
    getHeatMapResurvey: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    isCfgClient: PropTypes.bool.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
};

export default ResurveyBoard;
