import React, { useEffect, useState } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';

import { getStaticText } from '../../../utils/constants';
import colors from '../../../sass/colors';
import './index.scss';
import { getEEBoxStyle } from '../../../utils/functions';

const MetaChartModal = ({ metaData, closeModal, totalSize, isPracticeMeta, defaultSettings, isEE = false }) => {
    const { display_name: mainTitle, score: mainScore, quartile, meta_scores } = metaData;
    const { quartileColors = [], lang, survey_version = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        SINGLE_QUESTIONS_LEGEND_COLORS,
        SINGLE_QUESTIONS_LEGEND,
        QUESTIONS_LEGEND,
        QUESTIONS_PRACTICE_LEGEND,
        NEGATIVE_PRACTICES,
        NUMBER_OF_RESPONDENTS_N,
        DONT_KNOW_NA,
        QUESTION_HEAD,
        OF_TOTAL_NUMBER_OF_RESPONDENTS,
        AGREEMENT_LEVEL_IN_PERCENTAGE,
    } = SITE_TEXT || {};
    const background = quartileColors[quartile] ? quartileColors[quartile].background : colors.$white;
    const borderColor = quartileColors[quartile] ? quartileColors[quartile].border : 'rgb(208, 208, 208)';
    const clr = quartileColors[quartile] ? quartileColors[quartile].color : '#000000';
    const [cycleIndex, setCycleIndex] = useState(0);
    const [dataToShow, setDataToShow] = useState({
        title: '',
        data: [],
    });
    const isFirstElement = cycleIndex === 0;
    const isLastElement = cycleIndex === meta_scores.length - 1;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const [gradient, setGradient] = useState('');
    const [metaScoreSize, setMetaScoreSize] = useState('');
    const [currentMetaScoreSize, setCurrentMetaScoreSize] = useState('');

    useEffect(() => {
        setMetaScoreSize(meta_scores.length);
        setCurrentMetaScoreSize(cycleIndex + 1);

        if (meta_scores.length > 0 && cycleIndex >= 0 && cycleIndex < meta_scores.length) {
            const currentMetaScore = meta_scores[cycleIndex];

            const updatedData = [
                {
                    name: isPracticeMeta ? QUESTIONS_PRACTICE_LEGEND[0] : QUESTIONS_LEGEND[0],
                    y: currentMetaScore.bottom2_score,
                    color: !isOhi4 ? colors.$pink : colors.$pink100,
                },
                {
                    name: isPracticeMeta ? QUESTIONS_PRACTICE_LEGEND[1] : QUESTIONS_LEGEND[1],
                    y: currentMetaScore.nuetral_score,
                    color: !isOhi4 ? colors.$blue200 : colors.$yellow300,
                },
                {
                    name: isPracticeMeta ? QUESTIONS_PRACTICE_LEGEND[2] : QUESTIONS_LEGEND[2],
                    y: currentMetaScore.score,
                    color: !isOhi4 ? colors.$blue300 : colors.$green1000,
                },
            ];

            setDataToShow({
                title: currentMetaScore.qtext,
                naScore: currentMetaScore.na_score,
                meta: currentMetaScore.meta,
                data: updatedData,
            });

            if (currentMetaScore.meta === 'IENPS1') {
                const updatedDataWithGradient = updatedData.map((item, index, array) => {
                    const startAngle = array.slice(0, index).reduce((sum, curr) => sum + curr.y, 0);
                    const endAngle = startAngle + item.y;

                    return {
                        ...item,
                        color: SINGLE_QUESTIONS_LEGEND_COLORS[index],
                        name: SINGLE_QUESTIONS_LEGEND[index],
                        gradient: `${SINGLE_QUESTIONS_LEGEND_COLORS[index]} ${startAngle.toFixed(
                            2
                        )}% ${endAngle.toFixed(2)}%`,
                    };
                });

                setDataToShow(prevData => ({ ...prevData, data: updatedDataWithGradient }));
            }
        }
    }, [cycleIndex, meta_scores, isPracticeMeta, isOhi4]); // eslint-disable-line

    useEffect(() => {
        // Calculate the conic gradient dynamically for other cases
        const dynamicGradient = dataToShow.data
            .map((item, index, array) => {
                const startAngle = array.slice(0, index).reduce((sum, curr) => sum + curr.y, 0);
                const endAngle = startAngle + item.y;
                return `${item.color} ${startAngle.toFixed(2)}% ${endAngle.toFixed(2)}%`;
            })
            .join(', ');

        setGradient(dynamicGradient);
    }, [dataToShow]);

    const cycleIndexForward = () => setCycleIndex((cycleIndex + 1) % meta_scores.length);
    const cycleIndexBackward = () => setCycleIndex((cycleIndex ? cycleIndex - 1 : cycleIndex + 1) % meta_scores.length);

    const { positiveBg = '#000000', negativeBg = '#ffffff', color = '' } = getEEBoxStyle(mainScore) || {};
    const negativeTrait = NEGATIVE_PRACTICES.includes(mainTitle);

    const eeStyle = {
        background: negativeTrait ? negativeBg : positiveBg,
        color,
        borderWidth: '2px',
        borderColor: background,
    };

    return (
        <Dialog
            open
            onClose={() => closeModal(false)}
            maxWidth="sm"
            classes={{
                root: 'metaDialogRoot',
            }}
        >
            <section className="headContent">
                <IconButton
                    data-testid="closeButton"
                    aria-label="close"
                    className="closeButton"
                    onClick={() => closeModal(false)}
                >
                    <CloseIcon />
                </IconButton>
                {isOhi4 && !isEE ? (
                    <div style={{ position: 'relative', background, borderColor }}>
                        <div
                            style={{
                                background: isOhi4
                                    ? `linear-gradient(135deg, ${borderColor} 25%, ${background} 25%)`
                                    : background,
                                color,
                                borderColor: isOhi4 ? 'transparent' : borderColor,
                                borderWidth: quartile === '' ? 1 : 2,
                                borderStyle: isOhi4 ? 'none' : 'solid',
                            }}
                        />
                        <div style={{ color: clr }}>{mainScore}</div>
                    </div>
                ) : null}
                {!isOhi4 ? (
                    <div style={{ background, borderWidth: '2px', border: borderColor, borderStyle: 'solid' }}>
                        <div>{mainScore}</div>
                    </div>
                ) : null}
                {isEE ? (
                    <div style={eeStyle}>
                        <div>{mainScore}</div>
                    </div>
                ) : null}

                <h1>{mainTitle}</h1>
                <div className="nRes">
                    {NUMBER_OF_RESPONDENTS_N}: <span>{totalSize}</span>
                </div>
            </section>
            <div className="totalQuestionsHead">
                <div className="currentQuestionsHead">{currentMetaScoreSize}</div>/{metaScoreSize} {QUESTION_HEAD}
            </div>
            <div className="scoreBox">
                <div className="leftContent">
                    <div className="left-title">{dataToShow.title}</div>
                    <div className="dontKnow">{DONT_KNOW_NA}:</div>
                    <div className="naRespondents">
                        <span className="naRespondentsPercent">{dataToShow.naScore}% </span>
                        {OF_TOTAL_NUMBER_OF_RESPONDENTS}
                    </div>
                    <div className="horizontalLine" />
                    <div className="AgreementLevelHead">{AGREEMENT_LEVEL_IN_PERCENTAGE}:</div>
                    <div className="legend">
                        {dataToShow.data.map((item, index) => (
                            <div className="legend-item" key={index}>
                                <div className="legendPoints" style={{ backgroundColor: item.color }} />
                                <span>
                                    {dataToShow.meta === 'IENPS1'
                                        ? item.name
                                        : (isEE && QUESTIONS_LEGEND[index]) || item.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="rightContent">
                    <div className="pieChart">
                        <div className="tube-container">
                            <div className="outer-circle" style={{ background: `conic-gradient(${gradient})` }} />
                            <div className="inner-circle" />
                        </div>
                    </div>
                    <div className="legend">
                        {dataToShow.data.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="legendPercent" style={{ backgroundColor: item.color }} />
                                <span>{item.y}%</span>
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <div className="nextPrevious">
                {meta_scores && meta_scores.length > 1 && (
                    <>
                        <IconButton
                            data-testid="cycleIndexBackward"
                            onClick={cycleIndexBackward}
                            style={{
                                cursor: isFirstElement ? 'not-allowed' : 'pointer',
                                color: isFirstElement ? '#dcdcdc' : 'inherit',
                            }}
                            disabled={isFirstElement}
                        >
                            <NavigateBeforeIcon />
                        </IconButton>
                        <IconButton
                            data-testid="cycleIndexForward"
                            onClick={cycleIndexForward}
                            style={{
                                cursor: isLastElement ? 'not-allowed' : 'pointer',
                                color: isLastElement ? '#dcdcdc' : 'inherit',
                            }}
                            disabled={isLastElement}
                        >
                            <NavigateNextIcon />
                        </IconButton>
                    </>
                )}
            </div>
        </Dialog>
    );
};

MetaChartModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    metaData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    totalSize: PropTypes.number.isRequired,
    isPracticeMeta: PropTypes.bool.isRequired,
    isEE: PropTypes.bool.isRequired,
};

export default MetaChartModal;
