import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { List, ListItem, Collapse, Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import { ExpandLess, ExpandMore, ArrowBack } from '@material-ui/icons';
import { cloneDeep } from 'lodash';

import { getStaticText } from '../../utils/constants';
// import { SITE_TEXT, FILTER_LIST_RANGE } from '../../utils/constants';
import { addZeroToNUmber } from '../../utils/functions';
import CloseIcon from '../../assets/Close.svg';
import SearchIcon from '../../assets/Magnifying.svg';
import './index.scss';

function Demographics({
    demographics,
    setDemoActive,
    setBackOldView,
    updateDemographics,
    limitParent,
    limitChild,
    showMessage,
    noChild,
    showOption,
    addedParentLimit,
    hiddenOptions,
    excludeDemographic,
    defaultSettings,
    excludeGapText,
    hideHeading,
    setIsDemoChecked,
    isOhiReport,
    showBulk,
}) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, FILTER_LIST_RANGE } = staticText;
    const {
        VIEW_ALL,
        ALL_SELECTED,
        DEMOGRAPHIC,
        OPTION_TWO,
        OPTION_ONE,
        SELECT_MIN_2,
        DEMOGRAPHICS_SMALL,
        EXCLUDE_DEMO_GROUP_HEADING,
    } = SITE_TEXT || {};
    const head = excludeGapText ? EXCLUDE_DEMO_GROUP_HEADING : DEMOGRAPHIC;
    const selectAllChild = ({ checked }, indexParent) => {
        const newDemographics = cloneDeep(demographics);
        newDemographics[indexParent].options.forEach(option => {
            if (!checked && showOption && option.optionNo) {
                delete option.optionNo;
            }
            option.isSelected = checked;
        });
        setDemoActive(true);
        updateDemographics(newDemographics);
        if (isOhiReport && showBulk) {
            setIsDemoChecked(true);
            if (checked) {
                setIsDemoChecked(true);
            } else {
                setIsDemoChecked(false);
            }
        }
    };

    const handleChildCheck = ({ checked }, indexParent, childCode) => {
        const newDemographics = cloneDeep(demographics);
        const selectedItemIndex = newDemographics[indexParent].options.findIndex(({ code }) => code === childCode);
        if (showOption) {
            const findOption = newDemographics[indexParent].options.filter(({ optionNo }) => optionNo).length;
            newDemographics[indexParent].options.map(val => {
                if (checked && val.code === childCode) {
                    val.optionNo = findOption === 0 ? OPTION_ONE : OPTION_TWO;
                } else if (!checked && (findOption === 1 || findOption === 2) && val.code === childCode) {
                    delete val.optionNo;
                } else if (val.isSelected) {
                    val.optionNo = OPTION_ONE;
                }
                return val;
            });
        }
        newDemographics[indexParent].options[selectedItemIndex].isSelected = checked;
        setDemoActive(true);
        updateDemographics(newDemographics);
        if (isOhiReport && showBulk) {
            if (checked) {
                setIsDemoChecked(true);
            } else {
                setIsDemoChecked(false);
            }
        }
    };

    const handleParentClick = index => {
        const newDemographics = cloneDeep(demographics);
        const openItem = newDemographics.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openItem !== -1 && openItem !== index) {
            newDemographics[openItem].isOpen = false;
        }
        const { isOpen = false } = newDemographics[index];
        newDemographics[index].isOpen = !isOpen;
        newDemographics[index].viewAll = false;
        newDemographics[index].search = '';
        updateDemographics(newDemographics);
    };

    const selectOptions = ({ value }, index) => {
        const newDemographics = cloneDeep(demographics);
        newDemographics[index].search = value;
        updateDemographics(newDemographics);
    };

    const resetSearch = index => {
        const newDemographics = cloneDeep(demographics);
        newDemographics[index].search = '';
        updateDemographics(newDemographics);
    };

    const toggleView = index => {
        const newDemographics = cloneDeep(demographics);
        newDemographics[index].viewAll = true;
        updateDemographics(newDemographics);
    };

    const checkLength = () => {
        return demographics.filter(({ options = [] }) => options.filter(({ isSelected }) => isSelected).length).length;
    };
    const checkLimit = [1, 6, 8].includes(limitParent) || hideHeading ? '' : head;

    return (
        <div
            className={c('filterContent clearfix', {
                increaseMargin: [1, 6, 8].includes(limitParent) || hideHeading,
            })}
        >
            <h3 onClick={() => setBackOldView()}>
                <ArrowBack />
                {showMessage ? `Can select minimum 1 upto ${limitParent} \n demographics` : checkLimit}
            </h3>
            <div className="upperContent clearfix">
                {demographics.map(
                    (
                        { code = '', label = '', options = [], isOpen = false, viewAll = false, search = '' },
                        indexParent
                    ) => {
                        const selectedLength = options.filter(({ isSelected }) => isSelected).length;
                        const iconExpand = options.length ? (
                            <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                        ) : null;
                        const selectedOptions = search.trim()
                            ? options.filter(({ label: l = '' }) => `${l}`.toLowerCase().includes(search.toLowerCase()))
                            : options;
                        const optionsToUse = viewAll ? selectedOptions : selectedOptions.slice(0, FILTER_LIST_RANGE);
                        const textToShow =
                            selectedLength === options.length
                                ? ALL_SELECTED
                                : `${addZeroToNUmber(selectedLength)}/${addZeroToNUmber(options.length)}`;
                        const checkLengthParent = limitParent > 0 && checkLength() >= limitParent && !selectedLength;
                        const checkParentIsDisabled =
                            addedParentLimit && !selectedLength && checkLength() >= addedParentLimit;
                        const isDisabled = excludeDemographic ? excludeDemographic[code] : false;

                        const bulkDisableMethod = options.filter(({ isDisable }) => isDisable);
                        return (
                            <div className="mainListWrap" key={code}>
                                <ListItem
                                    classes={{
                                        root: c('mainListContent', {
                                            borderBottom: !isOpen,
                                            disableClass:
                                                checkLengthParent || isDisabled || hiddenOptions.indexOf(code) > -1,
                                        }),
                                    }}
                                    onClick={() =>
                                        noChild || checkLengthParent
                                            ? selectAllChild({ checked: !selectedLength }, indexParent)
                                            : handleParentClick(indexParent)
                                    }
                                    data-testid={label}
                                >
                                    <Checkbox
                                        checked={!!selectedLength}
                                        onChange={({ target }) =>
                                            !limitParent || ([1, 7].includes(limitParent) && selectedLength)
                                                ? selectAllChild(target, indexParent)
                                                : ''
                                        }
                                        name={code}
                                        disabled={
                                            (limitParent === 0 ? false : checkLengthParent) || !!checkParentIsDisabled
                                        }
                                        inputProps={{
                                            'data-testid': `ParentCheck${label}`,
                                        }}
                                    />
                                    {label}
                                    {selectedLength && !noChild ? (
                                        <span className="textToShow">{textToShow}</span>
                                    ) : null}
                                    {!noChild ? iconExpand : null}
                                </ListItem>
                                {!noChild ? (
                                    <Collapse
                                        in={checkLengthParent ? false : isOpen}
                                        timeout="auto"
                                        unmountOnExit
                                        classes={{ container: 'childWrapper' }}
                                    >
                                        {limitChild > 0 && !showOption ? (
                                            <div className="subHeading">
                                                {SELECT_MIN_2}
                                                {options.length > limitChild ? limitChild : options.length} sub
                                                {DEMOGRAPHICS_SMALL}
                                            </div>
                                        ) : null}
                                        <div className="searchHead">
                                            <img src={SearchIcon} alt="" className="searchIcon" />
                                            <TextField
                                                fullWidth
                                                value={search}
                                                placeholder="Search"
                                                onChange={({ target }) => selectOptions(target, indexParent)}
                                                inputProps={{
                                                    'data-testid': `Text${label}`,
                                                }}
                                            />
                                            <img
                                                src={CloseIcon}
                                                alt=""
                                                className="closeIcon"
                                                onClick={() => resetSearch(indexParent)}
                                                data-testid={`Close${label}`}
                                            />
                                        </div>

                                        <div className="optionList">
                                            {optionsToUse.map(
                                                ({
                                                    label: childLabel = '',
                                                    code: childCode = '',
                                                    isSelected = false,
                                                    optionNo = '',
                                                }) => {
                                                    const newDisables = bulkDisableMethod.map(item => item.code);
                                                    const isDisableBulkDemo = newDisables.includes(childCode);
                                                    const reportAccess = isOhiReport && showBulk;
                                                    return (
                                                        <List component="div" disablePadding key={childCode}>
                                                            <ListItem>
                                                                <FormControlLabel
                                                                    classes={{
                                                                        root: c('optionItem', {
                                                                            width80: showOption && optionNo !== '',
                                                                        }),
                                                                    }}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={isSelected}
                                                                            onChange={({ target }) =>
                                                                                handleChildCheck(
                                                                                    target,
                                                                                    indexParent,
                                                                                    childCode
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                (!isSelected &&
                                                                                    limitChild > 0 &&
                                                                                    selectedLength >= limitChild) ||
                                                                                (reportAccess && isDisableBulkDemo)
                                                                            }
                                                                            name={childCode}
                                                                            inputProps={{
                                                                                'data-testid': `ChildCheck${childLabel}`,
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={childLabel}
                                                                />
                                                                {isSelected && showOption ? (
                                                                    <span className="showOption">{optionNo}</span>
                                                                ) : null}
                                                            </ListItem>
                                                        </List>
                                                    );
                                                }
                                            )}
                                        </div>
                                        {!viewAll && selectedOptions.length > FILTER_LIST_RANGE ? (
                                            <div onClick={() => toggleView(indexParent)} className="viewAll">
                                                {VIEW_ALL}
                                            </div>
                                        ) : null}
                                    </Collapse>
                                ) : null}
                            </div>
                        );
                    }
                )}
            </div>
        </div>
    );
}

Demographics.defaultProps = {
    setDemoActive: () => {},
    setBackOldView: () => {},
    limitChild: 0,
    limitParent: 0,
    addedParentLimit: 0,
    noChild: false,
    showMessage: false,
    showOption: false,
    excludeDemographic: {},
    hiddenOptions: [],
    excludeGapText: false,
    hideHeading: false,
};

Demographics.propTypes = {
    setDemoActive: PropTypes.func,
    setBackOldView: PropTypes.func,
    demographics: PropTypes.array.isRequired,
    updateDemographics: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    limitChild: PropTypes.number,
    limitParent: PropTypes.number,
    addedParentLimit: PropTypes.number,
    noChild: PropTypes.bool,
    showMessage: PropTypes.bool,
    showOption: PropTypes.bool,
    hiddenOptions: PropTypes.array,
    excludeDemographic: PropTypes.object,
    excludeGapText: PropTypes.bool,
    hideHeading: PropTypes.bool,
    setIsDemoChecked: PropTypes.func.isRequired,
    isOhiReport: PropTypes.bool.isRequired,
    showBulk: PropTypes.bool.isRequired,
};

export default Demographics;
