import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import {
    ListItem,
    Collapse,
    TextField,
    FormControlLabel,
    RadioGroup,
    Radio,
    Select,
    MenuItem,
    Tooltip,
} from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { ExpandLess, ExpandMore, ArrowBack } from '@material-ui/icons';
import { cloneDeep } from 'lodash';

import { getStaticText } from '../../utils/constants';
// import { SITE_TEXT, FILTER_LIST_RANGE } from '../../utils/constants';
import CloseIcon from '../../assets/Close.svg';
import SearchIcon from '../../assets/Magnifying.svg';
import './index.scss';

function Benchmarks({
    showHeader,
    resetOnSelect,
    benchmarks,
    setBenchMark,
    setBackOldView,
    updateBenchmarks,
    getSelectedChild,
    surveyRespondents,
    surveys,
    defaultSettings,
    isInclusion,
    benchmarkVersions,
    setBmVersionInUse,
    bmVersionInUse,
    year,
}) {
    const { lang, inclusion_resurvey_settings = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, FILTER_LIST_RANGE } = staticText;
    const {
        VIEW_ALL,
        BENCHMARK_HEAD,
        SEARCH,
        NUMBER_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        SELECT_BM_VERSION,
        QUARTILE_MOVE,
        BM_DISABLE_TXT,
    } = SITE_TEXT || {};
    const isQuartileMovement = inclusion_resurvey_settings === QUARTILE_MOVE && isInclusion;
    const handleChildCheck = ({ value }, indexParent) => {
        const newBenchmarks = cloneDeep(benchmarks);
        const selectedItemIndex = newBenchmarks.findIndex(({ selectedOption }) => selectedOption);
        if (selectedItemIndex !== -1 && selectedItemIndex !== indexParent) {
            newBenchmarks[selectedItemIndex].selectedOption = '';
        }
        newBenchmarks[indexParent].selectedOption = parseInt(value, 10);
        if (resetOnSelect) {
            newBenchmarks[indexParent].isOpen = false;
            newBenchmarks[indexParent].viewAll = false;
            newBenchmarks[indexParent].search = '';
        }
        setBenchMark(true);
        updateBenchmarks(newBenchmarks);
        getSelectedChild(newBenchmarks[indexParent]);
    };

    const handleParentClick = index => {
        const newBenchmarks = cloneDeep(benchmarks);
        const openItem = newBenchmarks.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openItem !== -1 && openItem !== index) {
            newBenchmarks[openItem].isOpen = false;
        }
        const { isOpen = false } = newBenchmarks[index];
        newBenchmarks[index].isOpen = !isOpen;
        newBenchmarks[index].viewAll = false;
        newBenchmarks[index].search = '';
        updateBenchmarks(newBenchmarks);
    };

    const selectOptions = ({ value }, index) => {
        const newBenchmarks = cloneDeep(benchmarks);
        newBenchmarks[index].search = value;
        updateBenchmarks(newBenchmarks);
    };

    const resetSearch = index => {
        const newBenchmarks = cloneDeep(benchmarks);
        newBenchmarks[index].search = '';
        updateBenchmarks(newBenchmarks);
    };

    const toggleView = index => {
        const newBenchmarks = cloneDeep(benchmarks);
        newBenchmarks[index].viewAll = true;
        updateBenchmarks(newBenchmarks);
    };

    return (
        <div className="filterContent clearfix">
            {showHeader && (
                <Fragment>
                    <h3 onClick={() => setBackOldView()}>
                        <ArrowBack />
                        {BENCHMARK_HEAD}
                    </h3>
                    <div className="surveyData">
                        {isInclusion ? (
                            <div className="demographicDropdown">
                                {benchmarkVersions.length > 1 ? (
                                    <Select
                                        data-testid="demographicOptions"
                                        IconComponent={KeyboardArrowDownSharpIcon}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={bmVersionInUse}
                                        displayEmpty
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        onChange={({ target: { value: code } }) => {
                                            if (code) {
                                                setBenchMark(true);
                                                setBmVersionInUse(code);
                                            }
                                        }}
                                    >
                                        <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                            <em>{SELECT_BM_VERSION}</em>
                                        </MenuItem>
                                        {benchmarkVersions.map(
                                            ({
                                                BenchmarkPreCalculationSubVersionId,
                                                BenchmarkPreCalculationSubVersionName,
                                                SurveyCount,
                                                RespondentCount,
                                            }) => {
                                                return (
                                                    <MenuItem
                                                        key={BenchmarkPreCalculationSubVersionId}
                                                        classes={{ root: 'demographicOptions' }}
                                                        className="benchmarkList"
                                                        value={BenchmarkPreCalculationSubVersionId}
                                                    >
                                                        <Fragment>
                                                            <span>
                                                                {NUMBER_TEXT} = {RespondentCount}
                                                            </span>
                                                            <span>
                                                                {NUMBER_CAP_SURVEY_TEXT} :{' '}
                                                                {SurveyCount.toLocaleString('en-US')}
                                                            </span>
                                                            <span>{BenchmarkPreCalculationSubVersionName}</span>
                                                        </Fragment>
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                ) : (
                                    <div>
                                        {benchmarkVersions.map(
                                            ({
                                                BenchmarkPreCalculationSubVersionName,
                                                SurveyCount,
                                                RespondentCount,
                                            }) => {
                                                return (
                                                    <div>
                                                        <span>
                                                            {NUMBER_TEXT} = {RespondentCount}
                                                        </span>
                                                        <span>
                                                            {NUMBER_CAP_SURVEY_TEXT} :{' '}
                                                            {SurveyCount.toLocaleString('en-US')}
                                                        </span>
                                                        <span>{BenchmarkPreCalculationSubVersionName}</span>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <Fragment>
                                <div className="filterBenchRes">
                                    <div className="filterRes">
                                        <div>Year :</div>
                                        <div>{year}</div>
                                    </div>
                                    <div className="filterRes">
                                        <div>{NUMBER_TEXT} :</div>
                                        <div>{surveyRespondents.toLocaleString('en-US')}</div>
                                    </div>
                                    <div className="filterRes">
                                        <div>{NUMBER_CAP_SURVEY_TEXT} :</div>
                                        <div>{surveys.toLocaleString('en-US')}</div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </Fragment>
            )}
            <div className="upperContent clearfix">
                {benchmarks.map(
                    (
                        {
                            code = '',
                            label = '',
                            options = [],
                            isOpen = false,
                            viewAll = false,
                            search = '',
                            selectedOption = '',
                        },
                        indexParent
                    ) => {
                        const optionsLength = options.length;
                        const iconExpand =
                            options.length && !isInclusion ? (
                                <span className="expandIcon">{isOpen ? <ExpandLess /> : <ExpandMore />}</span>
                            ) : null;
                        const selectedOptions = search.trim()
                            ? options.filter(({ label: l = '' }) => `${l}`.toLowerCase().includes(search.toLowerCase()))
                            : options;

                        const optionsToUse = viewAll ? selectedOptions : selectedOptions.slice(0, FILTER_LIST_RANGE);
                        const { label: textToShow = '' } =
                            options.filter(({ code: childCod = '' }) => childCod === selectedOption)[0] || [];
                        const isDisabled = isQuartileMovement && code === -1;
                        return (
                            <div className="mainListWrap benchMarckList" key={code}>
                                <Tooltip title={isDisabled ? BM_DISABLE_TXT : ''}>
                                    <ListItem
                                        classes={{ root: c('mainListContent', { borderBottom: !isOpen }) }}
                                        onClick={() => handleParentClick(indexParent)}
                                        data-testid={label}
                                        disabled={isDisabled}
                                    >
                                        {optionsLength === 1 && (
                                            <Radio
                                                checked={!!selectedOption}
                                                value={options[0].code}
                                                onChange={() =>
                                                    handleChildCheck({ value: options[0].code }, indexParent)
                                                }
                                            />
                                        )}
                                        {label}
                                        {optionsLength > 1 && textToShow && (
                                            <span className="textToShow">{textToShow}</span>
                                        )}
                                        {iconExpand}
                                    </ListItem>
                                </Tooltip>
                                <Collapse
                                    in={isOpen && !isInclusion}
                                    timeout="auto"
                                    unmountOnExit
                                    classes={{ container: 'childWrapper' }}
                                >
                                    {optionsLength > 1 ? (
                                        <div className="searchHead">
                                            <img src={SearchIcon} alt="" className="searchIcon" />
                                            <TextField
                                                fullWidth
                                                value={search}
                                                placeholder={SEARCH}
                                                inputProps={{
                                                    'data-testid': `Text${label}`,
                                                }}
                                                onChange={({ target }) => selectOptions(target, indexParent)}
                                            />
                                            <img
                                                src={CloseIcon}
                                                alt=""
                                                className="closeIcon"
                                                onClick={() => resetSearch(indexParent)}
                                                data-testid={`Close${label}`}
                                            />
                                        </div>
                                    ) : null}

                                    <div className={c('optionList', { noMargin: optionsLength === 1 })}>
                                        <RadioGroup
                                            aria-label={label}
                                            name={label}
                                            value={selectedOption}
                                            onChange={({ target }) => handleChildCheck(target, indexParent)}
                                        >
                                            {optionsToUse.map(({ label: childLabel = '', code: childCode = '' }) => (
                                                <FormControlLabel
                                                    classes={{
                                                        root: c('optionItem', { hideRadio: optionsLength === 1 }),
                                                    }}
                                                    value={childCode}
                                                    control={<Radio />}
                                                    label={childLabel}
                                                    key={childCode}
                                                    data-testid={childLabel}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </div>
                                    {!viewAll && selectedOptions.length > FILTER_LIST_RANGE ? (
                                        <div onClick={() => toggleView(indexParent)} className="viewAll">
                                            {VIEW_ALL}
                                        </div>
                                    ) : null}
                                </Collapse>
                            </div>
                        );
                    }
                )}
            </div>
        </div>
    );
}

Benchmarks.defaultProps = {
    showHeader: true,
    resetOnSelect: false,
    surveyRespondents: 0,
    surveys: 0,
    setBenchMark: () => {},
    setBackOldView: () => {},
    getSelectedChild: () => {},
    isInclusion: false,
    benchmarkVersions: [],
    bmVersionInUse: 2,
    setBmVersionInUse: () => {},
};

Benchmarks.propTypes = {
    showHeader: PropTypes.bool,
    resetOnSelect: PropTypes.bool,
    setBenchMark: PropTypes.func,
    setBackOldView: PropTypes.func,
    getSelectedChild: PropTypes.func,
    surveyRespondents: PropTypes.number,
    surveys: PropTypes.number,
    benchmarks: PropTypes.array.isRequired,
    updateBenchmarks: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    isInclusion: PropTypes.bool,
    benchmarkVersions: PropTypes.array,
    bmVersionInUse: PropTypes.number,
    setBmVersionInUse: PropTypes.func,
};

export default Benchmarks;
