import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ArrowForwardIos, ArrowDropDown } from '@material-ui/icons';
import { Helmet } from 'react-helmet';

import AuthStore from '../../common/AuthStore';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import { getStaticText } from '../../utils/constants';
import './index.scss';
import ErrorToast from '../../components/InformationToast/ErrorToast';

function UserReports({
    errorInfo,
    apiLoadingCount,
    loginLoadingCount,
    reportsData,
    reportsDataFetched,
    getReports,
    updateApiParams,
    defaultSettings,
    fetchDefaultSettings,
}) {
    const staticText = getStaticText();
    const { SITE_TEXT, USER_ROLES } = staticText;
    const {
        CHOSE_REPORT,
        REPORT_TEXT,
        REPORT_VIEW,
        BU_REPORT_TEXT,
        SURVEY_ID,
        SURVEY_NAME,
        COACH_TEXT,
        YR_TEXT,
        NO_ACCESS,
        ANALYTICS_LANDING_PAGE,
    } = SITE_TEXT;
    const { CLIENT, CST, USER } = USER_ROLES;
    const USERS_IN_CHECK = [CLIENT, CST, USER];
    const [doRedirect, redirectPage] = useState('');
    const [errorMsg, updateError] = useState('');
    const [expandedList, updateExpandedList] = useState([0]);
    const [makeRedirect, setRedirectParam] = useState(false);
    const { accessToken, userRole } = AuthStore;
    const helpdeskMail = 'OHI-Helpdesk@mckinsey.com';

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        updateExpandedList(newList);
    };

    useEffect(() => {
        if (!reportsDataFetched && accessToken) {
            getReports();
        }
        AuthStore.deleteOhId();
        AuthStore.deleteReportId();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { ohid, health_access, response_rate_access } = defaultSettings;
        if (ohid && makeRedirect) {
            if (USERS_IN_CHECK.includes(userRole)) {
                if (health_access) {
                    redirectPage('/diagnose');
                } else if (response_rate_access) {
                    redirectPage('/response-rate');
                } else {
                    scrollToTop();
                    updateError(NO_ACCESS);
                }
            } else {
                redirectPage('/diagnose');
            }
        }
        // eslint-disable-next-line
    }, [defaultSettings]);

    const selectReport = (ohid, report_id, survey_name) => {
        AuthStore.ohid = ohid;
        AuthStore.report_id = report_id;
        AuthStore.buName = BU_REPORT_TEXT;
        AuthStore.practiceBU = BU_REPORT_TEXT;
        AuthStore.surveyName = survey_name;
        updateApiParams({ ohid });
        fetchDefaultSettings({ ohid });
        setTimeout(() => {
            setRedirectParam(true);
        }, 1);
    };

    return (
        <Layout showHeader>
            <Helmet>
                <title>{ANALYTICS_LANDING_PAGE}</title>
            </Helmet>
            <div className="userReports clearfix">
                {apiLoadingCount || loginLoadingCount ? (
                    <Loader position="absolute" showBackground background="transparent" />
                ) : null}
                <h2>{CHOSE_REPORT}</h2>
                {errorInfo && <ErrorToast message={errorInfo} />}
                {errorMsg && (
                    <div className="error">
                        {errorMsg} (<a href={`mailto:${helpdeskMail}`}>{helpdeskMail}</a>)
                    </div>
                )}
                {Object.keys(reportsData).map((dataSet, index) => {
                    const { report_id = '', report_name = '' } = reportsData[dataSet][0];
                    return (
                        <Accordion
                            classes={{ root: 'accordionRoot' }}
                            key={report_id}
                            expanded={expandedList.includes(index)}
                        >
                            <AccordionSummary
                                data-testid={`Accordion${report_id}_${index}`}
                                expandIcon={<ArrowDropDown />}
                                onClick={() => updateExpanded(index)}
                                classes={{ content: 'accordionHead' }}
                            >
                                {report_name || REPORT_TEXT} - {report_id}
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: 'clearfix' }}>
                                <section>
                                    <ul className="clearfix">
                                        <li className="clearfix">
                                            <span>{SURVEY_ID}</span>
                                            <span>{SURVEY_NAME}</span>
                                            <span>{COACH_TEXT}</span>
                                            <span>{YR_TEXT}</span>
                                        </li>
                                        {reportsData[dataSet].map(
                                            (
                                                {
                                                    coach_name = '',
                                                    survey_id = '',
                                                    survey_name = '',
                                                    survey_pid = '',
                                                    year = '',
                                                },
                                                indexRep
                                            ) => {
                                                return (
                                                    <li key={survey_id} className="clearfix">
                                                        <span>
                                                            {survey_id} ({survey_pid})
                                                        </span>
                                                        <span>{survey_name}</span>
                                                        <span>{coach_name}</span>
                                                        <span>{year}</span>
                                                        <span
                                                            data-testid={`reportTab${report_id}${indexRep}`}
                                                            onClick={() =>
                                                                selectReport(survey_id, report_id, survey_name)
                                                            }
                                                        >
                                                            {REPORT_VIEW}
                                                            <ArrowForwardIos />
                                                        </span>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </section>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
                {doRedirect && <Redirect to={doRedirect} />}
            </div>
        </Layout>
    );
}

UserReports.propTypes = {
    apiLoadingCount: PropTypes.number.isRequired,
    loginLoadingCount: PropTypes.number.isRequired,
    reportsData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    reportsDataFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    getReports: PropTypes.func.isRequired,
    updateApiParams: PropTypes.func.isRequired,
    fetchDefaultSettings: PropTypes.func.isRequired,
};

export default UserReports;
