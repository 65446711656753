import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import XLSX from 'xlsx-color';

import DownloadIcon from '../../assets/Download.svg';
import { getStaticText } from '../../../../utils/constants';
import ErrorToast from '../../../../components/InformationToast/ErrorToast';

function TextComments({
    apiLoadingCount,
    defaultSettings,
    commentsData,
    commentsDataFetched,
    getTextComments,
    apiParams,
    errorInfo,
}) {
    const { lang, benchmark = [] } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, TEXT_COMMENTS_KEYS } = staticText;
    const { TEXT_COMMENTS_TXT, DOWNLOAD } = SITE_TEXT || {};

    const { data = [], display_names = {} } = commentsData;
    const [isSticky, setSticky] = React.useState(false);

    const listenToScroll = () => {
        if (window.pageYOffset > 300) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    const getExport = () => {
        const table = document.getElementById('tableData');
        if (table) {
            const wb = XLSX.utils.table_to_book(table, { sheet: 'Report', raw: true });
            Object.keys(wb.Sheets.Report).forEach((val, valIndex) => {
                if (typeof wb.Sheets.Report[val] === 'object') {
                    let styles = {};
                    if (valIndex > 3) {
                        styles = {
                            font: {
                                sz: 12,
                            },
                        };
                    } else {
                        styles = {
                            font: {
                                bold: true,
                                sz: 14,
                            },
                        };
                    }
                    wb.Sheets.Report[val].s = {
                        alignment: {
                            vertical: 'left',
                            horizontal: 'left',
                        },
                        ...styles,
                    };
                }
            });
            XLSX.writeFile(wb, `${TEXT_COMMENTS_TXT} ${moment().format('MM_DD_YYYY')}.xlsx`);
        }
    };

    React.useEffect(() => {
        const { threshold, report_type, year, lang: langInUse, ohid } = apiParams;
        if (!commentsDataFetched && !apiLoadingCount) {
            getTextComments({
                threshold,
                report_type,
                year,
                lang: langInUse,
                bm_filters: benchmark,
                benchmarks: benchmark[0],
                filters: [],
                ohivn: '',
                pid: '',
                ohid,
            });
        }
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
        // eslint-disable-next-line
    }, [commentsDataFetched, apiLoadingCount]);

    return (
        <div className="textComments clearfix" data-testid="textComments">
            <h2>
                {TEXT_COMMENTS_TXT}
                <ul className="rightSideNav">
                    {data.length ? (
                        <li data-testid="getPptBtn" onClick={getExport} style={{ display: 'inline' }}>
                            <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                        </li>
                    ) : null}
                </ul>
            </h2>
            {errorInfo && <ErrorToast message={errorInfo} />}
            {data.length ? (
                <table id="tableData" className="tableData">
                    <tbody>
                        <tr>
                            {TEXT_COMMENTS_KEYS.map(mainKey => (
                                <th key={mainKey}>{display_names[mainKey]}</th>
                            ))}
                        </tr>
                        {isSticky && (
                            <tr className="fixedToTop">
                                {TEXT_COMMENTS_KEYS.map(mainKey => (
                                    <th key={mainKey}>{display_names[mainKey]}</th>
                                ))}
                            </tr>
                        )}
                        {data.map((dataValues, parentInd) => (
                            <tr key={parentInd}>
                                {TEXT_COMMENTS_KEYS.map(mainKey => (
                                    <td key={mainKey}>{dataValues[mainKey]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : null}
        </div>
    );
}

TextComments.propTypes = {
    apiLoadingCount: PropTypes.number.isRequired,
    apiParams: PropTypes.object.isRequired,
    errorInfo: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    commentsData: PropTypes.object.isRequired,
    commentsDataFetched: PropTypes.bool.isRequired,
    getTextComments: PropTypes.func.isRequired,
};

export default TextComments;
