import { getStaticTextAnalytics } from './constants';

const staticTextAnalytics = getStaticTextAnalytics();
const {
    GET_DATA_AT_GLANCE,
    GET_FILTERS_DATA,
    GET_ANALYTICS_SCORECARD,
    GET_ANALYTICS_CATEGORY,
    GET_RANKING,
    GET_DIFFERENTIAL,
    UPDATE_ANALYTICS_FILTERS,
    UPDATE_SCORECARD_DROPDOWN,
    UPDATE_ANALYTICS_CATEGORY,
    GET_ANALYTICS_FLAGPOLE,
    UPDATE_DIFFERENTIAL_DATA,
    GET_FILTERS_MAPPING,
    GET_DEFAULT_YEAR,
} = staticTextAnalytics;

export const getFilterData = payload => ({
    type: `${GET_FILTERS_DATA}_PENDING`,
    payload,
});

export const getFilterDataSuccess = payload => ({
    type: `${GET_FILTERS_DATA}_SUCCESS`,
    payload,
});

export const getFilterDataFailed = (payload = {}) => ({
    type: `${GET_FILTERS_DATA}_FAILED`,
    payload,
});

export const getFiltersMapping = payload => ({
    type: `${GET_FILTERS_MAPPING}_PENDING`,
    payload,
});

export const getFiltersMappingSuccess = payload => ({
    type: `${GET_FILTERS_MAPPING}_SUCCESS`,
    payload,
});

export const getFiltersMappingFailed = (payload = {}) => ({
    type: `${GET_FILTERS_MAPPING}_FAILED`,
    payload,
});

export const getDataAtGlanceSuccess = payload => ({
    type: `${GET_DATA_AT_GLANCE}_SUCCESS`,
    payload,
});

export const getDataAtGlanceFailed = (payload = {}) => ({
    type: `${GET_DATA_AT_GLANCE}_FAILED`,
    payload,
});

export const getDataAtGlance = payload => ({
    type: `${GET_DATA_AT_GLANCE}_PENDING`,
    payload,
});

export const getDifferentialSuccess = payload => ({
    type: `${GET_DIFFERENTIAL}_SUCCESS`,
    payload,
});

export const getDifferentialFailed = (payload = {}) => ({
    type: `${GET_DIFFERENTIAL}_FAILED`,
    payload,
});

export const getDifferential = payload => ({
    type: `${GET_DIFFERENTIAL}_PENDING`,
    payload,
});

export const getRankingSuccess = payload => ({
    type: `${GET_RANKING}_SUCCESS`,
    payload,
});

export const getRankingFailed = (payload = {}) => ({
    type: `${GET_RANKING}_FAILED`,
    payload,
});

export const getRanking = payload => ({
    type: `${GET_RANKING}_PENDING`,
    payload,
});

export const getAnalyticsPerCategorySuccess = payload => ({
    type: `${GET_ANALYTICS_CATEGORY}_SUCCESS`,
    payload,
});

export const getAnalyticsPerCategoryFailed = (payload = {}) => ({
    type: `${GET_ANALYTICS_CATEGORY}_FAILED`,
    payload,
});

export const getAnalyticsPerCategory = payload => ({
    type: `${GET_ANALYTICS_CATEGORY}_PENDING`,
    payload,
});

export const getFlagpoleSuccess = payload => ({
    type: `${GET_ANALYTICS_FLAGPOLE}_SUCCESS`,
    payload,
});

export const getFlagpoleFailed = (payload = {}) => ({
    type: `${GET_ANALYTICS_FLAGPOLE}_FAILED`,
    payload,
});

export const getFlagpole = payload => ({
    type: `${GET_ANALYTICS_FLAGPOLE}_PENDING`,
    payload,
});

export const getScorecardSuccess = payload => ({
    type: `${GET_ANALYTICS_SCORECARD}_SUCCESS`,
    payload,
});

export const getScorecardFailed = (payload = {}) => ({
    type: `${GET_ANALYTICS_SCORECARD}_FAILED`,
    payload,
});

export const getScorecard = payload => ({
    type: `${GET_ANALYTICS_SCORECARD}_PENDING`,
    payload,
});

export const updateFilters = payload => ({
    type: UPDATE_ANALYTICS_FILTERS,
    payload,
});

export const setDropdown = payload => ({
    type: UPDATE_SCORECARD_DROPDOWN,
    payload,
});

export const setAnalyticsPerCategory = payload => ({
    type: UPDATE_ANALYTICS_CATEGORY,
    payload,
});

export const setDifferentialData = payload => ({
    type: UPDATE_DIFFERENTIAL_DATA,
    payload,
});

export const getDefaultYear = payload => ({
    type: `${GET_DEFAULT_YEAR}_PENDING`,
    payload,
});

export const getDefaultYearSuccess = payload => ({
    type: `${GET_DEFAULT_YEAR}_SUCCESS`,
    payload,
});

export const getDefaultYearFailed = (payload = {}) => ({
    type: `${GET_DEFAULT_YEAR}_FAILED`,
    payload,
});
