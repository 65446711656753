import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilter from '../../assets/ActiveFilter.svg';
import HandshakeIcon from '../../assets/handshake.png';
import ManIcon from '../../assets/businessman.png';
import { getStaticText } from '../../../../utils/constants';
import ErrorToast from '../../../../components/InformationToast/ErrorToast';

function Engagement({
    pageData,
    errorInfo,
    defaultSettings,
    engagementDataFetched,
    callEngagement,
    addOnTab,
    setAddOnTabs,
    toggleFilterView,
    activeFilter,
    scoreData,
}) {
    const { lang, year } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, CURRENT_YEAR } = staticText;
    const { QUESTIONS_LEGEND, JOB_ENGAGEMENT, ORG_ENGAGEMENT, ENGAGEMENT_NAV_LINKS, FILTERS, TOTAL_RES_TEXT } =
        SITE_TEXT || {};
    const isCurrentYear = year === CURRENT_YEAR;
    const { overall = {} } = pageData || {};
    const { question_scores = [], job_engagement_score: job = '', org_engagement_score: org = '' } = overall || {};
    const jobData = question_scores.filter(({ q_code: code = '' }) =>
        isCurrentYear ? code.includes('q2_1') : code.includes('additional_8')
    );
    const orgData = question_scores.filter(({ q_code: code = '' }) =>
        isCurrentYear ? code.includes('q2_2') : code.includes('additional_9')
    );
    const { qbyq = {} } = scoreData;
    const { size = '' } = qbyq || {};
    const checkLength = Object.keys(overall).length;

    useEffect(() => {
        if (!engagementDataFetched) {
            callEngagement();
        }
        // eslint-disable-next-line
    }, [engagementDataFetched]);

    return (
        <Fragment>
            <div className="optionWrapper">
                <div>
                    <ul className="leftNav">
                        {ENGAGEMENT_NAV_LINKS.map((tabName, index) => {
                            return (
                                <li
                                    data-testid={tabName}
                                    key={tabName}
                                    className={c({ activeLink: addOnTab === index })}
                                    onClick={() => setAddOnTabs(index)}
                                >
                                    {tabName}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="rightOptions">
                    <ul className="rightNav">
                        <li className="strong">
                            {TOTAL_RES_TEXT} {size.toLocaleString('en-US')}
                        </li>
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" />
                            {FILTERS}
                        </li>
                    </ul>
                </div>
            </div>
            {checkLength ? (
                <div className="questionBoard clearfix">
                    {errorInfo && <ErrorToast message={errorInfo} />}
                    <div className="overallSection">
                        <div>
                            <h2>{JOB_ENGAGEMENT}</h2>
                            <div>
                                <h2>
                                    <span>
                                        <img src={ManIcon} alt="" />
                                    </span>
                                    {job}
                                </h2>
                            </div>
                        </div>
                        <div>
                            <h2>{ORG_ENGAGEMENT}</h2>
                            <div>
                                <h2>
                                    <span>
                                        <img src={HandshakeIcon} alt="" />
                                    </span>
                                    {org}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <Fragment>
                        <Accordion classes={{ root: 'accordionRoot' }} expanded>
                            <AccordionSummary expandIcon={null} classes={{ content: 'accordionHead' }}>
                                <div className="outcomeContent">
                                    {JOB_ENGAGEMENT}
                                    <ul className="questionLegends">
                                        {QUESTIONS_LEGEND.map(legendText => (
                                            <li key={legendText}>
                                                <span />
                                                {legendText}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: 'clearfix' }}>
                                <section className="leftSection">
                                    <div className="clearfix">
                                        {jobData.map(
                                            ({
                                                q_code: code = '',
                                                title: qtext = '',
                                                disagree = 0,
                                                neutral = 0,
                                                agree = 0,
                                            }) => {
                                                const totalScore = disagree + neutral + agree;
                                                return (
                                                    <section key={qtext} className="metaSection clearfix">
                                                        <div className="metaText">{qtext || code}</div>
                                                        {totalScore ? (
                                                            <div className="metaScore">
                                                                <div>
                                                                    <span style={{ width: `${disagree}%` }}>
                                                                        {disagree}
                                                                    </span>
                                                                    <span style={{ width: `${neutral}%` }}>
                                                                        {neutral}
                                                                    </span>
                                                                    <span style={{ width: `${agree}%` }}>{agree}</span>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </section>
                                                );
                                            }
                                        )}
                                    </div>
                                </section>
                            </AccordionDetails>
                            <AccordionSummary expandIcon={null} classes={{ content: 'accordionHead' }}>
                                <div className="outcomeContent">
                                    {ORG_ENGAGEMENT}
                                    <ul className="questionLegends">
                                        {QUESTIONS_LEGEND.map(legendText => (
                                            <li key={legendText}>
                                                <span />
                                                {legendText}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails classes={{ root: 'clearfix' }}>
                                <section className="leftSection">
                                    <div className="clearfix">
                                        {orgData.map(
                                            ({
                                                q_code: code = '',
                                                title: qtext = '',
                                                disagree = 0,
                                                neutral = 0,
                                                agree = 0,
                                            }) => {
                                                const totalScore = disagree + neutral + agree;
                                                return (
                                                    <section key={qtext} className="metaSection clearfix">
                                                        <div className="metaText">{qtext || code}</div>
                                                        {totalScore ? (
                                                            <div className="metaScore">
                                                                <div>
                                                                    <span style={{ width: `${disagree}%` }}>
                                                                        {disagree}
                                                                    </span>
                                                                    <span style={{ width: `${neutral}%` }}>
                                                                        {neutral}
                                                                    </span>
                                                                    <span style={{ width: `${agree}%` }}>{agree}</span>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </section>
                                                );
                                            }
                                        )}
                                    </div>
                                </section>
                            </AccordionDetails>
                        </Accordion>
                    </Fragment>
                </div>
            ) : null}
        </Fragment>
    );
}

Engagement.propTypes = {
    pageData: PropTypes.object.isRequired,
    errorInfo: PropTypes.string.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    engagementDataFetched: PropTypes.bool.isRequired,
    callEngagement: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    scoreData: PropTypes.object.isRequired,
};

export default Engagement;
