import React, { Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx-color';
import { cloneDeep } from 'lodash';
import getStaticTextDiagnose from '../../constants';
import { getStaticText } from '../../../../utils/constants';

const Excel = ({ engagementDropdown, demographics, engagementData, setDwnld, ohid }) => {
    const { job_engagement_score, org_engagement_score, overall, ...questionKeys } = engagementData;
    const staticText = getStaticText();
    const {
        HYBRID_QUES_KEY,
        ING_CURR_OHID,
        EXCLUDE_QUES_KEY,
        ING_PREV_SURVEY_EXC_QUES,
        OVERALL,
        SIZE_MAIN_TEXT,
        EXCEL_TEXT,
    } = staticText;
    const staticTextDiagnose = getStaticTextDiagnose();
    const { ENGAGEMENT_TABLE_HEADERS } = staticTextDiagnose;
    const { question_scores = {} } = overall || {};
    const { [HYBRID_QUES_KEY]: hybridQues = {}, ...rest } = questionKeys;
    const isCurrentSurvey = ohid === ING_CURR_OHID;
    const hybridQuesExist = Object.keys(hybridQues).length;
    const { title: hLabel = '', demographics: hDemos = {} } = hybridQues || {};
    const checkLength = Object.keys(rest) && Object.keys(rest).length;
    const selectedGroup = demographics.filter(({ code = '' }) => code === engagementDropdown)[0];
    const { label: groupLabel = '' } = selectedGroup || {};
    const getOverallData = qScoreObj => {
        if (!Object.keys(qScoreObj).length) return '';
        const key =
            Object.keys(qScoreObj).filter(item => {
                const { q_code = '' } = qScoreObj[item];
                if (q_code === HYBRID_QUES_KEY) {
                    return item;
                }
                return null;
            })[0] || '';
        return qScoreObj[key];
    };
    const { size: overallSize = '', title: overallTitle, q_code: overallCode, ...headers } = hybridQuesExist
        ? getOverallData(question_scores)
        : {};

    const dibi = useMemo(() => {
        const { q2_6: obj = {} } = cloneDeep(rest) || {};
        const { demographics: demographicObj = {} } = obj || {};
        // eslint-disable-next-line no-unused-vars
        const { title = '', ...demo } = demographicObj || {};
        Object.keys(demo).forEach(demoName => {
            const { agree: currAgree = 0 } = demo[demoName] || {};
            let sum = currAgree;
            let { agree: overallAgreeDibi, size: overallSizeDibi } =
                question_scores.filter(({ q_code = '' }) => q_code === 'q2_6')[0] || {};
            ING_PREV_SURVEY_EXC_QUES.forEach(key => {
                const { demographics: demoX = {} } = rest[key] || {};
                const { agree = 0 } = demoX[demoName] || {};
                const { agree: ovAgree = '', size: ovSize = '' } =
                    question_scores.filter(({ q_code = '' }) => q_code === key)[0] || {};
                sum += agree;
                overallSizeDibi += ovSize;
                overallAgreeDibi += ovAgree;
            });
            demo[demoName].agree = Math.round(sum / 5);
            demographicObj.overall = {
                agree: Math.round(overallAgreeDibi / 5),
                size: Math.round(overallSizeDibi / 5),
            };
        });
        return obj;
        // eslint-disable-next-line
    }, []);

    const { demographics: dibiDemo = {} } = dibi || {};
    const { overall: dibiOverall, ...dibiAllDemo } = dibiDemo || {};

    const getExport = () => {
        const table = document.getElementById('engagementtable');
        if (table) {
            const wb = XLSX.utils.table_to_book(table, { sheet: 'Report' });
            Object.keys(wb.Sheets.Report).forEach(val => {
                if (typeof wb.Sheets.Report[val] === 'object') {
                    let split = [];
                    if (wb.Sheets.Report[val].v.toString().includes('$$$')) {
                        split = wb.Sheets.Report[val].v.split('$$$');
                        const { length, [length - (length - 1)]: last } = split;
                        wb.Sheets.Report[val].v = last;
                    } else {
                        /* split = [wb.Sheets.Report[val].v];
                            wb.Sheets.Report[val].v = split[0]; */
                    }
                    if (split.length > 0) {
                        let align = 'left';
                        if (split.length === 3) {
                            align = 'center';
                        } else {
                            align = split[0] === 'bold' ? 'left' : 'center';
                        }
                        wb.Sheets.Report[val].s = {
                            font: {
                                color: { rgb: split.length > 2 ? split[2].replace('#', '') : 'ff000000' },
                                bold: split[0] === 'bold' || split[0] === 'header',
                                sz: split[0] === 'bold' ? 14 : 12,
                            },
                            alignment: {
                                vertical: align,
                                horizontal: align,
                            },
                            border: {
                                top: { style: 'thin', color: { auto: 1 } },
                                right: { style: 'thin', color: { auto: 1 } },
                                bottom: { style: 'thin', color: { auto: 1 } },
                                left: { style: 'thin', color: { auto: 1 } },
                            },
                            fill: {
                                patternType: 'solid',
                                fgColor: { rgb: split.length > 2 ? split[0].replace('#', '') : 'ffffffff' },
                            },
                        };
                    }
                }
            });
            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            const yyyy = today.getFullYear();
            if (dd < 10) {
                dd = `0${dd}`;
            }
            if (mm < 10) {
                mm = `0${mm}`;
            }
            today = `${mm}_${dd}_${yyyy}`;
            return XLSX.writeFile(wb, `ing_engagement_${today}.xlsx`);
        }
        return null;
    };

    useEffect(() => {
        getExport();
        setDwnld(false);
        // eslint-disable-next-line
    }, []);

    return (
        <table id="engagementtable">
            {checkLength &&
                Object.keys(rest).map(engKey => {
                    const { title: label = '', demographics: demos = {} } = engagementData[engKey];
                    const {
                        disagree: ovDisagree = '',
                        agree: ovAgree = '',
                        neutral: ovNeutral = '',
                        size: ovSize = '',
                    } = question_scores.filter(({ q_code = '' }) => q_code === engKey)[0] || {};
                    const data = Object.keys(demos);
                    if (
                        (engKey.includes(EXCLUDE_QUES_KEY) && isCurrentSurvey) ||
                        (ING_PREV_SURVEY_EXC_QUES.includes(engKey) && !isCurrentSurvey)
                    )
                        return null;
                    return (
                        <Fragment>
                            <tr className="question">
                                <td>bold$$${label || EXCEL_TEXT['2']}</td>
                                <td />
                            </tr>
                            <tr />
                            <thead className="col">
                                <tr>
                                    {ENGAGEMENT_TABLE_HEADERS.map((header, i) => (
                                        <th className="cell" key={header}>
                                            bold$$${!i ? groupLabel : header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="col">
                                    <td className=" groupName cell">bold$$${OVERALL}</td>
                                    <td className=" cell">{ovSize}</td>
                                    <td className=" cell">{ovDisagree}</td>
                                    <td className=" cell">{ovAgree}</td>
                                    <td className=" cell">{ovNeutral}</td>
                                </tr>
                                {data.length &&
                                    data.map((col, col_i) => {
                                        const { disagree, agree, neutral, size: nSize = '', title = '' } = demos[col];
                                        return (
                                            <tr className="col" key={'col' + col_i}>
                                                <td className=" groupName cell">bold$$${title || col}</td>
                                                <td className=" cell">{nSize}</td>
                                                <td className=" cell">{disagree}</td>
                                                <td className=" cell">{agree}</td>
                                                <td className=" cell">{neutral}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            <tr />
                            <tr />
                        </Fragment>
                    );
                })}
            {isCurrentSurvey && Object.keys(dibiAllDemo).length && (
                <Fragment>
                    <tr className="question">
                        <td>bold$$${EXCEL_TEXT['1']}</td>
                        <td />
                    </tr>
                    <tr />
                    <thead className="col">
                        <tr>
                            {[' ', 'Size', 'Agree'].map((header, i) => (
                                <th className="cell" key={header}>
                                    bold$$${!i ? groupLabel : header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="col">
                            <td className=" groupName cell">bold$$${OVERALL}</td>
                            <td className=" cell">{dibiOverall.size.toLocaleString('en-US')}</td>
                            <td className=" cell">{dibiOverall.agree}</td>
                        </tr>
                        {Object.keys(dibiAllDemo).map((col, col_i) => {
                            const { agree, size: nSize = '', title = '' } = dibiAllDemo[col];
                            return (
                                <tr className="col" key={'col' + col_i}>
                                    <td className=" groupName cell">bold$$${title || col}</td>
                                    <td className=" cell">{nSize}</td>
                                    <td className=" cell">{agree}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tr />
                    <tr />
                </Fragment>
            )}
            {!!hybridQuesExist && (
                <Fragment>
                    <tr className="question">
                        <td>bold$$${hLabel || EXCEL_TEXT['2']}</td>
                        <td />
                    </tr>
                    <tr />
                    <thead className="col">
                        <tr>
                            {Object.keys(headers).map((header, i) => {
                                const dayCount = parseInt(header, 10) - 1;
                                const days = dayCount === 1 ? ' day' : ' days';
                                return (
                                    <Fragment>
                                        {!i && (
                                            <Fragment>
                                                <th className="cell" key={header}>
                                                    header$$${groupLabel}
                                                </th>
                                                <th className="cell">header$$${SIZE_MAIN_TEXT}</th>
                                            </Fragment>
                                        )}
                                        <th className="cell" key={header}>
                                            header$$${dayCount + days}
                                        </th>
                                    </Fragment>
                                );
                            })}
                        </tr>
                    </thead>
                    <tr className="col">
                        {Object.keys(headers).map((header, i) => {
                            return (
                                <Fragment>
                                    {!i && (
                                        <Fragment>
                                            <td className="cell" key={header}>
                                                bold$$${OVERALL}
                                            </td>
                                            <td className="cell">{overallSize}</td>
                                        </Fragment>
                                    )}
                                    <td className="cell" key={header}>
                                        {headers[header] || '-'}
                                    </td>
                                </Fragment>
                            );
                        })}
                    </tr>
                    {Object.keys(hDemos).length &&
                        Object.keys(hDemos).map((col, col_i) => {
                            const { title = '', size: nSize, ...otherKeys } = hDemos[col];
                            return (
                                <Fragment>
                                    <tr className="col" key={'col' + col_i}>
                                        <td className=" groupName cell">bold$$${title || col}</td>
                                        <td className="cell">{nSize}</td>
                                        {Object.keys(headers).map((values, i) => (
                                            <td className=" cell" key={values + i}>
                                                {otherKeys[values] || '-'}
                                            </td>
                                        ))}
                                    </tr>
                                </Fragment>
                            );
                        })}
                </Fragment>
            )}
        </table>
    );
};
Excel.propTypes = {
    engagementDropdown: PropTypes.string.isRequired,
    demographics: PropTypes.array.isRequired,
    engagementData: PropTypes.object.isRequired,
    setDwnld: PropTypes.func.isRequired,
    ohid: PropTypes.string.isRequired,
};
export default Excel;
