import React, { Fragment, useEffect, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';

import { manipulateHeatmapTitle } from '../../../utils/functions';

const PracticeRankBoard = ({ items, itemLength, maxSliceValue, minSliceValue, scroll }) => {
    const itemsToIterate = items;
    const scrollIndex = useRef(null);
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        scrollIndex.current?.scrollIntoView({ behavior: 'instant' });
    }, [scroll]);
    return (
        <Fragment>
            {itemsToIterate.map(({ title: rowTitle = '', items: subItems = [] }, itemIndex) => {
                const { score = '' } = subItems[0] || {};
                const title = manipulateHeatmapTitle(rowTitle);
                const color = score > itemLength - 12 ? 'red' : '#000000';
                const colorToUse = score < 13 ? '#0679C3' : color;
                const bg = colorToUse === '#0679C3' ? '#F2F2F2' : '#FEEBEB';
                const isLevel = itemIndex === 12 || itemIndex === itemLength - 12;
                const borderStyle = isLevel ? { borderTop: '1px solid #CCCCCC' } : {};
                const id = itemIndex === 12 ? 'mid' : '';
                const idToUse = itemIndex === itemLength - 12 ? 'bottom' : id;
                return (
                    <div className="sectionDiv" key={`${title}${itemIndex}`} style={borderStyle} id={idToUse}>
                        <ul ref={scroll === itemIndex ? scrollIndex : null}>
                            <Tooltip placement="top" arrow title={title} aria-label={title}>
                                <li className="sectionContent">
                                    <span
                                        style={{
                                            color: colorToUse,
                                            fontWeight: colorToUse === '#0679C3' ? 500 : 400,
                                            background: colorToUse === '#000000' ? '#ffffff' : bg,
                                        }}
                                        className="rankBg"
                                    >
                                        {itemIndex + 1}
                                    </span>
                                </li>
                            </Tooltip>
                            <li
                                className="contentList"
                                key={score}
                                style={{
                                    background: '#fffffff',
                                    color: colorToUse,
                                    fontWeight: colorToUse === '#0679C3' ? 500 : 400,
                                }}
                            >
                                {title}
                            </li>
                            {subItems.slice(minSliceValue, maxSliceValue).map(({ score: innerScore }, index) => {
                                const searchIndex = index + minSliceValue;
                                const i =
                                    itemsToIterate.findIndex(
                                        ({ items: innerItem }) => innerItem[searchIndex].score === itemIndex + 1
                                    ) || 0;
                                const { title: prName = '' } = itemsToIterate[i];
                                const innerColor = i > itemLength - 12 ? 'red' : '#000000';
                                const innerColorToUse = i < 12 ? '#0679C3' : innerColor;
                                return (
                                    <li
                                        className="contentList"
                                        key={`${innerScore}${index}${Math.random()}`}
                                        style={{
                                            color: innerColorToUse,
                                            fontWeight: innerColorToUse === '#0679C3' ? 500 : 400,
                                        }}
                                    >
                                        {prName}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })}
        </Fragment>
    );
};

PracticeRankBoard.propTypes = {
    items: PropTypes.func.isRequired,
    itemLength: PropTypes.number.isRequired,
    maxSliceValue: PropTypes.number.isRequired,
    minSliceValue: PropTypes.number.isRequired,
    scroll: PropTypes.number.isRequired,
};

export default PracticeRankBoard;
