import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { getStaticText } from '../../../../utils/constants';
import NPS from './NPS';
import './index.scss';
import NPSDemographic from './NPSDemographic';

function NPSBoard({
    errorInfo,
    apiParams,
    apiLoadingCount,
    npsDataFetched,
    getNPSData,
    npsData,
    defaultSettings,
    filters,
    npsDemographicDataFetched,
    npsDemographicData,
    getNPSDemographicData,
    npsDropdown,
    setNpsDropdown,
    toggleFilterView,
    activeFilter,
    scoreData,
    scoreDataFetched,
    getScoreData,
}) {
    const { lang, threshold = 10 } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, NPS_CODE } = staticText;
    const { NPS_HEADING, NOTE, NPS_NOTE } = SITE_TEXT || {};
    const { ohid, filters: apiFilters = [], benchmarks = '' } = apiParams;
    const { demographics = [] } = filters[0] || {};
    const [addOnTab, setAddOnTabs] = useState(0);

    const callNps = () => {
        getNPSData({
            ohid,
            filters: apiFilters,
            lang: parseInt(lang, 10),
            label: NPS_CODE,
            benchmarks,
            threshold,
            demographics: '',
        });
    };

    const callNpsDemographic = code => {
        const { code: defaultCode = '' } = demographics[0] || {};
        const demoCode = code || defaultCode;
        getNPSDemographicData({
            ohid,
            filters: apiFilters,
            lang: parseInt(lang, 10),
            label: NPS_CODE,
            demographics: demoCode,
            benchmarks,
            threshold,
        });
    };

    useEffect(() => {
        if (!npsDropdown) {
            const { code: defaultCode = '' } = demographics[0] || {};
            setNpsDropdown(defaultCode);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!scoreDataFetched) {
            getScoreData(apiParams);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched]);

    const getPage = () => {
        switch (addOnTab) {
            case 0:
                return (
                    <NPS
                        pageData={npsData[0] || {}}
                        errorInfo={errorInfo}
                        defaultSettings={defaultSettings}
                        callNps={callNps}
                        npsDataFetched={npsDataFetched}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        activeFilter={activeFilter}
                        addOnTab={addOnTab}
                        scoreData={scoreData}
                    />
                );
            case 1:
                return (
                    <NPSDemographic
                        npsData={npsDemographicData}
                        selectedDemographic={npsDropdown}
                        defaultSettings={defaultSettings}
                        callNpsDemographic={callNpsDemographic}
                        npsDemographicDataFetched={npsDemographicDataFetched}
                        errorInfo={errorInfo}
                        setAddOnTabs={setAddOnTabs}
                        toggleFilterView={toggleFilterView}
                        activeFilter={activeFilter}
                        addOnTab={addOnTab}
                        npsDropdown={npsDropdown}
                        demographics={demographics}
                    />
                );
            default:
                return <Fragment />;
        }
    };

    return (
        <div className="npsBoard clearfix" data-testid="npsBoard">
            <h2>{NPS_HEADING}</h2>
            {getPage()}
            {!apiLoadingCount && (
                <div className="note">
                    <b>{NOTE}:</b>
                    <br />
                    {NPS_NOTE[0]}
                    <br />
                    {NPS_NOTE[1]}
                </div>
            )}
        </div>
    );
}

NPSBoard.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    npsDataFetched: PropTypes.bool.isRequired,
    apiParams: PropTypes.object.isRequired,
    npsData: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    getNPSData: PropTypes.func.isRequired,
    npsDemographicDataFetched: PropTypes.bool.isRequired,
    npsDemographicData: PropTypes.array.isRequired,
    getNPSDemographicData: PropTypes.func.isRequired,
    npsDropdown: PropTypes.string.isRequired,
    setNpsDropdown: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    scoreData: PropTypes.object.isRequired,
    getScoreData: PropTypes.func.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
};

export default NPSBoard;
