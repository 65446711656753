import React from 'react';
import PropTypes from 'prop-types';
import { getStaticTextAnalytics } from '../constants';
import CloudImage from '../assets/InsufficientData.svg';

import './index.scss';

function BlankPage({ error }) {
    const staticTextAnalytics = getStaticTextAnalytics();
    const { SITE_TEXT } = staticTextAnalytics;
    const { ERROR_DEFAULT_TXT, INSUFFICIENT_DATA } = SITE_TEXT;
    return (
        <div className="blankSection">
            <div className="blankPageWrapper">
                <div className="imageWrapper">
                    <img src={CloudImage} alt="" />
                </div>
                <div className="textInfoWrapper">
                    <h2>{INSUFFICIENT_DATA}</h2>
                    <div className="errorDescription">{error ? error[0] : ERROR_DEFAULT_TXT}</div>
                </div>
            </div>
        </div>
    );
}

BlankPage.propTypes = {
    error: PropTypes.array.isRequired,
};

export default BlankPage;
