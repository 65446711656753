import { fetchWebApi } from '../../webapis/dashboard';
import endpoints from '../../Endpoints';

export const getDefaultYearUrl = () => {
    const { gsodBaseUrl, defaultBmYrPath } = endpoints;
    const url = `${gsodBaseUrl}${defaultBmYrPath}`;
    return fetchWebApi(url).request;
};

export const getFilterDataUrl = year => {
    const { gsodBaseUrl, filterDataPath } = endpoints;
    const url = `${gsodBaseUrl}${filterDataPath}?year=${year}`;
    return fetchWebApi(url).request;
};

export const getFilterMappingUrl = year => {
    const { gsodBaseUrl, filterMappingPath } = endpoints;
    const url = `${gsodBaseUrl}${filterMappingPath}?year=${year}`;
    return fetchWebApi(url).request;
};

export const getBmFilterDataUrl = () => {
    const { gsodBaseUrl, bmFilterDataPath } = endpoints;
    const url = `${gsodBaseUrl}${bmFilterDataPath}`;
    return fetchWebApi(url).request;
};

export const getAnalyticsDataUrl = options => {
    const { gsodBaseUrl, analyticsDataPath } = endpoints;
    const url = `${gsodBaseUrl}${analyticsDataPath}?filters=${options}`;
    return fetchWebApi(url).request;
};

export const getAnalyticsRankingUrl = options => {
    const { gsodBaseUrl, analyticsRankingPath } = endpoints;
    const url = `${gsodBaseUrl}${analyticsRankingPath}?rank_type=overall&filters=${options}`;
    return fetchWebApi(url).request;
};

export const getDifferentialUrl = options => {
    const { gsodBaseUrl, analyticsRankingPath } = endpoints;
    const url = `${gsodBaseUrl}${analyticsRankingPath}?rank_type=differentiator&filters=${options}`;
    return fetchWebApi(url).request;
};

export const getFlagpoleUrl = options => {
    const { gsodBaseUrl, analyticsScorecardPath } = endpoints;
    const url = `${gsodBaseUrl}${analyticsScorecardPath}?analysis_type=flagpole&filters=${options}`;
    return fetchWebApi(url).request;
};

export const getAnalyticsPerCategoryUrl = options => {
    const { gsodBaseUrl, analyticsPerCategoryPath } = endpoints;
    const { filters = '', filter_id = '' } = options;
    const url = `${gsodBaseUrl}${analyticsPerCategoryPath}?filters=${filters}&filter_id=${filter_id}`;
    return fetchWebApi(url).request;
};

export const getScorecardUrl = options => {
    const { gsodBaseUrl, analyticsScorecardPath } = endpoints;
    const { filters = '' } = options || {};
    const url = `${gsodBaseUrl}${analyticsScorecardPath}?analysis_type=scorecard&filters=${filters}`;
    return fetchWebApi(url).request;
};
