import React, { useEffect, useMemo, useState } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';

import { cloneDeep } from 'lodash';
import Layout from '../../components/Layout';
import FilterBar from '../../components/FilterBarAnalytics';
import { getStaticTextAnalytics } from './constants';
import Loader from '../../components/Loader';
import { getStaticText } from '../../utils/constants';
import './index.scss';
import Intro from './Intro/Intro';
import Overview from './Overview';
import Scorecard from './Scorecard';
import FlagPole from './Flagpole';
import PracticeRanking from './PracticeRanking';
import viewIcon from '../Reports/assets/viewIcon.svg';
import FocusArea from '../Diagnose/assets/FocusArea.svg';

function Analytics({
    analyticsApiCount,
    updateFilters,
    filters,
    filtersApplied,
    filtersFetched,
    getFilterData,
    defaultSettings,
    filterParams,
    dataAtGlanceFetched,
    getDataAtGlance,
    getFiltersMapping,
    filtersMapping,
    filtersMappingFetched,
    defaultFilters,
    getDefaultYear,
    defaultYear,
}) {
    const { lang } = defaultSettings;
    const staticTextAnalytics = getStaticTextAnalytics(lang);
    const { DEFAULT_FILTER, NAV_LINKS } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, SLICE_VALUE } = staticText;
    const [activeIndex, updateIndex] = useState(0);
    const [filterOpen, toggleFilterView] = useState(false);
    const [tabs, setTabs] = useState(4);
    const [rankingTabs, setRankingTabs] = useState(0);
    const [sliceVal, setSliceVal] = useState(SLICE_VALUE);
    const { ANALYTICS_NAV_LINKS, VIEW_LESS, VIEW_MORE, FILTERS_APPLIED } = SITE_TEXT || {};
    const isDiffAnalysisScreen = activeIndex === 4 && rankingTabs === 1;

    useEffect(() => {
        if (!defaultYear) {
            getDefaultYear();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!filtersFetched && defaultYear) {
            getFilterData(defaultYear);
        }
        // eslint-disable-next-line
    }, [filtersFetched, defaultYear]);

    useEffect(() => {
        if (!filtersMappingFetched && defaultYear) {
            getFiltersMapping(defaultYear);
        }
        // eslint-disable-next-line
    }, [filtersMappingFetched, defaultYear]);

    useEffect(() => {
        if (!dataAtGlanceFetched && defaultYear) {
            getDataAtGlance(filterParams);
        }
        // eslint-disable-next-line
    }, [dataAtGlanceFetched, defaultYear]);

    const setFilters = (newFilters, filtersAppliedFlag) => {
        toggleFilterView(false);
        updateFilters({ filters: newFilters, filtersApplied: filtersAppliedFlag });
    };

    const updateFilterState = (index, childCode) => {
        const newFilterItem = cloneDeep(filters[index]);
        const selectedItemIndex = newFilterItem.filter_items.findIndex(
            ({ filter_item_id: code }) => code === childCode
        );
        newFilterItem.filter_items[selectedItemIndex].isSelected = false;
        const checkSelectedChild = newFilterItem.filter_items.filter(({ isSelected = false }) => isSelected).length;
        if (!checkSelectedChild) {
            newFilterItem.isSelected = false;
        }
        const newList = [...filters.slice(0, index), newFilterItem, ...filters.slice(index + 1)];
        updateFilters({ filters: newList });
    };

    const isFiltersApplied = filterParams.replace(DEFAULT_FILTER, '').length;

    // const resetFilters = () => {
    //     const newFilters = filters.map(filterItem => {
    //         const updatedFilter = cloneDeep(filterItem);
    //         const { filter_items = [], isDefault = false } = updatedFilter;
    //         if (isDefault) return updatedFilter;
    //         const selectedLength = filter_items.filter(({ isSelected }) => isSelected).length;
    //         if (selectedLength) {
    //             updatedFilter.filter_items = filter_items.map(oldOption => ({
    //                 ...oldOption,
    //                 isSelected: false,
    //             }));
    //         }
    //         updatedFilter.isSelected = false;
    //         return updatedFilter;
    //     });
    //     updateFilters({ filters: newFilters });
    //     setSliceVal(SLICE_VALUE);
    // };

    const renderfilterApplied = useMemo(() => {
        const selectedFilters = [];
        filters.forEach(({ filter_items, filter_id }) => {
            const skipQuartileFilter = filter_id === 'quartile' && isDiffAnalysisScreen;
            if (!skipQuartileFilter) {
                filter_items.forEach(({ isSelected = false, filter_item_id }) => {
                    if (isSelected) {
                        selectedFilters.push(`${filter_id}_${filter_item_id}`);
                    }
                });
            }
        });
        return (
            <div className="filterAppliedContent">
                <div className="filterDemo">
                    <div className="heading">{FILTERS_APPLIED}:</div>
                    {filters.map(
                        (
                            { filter_name, isSelected = false, filter_items = [], filter_id, isDefault = false },
                            index
                        ) => {
                            if (!isSelected) return null;
                            return filter_items.map(
                                ({ isSelected: selectedChild = false, filter_item_name, filter_item_id }) => {
                                    const checkLimit = selectedFilters
                                        .slice(0, sliceVal)
                                        .includes(`${filter_id}_${filter_item_id}`);
                                    return selectedChild && checkLimit ? (
                                        <div className="filterContent" key={filter_item_name + filter_item_id}>
                                            <div className="filterHighlight filterKey filterLab">
                                                {filter_name} - {filter_item_name}
                                            </div>
                                            {!isDefault && (
                                                <img
                                                    src={FocusArea}
                                                    alt=""
                                                    className="filterImg"
                                                    onClick={() => updateFilterState(index, filter_item_id)}
                                                />
                                            )}
                                        </div>
                                    ) : null;
                                }
                            );
                        }
                    )}

                    {selectedFilters.length >= sliceVal ? (
                        <div className="viewIconContent" onClick={() => setSliceVal(10000)}>
                            <img src={viewIcon} alt="" className="viewImg" />
                            <div className="viewText">{VIEW_MORE}</div>
                        </div>
                    ) : null}
                    {sliceVal === 10000 ? (
                        <div className="viewIconContent" onClick={() => setSliceVal(SLICE_VALUE)}>
                            <img src={viewIcon} alt="" className="viewImg" />
                            <div className="viewText"> {VIEW_LESS}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
        // eslint-disable-next-line
    }, [filters, sliceVal, isDiffAnalysisScreen]);

    const getPage = () => {
        let page;
        switch (activeIndex) {
            case 0:
            default:
                page = <Intro updateTab={updateIndex} defaultYear={defaultYear} />;
                break;
            case 1:
                page = (
                    <Overview
                        toggleFilterView={toggleFilterView}
                        tabs={tabs}
                        setTabs={setTabs}
                        renderfilterApplied={renderfilterApplied}
                        filtersApplied={isFiltersApplied}
                    />
                );
                break;
            case 2:
                page = (
                    <Scorecard
                        toggleFilterView={toggleFilterView}
                        renderfilterApplied={renderfilterApplied}
                        filtersApplied={isFiltersApplied}
                    />
                );
                break;
            case 3:
                page = (
                    <FlagPole
                        toggleFilterView={toggleFilterView}
                        renderfilterApplied={renderfilterApplied}
                        filtersApplied={isFiltersApplied}
                    />
                );
                break;
            case 4:
                page = (
                    <PracticeRanking
                        toggleFilterView={toggleFilterView}
                        renderfilterApplied={renderfilterApplied}
                        tab={rankingTabs}
                        setTab={setRankingTabs}
                        filtersApplied={isFiltersApplied}
                    />
                );
                break;
        }
        return page;
    };

    return (
        <Layout showHeader>
            <div
                className={c('analyticsContent clearfix', {
                    isNonProfit: false,
                    hideAddOns: true,
                })}
            >
                <ul className={c({ isLoading: false })}>
                    {NAV_LINKS.map(({ imgSrc, imgSrcActive, index }, mainIndex) => {
                        const text = ANALYTICS_NAV_LINKS[mainIndex];
                        return (
                            <li
                                className={c({ selected: activeIndex === index }, text)}
                                key={text}
                                data-testid={text}
                                onClick={() => {
                                    updateIndex(index);
                                }}
                            >
                                <img src={activeIndex === index ? imgSrcActive : imgSrc} alt="" />
                                {text}
                            </li>
                        );
                    })}
                </ul>
                <section>
                    {analyticsApiCount ? (
                        <Loader position="absolute" showBackground background="transparent" />
                    ) : (
                        getPage()
                    )}
                </section>
                {filterOpen && (
                    <FilterBar
                        closeFilters={toggleFilterView}
                        setFilters={setFilters}
                        filtersApplied={filtersApplied}
                        filters={filters}
                        defaultSettings={defaultSettings}
                        filtersMapping={filtersMapping}
                        defaultFilters={defaultFilters}
                        isFiltersApplied={isFiltersApplied}
                        hideFilterIds={isDiffAnalysisScreen ? ['quartile'] : []}
                    />
                )}
            </div>
        </Layout>
    );
}

Analytics.propTypes = {
    analyticsApiCount: PropTypes.number.isRequired,
    updateFilters: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
    filtersFetched: PropTypes.bool.isRequired,
    getFilterData: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    filterParams: PropTypes.string.isRequired,
    dataAtGlanceFetched: PropTypes.bool.isRequired,
    getDataAtGlance: PropTypes.func.isRequired,
    getFiltersMapping: PropTypes.func.isRequired,
    filtersMapping: PropTypes.array.isRequired,
    filtersMappingFetched: PropTypes.bool.isRequired,
    defaultFilters: PropTypes.object.isRequired,
    getDefaultYear: PropTypes.func.isRequired,
    defaultYear: PropTypes.number.isRequired,
};

export default Analytics;
