import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Helmet } from 'react-helmet';

import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import { getStaticText } from '../../../utils/constants';
import { getStaticTextAnalytics } from '../constants';
import colors from '../../../sass/colors';
import './index.scss';
import BlankPage from '../BlankPage/BlankPage';
import QuickHelp from '../QuickHelp/QuickHelp';

function Flagpole({
    toggleFilterView,
    getFlagpole,
    flagpoleFetched,
    flagpoleData,
    defaultSettings,
    filterParams,
    renderfilterApplied,
    flagpoleError,
    dataAtGlance,
    filtersApplied,
}) {
    useEffect(() => {
        if (!flagpoleFetched) {
            getFlagpole(filterParams);
        }
        // eslint-disable-next-line
    }, [flagpoleFetched]);
    const { quartileColors = [], lang } = defaultSettings;
    const staticTextAnalytics = getStaticTextAnalytics(lang);
    const { ANALYTICS_QUARTILE_COLORS } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;

    const { $white } = colors;
    const [expandedList, updateExpandedList] = useState([0]);
    const [helpView, helpMode] = useState(false);
    const {
        COMPARISON,
        FILTERS,
        NOTE,
        BENCH_NOTE,
        SIGNIFICANCE,
        BENCHMARK_HEAD,
        BENCHMARK_NA,
        ANALYTIC_FLAGPOLE_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_TEXT,
        GSOD_FLAGPOLE_NAME,
    } = SITE_TEXT || {};

    const { records = [] } = flagpoleData || {};
    const { total_survey: n_survey = '' } = dataAtGlance;
    const dataToIterate = records.slice(1);
    const {
        display_name: ohiName,
        quartile: ohiQuartile = '',
        score: ohiScore = '',
        diff: ohiScoreDiff = '',
        sig: ohiScoreSig = '',
    } = records[0] || {}; //
    const { background: ohiBg, border: ohiBorder } = quartileColors[ohiQuartile] || {};
    const ohiStyleBlack = ohiQuartile === ('' || 999) ? { border: '2px solid #000000' } : {};
    const { bg: ohiOneBg } = SIGNIFICANCE[ohiScoreSig] || {};

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        updateExpandedList(newList);
    };

    return (
        <div className="flagpole clearfix">
            <Helmet>
                <title>{ANALYTIC_FLAGPOLE_TEXT['1']} </title>
            </Helmet>
            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="mainHeading">{ANALYTIC_FLAGPOLE_TEXT['2']}</div>
                    <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${n_survey}`}</div>
                </div>
                <ul className="navScoreList">
                    <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                        <span className="filter">{FILTERS}</span>
                    </li>
                </ul>
            </div>
            <div className="benchmarkInfo">{ANALYTIC_FLAGPOLE_TEXT['3']}</div>
            {renderfilterApplied}
            {/* {errorInfo && <ErrorToast message={errorInfo} />} */}
            {dataToIterate.length ? (
                <div className="ChartsContainer">
                    <section className="outcomeContent selectContent clearfix">
                        <div />
                        <div>
                            <span>{GSOD_FLAGPOLE_NAME}</span>
                        </div>
                        <div className="leftAlign">
                            <span>
                                {ANALYTIC_FLAGPOLE_TEXT['5']} <br />
                                <span className="subTxt">{ANALYTIC_FLAGPOLE_TEXT['6']}</span>
                            </span>
                        </div>
                    </section>
                    <section className="outcomeContent ohiContent clearfix">
                        <div className="ellipsisPro">{ohiName}</div>
                        <div>
                            <div className="rank">
                                <div
                                    className="textBorder"
                                    style={{
                                        background: ohiBg,
                                        borderColor: ohiBorder,
                                        width: `${Math.abs(ohiScore)}%`,
                                        ...ohiStyleBlack,
                                    }}
                                >
                                    <div className="textRank">{ohiScore}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={c('rank', { negative: ohiScoreDiff < 0 })}>
                                <div
                                    className="textBorder"
                                    style={{
                                        background: ohiOneBg,
                                        width: `${2 * Math.abs(ohiScoreDiff)}%`,
                                    }}
                                >
                                    <div className="textRank">
                                        {ohiScoreDiff > 0 ? `+${ohiScoreDiff}` : ohiScoreDiff}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {dataToIterate.map((dataSet, index) => {
                        const {
                            display_name = '',
                            quartile = '',
                            score = '',
                            children = {},
                            diff: recordDiff = '',
                            sig: pSig = '',
                        } = dataSet;
                        const { background = $white, border: borderColor = $white } = quartileColors[quartile] || {};
                        const styleBlack = quartile === ('' || 999) ? { border: '2px solid #000000' } : {};
                        const { bg: recordOneBg } = SIGNIFICANCE[pSig] || {};
                        return (
                            <Accordion
                                classes={{ root: 'accordionRoot' }}
                                key={index}
                                expanded={expandedList.includes(index)}
                            >
                                <AccordionSummary
                                    data-testid={dataSet}
                                    expandIcon={<ExpandMoreIcon />}
                                    onClick={() => updateExpanded(index)}
                                    classes={{ content: 'accordionHead' }}
                                >
                                    <section className="outcomeContent clearfix">
                                        <div className="ellipsisPro">{display_name}</div>
                                        <div>
                                            <div className="rank">
                                                <div
                                                    className="textBorder"
                                                    style={{
                                                        background,
                                                        borderColor,
                                                        width: `${Math.abs(score)}%`,
                                                        ...styleBlack,
                                                    }}
                                                >
                                                    <div className="textRank">{score}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={c('rank', { negative: recordDiff < 0 })}>
                                                <div
                                                    className="textBorder"
                                                    style={{
                                                        background: recordOneBg,
                                                        width: `${2 * Math.abs(recordDiff)}%`,
                                                    }}
                                                >
                                                    <div className="textRank">
                                                        {recordDiff > 0 ? `+${recordDiff}` : recordDiff}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </AccordionSummary>
                                <AccordionDetails classes={{ root: 'clearfix' }}>
                                    {children.map(childSet => {
                                        const {
                                            display_name: childName = '',
                                            score: childScore = '',
                                            quartile: childQuartile,
                                            diff: recordOneDiff,
                                            sig: recordOneSign,
                                        } = childSet;
                                        const { background: childBg = $white, border: childBd = $white } =
                                            quartileColors[childQuartile] || {};
                                        const styleBlackC =
                                            childQuartile === ('' || 999) ? { border: '2px solid #000000' } : {};
                                        const { bg: recordOneChildBg } = SIGNIFICANCE[recordOneSign] || {};
                                        return (
                                            <section className="outcomeContent clearfix" key={childName}>
                                                <div className="ellipsisPro">{childName}</div>
                                                <div>
                                                    <div className="rank">
                                                        <div
                                                            className="textBorder"
                                                            style={{
                                                                background: childBg,
                                                                borderColor: childBd,
                                                                width: `${Math.abs(childScore)}%`,
                                                                ...styleBlackC,
                                                            }}
                                                        >
                                                            <div className="textRank">{childScore}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={c('rank', { negative: recordOneDiff < 0 })}>
                                                        <div
                                                            className="textBorder"
                                                            style={{
                                                                background: recordOneChildBg,
                                                                width: `${2 * Math.abs(recordOneDiff)}%`,
                                                            }}
                                                        >
                                                            <div className="textRank">
                                                                {recordOneDiff > 0
                                                                    ? `+${recordOneDiff}`
                                                                    : recordOneDiff}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                    <ul className="bottomList clearfix">
                        <li>{COMPARISON} : </li>
                        {SIGNIFICANCE.map(({ title, bg: background }) => (
                            <li key={title}>
                                <span style={{ background }} />
                                {title}
                            </li>
                        ))}
                    </ul>
                    <ul className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {ANALYTICS_QUARTILE_COLORS.slice()
                            .reverse()
                            .map(({ title, background, border: borderColor }) => {
                                const bottomDecileVar = title === 'Bottom decile';
                                const BenchmarkTitle = title === 'No benchmarks';
                                return (
                                    <li
                                        key={title}
                                        style={{ color: background }}
                                        className={c({
                                            hide: bottomDecileVar || BenchmarkTitle,
                                        })}
                                    >
                                        <span
                                            className="benchmarkTriangle"
                                            style={{
                                                background,
                                                borderWidth: '13px 13px 0 0',
                                                borderColor: bottomDecileVar
                                                    ? `transparent ${borderColor} transparent transparent`
                                                    : `${borderColor} transparent transparent transparent`,
                                                borderStyle: 'solid',
                                            }}
                                        />
                                        <div style={{ color: 'black' }}>{title}</div>
                                    </li>
                                );
                            })}
                        <li>
                            <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                            {BENCHMARK_NA}
                        </li>
                    </ul>
                    <div className="note">
                        <b>{NOTE}:</b>
                        {BENCH_NOTE}
                    </div>
                </div>
            ) : (
                <section className="sectionWrapper">
                    <BlankPage error={flagpoleError} />
                </section>
            )}
            <QuickHelp helpMode={helpMode} helpView={helpView} activePage="3" />
        </div>
    );
}

Flagpole.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    flagpoleData: PropTypes.object.isRequired,
    flagpoleFetched: PropTypes.bool.isRequired,
    getFlagpole: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    filterParams: PropTypes.string.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    flagpoleError: PropTypes.array.isRequired,
    dataAtGlance: PropTypes.object.isRequired,
    filtersApplied: PropTypes.bool.isRequired,
};

export default Flagpole;
