import React, { useEffect, useState, useMemo, Fragment, useRef } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Select, MenuItem, Tooltip } from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { Helmet } from 'react-helmet';

import { cloneDeep } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Excel from './Excel';
import SelectModal from '../../../components/SelectModal';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';
import {
    getBenchmarkName,
    getDemographicName,
    getStylesForPpt,
    getPptValues,
    getFiltersAppliedText,
    manipulateHeatmapTitle,
    getOutputFilter,
} from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';
import colors from '../../../sass/colors';
import FilterSvg from '../assets/Filter.svg';
import ArrowLeft from '../assets/ArrowLeft.svg';
import ArrowRight from '../assets/ArrowRight.svg';
import ArrowUp from '../assets/ArrowUp.svg';
import ArrowDown from '../assets/ArrowDown.svg';
import DownloadIcon from '../assets/Download.svg';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import FocusArea from '../assets/FocusArea.svg';
import viewIcon from '../../Reports/assets/viewIcon.svg';
import BulletList from '../assets/BulletList.svg';
import UpDown from '../assets/UpDown.svg';
import Close from '../../../assets/Close.svg';
import PracticeRankBoard from './PracticeRankBoard';
import Down from '../assets/Down.svg';
import Reload from '../assets/Reload.svg';
import Xlsx from '../assets/Xlxs.svg';
import Pdf from '../assets/Pdf.svg';

function DemographicBoard({
    heatMapData,
    defaultSettings,
    getHeatMapOutcome,
    toggleFilterView,
    heatMapDataFetched,
    demographicCurrentTab,
    setDemographicCurrentTab,
    apiLoadingCount,
    selectedDemographic,
    setSelectedDemographic,
    heatMapPractice,
    getHeatMapPractice,
    heatMapPracticeFetched,
    apiParams,
    sortHeatMapOutcome,
    filters,
    activeFilter,
    toolOpen,
    getPptData,
    isOutcomeRetrieved,
    isPracticeRetrived,
    loadHeatMapPractice,
    loadHeatMapOutcome,
    practicePageNo,
    outcomePageNo,
    setHeatMapOutcomeData,
    setHeatMapPracticeData,
    setPracticePageNo,
    setOutcomePageNo,
    updateHeatMapPractice,
    updateHeatMapOutcome,
    heatMapInclusion,
    heatMapInclusionFetched,
    heatMapInclusionError,
    getHeatMapInclusion,
    getDemographics,
    getBenchmarks,
    projects,
    getMappedDemographics,
    updateHeatMapInfo,
    setReport,
    heatMapResurveyInfo,
    isCfgClient,
    getReport,
    getRawPptData,
    reportError,
    getSinglePastReport,
    singlePastReport,
    setFilters,
    heatMapRankingFetched,
    heatMapRanking,
    getRankingHeatMap,
    updateHeatMapRanking,
    loadHeatMapRanking,
    rankingPageNo,
    setRankingPageNo,
    setHeatMapRankingData,
    cohesionScore,
    getCohesionData,
    setCohesionDemographic,
    cohesionDataFetched,
    // scoreData,
}) {
    const {
        threshold = 10,
        quartileColors = [],
        report_name = '',
        report_name_or_client_name: rName = '',
        demographic = '',
        template_name: template_file_name,
        lang,
        engagement,
        benchmark,
        ohi_text = '',
        report_id = '',
        checkme_mapping = {},
        survey_version = '',
        survey_type = '',
        benchmark_year: bmYear = '',
        report_type: surveyType = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const {
        MAX_LENGTH_SUBSTRING,
        OUTCOME_VAR,
        PRACTICE_VAR,
        INCLUSION_VAR,
        SLICE_MAX_VALUE,
        SLICE_MIN_VALUE,
        SORT_ASCENDING,
        SORT_DESCENDING,
        NUMBER_OF_RESPONDENTS_N,
        DEMO_INLUSION_OHID,
        PERCENTILE,
        PR_VAR,
        PR,
        PULSE_SURVEY,
    } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT, NON_PROFIT_SURVEY, SINGLE_PAGE_EXPORT_PARAMS } = staticText;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const filterCheck = apiParams.filters || [];
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const pulseSurvey = survey_type === PULSE_SURVEY;
    const {
        DEMOGRAPHIC,
        NOTE,
        FILTERS,
        BENCHMARK_HEAD,
        PRACTICES,
        OUTCOMES,
        DOWNLOAD,
        DOWNLOAD_PPT,
        DOWNLOAD_EXCEL,
        DISCLAIMER_DEMO_PART1,
        DISCLAIMER_DEMO_PART2,
        DISCLAIMER_DEMO_PART3,
        N_SIZE,
        PPT_RES_TEXT,
        PPT_RES_TEXT2,
        PPT_RES_TEXT3,
        DYNAMIC_TEXT_INFO,
        SOURCE_TEXT,
        NUMBER_SURVEY_TEXT,
        DEMO_INCLUSION_LEGENDS,
        RESURVEY_TEXT,
        OUTCOME_TEXT,
        PRACTICE_TEXT,
        ALL_PPT_TEXT,
        PPT_VALUES,
        BY_TEXT,
        // BENCHMARKS_APPLIED,
        // FILTERS_APPLIED,
        // NONE,
        VIEW_LESS,
        VIEW_MORE,
        BENCHMARK,
        PR_HEATMAP_LEGENDS,
        ADDONS_PAGE_TEXT,
        HEALTH_DEMOGRAPHIC,
        PRACTICE_NAME_VIEW,
        COHESION_SCORE,
        TOP_RANKING,
        MIDDLE_RANKING,
        BOTTOM_RANKING,
        RANK,
        PERCENT_FAVORABLE,
    } = SITE_TEXT || {};
    const { OHI_SCORE_TEXT } = DYNAMIC_TEXT_INFO({ ohi_text });
    const [minSliceValue, setMinSliceValue] = useState(SLICE_MIN_VALUE);
    const [maxSliceValue, setMaxSliceValue] = useState(SLICE_MAX_VALUE);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const [selectedDownload, setSelectedDownload] = useState('');
    const [showReSurveyModal, setReSurveyModal] = useState(false);
    const [sortOrder, setSortOrder] = useState({});
    const [sortBy, setSortBy] = useState('');
    const [scroll, setScroll] = useState(-1);
    const [activeBtn, setActiveBtn] = useState(-1);
    const [prSortIndex, setSortIndex] = useState(-1);
    const [dwnldDrop, showDwnldDrop] = useState(false);
    const scrollIndex = useRef(null);
    const [rankingData, setRankingData] = useState(heatMapRanking);

    function removeDuplicates(array) {
        return [...new Set(array)];
    }

    const practiceToUse = useMemo(() => {
        const practiceCopy = cloneDeep(heatMapPractice);
        const { items = [], header = [] } = practiceCopy || {};
        const newItems = [items[0]];
        const allSlugs = items.map(({ parent_name }) => parent_name || '');
        const filteredSlug = removeDuplicates(allSlugs);
        filteredSlug.forEach(slug => {
            const itemsWithSlug = items.filter(({ parent_name }) => parent_name === slug);
            if (slug) {
                newItems.push(...itemsWithSlug);
            }
        });
        return items.length ? { items: newItems, header } : {};
    }, [heatMapPractice]);

    const heatMapType = demographicCurrentTab === OUTCOME_VAR ? heatMapData : practiceToUse;
    const heatMapDataType = demographicCurrentTab === PR_VAR ? rankingData : heatMapType;
    const { items = [], error: message = '', status = '', header = [] } = heatMapDataType;
    const { items: heatmapItems = [] } = heatMapType;
    const { qbyq = {} } = heatMapInclusion;
    const mainKeys = Object.keys(qbyq);
    const headerInclusion = useMemo(() => {
        if (!mainKeys.length) return [];
        const { records: innerRec = {} } = qbyq[mainKeys[0]];
        return innerRec.map(({ display_name = '' }) => display_name);
        // eslint-disable-next-line
    }, [mainKeys]);
    const isInclusion = demographicCurrentTab === INCLUSION_VAR;
    const isPR = demographicCurrentTab === PR_VAR;
    const headerToUse = isInclusion ? headerInclusion : header;
    const { ohid, report_type } = apiParams;
    const isPercentile = report_type === PERCENTILE;
    const { selectedProjectIndex: oldIndex = '', heatMapDemo: oldDemo = '' } = heatMapResurveyInfo;
    const [dwnld, setdwnld] = useState(false);
    const header0 = manipulateHeatmapTitle(header[0]);
    const [showAll, setShowAll] = useState(false);
    const [prView, togglePRView] = useState(false);
    const [maxVal, setMaxVal] = useState(SLICE_MAX_VALUE);
    const { items: newItems = [] } = heatmapItems[0] || [];
    const { score: resNsize = '' } = newItems[0] || [];
    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    const maxItemsToShow = 6;

    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
    };

    useEffect(() => {
        setRankingData(heatMapRanking);
        if (isPR) {
            if (prView) {
                setMaxSliceValue(4);
                setMaxVal(4);
            } else {
                setMaxSliceValue(SLICE_MAX_VALUE);
                setMinSliceValue(SLICE_MIN_VALUE);
                setMaxVal(SLICE_MAX_VALUE);
                setShowLeftArrow(false);
            }
        } else {
            togglePRView(false);
            setMaxSliceValue(SLICE_MAX_VALUE);
            setMaxVal(SLICE_MAX_VALUE);
            setShowLeftArrow(false);
        }
        // eslint-disable-next-line
    }, [prView, demographicCurrentTab]);

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        scrollIndex.current?.scrollIntoView({ behavior: 'instant', alignToTop: true });
        if (scroll === -1) {
            window.scrollBy(0, 60);
        }
    }, [scroll]);

    const handleCohesionData = code => {
        getCohesionData({
            ohid,
            lang: parseInt(lang, 10),
            threshold: 50,
            columns: 500,
            page: 1,
            percentile: 0,
            filters: apiParams.filters,
            type: PRACTICE_VAR,
            demographic: code,
        });
        setCohesionDemographic(code);
    };

    const callHeatMap = (code, type) => {
        if (type === OUTCOME_VAR) {
            getHeatMapOutcome({
                ...apiParams,
                ...addOns,
                type,
                demographic: code,
            });
        } else if (type === PR_VAR) {
            const obj = {
                threshold: apiParams.threshold,
                lang: apiParams.lang,
                page: apiParams.page,
                ohid: apiParams.ohid,
                filters: apiParams.filters,
                columns: apiParams.columns,
                others: addOns.others,
            };
            getRankingHeatMap({
                ...obj,
                type,
                demographic: code,
                get_all: 0,
                demo_heatmap: 1,
            });
            if (!cohesionDataFetched) {
                handleCohesionData(code);
            }
        } else if (type === INCLUSION_VAR) {
            const { benchmarks, filters: filtersToAdd } = apiParams;
            getHeatMapInclusion({
                lang: parseInt(lang, 10),
                ohid,
                benchmarks,
                filters: filtersToAdd,
                threshold,
                gap_on: code,
            });
        } else {
            getHeatMapPractice({
                ...apiParams,
                ...addOns,
                type,
                demographic: code,
            });
        }
    };

    const handleScroll = index => {
        setScroll(index);
        setActiveBtn(index);
    };

    const switchBtnHighlight = () => {
        const mid = document.getElementById('mid');
        const bottom = document.getElementById('bottom');
        const midOffSet = mid.offsetTop + 200;
        const bottomOffSet = bottom.offsetTop + 200;
        if (isPR) {
            if (window.scrollY > midOffSet && window.scrollY < bottomOffSet) {
                setActiveBtn(11);
            } else if (window.scrollY > bottomOffSet) {
                setActiveBtn(items.length - 13);
            } else {
                setActiveBtn(-1);
            }
        }
    };

    const listenToScroll = () => {
        const headerId = document.getElementById('myHeader');
        const sticky = headerId.offsetTop;
        if (window.pageYOffset > sticky) {
            setSticky(true);
        } else {
            setSticky(false);
        }
        if (isPR) {
            switchBtnHighlight();
        }
    };

    useEffect(() => {
        if (header.length - 1 >= maxSliceValue) {
            setShowRightArrow(true);
        } else {
            setShowRightArrow(false);
        }
        if (loadHeatMapPractice) {
            setHeatMapPracticeData(false);
            setPracticePageNo(practicePageNo + 1);
            updateHeatMapPractice({
                ...apiParams,
                ...addOns,
                type: PRACTICE_VAR,
                demographic: selectedDemographic,
                page: practicePageNo + 1,
            });
        }
        if (loadHeatMapOutcome) {
            setHeatMapOutcomeData(false);
            setOutcomePageNo(outcomePageNo + 1);
            updateHeatMapOutcome({
                ...apiParams,
                ...addOns,
                type: OUTCOME_VAR,
                demographic: selectedDemographic,
                page: outcomePageNo + 1,
            });
        }
        if (loadHeatMapRanking) {
            const obj = {
                threshold: apiParams.threshold,
                lang: apiParams.lang,
                page: apiParams.page,
                ohid: apiParams.ohid,
                filters: apiParams.filters,
                columns: apiParams.columns,
                others: addOns.others,
            };
            setHeatMapRankingData(false);
            setRankingPageNo(rankingPageNo + 1);
            updateHeatMapRanking({
                ...obj,
                type: PR_VAR,
                demographic: selectedDemographic,
                page: rankingPageNo + 1,
                get_all: 0,
                demo_heatmap: 1,
            });
        }
        window.addEventListener('scroll', listenToScroll);
        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
        // eslint-disable-next-line
    }, [filters, apiParams, header, maxSliceValue, loadHeatMapPractice, loadHeatMapOutcome, loadHeatMapRanking]);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const handleAllFilterData = (
        parKey,
        childKey,
        ParDemographic,
        filtersHere,
        updateRedux = true,
        activeFilters = true
    ) => {
        const newDemographic = cloneDeep(ParDemographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks = [] } = filtersHere[0];
        const allList = { demographics: newDemographic, benchmarks };
        const newList = [...filters.slice(0, 0), allList, ...filters.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilters, 0);
        }
    };

    const appliedFiltersInfo = () => {
        const { benchmarks = [], demographics = [] } = filters[0] || {};
        const { filters: filterApplied = [] } = apiParams;
        const label = getBenchmarkName(benchmarks);
        const arrItem = getFiltersAppliedText(filterApplied, demographics);
        const newItem = getOutputFilter(filterApplied, demographics);
        const { n_respondents: nRes = '', n_survey: nSurvey = '' } = heatMapData;

        return (
            <div className="filterAppliedContent">
                {arrItem.length > 0 && newItem !== null && (
                    <div className="filterDemo">
                        {newItem !== null && <div className="heading">{DEMOGRAPHIC + ':'}</div>}
                        {newItem !== null &&
                            newItem
                                .slice(0, showAll ? newItem.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllFilterData(parentCode, childCode, demographics, filters);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItem.length >= maxItemsToShow || showAll) && (
                            <div onClick={toggleShowAll}>
                                {showAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                <div className="filterDemo">
                    <div className="heading">{BENCHMARK + ':'}</div>
                    <div className="nsizeContent">
                        <div>Year :</div>
                        <div>{bmYear}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n :</div>
                        <div>{nRes}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>No of surveys :</div>
                        <div>{nSurvey}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{label}</div>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (
            (!heatMapPracticeFetched && !apiLoadingCount && demographicCurrentTab === PRACTICE_VAR) ||
            (!heatMapDataFetched && !apiLoadingCount && demographicCurrentTab === OUTCOME_VAR) ||
            (!heatMapInclusionFetched && !apiLoadingCount && demographicCurrentTab === INCLUSION_VAR) ||
            (!heatMapRankingFetched && !apiLoadingCount && demographicCurrentTab === PR_VAR)
        ) {
            let currentDemographics = selectedDemographic;
            if (selectedDemographic === '') {
                const { demographics = [] } = filters[0] || {};
                const { code } = demographics[0] || {};
                currentDemographics = code;
                setSelectedDemographic(currentDemographics);
            }
            callHeatMap(currentDemographics, demographicCurrentTab);
        }
        if (heatMapRankingFetched) {
            setRankingData(heatMapRanking);
        }
        // eslint-disable-next-line
    }, [
        heatMapPracticeFetched,
        apiLoadingCount,
        demographicCurrentTab,
        heatMapDataFetched,
        heatMapInclusionFetched,
        heatMapRankingFetched,
        filters,
        selectedDemographic,
    ]);

    const handleTabData = tab => {
        if (demographicCurrentTab !== tab) {
            setSelectedDownload('');
            setDemographicCurrentTab(tab);
            setMinSliceValue(SLICE_MIN_VALUE);
            setMaxSliceValue(maxVal);
            if (maxSliceValue <= header.length - 1) {
                setShowRightArrow(true);
            }
            setShowLeftArrow(false);
        }
    };

    const sortData = type => {
        if (isInclusion) return;
        if (type === N_SIZE || type === OHI_SCORE_TEXT) {
            setSortOrder({ [type]: sortOrder[type] === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING });
            let sortingKey;
            const isAscending = sortOrder[type] === SORT_ASCENDING;
            setSortOrder({ [type]: isAscending ? SORT_DESCENDING : SORT_ASCENDING });
            if (type === N_SIZE) {
                sortingKey = isAscending ? 'nsize:asc' : 'nsize:desc';
            } else if (type === OHI_SCORE_TEXT) {
                sortingKey = isAscending ? 'score:asc' : 'score:desc';
            }
            setSortBy(sortingKey);
            const arr = [];
            items.map(({ items: subItems = [] }, i) => {
                const { score = '' } = type === N_SIZE ? subItems[0] || {} : subItems[1] || {};
                arr.push(score + '#' + i);
                return items;
            });
            const sortArr =
                sortOrder[type] === 1
                    ? arr.slice(1, arr.length).sort((a, b) => a.split('#')[0] - b.split('#')[0])
                    : arr.slice(1, arr.length).sort((a, b) => b.split('#')[0] - a.split('#')[0]);
            sortArr.unshift(arr[0]);
            const indexArr = [];
            sortArr.map(val => {
                return indexArr.push(arr.indexOf(val));
            });
            const output = indexArr.map(i => items[i]);
            sortHeatMapOutcome(output);
        }
    };

    const sortPRData = index => {
        if (!prView) {
            const arr = cloneDeep(items);
            const output = arr.sort(({ items: a = [] }, { items: b = [] }) => a[index + 1].score - b[index + 1].score);
            setRankingData({ ...rankingData, items: output });
            setSortIndex(index);
        } else {
            const outputHeader = header.filter((_, i) => i !== index + 1);
            const output = items.map(({ items: subItems = [], en_title = '', title = '' }) => {
                return {
                    items: subItems.filter((_, i) => i !== index + 1),
                    en_title,
                    title,
                };
            });
            setRankingData({ header: outputHeader, items: output });
        }
    };

    const { benchmarks = [], demographics = [] } = filters[0] || {};

    const removeIntitalSlide = (isPractice = false) => {
        return [
            {
                identify_shape_name: 'Checkme',
                identify_shape_value: isPractice ? 'practicebydemo' : 'copyslide',
                is_copy: false,
                is_delete: true,
                styles: [],
            },
        ];
    };

    const slidesArrayObject = (styleArr, tableDataArr, iteration, itemPerPage, isPractice = false) => {
        const { n_respondents = '', n_survey = '' } = heatMapType;
        const { benchmarks: selectedBenchmark = [] } = filters[0];
        const label = getBenchmarkName(selectedBenchmark);
        const demograhicLabel = getDemographicName(demographics, selectedDemographic);
        const originalSub = isPractice ? PPT_RES_TEXT2 : PPT_RES_TEXT;
        const { GLOBAL_VALS, comparisonMeta } = PPT_VALUES({ rName });
        return {
            identify_shape_name: 'Checkme',
            identify_shape_value: isPractice ? 'practicebydemo' : 'copyslide',
            is_copy: true,
            is_delete: false,
            styles: styleArr,
            data: [
                {
                    name: '',
                    type: 'table',
                    meta_data: {
                        left_position: '5500',
                        top_position: isPractice ? '13800' : '15434',
                        height: isPractice ? '100' : '3000',
                        width: isPractice ? '12300' : '8400',
                        font_size: 8,
                        font_family: 'Arial',
                        text_color: '000000',
                        bgcolor: 'FFFFFF',
                        border_color: isPractice ? '000000' : 'FFFFFF',
                        border_width: '80',
                        alignment: 'center',
                        v_alignment: 'center',
                        margin: isPractice ? [0, -1, 0, -1] : null,
                    },
                    table_data: tableDataArr,
                },
                {
                    name: 'SigChangeLegend',
                    is_delete: 'true',
                },
                {
                    name: 'QuartileLegend',
                    is_delete: 'true',
                },
                {
                    name: 'Group 4',
                    is_delete: 'true',
                },
                {
                    name: 'AutoShape 250',
                    value: isPercentile ? PPT_RES_TEXT3 : originalSub,
                },
                {
                    name: 'Checkme',
                    is_delete: 'true',
                },
                {
                    name: 'Group 23',
                    is_delete: 'true',
                },
                { name: 'OHILegend_TopDecile_Alt', style: 0 },
                { name: 'OHILegend_TopQuartile', style: 1 },
                { name: 'OHILegend_SecondQuartile', style: 2 },
                { name: 'OHILegend_ThirdQuartile', style: 3 },
                { name: 'OHILegend_BottomQuartile', style: 4 },
                {
                    name: 'HeaderShape',
                    value: isPractice
                        ? `${PRACTICE_TEXT} ${BY_TEXT} ${demograhicLabel} (${iteration}/${itemPerPage})`
                        : `${OUTCOME_TEXT} ${BY_TEXT} ${demograhicLabel} (${iteration}/${itemPerPage})`,
                },
                {
                    name: 'source',
                    value: `${SOURCE_TEXT} ${rName} (n=${items[0]?.items[0]?.score}); ${BENCHMARK_HEAD}: ${label} (n=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
                },
                ...getPptValues(GLOBAL_VALS),
                ...getPptValues(comparisonMeta),
                { name: '4. Footnote', value: ALL_PPT_TEXT[28] },
            ],
        };
    };

    const headerObject = (value, row_index, column_index, column_width = '', style = '5', rowspan = '') => {
        const obj = {
            value,
            row_index,
            column_index,
            column_width,
            style,
            is_bold: 'true',
        };
        if (rowspan) {
            obj.rowspan = rowspan;
        }
        return obj;
    };

    const dataObject = (row_index, column_index, value, style, alignment = 'center', rowspan) => {
        const obj = {
            row_index,
            column_index,
            value,
            style,
            alignment,
            is_bold: 'false',
        };
        if (rowspan) {
            obj.rowspan = rowspan;
        }
        return obj;
    };

    const getPpt = (slideArr = [], pptName = 'Demographic') => {
        const { pptAccessToken = '' } = AuthStore;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: slideArr,
        };
        getPptData(output, pptAccessToken, pptName);
    };

    const getHeatmapDemographic = () => {
        const map = new Map();
        demographics.forEach(({ code, label }) => {
            if (selectedDemographic === code) {
                map.set(code, label);
            }
        });
        return Object.fromEntries(map);
    };

    const v4PptDownload = () => {
        const { header: rankingHeader = [] } = heatMapRanking || {};
        const checkMe =
            demographicCurrentTab === OUTCOME_VAR
                ? checkme_mapping.demographic_outcome
                : checkme_mapping.demographic_practice;
        const checkmeToUse = demographicCurrentTab === PR_VAR ? ['Copy_slide_practice_rank'] : checkMe;
        const deleted_column_label = rankingHeader.filter(item => !headerToUse.includes(item));
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS,
            ...addOns,
            report_name: rName,
            ...apiParams,
            sort_by: sortBy,
            heatmap_demographic: getHeatmapDemographic(),
            report_id,
            single_page_meta: {
                checkme: checkmeToUse,
                name: 'heatmap_demographics',
            },
        };
        if (isPR && prView) {
            valueToSend.deleted_column_label = deleted_column_label;
        }
        setdwnld(true);
        getReport(valueToSend);
    };

    const getStylesArr = (isOutcome = false) => {
        const styleArr = getStylesForPpt(quartileColors);
        if (isOutcome) {
            styleArr.push({
                bgcolor: 'FFFFFF',
                text_color: '000000',
                border_color: 'FFFFFF,FFFFFF,CFCFCF,FFFFFF', // Outcome and practise header
            });
            styleArr.push({
                bgcolor: 'FFFFFF',
                text_color: '000000', // Headers other than Outcome and practice
                border_color: 'FFFFFF',
            });
            styleArr.push({
                bgcolor: 'FFFFFF',
                text_color: '000000', // practise column exclude header
                border_color: 'CFCFCF,FFFFFF,CFCFCF,A6A6A6',
                height: '1000',
            });
            styleArr.push({
                bgcolor: 'FFFFFF',
                text_color: '000000',
                border_color: 'CFCFCF', // outcome column exclude header
            });
        } else {
            styleArr.push({
                text_color: '#000000',
                bgcolor: '#ffffff',
            });
        }

        return styleArr;
    };

    const getDataForPpt = () => {
        const pptArr = [];
        const slidesArr = [];

        const { header: headerArr = [], items: itemsArr = [] } = heatMapData;
        headerArr.unshift('');
        headerArr.forEach((val, i) => {
            pptArr.push(headerObject(val, 0, i, i === 0 ? '18000' : ''));
        });
        const itemPerPage = Math.ceil(itemsArr.length / 13);
        let min = 0;
        const initialMax = 13;
        let max = initialMax;
        // eslint-disable-next-line no-plusplus
        for (let iteration = 1; iteration <= itemPerPage; iteration++) {
            min = iteration === 1 ? 0 : max;
            max = iteration === 1 ? max : max + initialMax;
            const dataArr = [];
            // append first row
            if (min !== 0) {
                const { title = '', items: subItems = [] } = itemsArr[0] || [];
                dataArr.push(dataObject(1, 0, title, '5', 'left'));
                subItems.forEach(({ quartile = '', score = '' }, itemIndex) => {
                    const scoreToSend = score === '' ? '-' : score;
                    dataArr.push(dataObject(1, itemIndex + 1, scoreToSend, itemIndex === 0 ? '5' : quartile));
                });
            }
            // eslint-disable-next-line array-callback-return
            itemsArr.slice(min, max).forEach(({ title, items: subItems = [] }, itemIndex) => {
                dataArr.push(dataObject(iteration === 1 ? itemIndex + 1 : itemIndex + 2, 0, title, '5', 'left'));
                // eslint-disable-next-line array-callback-return
                subItems.forEach(({ quartile, score }, index) => {
                    const scoreToSend = score === '' ? '-' : score;
                    dataArr.push(
                        dataObject(
                            iteration === 1 ? itemIndex + 1 : itemIndex + 2,
                            index + 1,
                            scoreToSend,
                            index === 0 ? '5' : quartile
                        )
                    );
                });
            });
            slidesArr.push(slidesArrayObject(getStylesArr(), pptArr.concat(dataArr), iteration, itemPerPage, false));
        }

        getPpt(
            [...slidesArr, ...removeIntitalSlide()],
            `${report_name} ${selectedDemographic} ${OUTCOME_TEXT} HeatMap`
        );
    };

    const getDataForPpt2 = () => {
        const slidesArr = [];
        const { header: headerArr = [], items: itemsArr = [] } = heatMapType;
        // tmp changes
        const getPageNo = Math.ceil((headerArr.length - 1) / 5);
        const itemPerPage = getPageNo === 0 ? 1 : getPageNo;
        let min = 0;
        const initialMax = 5;
        let max = initialMax;
        let arr = [];
        // eslint-disable-next-line no-plusplus
        for (let iteration = 1; iteration <= itemPerPage; iteration++) {
            min = iteration === 1 ? 1 : max;
            max = iteration === 1 ? max + 1 : max + initialMax;
            const dataArr = [];
            const pptArr = [];
            pptArr.push({
                value: OUTCOMES,
                row_index: '0',
                column_index: '0',
                column_width: '15000',
                style: '5',
                rowspan: '2',
                alignment: 'left',
                v_alignment: 'top',
            });
            pptArr.push({
                row_index: 0,
                column_index: 1,
                value: PRACTICES,
                column_width: '21000',
                style: '5',
                rowspan: 2,
                alignment: 'left',
                v_alignment: 'top',
            });
            pptArr.push(headerObject(headerArr[0], 0, 2, '', '6'));
            headerArr.slice(min, max).forEach((val, i) => {
                pptArr.push(headerObject(val, 0, iteration === 1 ? i + 3 : i + 3, '', '6'));
            });
            const obj = {};
            itemsArr
                .filter(({ parent_name }) => parent_name)
                .forEach(({ parent_name = '' }) => {
                    if (obj[parent_name]) {
                        obj[parent_name] += 1;
                    } else {
                        obj[parent_name] = 1;
                    }
                });
            // eslint-disable-next-line no-loop-func
            itemsArr.forEach(({ parent_name = '', title = '', items: subItems = [] }, itemIndex) => {
                const { quartile: quar, score: scoreVal } = subItems[0] || [];
                const scoreToSendVal = scoreVal === '' ? '-' : scoreVal;
                dataArr.push(dataObject(itemIndex + 1, 2, scoreToSendVal, itemIndex === 0 ? '5' : quar || '6'));
                if (arr.indexOf(parent_name) > -1) {
                    dataArr.push(dataObject(itemIndex + 1, 1, title, '7', 'left'));
                    subItems.slice(min, max).forEach(({ quartile, score }, index) => {
                        const scoreToSend = score === '' ? '-' : score;
                        dataArr.push(
                            dataObject(
                                itemIndex + 1,
                                iteration !== 1 ? index + 3 : index + 3,
                                scoreToSend,
                                quartile || '6'
                            )
                        );
                    });
                } else if (parent_name) {
                    arr.push(parent_name);
                    dataArr.push(dataObject(itemIndex + 1, 0, parent_name, '8', 'left', obj[parent_name]));
                    dataArr.push(dataObject(itemIndex + 1, 1, title, '7', 'left'));
                    subItems.slice(min, max).forEach(({ quartile, score }, index) => {
                        const scoreToSend = score === '' ? '-' : score;
                        dataArr.push(
                            dataObject(
                                itemIndex + 1,
                                iteration !== 1 ? index + 3 : index + 3,
                                scoreToSend,
                                quartile || '6'
                            )
                        );
                    });
                } else if (title !== N_SIZE) {
                    dataArr.push(dataObject(itemIndex + 1, 0, title, '5', 'left'));
                    subItems.slice(min, max).forEach(({ quartile, score }, index) => {
                        const scoreToSend = score === '' ? '-' : score;
                        dataArr.push(
                            dataObject(
                                itemIndex + 1,
                                iteration !== 1 ? index + 2 : index + 2,
                                scoreToSend,
                                quartile || '6'
                            )
                        );
                    });
                } else {
                    // eslint-disable-next-line array-callback-return
                    subItems.slice(min, max).map(({ score }, index) => {
                        const scoreToSend = score === '' ? '-' : score;
                        dataArr.push(
                            dataObject(itemIndex + 1, iteration !== 1 ? index + 3 : index + 3, scoreToSend, '5')
                        );
                    });
                }
            });
            arr = [];
            slidesArr.push(slidesArrayObject(getStylesArr(true), pptArr.concat(dataArr), iteration, itemPerPage, true));
        }
        getPpt(
            [...slidesArr, ...removeIntitalSlide(true)],
            `${report_name} ${selectedDemographic} ${PRACTICE_TEXT} HeatMap`
        );
    };

    const checkSelectDisabled = () => {
        const practiseResult = demographicCurrentTab === PRACTICE_VAR ? isPracticeRetrived : false;
        const result = demographicCurrentTab === OUTCOME_VAR ? isOutcomeRetrieved : practiseResult;
        return result;
    };

    const getHeapMapValues = itemsToIterate => {
        return itemsToIterate.map(({ title: rowTitle = '', items: subItems = [] }, itemIndex) => {
            const { quartile = '', score = '' } = subItems[0] || {};
            const { background: bcground = colors.$white, border: border0 = '' } = quartileColors[quartile] || {};
            const title = manipulateHeatmapTitle(rowTitle);
            return (
                <div className="sectionDiv" key={`${title}${itemIndex}`}>
                    <ul>
                        <Tooltip placement="top" arrow title={title} aria-label={title}>
                            <li className="sectionContent">{title}</li>
                        </Tooltip>
                        <li
                            className={c({ bold: !quartileColors[quartile] }, 'contentList firstCol')}
                            key={score}
                            style={{
                                border:
                                    quartile === '0' || quartile === 0
                                        ? '1px solid rgb(255, 216, 0)'
                                        : '1px solid #f2f2f2',
                                background: bcground,
                                color: quartileColors[quartile] ? quartileColors[quartile].color : colors.$black100,
                            }}
                        >
                            {isOhi4 && (
                                <div
                                    className="triangle"
                                    style={{
                                        borderWidth: '3vh 3vh 0 0',
                                        borderColor: `${border0} transparent transparent transparent`,
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        borderStyle: 'solid',
                                    }}
                                />
                            )}
                            {!isNaN(parseInt(score, 10)) ? score.toLocaleString('en-US') : '-'}
                        </li>
                        {subItems
                            .slice(minSliceValue, maxSliceValue)
                            .map(({ score: innerScore, quartile: innerQuartile }, index) => {
                                const background = quartileColors[innerQuartile]
                                    ? quartileColors[innerQuartile].background
                                    : colors.$white;
                                const color = quartileColors[innerQuartile]
                                    ? quartileColors[innerQuartile].color
                                    : colors.$black100;
                                const borderColor = quartileColors[innerQuartile]
                                    ? quartileColors[innerQuartile].border
                                    : colors.$white;
                                return (
                                    <>
                                        {isOhi4 ? (
                                            <li
                                                className={c({ bold: color === colors.$black100 }, 'contentList')}
                                                key={`${innerScore}${index}${Math.random()}`}
                                                style={{
                                                    border: '1px solid #f2f2f2',
                                                    background,
                                                    color,
                                                }}
                                            >
                                                <div>
                                                    <div
                                                        className="triangle"
                                                        style={{
                                                            borderWidth: '3vh 3vh 0 0',
                                                            borderColor: `${borderColor} transparent transparent transparent`,
                                                            position: 'absolute',
                                                            top: '0',
                                                            left: '0',
                                                            borderStyle: 'solid',
                                                        }}
                                                    />
                                                    {!isNaN(parseInt(innerScore, 10)) ? innerScore : '-'}
                                                </div>
                                            </li>
                                        ) : (
                                            <li
                                                className={c({ bold: color === colors.$black100 }, 'contentList')}
                                                key={`${innerScore}${index}${Math.random()}`}
                                                style={{
                                                    border:
                                                        innerQuartile === '0' || innerQuartile === 0
                                                            ? '1px solid rgb(255, 216, 0)'
                                                            : '1px solid #f2f2f2',
                                                    background,
                                                    color,
                                                }}
                                            >
                                                {!isNaN(parseInt(innerScore, 10)) ? innerScore : '-'}
                                            </li>
                                        )}
                                    </>
                                );
                            })}
                    </ul>
                </div>
            );
        });
    };

    const getRankingValues = itemsToIterate => {
        const itemLength = itemsToIterate.length;
        if (prView) {
            return (
                <PracticeRankBoard
                    items={items}
                    itemLength={itemLength}
                    maxSliceValue={maxSliceValue}
                    minSliceValue={minSliceValue}
                    scroll={scroll}
                />
            );
        }
        return itemsToIterate.map(({ title: rowTitle = '', items: subItems = [] }, itemIndex) => {
            const { score = '' } = subItems[0] || {};
            const title = manipulateHeatmapTitle(rowTitle);
            const color = score > itemLength - 12 ? 'red' : '#000000';
            const colorToUse = score < 13 ? '#0679C3' : color;
            const bg = colorToUse === '#0679C3' ? '#F2F2F2' : '#FEEBEB';
            const isLevel = itemIndex === 12 || itemIndex === itemLength - 12;
            const borderStyle = isLevel ? { borderTop: '1px solid #CCCCCC' } : {};
            const id = itemIndex === 12 ? 'mid' : '';
            const idToUse = itemIndex === itemLength - 12 ? 'bottom' : id;
            return (
                <div className="sectionDiv" key={`${title}${itemIndex}`} style={borderStyle}>
                    <ul ref={scroll === itemIndex ? scrollIndex : null} id={idToUse}>
                        <Tooltip placement="top" arrow title={title} aria-label={title}>
                            <li
                                className="sectionContent"
                                style={{ color: colorToUse, fontWeight: colorToUse === '#0679C3' ? 500 : 400 }}
                            >
                                {title}
                            </li>
                        </Tooltip>
                        <li
                            className="contentList firstCol"
                            key={score}
                            style={{
                                border: `1px solid ${prSortIndex === -1 ? '#C1D3E4' : '#f2f2f2'}`,
                                background: '#fffffff',
                                color: colorToUse,
                                fontWeight: colorToUse === '#0679C3' ? 500 : 400,
                            }}
                        >
                            <span
                                style={{
                                    background: colorToUse === '#000000' ? '#ffffff' : bg,
                                }}
                                className="rankBg"
                            >
                                {!isNaN(parseInt(score, 10)) ? score.toLocaleString('en-US') : '-'}
                            </span>
                        </li>
                        {subItems.slice(minSliceValue, maxSliceValue).map(({ score: innerScore }, index) => {
                            const innerColor = innerScore > itemLength - 12 ? 'red' : '#000000';
                            const innerColorToUse = innerScore < 13 ? '#0679C3' : innerColor;
                            const innerbg = innerColorToUse === '#0679C3' ? '#F2F2F2' : '#FEEBEB';
                            return (
                                <li
                                    className="contentList"
                                    key={`${innerScore}${index}${Math.random()}`}
                                    style={{
                                        border: `1px solid ${
                                            prSortIndex === index + minSliceValue - 1 ? '#C1D3E4' : '#f2f2f2'
                                        }`,
                                        color: innerColorToUse,
                                        fontWeight: innerColorToUse === '#0679C3' ? 500 : 400,
                                    }}
                                >
                                    <span
                                        style={{
                                            background: innerColorToUse === '#000000' ? '#ffffff' : innerbg,
                                        }}
                                        className="rankBg"
                                    >
                                        {innerScore}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        });
    };

    const getInclusionValues = () => {
        return mainKeys.map(innerKey => {
            const { records: innerRec = [], display_name: title = '', size } = qbyq[innerKey];
            return (
                <div className="sectionDiv" key={innerKey}>
                    <ul>
                        <Tooltip placement="top" arrow title={title} aria-label={title}>
                            <li
                                className="sectionContent"
                                dangerouslySetInnerHTML={{
                                    __html: title,
                                }}
                            />
                        </Tooltip>
                        <li className="contentList firstCol">{size}</li>
                        {innerRec.map(({ score }, index) => {
                            const lowScore = score < 50 ? 0 : 1;
                            const indexInUse = score > 75 ? 2 : lowScore;
                            const { background } = DEMO_INCLUSION_LEGENDS[indexInUse];
                            return (
                                <li
                                    style={{ background, border: '1px solid #ffffff' }}
                                    className="contentList"
                                    key={`${innerKey}${index}`}
                                >
                                    {score}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        });
    };

    const setInclusionDownload = () => {
        setSelectedDownload('excel');
        setTimeout(() => setSelectedDownload(''));
    };

    const selectProject = (selectedProjectIndex, heatMapDemo) => {
        if (selectedProjectIndex !== oldIndex || heatMapDemo !== oldDemo) {
            updateHeatMapInfo({
                selectedProjectIndex,
                heatMapDemo,
            });
        }
        if (selectedProjectIndex !== oldIndex) {
            const { ohid: newId, year: newYear } = projects[selectedProjectIndex];
            getDemographics({ ohid: newId, lang: parseInt(lang, 10) }, 1);
            getBenchmarks({ ohid: newId, year: newYear, lang }, 1, benchmark);
        }
        setReSurveyModal(false);
        setReport(1);
    };

    const handleDwnld = code => {
        setSelectedDownload(code);
        if (demographicCurrentTab === OUTCOME_VAR && code === 'ppt' && !isOhi4) {
            getDataForPpt();
        } else if (demographicCurrentTab === PRACTICE_VAR && code === 'ppt' && !isOhi4) {
            getDataForPpt2();
        } else if (isOhi4 && code === 'ppt') {
            v4PptDownload();
        }
        showDwnldDrop(false);
    };

    const hidePptDwnld = isNonProfit || (isPR && !prView);

    return (
        <div className="demographicBoard clearfix">
            <Helmet>
                <title>
                    {HEALTH_DEMOGRAPHIC} {demographicCurrentTab} {ADDONS_PAGE_TEXT['4']}
                </title>
            </Helmet>
            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="mainHeading">{DEMOGRAPHIC}</div>
                    {isOhi4 && (
                        <div className="nRes">
                            {NUMBER_OF_RESPONDENTS_N}: <span>{resNsize.toLocaleString('en-US')}</span>
                        </div>
                    )}
                </div>
                <ul className="navScoreList">
                    {demographicCurrentTab !== PR_VAR && (
                        <li
                            data-testid="setReSurveyModalBtn"
                            onClick={() => setReSurveyModal(true)}
                            className="fImage resurveyImg"
                        >
                            <img src={BulletList} alt="" />
                            <span className="upperHead">{RESURVEY_TEXT}</span>
                        </li>
                    )}
                    {!isInclusion && !isCfgClient && items.length ? (
                        <li
                            data-testid="getPptBtn"
                            onClick={() => showDwnldDrop(!dwnldDrop)}
                            className="resurveyImg dwnld"
                            disabled={checkSelectDisabled()}
                        >
                            <img src={Down} alt="" />
                            <span className="lineHead upperHead">{DOWNLOAD}</span>
                            {dwnldDrop && (
                                <div className="tabsContent" onPointerLeave={() => showDwnldDrop(false)}>
                                    {!hidePptDwnld && (
                                        <span data-testid="valuesBtn" onClick={() => handleDwnld('ppt')}>
                                            <span>
                                                <img src={Pdf} alt="" />
                                            </span>
                                            {DOWNLOAD_PPT}
                                        </span>
                                    )}
                                    <span data-testid="commentsBtn" onClick={() => handleDwnld('excel')}>
                                        <span>
                                            <img src={Xlsx} alt="" />
                                        </span>
                                        {DOWNLOAD_EXCEL}
                                    </span>
                                </div>
                            )}
                        </li>
                    ) : null}
                    {isPR && (
                        <li className="toggleBtn separator">
                            <div
                                className={c({ overallView: prView })}
                                onClick={() => {
                                    togglePRView(!prView);
                                    setScroll(-2);
                                }}
                            >
                                <div data-testid="hideGapBtn" className={c({ isActive: !prView })} />
                                <div data-testid="showGapBtn" className={c({ isActive: prView })} />
                            </div>
                            <div className="tagName">{PRACTICE_NAME_VIEW}</div>
                        </li>
                    )}
                    <li onClick={() => toggleFilterView(true)} data-testid="filterButton" className="lineHead">
                        <img src={activeFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg} alt="" />
                        <span className="filter">{FILTERS}</span>
                    </li>
                </ul>
            </div>
            {appliedFiltersInfo()}
            <div className="optionWrapper">
                <div>
                    <ul className="leftNav">
                        <li
                            data-testid={OUTCOME_VAR}
                            className={c({ activeLink: demographicCurrentTab === OUTCOME_VAR })}
                            onClick={() => handleTabData(OUTCOME_VAR)}
                        >
                            {OUTCOMES}
                        </li>
                        <li
                            data-testid={PRACTICE_VAR}
                            className={c({ activeLink: demographicCurrentTab === PRACTICE_VAR })}
                            onClick={() => handleTabData(PRACTICE_VAR)}
                        >
                            {PRACTICES}
                        </li>
                        {isOhi4 && !pulseSurvey && (
                            <li
                                data-testid={PR_VAR}
                                className={c({ activeLink: demographicCurrentTab === PR_VAR })}
                                onClick={() => handleTabData(PR_VAR)}
                            >
                                {PR}
                            </li>
                        )}
                        {ohid === DEMO_INLUSION_OHID && (
                            <li
                                data-testid={INCLUSION_VAR}
                                className={c({ activeLink: demographicCurrentTab === INCLUSION_VAR })}
                                onClick={() => handleTabData(INCLUSION_VAR)}
                            >
                                {INCLUSION_VAR}
                            </li>
                        )}
                    </ul>
                </div>
                <div className="rightOptions">
                    <span>
                        <Select
                            IconComponent={KeyboardArrowDownSharpIcon}
                            value={selectedDemographic}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={({ target: { value: code } }) => {
                                setMaxSliceValue(maxVal);
                                setMinSliceValue(SLICE_MIN_VALUE);
                                setShowLeftArrow(false);
                                setSelectedDemographic(code);
                                callHeatMap(code, demographicCurrentTab);
                                setSortOrder({});
                                setOutcomePageNo(1);
                                setPracticePageNo(1);
                                setRankingPageNo(1);
                                if (isPR) {
                                    handleCohesionData(code);
                                    setSortIndex(-1);
                                }
                            }}
                        >
                            {demographics.map(({ label, code }) => {
                                return (
                                    <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </span>
                    <span>
                        {mainKeys.length && isInclusion ? (
                            <span
                                className="downloadInclusion"
                                data-testid="downloadInclusion"
                                onClick={setInclusionDownload}
                            >
                                <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                            </span>
                        ) : null}
                    </span>
                    {selectedDownload === 'excel' ? (
                        <Excel
                            benchmarks={benchmarks}
                            apiParams={apiParams}
                            demographicCurrentTab={demographicCurrentTab}
                            demographics={demographics}
                            items={isInclusion ? qbyq : items}
                            header={headerToUse}
                            defaultSettings={defaultSettings}
                            selectedDemographic={selectedDemographic}
                            isInclusion={isInclusion}
                            isPR={demographicCurrentTab === PR_VAR}
                        />
                    ) : null}
                </div>
            </div>
            {isPR ? (
                <ul className={c('headContent', 'headList', 'cardsList')} ref={scroll === -1 ? scrollIndex : null}>
                    <li data-testid="headContent0" className="cardList">
                        <h3>{cohesionScore}</h3>
                        <div className="ellipsisPro">
                            <span className="scoreContent">{COHESION_SCORE}</span>
                        </div>
                    </li>
                </ul>
            ) : null}
            {prView && (
                <div
                    onClick={() => {
                        setRankingData({ header: heatMapRanking.header, items: heatMapRanking.items });
                    }}
                    className="reloadBtn"
                >
                    <span>
                        <img src={Reload} alt="" />
                    </span>
                    RESET
                </div>
            )}
            {isPR ? (
                <div className={c('sideScrolling', { stickyBtn: isSticky, prView })}>
                    <div className={c('rankingScroll', { active: activeBtn === -1 })} onClick={() => handleScroll(-1)}>
                        <div className="ranking">{TOP_RANKING}</div>
                    </div>
                    <div className={c('rankingScroll', { active: activeBtn === 11 })} onClick={() => handleScroll(11)}>
                        <div className="ranking">{MIDDLE_RANKING}</div>
                    </div>
                    <div
                        className={c('rankingScroll', { active: activeBtn === items.length - 13 })}
                        onClick={() => handleScroll(items.length - 13)}
                    >
                        <div className="ranking">{BOTTOM_RANKING}</div>
                    </div>
                </div>
            ) : null}
            <section className="sectionWrapper">
                <div
                    className={c('headerWrapper clearfix', {
                        fixedHeader: isSticky,
                        adjustHeader: toolOpen && isSticky,
                        prViewHeader: prView,
                    })}
                >
                    {showLeftArrow ? (
                        <div
                            data-testid="arrowLeft"
                            className="arrow"
                            onClick={() => {
                                setMaxSliceValue(minSliceValue);
                                setMinSliceValue(minSliceValue - (maxVal - SLICE_MIN_VALUE));
                                setShowRightArrow(maxSliceValue >= maxVal);
                                setShowLeftArrow(minSliceValue > maxVal);
                            }}
                        >
                            <img className="imgClass" src={ArrowLeft} alt="" />
                        </div>
                    ) : null}

                    <div>
                        {headerToUse.length ? (
                            <ul className={c({ tabLeft: demographicCurrentTab === OUTCOME_VAR })}>
                                {isInclusion && <li className="headerList firstCol">{N_SIZE}</li>}
                                {isPR && prView && <li className="headerList ranking firstCol">{RANK}</li>}
                                <Tooltip
                                    key={headerToUse[0]}
                                    placement="top"
                                    arrow
                                    title={headerToUse[0] || ''}
                                    aria-label={headerToUse[0]}
                                >
                                    {isInclusion ? (
                                        <li
                                            className="headerList firstCol"
                                            dangerouslySetInnerHTML={{
                                                __html: headerToUse[0],
                                            }}
                                        />
                                    ) : (
                                        <li
                                            className={c(
                                                { bold: prSortIndex === -1, firstCol: !(isPR && prView) },
                                                'headerList'
                                            )}
                                            data-testid="headerList"
                                            onClick={() => {
                                                if (!isPR) {
                                                    sortData(header[0]);
                                                } else {
                                                    sortPRData(-1);
                                                }
                                            }}
                                        >
                                            {header[0]?.length > MAX_LENGTH_SUBSTRING
                                                ? `${header[0].substring(0, MAX_LENGTH_SUBSTRING) + '...'}`
                                                : header0}
                                            {header[0] === N_SIZE ? (
                                                <img
                                                    className="arrowSort"
                                                    src={sortOrder[header[0]] === 1 ? ArrowUp : ArrowDown}
                                                    alt=""
                                                />
                                            ) : null}
                                            {isPR && !prView && <img className="arrowSort" src={UpDown} alt="" />}
                                        </li>
                                    )}
                                </Tooltip>
                                {headerToUse &&
                                    headerToUse.slice(minSliceValue, maxSliceValue).map((val, i) => {
                                        return (
                                            <Tooltip
                                                key={`${val}${i}`}
                                                placement="top"
                                                arrow
                                                title={val}
                                                aria-label={val}
                                            >
                                                <li
                                                    className={c(
                                                        { bold: i === prSortIndex - minSliceValue + 1 },
                                                        'headerList'
                                                    )}
                                                    onClick={() => {
                                                        if (!isPR) {
                                                            sortData(val);
                                                        } else {
                                                            sortPRData(i + minSliceValue - 1);
                                                        }
                                                    }}
                                                >
                                                    {val.length > MAX_LENGTH_SUBSTRING && !isInclusion
                                                        ? `${val.substring(0, MAX_LENGTH_SUBSTRING) + '...'}`
                                                        : val}
                                                    {val === OHI_SCORE_TEXT && !isInclusion && (
                                                        <img
                                                            className="arrowSort"
                                                            src={sortOrder[val] === 1 ? ArrowUp : ArrowDown}
                                                            alt=""
                                                        />
                                                    )}
                                                    {isPR && (
                                                        <img
                                                            className="arrowSort"
                                                            src={prView ? Close : UpDown}
                                                            alt=""
                                                        />
                                                    )}
                                                </li>
                                            </Tooltip>
                                        );
                                    })}
                            </ul>
                        ) : null}
                    </div>
                    {showRightArrow ? (
                        <div
                            data-testid="arrowRight"
                            className="arrow arrowRight"
                            onClick={() => {
                                setMinSliceValue(maxSliceValue);
                                setMaxSliceValue(maxSliceValue + maxVal - 1);
                                setShowRightArrow(maxSliceValue + maxVal <= header.length);
                                setShowLeftArrow(maxSliceValue + maxVal > maxVal);
                            }}
                        >
                            <img className="imgClass" src={ArrowRight} alt="" />
                        </div>
                    ) : null}
                </div>
                {status === 400 || (heatMapInclusionError && isInclusion) ? (
                    <ErrorToast message={message || heatMapInclusionError} />
                ) : null}
                <div className="contentWrapper clearfix">
                    <div className={c({ prView }, 'mainContent')} id="myHeader">
                        {demographicCurrentTab === PR_VAR ? (
                            <Fragment>{getRankingValues(items)}</Fragment>
                        ) : (
                            <Fragment>{isInclusion ? getInclusionValues() : getHeapMapValues(items)}</Fragment>
                        )}
                    </div>
                </div>
            </section>
            {items.length && !isInclusion && !isPR ? (
                <Fragment>
                    {isOhi4 ? (
                        <ul className="benchmark clearfix">
                            <li>{BENCHMARK_HEAD} : </li>
                            {quartileColors
                                .slice()
                                .reverse()
                                .map(({ title, background, border: borderColor }) => {
                                    const bottomDecileVar = title === 'Bottom decile';
                                    const BenchmarkTitle = title === 'No benchmarks';

                                    return (
                                        <li
                                            key={title}
                                            style={{ color: background }}
                                            className={c({
                                                hide: bottomDecileVar || BenchmarkTitle,
                                            })}
                                        >
                                            <span
                                                className="benchmarkTriangle"
                                                style={{
                                                    background,
                                                    borderWidth: '13px 13px 0 0',
                                                    borderColor: bottomDecileVar
                                                        ? `transparent ${borderColor} transparent transparent`
                                                        : `${borderColor} transparent transparent transparent`,
                                                    borderStyle: 'solid',
                                                }}
                                            />
                                            <div style={{ color: 'black' }}>{title}</div>
                                        </li>
                                    );
                                })}
                        </ul>
                    ) : (
                        <ul className="benchmark clearfix">
                            <li>{BENCHMARK_HEAD} : </li>
                            {quartileColors
                                .slice()
                                .reverse()
                                .map(({ title, background, border: borderColor }) => {
                                    const bottomDecileVar = title === 'Bottom decile';
                                    const BenchmarkTitle = title === 'No benchmarks';
                                    return (
                                        <li
                                            key={title}
                                            style={{ color: background }}
                                            className={c({
                                                hide: bottomDecileVar || BenchmarkTitle,
                                            })}
                                        >
                                            <span
                                                className="benchmarkTriangle"
                                                style={{
                                                    background,
                                                    borderWidth: '13px 13px 0 0',
                                                    border: borderColor,
                                                    borderStyle: 'solid',
                                                }}
                                            />
                                            <div style={{ color: 'black' }}>{title}</div>
                                        </li>
                                    );
                                })}
                        </ul>
                    )}
                    <div className="note">
                        <b>{NOTE}:</b>
                        <br />
                        1. {`${DISCLAIMER_DEMO_PART1} < ${threshold} ${DISCLAIMER_DEMO_PART2}`}
                        <br />
                        {surveyType === 'percent favorable' && <div>2. {PERCENT_FAVORABLE}</div>}
                        {surveyType === 'percentile' && <div>2. {PERCENTILE}</div>}
                    </div>
                </Fragment>
            ) : null}
            {mainKeys.length && isInclusion ? (
                <Fragment>
                    <ul className="benchmark clearfix">
                        <li>{BENCHMARK_HEAD} : </li>
                        {DEMO_INCLUSION_LEGENDS.map(({ title, background }) => (
                            <li key={title}>
                                <span style={{ background, borderColor: background }} />
                                {title}
                            </li>
                        ))}
                    </ul>
                    {items.length ? appliedFiltersInfo() : null}
                    <div className="note">{DISCLAIMER_DEMO_PART3}</div>
                </Fragment>
            ) : null}
            {items.length && isPR ? (
                <Fragment>
                    <ul className="benchmark clearfix prView">
                        <li>{BENCHMARK_HEAD} : </li>
                        {PR_HEATMAP_LEGENDS.map(({ title, background }) => (
                            <li key={title}>
                                <span style={{ background, borderColor: background }} />
                                {title}
                            </li>
                        ))}
                    </ul>
                    {/* <div className="note">{DISCLAIMER_DEMO_PART3}</div> */}
                </Fragment>
            ) : null}

            {showReSurveyModal && (
                <SelectModal
                    defaultSettings={defaultSettings}
                    oldSelectedId={oldIndex}
                    oldSelectedDemo={oldDemo}
                    projects={projects}
                    projectsFetched
                    closeModal={setReSurveyModal}
                    selectProject={selectProject}
                    demographics={demographics}
                    current_ohid={ohid}
                    apiLoadingCount={apiLoadingCount}
                    heatMapResurvey
                    getMappedDemographics={getMappedDemographics}
                />
            )}
            {dwnld && !reportError && (
                <DwnldModal
                    getRawPptData={getRawPptData}
                    reportId={report_id}
                    open={dwnld}
                    setOpen={setdwnld}
                    getSinglePastReport={getSinglePastReport}
                    singlePastReport={singlePastReport}
                    defaultSettings={defaultSettings}
                />
            )}
        </div>
    );
}

DemographicBoard.propTypes = {
    filters: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    heatMapData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    heatMapPractice: PropTypes.object.isRequired,
    apiParams: PropTypes.object.isRequired,
    demographicCurrentTab: PropTypes.string.isRequired,
    selectedDemographic: PropTypes.string.isRequired,
    heatMapPracticeFetched: PropTypes.bool.isRequired,
    toolOpen: PropTypes.bool.isRequired,
    isOutcomeRetrieved: PropTypes.bool.isRequired,
    heatMapDataFetched: PropTypes.bool.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    isPracticeRetrived: PropTypes.bool.isRequired,
    loadHeatMapPractice: PropTypes.bool.isRequired,
    loadHeatMapOutcome: PropTypes.bool.isRequired,
    practicePageNo: PropTypes.number.isRequired,
    outcomePageNo: PropTypes.number.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    heatMapInclusion: PropTypes.object.isRequired,
    heatMapInclusionFetched: PropTypes.bool.isRequired,
    heatMapInclusionError: PropTypes.string.isRequired,
    sortHeatMapOutcome: PropTypes.func.isRequired,
    setDemographicCurrentTab: PropTypes.func.isRequired,
    getHeatMapOutcome: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    setSelectedDemographic: PropTypes.func.isRequired,
    getHeatMapPractice: PropTypes.func.isRequired,
    setHeatMapOutcomeData: PropTypes.func.isRequired,
    setHeatMapPracticeData: PropTypes.func.isRequired,
    setPracticePageNo: PropTypes.func.isRequired,
    setOutcomePageNo: PropTypes.func.isRequired,
    updateHeatMapPractice: PropTypes.func.isRequired,
    updateHeatMapOutcome: PropTypes.func.isRequired,
    getHeatMapInclusion: PropTypes.func.isRequired,
    getMappedDemographics: PropTypes.func.isRequired,
    updateHeatMapInfo: PropTypes.func.isRequired,
    setReport: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    heatMapResurveyInfo: PropTypes.object.isRequired,
    isCfgClient: PropTypes.bool.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    heatMapRankingFetched: PropTypes.bool.isRequired,
    heatMapRanking: PropTypes.object.isRequired,
    getRankingHeatMap: PropTypes.func.isRequired,
    updateHeatMapRanking: PropTypes.func.isRequired,
    loadHeatMapRanking: PropTypes.bool.isRequired,
    rankingPageNo: PropTypes.number.isRequired,
    setRankingPageNo: PropTypes.func.isRequired,
    setHeatMapRankingData: PropTypes.func.isRequired,
    cohesionScore: PropTypes.number.isRequired,
    getCohesionData: PropTypes.func.isRequired,
    setCohesionDemographic: PropTypes.func.isRequired,
    cohesionDataFetched: PropTypes.bool.isRequired,
    // scoreData: PropTypes.object.isRequired,
};

export default DemographicBoard;
