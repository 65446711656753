import React, { useEffect, useRef, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import isEqual from 'lodash/isEqual';

import { cloneDeep } from 'lodash';
import { Tooltip, Button } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import AuthStore from '../../common/AuthStore';
import Loader from '../../components/Loader';
import Layout from '../../components/Layout';
import ReportContent from './ReportContent';
import PastReports from './PastReports';
import Edit from './assets/Edit.svg';
import CircleCheck from './assets/CircleCheck.svg';
// import Refresh from './assets/Refresh.svg';
// import Close from '../../assets/Close.svg';

import { getStaticText } from '../../utils/constants';
import { NAV_LINKS } from './constants';
import './index.scss';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function Reports({
    apiLoadingCount,
    apiParams,
    scoreDataFetched,
    scoreData,
    inclusionData,
    getScoreData,
    projectsFetched,
    getProjectsData,
    getPastReport,
    reportApiCount,
    currentTab,
    setCurrentTab,
    respondent,
    updateRespondents,
    getInclusionData,
    inclusionDataFetched,
    defaultSettings,
    reportApiParams,
    updatePreviousRespondent,
    previousRespondent,
    getAllGapData,
    dailyResponses,
    dailyResponsesFetched,
    getDailyResponses,
    resRateApiCount,
    pastReport,
    getMultiPptData,
    updatedPastRpts,
    updateCompPrevRespondent,
    previousCompRespondent,
}) {
    const {
        full_inclusion_access = false,
        inclusion_threshold = 10,
        lang,
        rr_survey_type = 1,
        engagement = '',
        survey_version = '',
    } = defaultSettings;
    const staticText = getStaticText(lang);
    const {
        INCLUSION_DEFAULT_BENCHMARK_STR,
        SITE_TEXT,
        NON_PROFIT_SURVEY,
        ING_REPORT_ID,
        AMMEGA_OHI_ID,
        THOMSON_OHI_ID,
    } = staticText;
    const { options: reportOptions, additionalSettings, ohid: currentOhid = '' } = reportApiParams;
    const { ReportType } = reportOptions;
    const isInclusionResurvey = ReportType === '6';
    const {
        REPORTS,
        GENERATE_REPORT,
        PAST_REPORT,
        REFRESH_REPORTS,
        SURVEY_SENT,
        SURVEY_COMPLETED,
        COMPLETED,
        INCLUSION_COMPLETED,
        PREVIOUS_SURVEY,
        CURRENT_SURVEY,
        REPORT_NAV_LINKS,
        REPORT_NAV_LINKS_V4,
    } = SITE_TEXT;

    const [currentMenu, setCurrentMenu] = useState(0);
    const [isDisabled, setIsDisabled] = useState(true);
    const [pastDwnld, setPastDwnld] = useState(true);
    const [sideMenu, setSideMenu] = useState(1);
    const [ohidArr, setOhidArr] = useState([]);
    const [isLastMenuPage, setLastMenu] = useState(false);
    const [tooglePdfReport, setTooglePdfReport] = useState(false);
    const [sentSurvey, setSentSurvey] = useState('');
    const { qbyq: inclusionqbyq = {} } = inclusionData || {};
    const { size: inclusionSize = 0 } = inclusionqbyq;
    const { qbyq = {} } = scoreData || {};
    const { size = 0 } = qbyq;
    const { report_id = '', pptAccessToken = '' } = AuthStore;
    const { records = [] } = pastReport || {};
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const isIngClient = report_id === ING_REPORT_ID;
    const isAmmegaRpt = currentOhid === AMMEGA_OHI_ID;
    const isThomsonRpt = currentOhid === THOMSON_OHI_ID;
    const surveySent = isNonProfit ? respondent : sentSurvey;
    const percentage = Math.round(((tooglePdfReport ? inclusionSize : size) / surveySent) * 100);
    const isv4 = survey_version === '3_2' || survey_version === '4';
    const prevAmount = usePrevious(apiParams);

    const { benchmarks: oldBench, filters: oldFilters } = prevAmount || {};

    const getOhid = () => {
        const ohidArray = [];
        ohidArray.push(currentOhid);
        additionalSettings.forEach(({ surveyType }) => {
            ohidArray.push(surveyType.split('+')[0]);
        });
        setOhidArr(ohidArray);
        return ohidArray;
    };

    const getMultipleExport = () => {
        const ids = records
            .filter(({ isSelected = false, is_json_created }) => is_json_created && isSelected)
            .map(({ id }) => id);
        getMultiPptData({ ids, pptAccessToken, report_name: report_id });
    };

    useEffect(() => {
        if (records.filter(({ isSelected = false }) => isSelected).length) {
            setPastDwnld(true);
        } else {
            setPastDwnld(false);
        }
        // eslint-disable-next-line
    }, [pastReport]);

    useEffect(() => {
        if (!projectsFetched) {
            getProjectsData({ report_id: parseInt(report_id, 10) });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { n_size: responseSent = 1 } = dailyResponses || {};
        if (!dailyResponsesFetched) {
            if (!isNonProfit) {
                const { filters, ohid } = apiParams;
                getDailyResponses({ filters, ohid, lang: parseInt(lang, 10), ind_global: rr_survey_type });
            }
        }
        setSentSurvey(responseSent);
        // eslint-disable-next-line
    }, [dailyResponsesFetched]);

    useEffect(() => {
        if (!isLastMenuPage && !isDisabled) {
            setIsDisabled(true);
        }
        // eslint-disable-next-line
    }, [isLastMenuPage]);

    useEffect(() => {
        const { benchmarks, filters, ohid } = apiParams;
        const benchmarksUpdated = !isEqual(benchmarks, oldBench);
        const filtersUpdated = !isEqual(filters, oldFilters);
        if (tooglePdfReport) {
            if (!inclusionDataFetched && (filtersUpdated || !Object.keys(inclusionData).length)) {
                getInclusionData({
                    ohid,
                    filters,
                    inclusion_threshold,
                    with_benchmark: true,
                    benchmarks: INCLUSION_DEFAULT_BENCHMARK_STR,
                });
                getAllGapData({
                    ohid: currentOhid,
                    filters,
                    lang: parseInt(lang, 10),
                    inclusion_threshold,
                    exclude_from_gap: '',
                });
            }
        } else if (!scoreDataFetched && !apiLoadingCount && (benchmarksUpdated || filtersUpdated)) {
            getScoreData(apiParams);
        }
        if (isInclusionResurvey) {
            getOhid();
        }
        // eslint-disable-next-line
    }, [scoreDataFetched, inclusionDataFetched, apiParams, tooglePdfReport]);

    const currentActiveMenu = index => {
        if (currentMenu > 1 && [1, 3, 4].includes(sideMenu)) {
            return currentMenu - 1 === index;
        }
        return currentMenu === index;
    };

    const reportSurveyResurvey = () => {
        return (
            <section className="reportSurvey">
                <div>
                    <h3>
                        <div>{CURRENT_SURVEY}</div>
                        <div>{`(${currentOhid})`}</div>
                    </h3>
                </div>
                <div>
                    <div>
                        <input
                            className={c({ activeInput: !isDisabled })}
                            onChange={({ target: { value } }) => updateRespondents(parseInt(value || 1, 10))}
                            disabled={isDisabled}
                            type="number"
                            value={surveySent}
                        />
                        <span>
                            {isDisabled ? (
                                <img onClick={() => setIsDisabled(false)} src={Edit} alt="" />
                            ) : (
                                <img onClick={() => setIsDisabled(true)} src={CircleCheck} alt="" />
                            )}
                        </span>
                    </div>
                    <div>{SURVEY_SENT}</div>
                </div>
                {ohidArr[1] && (
                    <Fragment>
                        <div>
                            <h3>
                                <div>{PREVIOUS_SURVEY}</div>
                                <div>{`(${ohidArr[1]})`}</div>
                            </h3>
                        </div>
                        <div>
                            <div>
                                <input
                                    className={c({ activeInput: !isDisabled })}
                                    onChange={({ target: { value } }) =>
                                        updatePreviousRespondent(parseInt(value || 1, 10))
                                    }
                                    disabled={isDisabled}
                                    type="number"
                                    value={previousRespondent}
                                />
                                <span>
                                    {isDisabled ? (
                                        <img onClick={() => setIsDisabled(false)} src={Edit} alt="" />
                                    ) : (
                                        <img onClick={() => setIsDisabled(true)} src={CircleCheck} alt="" />
                                    )}
                                </span>
                            </div>
                            <div>{SURVEY_SENT}</div>
                        </div>
                    </Fragment>
                )}
                {ohidArr[2] && (
                    <Fragment>
                        <div>
                            <h3>
                                <div>{PREVIOUS_SURVEY}</div>
                                <div>{`(${ohidArr[2]})`}</div>
                            </h3>
                        </div>
                        <div>
                            <div>
                                <input
                                    className={c({ activeInput: !isDisabled })}
                                    onChange={({ target: { value } }) =>
                                        updateCompPrevRespondent(parseInt(value || 1, 10))
                                    }
                                    disabled={isDisabled}
                                    type="number"
                                    value={previousCompRespondent}
                                />
                                <span>
                                    {isDisabled ? (
                                        <img onClick={() => setIsDisabled(false)} src={Edit} alt="" />
                                    ) : (
                                        <img onClick={() => setIsDisabled(true)} src={CircleCheck} alt="" />
                                    )}
                                </span>
                            </div>
                            <div>{SURVEY_SENT}</div>
                        </div>
                    </Fragment>
                )}
            </section>
        );
    };

    const resetPastRpts = () => {
        const rptRecords = cloneDeep(records);
        rptRecords.forEach(rec => {
            rec.isSelected = false;
        });
        updatedPastRpts({ ...pastReport, records: rptRecords });
    };

    return (
        <Layout showHeader>
            <div className="reportsSection">
                <div className="reportContent clearfix">
                    <div className="upperSection">
                        <div>
                            <h2>{REPORTS}</h2>
                        </div>
                    </div>

                    <div className="upperHeader">
                        <div>
                            <ul className="leftNav clearfix">
                                <li
                                    data-testid={GENERATE_REPORT}
                                    className={c({ activeLink: currentTab === 0 })}
                                    onClick={() => {
                                        setCurrentMenu(0);
                                        setCurrentTab(0);
                                    }}
                                >
                                    {GENERATE_REPORT}
                                </li>
                                <li
                                    data-testid={PAST_REPORT}
                                    className={c({ activeLink: currentTab === 1 })}
                                    onClick={() => {
                                        setCurrentMenu(0);
                                        setSideMenu(1);
                                        setCurrentTab(1);
                                    }}
                                >
                                    {PAST_REPORT}
                                </li>
                            </ul>
                        </div>
                        {currentTab === 1 ? (
                            <div className="rightPastNav clearfix">
                                {pastDwnld && (
                                    <Button
                                        startIcon={
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <path
                                                    d="M5.625 0.375V7.71967L2.51516 4.60984C2.36872 4.46339 2.13128 4.46339 1.98484 4.60984C1.83839 4.75628 1.83839 4.99372 1.98484 5.14016L5.73484 8.89017C5.77079 8.92612 5.81223 8.95325 5.85646 8.97155C5.90067 8.98988 5.94915 9 6 9C6.05085 9 6.09933 8.98988 6.14354 8.97155C6.18777 8.95325 6.22921 8.92612 6.26516 8.89017L10.0152 5.14016C10.1616 4.99372 10.1616 4.75628 10.0152 4.60984C9.86872 4.46339 9.63128 4.46339 9.48483 4.60984L6.375 7.71967V0.375C6.375 0.167893 6.20711 0 6 0C5.79289 0 5.625 0.167893 5.625 0.375Z"
                                                    fill="currentcolor"
                                                />
                                                <path
                                                    d="M0.375 11.625C0.375 11.4179 0.542893 11.25 0.75 11.25H11.25C11.4571 11.25 11.625 11.4179 11.625 11.625C11.625 11.8321 11.4571 12 11.25 12H0.75C0.542893 12 0.375 11.8321 0.375 11.625Z"
                                                    fill="currentcolor"
                                                />
                                            </svg>
                                        }
                                        onClick={() => getMultipleExport()}
                                    >
                                        Download All
                                    </Button>
                                )}
                                <Button
                                    startIcon={
                                        <svg width="15px" height="16px" viewBox="0 0 15 16" version="1.1">
                                            <g
                                                id="Reports"
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                            >
                                                <g transform="translate(-1260.000000, -171.000000)" fill="currentcolor">
                                                    <g
                                                        id="Icons-/-Outline-/-16-/-Actions-/-Refresh"
                                                        transform="translate(1260.500000, 171.500000)"
                                                    >
                                                        <path d="M12.5010681,0 C12.7772105,0 13.0010681,0.223857625 13.0010681,0.5 L13.0010681,0.5 L13.0010681,4.5 C13.0010681,4.77614237 12.7772105,5 12.5010681,5 L12.5010681,5 L8.50106811,5 C8.22492573,5 8.00106811,4.77614237 8.00106811,4.5 C8.00106811,4.22385763 8.22492573,4 8.50106811,4 L8.50106811,4 L11.4751069,4.00004761 C9.75351644,2.07101335 6.9410535,1.43643208 4.52033914,2.53564023 C1.80211368,3.76994347 0.404809083,6.81488783 1.24158454,9.6805572 C2.07836,12.5462266 4.89464463,14.3608029 7.84995483,13.9384337 C10.805265,13.5160645 13.0005114,10.9852466 13.0010681,7.99990677 C13.0011196,7.7237644 13.225019,7.49994852 13.5011613,7.50000001 C13.7773037,7.5000515 14.0010681,7.72395086 14.0010681,8.00009323 C14.0004187,11.4829897 11.4392978,14.4356106 7.99143593,14.9283747 C4.54357403,15.4211387 1.25790863,13.304133 0.281670593,9.96085207 C-0.69456744,6.61757114 0.935621256,3.06513605 4.10688429,1.6251156 C6.83100706,0.388134491 9.98036275,1.0331369 12.0026145,3.10012792 L12.0010681,0.5 C12.0010681,0.223857625 12.2249257,0 12.5010681,0 Z" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    }
                                    className="refreshReport"
                                    onClick={() => getPastReport(report_id)}
                                >
                                    {REFRESH_REPORTS}
                                </Button>
                            </div>
                        ) : null}
                    </div>

                    {currentTab === 0 ? (
                        <div className="sectionWrapper">
                            {sideMenu !== 5 ? (
                                <section className="reportItems">
                                    {NAV_LINKS.filter(
                                        ({
                                            defaultNav,
                                            checkAccess = false,
                                            checkIngClient = false,
                                            checkv4 = false,
                                        }) => {
                                            if (sideMenu === 3 || sideMenu === 4) {
                                                return (
                                                    [0, sideMenu].includes(defaultNav) &&
                                                    (sideMenu === 4 ||
                                                        !checkAccess ||
                                                        (full_inclusion_access && !isInclusionResurvey))
                                                );
                                            }
                                            if (isv4) {
                                                if (
                                                    ((isIngClient || isAmmegaRpt || isThomsonRpt) && checkIngClient) ||
                                                    (!isv4 && !checkv4)
                                                )
                                                    return null;
                                                return [0, 10, sideMenu].includes(defaultNav);
                                            }
                                            if (
                                                ((isIngClient || isAmmegaRpt || isThomsonRpt) && checkIngClient) ||
                                                (isv4 && checkv4)
                                            )
                                                return null;
                                            return [0, 10, sideMenu].includes(defaultNav);
                                        }
                                    ).map(({ imgSrc, imgSrcActive, mainIndex }, index) => {
                                        const text = REPORT_NAV_LINKS[mainIndex];
                                        const v4Text = REPORT_NAV_LINKS_V4[mainIndex];
                                        return (
                                            <div key={`${text}${index}`}>
                                                <span
                                                    className={c({
                                                        activeMenu: currentActiveMenu(index),
                                                    })}
                                                >
                                                    <img
                                                        className="reportIcon"
                                                        src={currentActiveMenu(index) ? imgSrcActive : imgSrc}
                                                        alt=""
                                                    />
                                                </span>
                                                {isv4 ? (
                                                    <div className="sideHeading">{v4Text}</div>
                                                ) : (
                                                    <div className="sideHeading">{text}</div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </section>
                            ) : (
                                <section className="reportItems">
                                    {NAV_LINKS.filter(({ mainIndex }) => mainIndex < 2).map(
                                        ({ imgSrc, imgSrcActive, mainIndex }, index) => {
                                            const text = REPORT_NAV_LINKS[mainIndex];
                                            return (
                                                <div key={`${text}${index}`}>
                                                    <span
                                                        className={c({
                                                            activeMenu: currentActiveMenu(index),
                                                        })}
                                                    >
                                                        <img
                                                            className="reportIcon"
                                                            src={currentActiveMenu(index) ? imgSrcActive : imgSrc}
                                                            alt=""
                                                        />
                                                    </span>
                                                    <div className="sideHeading">{text}</div>
                                                </div>
                                            );
                                        }
                                    )}
                                </section>
                            )}
                            <section className="reportSection">
                                <ReportContent
                                    surveySent={surveySent}
                                    previousSurveySent={previousRespondent}
                                    surveyCompleted={tooglePdfReport ? Number(inclusionSize) : Number(size)}
                                    sideMenu={sideMenu}
                                    setSideMenu={setSideMenu}
                                    currentMenu={currentMenu}
                                    setCurrentMenu={setCurrentMenu}
                                    setTooglePdfReport={setTooglePdfReport}
                                    comparisonPrevSurveySent={previousCompRespondent}
                                    setLastMenu={setLastMenu}
                                />
                            </section>
                            {isInclusionResurvey ? (
                                reportSurveyResurvey()
                            ) : (
                                <section className="reportSurvey">
                                    <div>
                                        <div>
                                            <input
                                                className={c({ activeInput: !isDisabled })}
                                                onChange={({ target: { value } }) => {
                                                    setSentSurvey(parseInt(value || '', 10));
                                                    updateRespondents(parseInt(value || 1, 10));
                                                }}
                                                disabled={isDisabled}
                                                type="number"
                                                value={surveySent}
                                            />
                                            <span>
                                                {isDisabled ? (
                                                    <Fragment>
                                                        {isLastMenuPage ? (
                                                            <img
                                                                onClick={() => setIsDisabled(false)}
                                                                src={Edit}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <Tooltip title="You can edit surveys sent in the last screen after applying all the filters">
                                                                <Info />
                                                            </Tooltip>
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <img onClick={() => setIsDisabled(true)} src={CircleCheck} alt="" />
                                                )}
                                            </span>
                                        </div>
                                        <div>{SURVEY_SENT}</div>
                                    </div>
                                    <div>
                                        <div>{tooglePdfReport ? inclusionSize : size}</div>
                                        <div>{SURVEY_COMPLETED}</div>
                                    </div>
                                    <div>
                                        <div>{percentage || 0}%</div>
                                        <div>{sideMenu === 3 ? INCLUSION_COMPLETED : COMPLETED}</div>
                                    </div>
                                </section>
                            )}
                        </div>
                    ) : null}
                    {currentTab === 1 ? (
                        <PastReports
                            setPastDwnld={setPastDwnld}
                            pastReport={pastReport}
                            resetPastRpts={resetPastRpts}
                        />
                    ) : null}
                </div>
            </div>
            {apiLoadingCount || reportApiCount || resRateApiCount ? (
                <Loader position="absolute" showBackground background="transparent" />
            ) : null}
        </Layout>
    );
}

Reports.propTypes = {
    reportApiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    reportApiCount: PropTypes.number.isRequired,
    respondent: PropTypes.number.isRequired,
    previousRespondent: PropTypes.number.isRequired,
    inclusionData: PropTypes.object.isRequired,
    scoreData: PropTypes.object.isRequired,
    getScoreData: PropTypes.func.isRequired,
    apiParams: PropTypes.object.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    inclusionDataFetched: PropTypes.bool.isRequired,
    getProjectsData: PropTypes.func.isRequired,
    getPastReport: PropTypes.func.isRequired,
    currentTab: PropTypes.string.isRequired,
    setCurrentTab: PropTypes.func.isRequired,
    updateRespondents: PropTypes.func.isRequired,
    updatePreviousRespondent: PropTypes.func.isRequired,
    getInclusionData: PropTypes.func.isRequired,
    getAllGapData: PropTypes.func.isRequired,
    dailyResponses: PropTypes.object.isRequired,
    dailyResponsesFetched: PropTypes.bool.isRequired,
    getDailyResponses: PropTypes.func.isRequired,
    resRateApiCount: PropTypes.number.isRequired,
    pastReport: PropTypes.object.isRequired,
    getMultiPptData: PropTypes.func.isRequired,
    updatedPastRpts: PropTypes.func.isRequired,
    updateCompPrevRespondent: PropTypes.func.isRequired,
    previousCompRespondent: PropTypes.number.isRequired,
};

export default Reports;
